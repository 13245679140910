import React, { useState, useEffect } from 'react';

// Externals
import 'moment/locale/es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { compose } from 'recompose'


// Material helpers
import {
  withStyles,
  withWidth,
  Link
} from '@material-ui/core';

// Material components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';

// Shared components
import { Portlet, PortletContent } from 'components';

//Redux
import { connect } from 'react-redux'

// Component styles
import styles from './styles';


function ServicesList(props) {
  const { generalParameters, currentPort, classes, setService, width } = props;

  const [services, setServices] = useState([]),
    { t } = useTranslation();


  useEffect(() => {
    if (generalParameters && generalParameters.services) {
      if (currentPort && currentPort.code) {
        setServices(generalParameters.services.filter(s => s.portCode === currentPort.code))
      } else {
        setServices(generalParameters.services)
      }
    }
  }, [setServices, generalParameters, generalParameters.services, currentPort])


  return (
    <>
      {(width !== 'xs' && !isMobile) ? (
        <Portlet>
          <PortletContent noPadding>
            <PerfectScrollbar>
              <Table>
                <TableHead>
                  <TableRow className={classes.recalada__header}>
                    <TableCell
                      align="left"
                      className={classes.tableCell__header}
                      style={{ paddingLeft: '24px' }}>
                      {t('Name')}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell__header}>
                      {t('Liner')}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell__header}>
                      {t('Template')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {services
                    .map((service, i) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={i}
                        onClick={() => setService(service)}
                      >
                        <TableCell className={classes.tableCell}>
                          <div className={classes.tableCellInner}>
                            <Link to="#">
                              <Typography
                                className={classes.nameText}
                                variant="body1"
                              >
                                {service.name}
                              </Typography>

                            </Link>
                          </div>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          <Typography
                            variant="body1"
                          >
                            {service.liner.name}
                          </Typography>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          <Typography
                            variant="body1"
                          >
                            {service.template.name}
                          </Typography>
                        </TableCell>

                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </PortletContent>
        </Portlet>
      ) : (
          <List
            classes={{ root: classes.list__paper }}>
            {services
              .map((service, i) => (
                <React.Fragment key={i}>
                  <ListItem key={i}
                    onClick={() => setService(service)}
                    className={classes.list__item__card}
                  >
                    <Typography
                      variant="body1"
                    >
                      <strong>{service.name}</strong>
                    </Typography>
                    <Typography
                      variant='body2'
                      color='textSecondary'>
                      {service.liner.name}
                    </Typography>
                    <br />
                    <Typography
                      variant='body2'
                      color='textSecondary'>
                      {service.template.name}
                    </Typography>
                  </ListItem>
                  <Divider style={{ width: '100%' }} />
                </React.Fragment>
              ))}
          </List>
        )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    generalParameters: state.generalParameters,
    currentPort: state.currentPort
  }
}

export default compose(
  connect(mapStateToProps, null),
  withStyles(styles),
  withWidth())(ServicesList);
