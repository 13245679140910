import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    CircularProgress,
    Button,
    Link,
    withStyles
} from '@material-ui/core/';

import {
    Warning
} from '@material-ui/icons'


const styles = theme => ({
    buttonSpinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px'
    },
    button__wrapper: {
        margin: theme.spacing(),
        position: 'relative'
    },
    cancelButton: {
        width: '110px',
        cursor: 'pointer',
        fontSize: '12px'
    },
    warning__message__container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: theme.spacing(),
        whiteSpace: 'pre-line'
    }
})


class ConfirmationDialog extends React.Component {

    render() {
        const {
            message,
            confirmLabel,
            cancelLabel,
            open,
            loading,
            title,
            classes } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {title &&
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                }
                <DialogContent>

                    <DialogContentText style={{display: 'flex', alignItems: 'center', whiteSpace: 'pre', textAlign: 'center'}}>
                        <Warning style={{color: 'orange', marginRight: '8px'}}/> 
                        {message}
                    </DialogContentText>

                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Link
                        className={classes.cancelButton}
                        size='small'
                        variant="button"
                        color="inherit"
                        style={{ textAlign: 'center' }}
                        onClick={this.props.handleClose}>
                        {cancelLabel}
                    </Link>
                    <div className={classes.button__wrapper}>
                        <Button
                            // style={{ width: '110px' }}
                            disabled={loading}
                            color="primary"
                            size='small'
                            variant="contained"
                            onClick={this.props.onConfirm}>
                            {confirmLabel}
                        </Button>
                        {loading && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ConfirmationDialog);