import React, { useState, useEffect } from 'react'
import {
    Typography,
    CircularProgress,
    Button,
    IconButton,
    withStyles
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateTaskTemplateParentTask } from 'actions/taskTemplateActions'
import { Tasks } from 'services'
import { CustomSelect } from 'components/Common'

import styles from './styles'

const ParentTask = props => {
    const { classes, taskTemplate } = props,
        [saving, setSaving] = useState(false),
        [selectedPreviousTask, setSelectedPreviousTask] = useState(taskTemplate && taskTemplate.parentTaskTemplateId ? taskTemplate.parentTaskTemplateId : 0),
        [showActions, setShowActions] = useState(false),
        generalParameters = useSelector(state => state.generalParameters),
        serviceTemplate = taskTemplate ? generalParameters.servicesTemplates.find(st => st.serviceTemplateId === taskTemplate.serviceTemplateId) : null,
        { t } = useTranslation(),
        dispatch = useDispatch();

    useEffect(() => {
        let isSuscribed = true;
        if (taskTemplate && taskTemplate.parentTaskTemplateId && isSuscribed) {
            setSelectedPreviousTask(taskTemplate.parentTaskTemplateId)
        }

        return () => {
            isSuscribed = false;
        };
    }, [taskTemplate])


    if (!serviceTemplate) {
        return null;
    }

    let validParentsTemplates = serviceTemplate.taskTemplates.filter(t => t.id !== taskTemplate.id && t.dueHours <= taskTemplate.dueHours)

    if (validParentsTemplates.length <= 0) {
        return null
    }

    const handleChangeSelectedPreviousTask = (e) => {
        if (e.target.value) {
            if (e.target.value !== taskTemplate.parentTaskTemplateId) {
                setShowActions(true);
            } else {
                setShowActions(false);
            }
            setSelectedPreviousTask(e.target.value)
        } else {
            if (taskTemplate.parentTaskTemplateId) {
                setShowActions(true);
            } else {
                setShowActions(false);
            }

            setSelectedPreviousTask(0)
        }
    }

    const handleCancel = () => {
        setShowActions(false);
        setSelectedPreviousTask(taskTemplate.parentTaskTemplateId ? taskTemplate.parentTaskTemplateId : 0)
    }

    const handleSave = () => {
        setSaving(true)

        Tasks.updateTaskTemplateParentTask({
            taskTemplateId: taskTemplate.id,
            parentTaskTemplateId: selectedPreviousTask || null
        }).then(response => {
            if (response.success) {
                dispatch(updateTaskTemplateParentTask({
                    ...response.record,
                    serviceTemplateId: taskTemplate.serviceTemplateId
                }))
            }
            setSaving(false)
            setShowActions(false);
        })
    }

    return (
        <div>
            <Typography variant='h6'>{t('Tarea Previa')}</Typography>
            <div className={classes.parent__task__container}>
                <div style={{ minWidth: '200px', maxWidth: '100%', width: '50%' }}>
                    <CustomSelect options={validParentsTemplates ?
                        [{
                            label: t('Ninguna'),
                            value: 0
                        },
                        ...validParentsTemplates.map((e) => ({
                            "label": e.name,
                            "value": e.id
                        }))] : []}
                        placeholder={t('Ninguna')}
                        label={''}
                        value={selectedPreviousTask}
                        fullWidth={true}
                        inputProps={{
                            className: classes.select__root
                        }}
                        required={true}
                        disabled={saving}
                        onSelect={(e) => {
                            handleChangeSelectedPreviousTask({
                                target: {
                                    value: e ? e.value : 0
                                }
                            });
                        }}
                    />
                </div>


                {showActions && (
                    <div className={classes.root}>
                        <Button
                            disabled={saving}
                            variant='contained'
                            size='small'
                            color='primary'
                            onClick={handleSave}
                            className={classes.actions__save}>
                            {t('Save')}
                            {saving && (
                                <CircularProgress
                                    color='secondary'
                                    style={{ marginLeft: 5 }}
                                    size={15} />
                            )}
                        </Button>
                        <IconButton size="small"
                            onClick={handleCancel}
                        >
                            <Close />
                        </IconButton>
                    </div>
                )}
            </div>
        </div>
    )
}

export default withStyles(styles)(ParentTask)