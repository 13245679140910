import React, { useState, useEffect } from 'react'
import {
    Typography,
    Button,
    Chip,
    withStyles,
    TextField,
    IconButton
} from '@material-ui/core'
import styles from './styles'
import { Add, Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { Tasks } from 'services'
import { updateTaskTemplateKeywords } from 'actions/taskTemplateActions'
import { useDispatch } from 'react-redux';

const Keywords = props => {
    const { classes, taskTemplate } = props;
    const [showAddSection, setShowAddSection] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [keywords, setKeywords] = useState(taskTemplate && taskTemplate.keywords ? taskTemplate.keywords : null)
    const { t } = useTranslation();
    const dispatch = useDispatch()

    useEffect(() => {
        if (taskTemplate && !keywords && taskTemplate.keywords) {
            setKeywords(taskTemplate.keywords)
        }
    }, [keywords, taskTemplate])

    const handleDelete = (key) => {
        let newKeywords = keywords.filter(k => k !== key);

        setKeywords(keywords.filter(k => k !== key))

        Tasks.updateTaskTemplateKeywords({
            taskTemplateId: taskTemplate.id,
            keywords: newKeywords
        })
            .then(updtateResponse => {
                if (updtateResponse.success) {
                    dispatch(updateTaskTemplateKeywords({
                        serviceTemplateId: taskTemplate.serviceTemplateId,
                        taskTemplateId: taskTemplate.id,
                        keywords: newKeywords
                    }))
                }
            })
    }

    const showAddKeywordSection = () => {
        setShowAddSection(true)
    }
    const handleCancel = () => {
        setShowAddSection(false)
    }


    const handleAddKeyword = () => {
        let index = keywords.findIndex(k => k.trim() === keyword.trim());
        if (index < 0) {
            let newKeywords = [
                ...keywords,
                keyword.trim()
            ].filter((value, index, self) => value !== '' && self.indexOf(value) === index);
            setKeywords(newKeywords)

            Tasks.updateTaskTemplateKeywords({
                taskTemplateId: taskTemplate.id,
                keywords: newKeywords
            })
                .then(updtateResponse => {
                    if (updtateResponse.success) {
                        dispatch(updateTaskTemplateKeywords({
                            serviceTemplateId: taskTemplate.serviceTemplateId,
                            taskTemplateId: taskTemplate.id,
                            keywords: newKeywords
                        }))
                    }
                })
        }
        setKeyword('');
        setShowAddSection(false)
    }
    const handleInputChange = (e) => {
        setKeyword(e.target.value)
    }

    if (!taskTemplate) return null;

    return (
        <div>
            <Typography variant='h6'>{t('Keywords')}</Typography>
            <div className={classes.keywords__container}>
                {keywords && keywords.length > 0 && keywords.map((keyword, i) => (
                    <Chip
                        key={i}
                        label={keyword}
                        value={keyword}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => { handleDelete(keyword) }}
                        className={classes.keyword__chip}
                    />
                ))}
                {!showAddSection && (
                    <div className={classes.add__keyword__actions__container}>
                        {keywords && keywords.length > 0 ? (
                            <IconButton size="small"
                                onClick={showAddKeywordSection}>
                                <Add color='primary' />
                            </IconButton>
                        ) : (
                                <Button size="small"
                                    color='primary'
                                    onClick={showAddKeywordSection}>
                                    <Add color='primary' style={{ fontSize: 20, marginRight: 5 }} />
                                    {t('Add Keywords')}
                                </Button>
                            )}

                    </div>
                )}
                {showAddSection && (
                    <div className={classes.add__keyword__actions__container}>
                        <TextField
                            value={keyword}
                            onChange={handleInputChange}
                            autoFocus
                            className={classes.keywords__textfield}
                        />
                        <Button
                            variant='contained'
                            size='small'
                            color='primary'
                            onClick={handleAddKeyword}
                            className={classes.actions__save}
                        >{t('Agregar')}</Button>
                        <IconButton size="small"
                            onClick={handleCancel}>
                            <Close />
                        </IconButton>
                    </div>
                )}
            </div>
        </div>
    )
}
export default withStyles(styles)(Keywords)