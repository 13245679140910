export default theme => ({
    reminders__root: {
        padding: 0
    },
    reminder__form: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 20,
        border: 'solid thin #c3c3c3',
        borderRadius: 5,
        // width: '60%',
        maxWidth: 450,
        backgroundColor: '#fafafa',
        [theme.breakpoints.down('xs')]: {
            minWidth: 'unset'
        }
    },
    reminder__form__date: {
        display: 'flex',
        alignItems: 'center',
    },
    dueHours__textfield__input: {
        padding: 10,
        width: 80,
    },
    title__textfield__input: {
        padding: 10,
        width: '100%',
    },
    reference__label: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },

    textfield: {
        padding: theme.spacing(),
        marginRight: 15,
        width: 60,
    },
    label: {
        marginRigth: 15,
    },
    select__root: {
        padding: 10,
        marginTop: 1,
        marginRight: 15,
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    actions__save: {
        marginRight: 10
    },
    //Reminder rows:
    reminder__row: {
        display: 'flex',
        alignItems: 'center'
    },
    reminder__row__content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        border: 'solid thin #ccc',
        borderRadius: 5,
        position: 'relative'
    },
    reminders__empty__container: {
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    reminders__empty__container__icon: {
        marginBottom: 10,
        width: 40,
        height: 40,
        color: '#3c3c3c'
    },
    add__reminder__button: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontWeight: 300,
    },
    alert__icon: { fontSize: 16, width: 16, marginRight: 10 }

})
