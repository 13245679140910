import React, { useEffect, useState } from 'react'
import {
    Typography,
    Hidden,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    List,
    ListItem,
    Grid,
    withStyles,
} from '@material-ui/core'
import { CheckCircle, Warning, Schedule } from '@material-ui/icons'
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { taskPastDue, parseHumandFrendlyDueDate, taskDueSoon, taskDate } from 'lib'
import { connect } from 'react-redux'
import moment from 'moment';
import 'moment/locale/es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Portlet, PortletContent } from 'components';
import { useSelector } from 'react-redux'
import styles from './styles'
import { mediumDateFormat } from 'lib'


// const TasksProgress = props => {
//     // const { t } = useTranslation();
//     const { progressBarTasks, recalada, classes } = props;
//     let completedTasksCounter = progressBarTasks.filter(task => task.complete).length,
//         dueTasksCounter = progressBarTasks.filter(task => !task.complete && taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId)).length,
//         completedPercentage = progressBarTasks ? ((completedTasksCounter * 100) / progressBarTasks.length) : 0,
//         dueTaskPercentage = (dueTasksCounter / progressBarTasks.length) * 100;


//     return <>
//         {
//             progressBarTasks.length > 0 && (completedTasksCounter > 0 || dueTasksCounter > 0) &&
//             <Grid container style={{ margin: '16px 0', position: 'relative' }} alignItems='center'>
//                 {/* <Grid item xs={12} sm={2} className={classes.progress__bar__leyend} style={{ flexDirection: 'column' }}>
//                     {completedTasksCounter > 0 && (
//                         <Typography variant='body1'>
//                             {`${completedTasksCounter} (${parseInt(completedPercentage)}%) ${t('Completed')}`}
//                         </Typography>
//                     )}
//                     {dueTasksCounter > 0 && (
//                         <Typography variant='body1'>
//                             {`${dueTasksCounter} (${parseInt(dueTaskPercentage)}%) ${t('Past Due')}`}
//                         </Typography>
//                     )}
//                     {(completedTasksCounter > 0 || dueTasksCounter > 0) && (
//                         <Typography variant='body1'>
//                             {`${progressBarTasks.length} ${t('Total')}`}
//                         </Typography>
//                     )}
//                 </Grid> */}
//                 <Grid item xs={12} >
//                     <div className={classes.progress__bar__root} style={{
//                         height: completedPercentage === dueTaskPercentage ? 12 : 10
//                     }}>
//                         <div className={classes.progress__bar__completed} style={{
//                             width: `${completedPercentage}%`,
//                             zIndex: completedPercentage <= dueTaskPercentage ? 1 : 0,
//                         }} />
//                         <div className={classes.progress__bar__due} style={{
//                             width: `${dueTaskPercentage}%`,
//                             zIndex: completedPercentage <= dueTaskPercentage ? 0 : 1,
//                             height: completedPercentage === dueTaskPercentage ? 12 : 10
//                         }} />
//                     </div>
//                 </Grid>
//             </Grid>
//         }
//     </>
// }

const TasksTable = props => {
    const { tasks, classes, ship, recalada, filterType, selectedRecaladaIdFilter, history, currentPort } = props, //entities, currentPort 
        // currentEntities = entities && currentPort ? entities[currentPort.code] : null,
        usersFilter = useSelector(state => state.usersFilter),
        [filteredTasks, setFilteredTasks] = useState([]),
        // [progressBarTasks, setProgressBarTasks] = useState([]),
        { i18n, t } = useTranslation();


    useEffect(() => {
        if (tasks) {
            let filteredUsersIds = usersFilter.map(u => u.id),
                taskByType = tasks.filter(task => filterType ?
                    (filterType === 1 ? !task.complete && (taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReference) || taskDueSoon(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReference))
                        : task.complete) : true),
                // taskToProgressBar = tasks.filter(task => task.responsiblesUsers.length === 0 ? true : (task.responsiblesUsers.filter(us => filteredUsersIds.indexOf(us.id ? us.id : us.userId) >= 0).length > 0 ? true : false)),
                taskByUsers = taskByType.filter(task => (task.responsiblesUsers.length === 0 ? true : (task.responsiblesUsers.filter(us => filteredUsersIds.indexOf(us.id ? us.id : us.userId) >= 0).length > 0 ? true : false)));
            if (selectedRecaladaIdFilter) {
                taskByUsers = taskByUsers.filter(t => t.recalada.recaladaId === selectedRecaladaIdFilter);
            }

            setFilteredTasks(taskByUsers)
            // setProgressBarTasks(taskToProgressBar)
        }
    }, [tasks, filterType, usersFilter, selectedRecaladaIdFilter])

    if (!tasks) return null;

    const handleSelectTask = task => {
        props.onSelect(task);
    }

    const handleSelectShip = task => {
        props.onShipSelect && props.onShipSelect(task);
    }

    const renderDueDate = task => {
        let dueDate = parseHumandFrendlyDueDate(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId, true);
        if (dueDate.day && dueDate.time) {
            return `${dueDate.day.replace(/^\w/, c => c.toUpperCase())} ${dueDate.time}`
        } else {
            return `${dueDate.line1} ${dueDate.line2}`
        }

    }

    return (
        <div className={classes.root}>

            {/* <TasksProgress progressBarTasks={progressBarTasks} recalada={recalada} classes={classes} /> */}

            {filteredTasks.length === 0 &&
                <div className={classes.noTasks__container}>
                    <Typography variant="h6">
                        {t('There are no tasks')}
                        {`${usersFilter.length > 2 ? ` ${t('for the {{usersLength}} selected users', { usersLength: usersFilter.length })} ` :
                            usersFilter.length === 2 ? ` ${t('for {{user1}} and {{user2}}', { user1: usersFilter[0].firstName, user2: usersFilter[1].firstName })}` :
                                ` ${t('for {{user1}}', { user1: usersFilter[0].firstName })}`}`}
                        {` ${t('today')} `}
                    </Typography>
                </div>
            }

            {filteredTasks.length > 0 && (
                <>
                    <Hidden xsDown implementation="css">
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                <PerfectScrollbar>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow className={classes.table__header}>
                                                <TableCell
                                                    width={40}
                                                    align="center"
                                                    className={classes.tableCell__header}
                                                    style={{ paddingLeft: '24px' }}>
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={classes.tableCell__header}
                                                    style={{ paddingLeft: '24px' }}>
                                                    {t('Task')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell__header}
                                                    align="center">
                                                    {t('Status')}
                                                </TableCell>

                                                {usersFilter && usersFilter.length > 1 &&
                                                    <TableCell
                                                        width={70}
                                                        className={classes.tableCell__header}
                                                        align="center">
                                                        {t('Responsible')}
                                                    </TableCell>
                                                }

                                                {!ship &&
                                                    <TableCell
                                                        align="right"
                                                        className={classes.tableCell__header}>
                                                        {t('Recalada')}
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredTasks.map((task, i) => {
                                                return (
                                                    <TableRow
                                                        className={`${classes.tableRow} ${task.complete ? classes['taskRow--completed'] : ''}`}
                                                        hover
                                                        key={task.taskId}
                                                    >
                                                        <TableCell
                                                            className={classes.tableCell}
                                                            style={{ maxWidth: 40 }}>
                                                            <strong>{task.code ? task.code : ''}</strong>
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={() => { handleSelectTask(task) }}
                                                            className={classes.tableCell}
                                                            style={{ maxWidth: 300, cursor: 'pointer' }}>
                                                            <div className={classes.tableCellInner}>
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                    color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                                    style={{
                                                                        fontWeight: !task.complete ? 'bold' : 'normal',
                                                                        width: '100%', whiteSpace: 'pre-wrap',
                                                                        textDecoration: 'underline',
                                                                    }}
                                                                >
                                                                    {task.name}
                                                                </Typography>
                                                                <Typography
                                                                    className={classes.typeText}
                                                                    variant="body2"
                                                                    color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                                >
                                                                    {task.taskType ? task.taskType.name : task.taskTypeName}
                                                                </Typography>

                                                            </div>
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell} align='center' style={{ color: '#999' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                                {task.complete ?
                                                                    <CheckCircle className={classes.successIcon} style={{ marginRight: '4px' }} />
                                                                    : <>
                                                                        {taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) ?
                                                                            <Warning style={{ marginRight: '8px' }} className={classes.warningIcon} />
                                                                            :
                                                                            <Schedule style={{
                                                                                marginRight: '8px',
                                                                                color: taskDueSoon(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) ? 'orange' : '#66788A'
                                                                            }}
                                                                            />
                                                                        }
                                                                    </>
                                                                }
                                                                {task.currentStatus &&
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <Typography variant='body1' color={task.complete ? 'textSecondary' : 'textPrimary'}>
                                                                            {task.currentStatus.title}
                                                                        </Typography>

                                                                        <Typography variant='body1' color={task.complete ? 'textSecondary' : 'textPrimary'}>
                                                                            {moment(task.currentStatus.timestamp).format('MMMM DD hh:mm a').replace(/^\w/, c => c.toUpperCase())}
                                                                        </Typography>
                                                                    </div>
                                                                }

                                                            </div>

                                                            {task.complete === 0 && taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) && task.parentTask && task.parentTaskComplete === 0 &&
                                                                <Typography
                                                                    color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                                    style={{ whiteSpace: 'pre-line' }}>
                                                                    {t('Waiting requirements')}
                                                                </Typography>
                                                            }

                                                            {!task.currentStatus &&
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <Typography variant="body2"
                                                                        style={{ textTransform: 'capitalize', textAlign: 'center' }}
                                                                        className={task.complete ? classes.complete__task : taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) ? classes.due__task : ''}>
                                                                        {renderDueDate(task)}
                                                                    </Typography>
                                                                </div>
                                                            }

                                                        </TableCell>

                                                        {usersFilter && usersFilter.length > 1 && task.responsiblesUsers &&
                                                            <TableCell
                                                                onClick={() => { handleSelectTask(task) }}
                                                                className={classes.tableCell}
                                                                style={{ maxWidth: 40, cursor: 'pointer', textAlign: 'center' }}>
                                                                <Typography
                                                                    color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                                    style={{ whiteSpace: 'pre-line' }}>

                                                                    {task.responsiblesUsers.map(user => (`${user.firstName} ${user.lastName}`)).join(', ')}

                                                                </Typography>
                                                            </TableCell>
                                                        }

                                                        {!ship &&
                                                            <TableCell
                                                                onClick={() => {
                                                                    history.push(`/${i18n.language}/${currentPort.code}/recaladas/${task.recalada.recaladaId}`)
                                                                }}//() => { handleSelectShip(task) }}
                                                                className={classes.tableCell}
                                                                style={{ padding: '10px 0', cursor: ship ? 'default' : 'pointer' }}>
                                                                <div className={classes.tableCellInner} style={{ alignItems: 'flex-end', paddingRight: '16px' }}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        style={ship ? {} : { textDecoration: 'underline' }}
                                                                        color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                                    >
                                                                        {task.ship.name} V-{task.itinerary.name}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                                    >
                                                                        {/* {moment(task.recalada ? task.recalada.ETA : recalada.ETA).format('dddd DD MMMM').toUpperCase()} */}
                                                                        {mediumDateFormat(task.recalada ? task.recalada.ETA : recalada.ETA)}
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>
                                                        }
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </PerfectScrollbar>
                            </PortletContent>
                        </Portlet>
                    </Hidden>
                    <Hidden smUp implementation="css">
                        <List component="div"
                            classes={{
                                root: classes.tasks__list__root
                            }}>
                            {filteredTasks.map((task, i) => (
                                <ListItem
                                    className={`${classes.nested} ${task.complete ? classes['taskRow--completed'] : ''}`}
                                    key={i}
                                    style={{ padding: 8 }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                onClick={() => { handleSelectTask(task) }}
                                                variant='body1'
                                                color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                style={{ fontWeight: !task.complete ? 'bold' : 'normal', marginBottom: 10, cursor: 'pointer', textDecoration: 'underline' }}>
                                                {`${task.code ? task.code : ''} ${task.name}`}
                                            </Typography>
                                            <Typography
                                                // className={classes.typeText}
                                                variant="body2"
                                                color={task.complete ? 'textSecondary' : 'textPrimary'}
                                            >
                                                {`${task.taskType ? task.taskType.name : task.taskTypeName} ${usersFilter && usersFilter.length > 1 && task.responsiblesUsers ? ` - ${task.responsiblesUsers.map(user => (`${user.firstName} ${user.lastName}`)).join(', ')}` : ''}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {task.currentStatus &&
                                                <Typography variant='body1' color={task.complete ? 'textSecondary' : 'textPrimary'}>
                                                    {task.currentStatus.title}
                                                </Typography>
                                            }

                                            {task.complete === 0 && taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) && task.parentTask && task.parentTask.id &&
                                                <Typography
                                                    color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                    style={{ whiteSpace: 'pre-line' }}>
                                                    {t('Waiting requirements')}
                                                </Typography>
                                            }

                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                {task.complete ?
                                                    <CheckCircle className={classes.successIcon} style={{ marginRight: '4px' }} />
                                                    : <>
                                                        {taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) ?
                                                            <Warning style={{ marginRight: '8px' }} className={classes.warningIcon} />
                                                            :
                                                            <Schedule style={{ marginRight: '8px' }} />
                                                        }
                                                    </>
                                                }

                                                {task.currentStatus ?
                                                    <Typography variant='body1' color={task.complete ? 'textSecondary' : 'textPrimary'}>
                                                        {moment(task.currentStatus.timestamp).format('MMMM DD hh:mm a').replace(/^\w/, c => c.toUpperCase())}
                                                    </Typography>
                                                    :
                                                    <Typography variant="body2"
                                                        style={{ textTransform: 'capitalize', textAlign: 'left' }}
                                                        className={task.complete ? classes.complete__task : taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) ? classes.due__task : ''}>
                                                        {renderDueDate(task)}
                                                    </Typography>
                                                }
                                            </div>
                                        </Grid>
                                        {!ship &&
                                            <Grid item xs={12} style={{ marginTop: '16px' }}>
                                                <Typography
                                                    variant="body1"
                                                    color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                    style={{ marginBottom: 10, cursor: 'pointer', textDecoration: ship ? 'none' : 'underline' }}
                                                    onClick={() => {
                                                        history.push(`/${i18n.language}/${currentPort.code}/recaladas/${task.recalada.recaladaId}`)
                                                    }}
                                                //onClick={() => { handleSelectShip(task) }}
                                                >
                                                    {task.ship.name} V-{task.itinerary.name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                >
                                                    {/* {moment(task.recalada.ETA).format('dddd DD MMMM').toUpperCase()} */}
                                                    ETA {mediumDateFormat(task.recalada.ETA)}
                                                </Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                </ListItem>
                            ))}
                        </List>
                    </Hidden>
                </>
            )
            }
        </div >
    )
}

function mapStateToProps(state) {
    return {
        entities: state.entities,
        currentPort: state.currentPort
    }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(TasksTable)))
