import React, { useState } from 'react';

// Externals
import classNames from 'classnames';
import {
	Typography,
	TextField,
	MenuItem,
	Grid,
	IconButton
} from '@material-ui/core';
import { Close } from '@material-ui/icons'
import moment from 'moment'
// Material helpers
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { isMobile } from 'react-device-detect'


// Shared components
// import { SearchInput } from 'components';

// Component styles
import styles from './styles';

// import CalendarDateSelector from './CalendarDateSelector'

import { UsersFilterSelector } from 'components'
import RecaladaTaskTypeFilter from 'components/RecaladaTaskTypeFilter';


const TasksToolbar = props => {
	const { classes, className, t, selectedShip, clearShip } = props;
	const rootClassName = classNames(classes.root, className);
	const [selectedType, setSelectedType] = useState(0)

	const taskStatusOptions = [
		{ label: t('All'), value: 0 },
		{ label: t('Pending'), value: 1 },
		{ label: t('Done'), value: 2 },
	]
	const handleChangeType = e => {
		setSelectedType(e.target.value)
		props.handleFilterByStatus && props.handleFilterByStatus(e.target.value);
	}


	const handleTypeFilter = (type) => {
		props.handleTypeFilter(type)
	}

	return (
		<div className={rootClassName}>
			{isMobile ?
				<Grid container spacing={2} className={classes.row}>
					<Grid item xs={12} sm={3}>
						<UsersFilterSelector style={{ marginRight: 10 }} />
						{/* <SearchInput
							className={classes.searchInput}
							placeholder={t("Search task")}
							onChange={props.handleSearch}
						/> */}
					</Grid>
					<Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
						{/* <CalendarDateSelector {...props} style={{ marginRight: 10 }} /> */}
						<RecaladaTaskTypeFilter
							onChange={handleTypeFilter}
						/>
						<TextField
							InputProps={{
								inputProps: {
									className: classes.select__root
								}
							}}
							select
							labe=''
							value={selectedType}
							style={{ marginLeft: 10 }}
							onChange={handleChangeType}
							variant='outlined'
						>
							{taskStatusOptions.map(option => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</Grid>
				:
				<Grid container spacing={2} className={classes.row}>
					<Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
						<UsersFilterSelector style={{ marginRight: 10 }} />
						<RecaladaTaskTypeFilter
							onChange={handleTypeFilter}
						/>
						<TextField
							InputProps={{
								inputProps: {
									className: classes.select__root
								}
							}}
							select
							labe=''
							value={selectedType}
							style={{ marginLeft: 10 }}
							onChange={handleChangeType}
							variant='outlined'
						>
							{taskStatusOptions.map(option => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
						{selectedShip && (
							<div style={{
								marginLeft: 'auto',
								alignSelf: 'flex-end',
								display: 'flex',
								alignItems: 'center'
							}}
							>
								<Typography variant='body1' color='textSecondary'>{selectedShip}</Typography>
								<IconButton
									size='small'
									style={{ marginLeft: 10 }} onClick={clearShip}>
									<Close />
								</IconButton>
							</div>
						)}
					</Grid>
					<Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
						<Typography
							variant='h5'
							color={'textSecondary'}
						>
							{t('Tareas del día')}
						</Typography>
					</Grid>
				</Grid>
			}
		</div >
	);
}

export default compose(withStyles(styles), withTranslation())(TasksToolbar);
