export const SET_GENERAL_PARAMETERS = 'SET_GENERAL_PARAMETERS';
export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';
export const CLEAR_SELECTED_FILTER = 'CLEAR_SELECTED_FILTER';
export const DELETE_TASK_TEMPLATE = 'DELETE_TASK_TEMPLATE'

export function setGeneralParameters(parameters) {
    return {
        type: SET_GENERAL_PARAMETERS,
        parameters
    }
}

export function setSelectedFilter(filter) {
    return {
        type: SET_SELECTED_FILTER,
        filter
    }
}

export function clearSelectedFilter() {
    return {
        type: CLEAR_SELECTED_FILTER
    }
}

export function deleteTaskTemplate(parameters){
    return {
        type: DELETE_TASK_TEMPLATE,
        parameters
    }
}