import React, { useState, useEffect } from 'react'
import {
    MenuItem,
    Button,
    IconButton,
    FormControl,
    Select,
    withStyles,
    Typography,
    CircularProgress,
    Grid
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Tasks } from 'services'

import { useDispatch, useSelector } from 'react-redux'
import { updateTaskTemplateType } from 'actions/taskTemplateActions'


import styles from './styles'

const TaskType = props => {
    const { classes, taskTemplate } = props,
        { t } = useTranslation(),
        [saving, setSaving] = useState(false),
        [showAddTaskSection, setShowAddTaskSection] = useState(false),
        generalParameters = useSelector(state => state.generalParameters),
        currentPort = useSelector(state => state.currentPort),
        taskTypes = generalParameters.taskTypes,
        roles = generalParameters.roles,
        users = generalParameters.users,
        [selectedType, setSelectedType] = useState(taskTemplate && taskTemplate.taskTypeId ? taskTemplate.taskTypeId : 0),
        [selectedUserRole, setSelectedUserRole] = useState(taskTemplate && taskTemplate.roleId ? taskTemplate.roleId : 0),
        dispatch = useDispatch();


    useEffect(() => {
        let isSuscribed = true;
        if (taskTemplate && taskTemplate.taskTypeId && isSuscribed) {
            setSelectedType(taskTemplate.taskTypeId)
        }

        if (taskTemplate && taskTemplate.roleId && isSuscribed) {
            setSelectedUserRole(taskTemplate.roleId)
        }

        return () => {
            isSuscribed = false;
        };
    }, [taskTemplate, taskTypes])

    if (!taskTemplate) return null;


    const handleChangeType = (e) => {
        if (e.target.value !== taskTemplate.taskTypeId) {
            setShowAddTaskSection(true);
        } else {
            setShowAddTaskSection(false);
        }
        setSelectedType(e.target.value)
    }

    const handleChangeRole = (e) => {
        if (e.target.value !== taskTemplate.roleId) {
            setShowAddTaskSection(true);
        } else {
            setShowAddTaskSection(false);
        }
        setSelectedUserRole(e.target.value)
    }


    const handleSaveUserRole = () => {
        setSaving(true)

        Tasks.updateTaskTemplateType({
            taskTemplateId: taskTemplate.id,
            taskTypeId: selectedType,
            roleId: selectedUserRole
        }).then(response => {
            if (response.success) {
                dispatch(updateTaskTemplateType({
                    ...response.record,
                    serviceTemplateId: taskTemplate.serviceTemplateId
                }))
            }
            setSaving(false)
            setShowAddTaskSection(false);
        })
    }
    const handleCancel = () => {
        setShowAddTaskSection(false);
        setSelectedType(taskTemplate.taskTypeId)
        setSelectedUserRole(taskTemplate.roleId)
    }

    let currentUsers = users.filter(user => user.portCode === currentPort.code),
        rolesIds = currentUsers.map(rol => (rol.roleId)),
        currentRoles = roles.filter(rol => rolesIds.includes(rol.roleId))

    return (
        <div>
            <div className={classes.add__user__actions__container}>
                <Grid container spacin={0}>
                    <Grid item xs={12} sm={6}>
                        <div>
                            <Typography variant='h6'>{t('Task Type')}</Typography>
                            <div className={classes.root}>
                                <FormControl variant="outlined" style={{ width: '200px', marginRight: 10 }}>
                                    <Select
                                        disabled={saving}
                                        labelId="task-type-selector"
                                        placeholder={t('Select Type')}
                                        value={selectedType}
                                        onChange={handleChangeType}
                                        variant='outlined'
                                        classes={{
                                            root: classes.select__root
                                        }}
                                        labelWidth={0}
                                    >
                                        {taskTypes.map(type => {
                                            return (
                                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div>
                            <Typography variant='h6'>{t('Responsible')}</Typography>
                            <div className={classes.root}>
                                <FormControl
                                    variant="outlined"
                                    style={{ minWidth: '200px', marginRight: 10 }}
                                >
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        placeholder={t('Select User')}
                                        value={selectedUserRole}
                                        onChange={handleChangeRole}
                                        classes={{
                                            root: classes.select__root
                                        }}
                                        labelWidth={0}>
                                        {currentRoles.map(rol => {
                                            return (
                                                <MenuItem key={rol.roleId} value={rol.roleId}>{rol.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Grid>
                    {showAddTaskSection && (
                        <Grid item xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                            <div style={{ height: '20px' }} />
                            <div className={classes.root}>
                                <Button
                                    disabled={saving}
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    onClick={handleSaveUserRole}
                                    className={classes.actions__save}>
                                    {t('Save')}
                                    {saving && (
                                        <CircularProgress
                                            color='secondary'
                                            style={{ marginLeft: 5 }}
                                            size={15} />
                                    )}
                                </Button>
                                <IconButton size="small"
                                    onClick={handleCancel}>
                                    <Close />
                                </IconButton>
                            </div>
                        </Grid>
                    )}
                </Grid>




            </div>
        </div>
    )
}

export default withStyles(styles)(TaskType)