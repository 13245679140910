import { SET_EMAILS_COUNTERS } from '../actions/emailsActions'

export default function emailsCounters(state = {}, action) {
    switch (action.type) {
        case SET_EMAILS_COUNTERS:
            return {
                ...state,
                ...action.counters
            }
        default:
            return state;
    }
}