import React, { useState, useEffect } from 'react';

// Externals
import 'moment/locale/es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { compose } from 'recompose'


// Material helpers
import {
	withStyles,
	withWidth,
	Link
} from '@material-ui/core';

// Material components
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	List,
	ListItem,
	Divider,
} from '@material-ui/core';

// Shared components
import { Portlet, PortletContent } from 'components';

//Redux
import { connect } from 'react-redux'

// Component styles
import styles from './styles';

function TemplatesList(props) {
	const { classes, currentPort, width, generalParameters } = props, //setSelectedEntity
		entities = generalParameters.entities,
		[currentEntities, setCurrentEntities] = useState([]),
		{ t } = useTranslation();


	useEffect(() => {
		if (entities && currentPort) {
			console.log(entities[currentPort.code])
			setCurrentEntities(entities[currentPort.code])
		}
	}, [setCurrentEntities, entities, currentPort])


	return (
		<>
			{(width !== 'xs' && !isMobile) ? (
				<Portlet>
					<PortletContent noPadding>
						<PerfectScrollbar>
							<Table>
								<TableHead>
									<TableRow className={classes.recalada__header}>
										<TableCell
											align="left"
											className={classes.tableCell__header}>
											{t('Name')}
										</TableCell>
										<TableCell
											align="left"
											className={classes.tableCell__header}>
											{t('Contacts')}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{currentEntities
										.map((entity, i) => (
											<TableRow
												className={classes.tableRow}
												// onClick={() => setSelectedEntity(entity)}
												hover
												key={i}
											>
												<TableCell className={classes.tableCell}>
													<div className={classes.tableCellInner}>
														<Link to="#">
															<Typography
																variant="body1"
															>
																{entity.name}
															</Typography>

														</Link>
													</div>
												</TableCell>
												<TableCell className={classes.tableCell}>
													{entity.contacts && entity.contacts.map((contact) => (<>
														<Typography variant="body1" style={{ display: 'flex', flexDirection: 'column' }}>
															<strong>{contact.name}</strong>
															<span>{contact.url ? contact.url : contact.email ? contact.email : contact.phone}</span>
														</Typography>
														<br />
													</>))}
												</TableCell>

											</TableRow>
										))}
								</TableBody>
							</Table>
						</PerfectScrollbar>
					</PortletContent>
				</Portlet>
			) : (
					<List
						classes={{ root: classes.list__paper }}>
						{currentEntities
							.map((entity, i) => (
								<React.Fragment key={i}>
									<ListItem key={i}
										className={classes.list__item__card}
									// onClick={() => setSelectedEntity(entity)}
									>
										<Typography
											variant="body1"
										>
											<strong>{entity.company}</strong>
										</Typography>
										<br />
										<Typography
											variant="body1"
										>
											{entity.name}
										</Typography>

										{entity.contacts && entity.contacts.map((contact) => (
											<Typography variant="body1" >
												<strong>{contact.name}</strong> {contact.url ? contact.url : contact.email ? contact.email : contact.phone}
											</Typography>
										))}
									</ListItem>
									<Divider style={{ width: '100%' }} />
								</React.Fragment>
							))}
					</List>
				)}
		</>
	);
}

function mapStateToProps(state) {
	return {
		currentPort: state.currentPort,
		generalParameters: state.generalParameters
	}
}

export default compose(
	connect(mapStateToProps, null),
	withStyles(styles),
	withWidth())(TemplatesList);
