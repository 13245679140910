import { red } from '@material-ui/core/colors';

export default theme => ({
    dialog__title__container: {
        color: '#fff',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    dialog__title: {
        backgroundColor: theme.palette.primary.main,
    },
    dialog__container: {
        padding: theme.spacing(2)
    },
    loading__container: {
        height: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    description__container: {
        padding: theme.spacing(2)
    },
    dueReminders__container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center'
        }
    },
    reminders__button: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 16,
            alignSelf: 'flex-end'
        }
    },
    due__task: {
        color: red[400]
    },
    email__actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing()
        }
    },
    email__paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: theme.spacing(3)
    },
    email__sender__container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    email__sender__timestamp: {

    },
    email__sender: {
        fontWeight: 'bold',
        textAlign: 'left'
    },
    email__recipient: {

    },
    email__subject: {
        fontWeight: 'bold',
        textAlign: 'left'
    },
    email__body: {
        textAlign: 'left',
        whiteSpace: 'pre-line'
    },
    attachments__container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    attachment: {
        maxWidth: 150,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer'
    },
    attachments__conponents: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    search__root: {
        margin: '10px 0',
        maxWidth: 400,
        width: '100%',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    search__input: {
        width: '90%'
    },
    file__list__item: {
        cursor: 'pointer',
        border: 'solid thin transparent',
        '&:hover': {
            backgroundColor: '#eeeeee',
            boxShadow: '3px 3px #dddddd',
            border: 'solid thin #dddddd'
        }
    },
    dialog__paper: {
        width: '100%'
    },
    assign__button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary
    },
    actionsButtons: {
        color: theme.palette.text.secondary
    },
    attachments__container: {
        // padding: theme.spacing(),
        display: 'flex',
    },
    attachmentLink__container: {
        marginBottom: 10,
        display: 'flex',
        height: 'fit-content'
    },
    attachment__name__container: {
        marginLeft: 10
    },
    actions: {
        marginTop: theme.spacing(),
        display: 'flex'
    },
    keywords__textfield: {
        padding: theme.spacing(),
        marginRigth: 5
    },
    keyword__chip: {
        marginRight: 10,
        marginBottom: 10,
        marginTop: 10,
    },
    add__keyword__actions__container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 10,
        marginTop: 10,
    },
    link: {
        color: theme.palette.primary.main
    },
    attach__button: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontWeight: 500,
    },
    attach__input__file: {
        display: 'none'
    },
    attachment__item: {
        margin: 10
    },
    attachment__label__container: {
        display: 'flex', alignItems: 'center'
    },
    attachment__remove__button: {
        cursor: 'pointer',
        color: 'red',
        fontSize: '1.2em',
    },
    new_attachments__container: {
        display: 'flex',
        alignItems: 'center'
    },
    upload__button__container: { width: '100%', padding: 10 },
    attach__icon: { fontSize: 16, width: 16, marginRight: 10 },

})