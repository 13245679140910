import React, {useState, useEffect} from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    withStyles,
    Typography,
    IconButton,
    Grid,
    withMobileDialog,
    TextField,
    InputLabel,
    MenuItem,
    Select,
    CircularProgress,
    FormControl,
    Button
} from '@material-ui/core'

import { Close } from '@material-ui/icons'
import styles from './styles';
import compose from 'recompose/compose'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Services } from 'services'
import { createService } from 'actions/serviceActions'


import {
    Name,
    Liner,
    ServiceTemplates
} from './components'

const ServiceDetails = props => {

    const {
        open,
        onClose,
        classes,
        fullScreen
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const service = useSelector(state => state.service)
    const generalParameters = useSelector(state => state.generalParameters)
    const [saving, setSaving] = useState(false)
    const [serviceName, setServiceName] = useState('')
    const [selectedLiner, setSelectedLiner] = useState(0)
    const [liners, setLiners] = useState([])
    const [servicesTemplates, setServicesTemplates] = useState([])
    const [selectedServiceTemplate, setSelectedServiceTemplate] = useState(service && service.template ? service.template.id : 0)

    useEffect(() => {
        if (generalParameters) {
            setLiners(generalParameters.liners)
            setServicesTemplates(generalParameters.servicesTemplates)
        }
    }, [generalParameters, generalParameters.liners, generalParameters.servicesTemplates])

    const handleInputChange = (e) => {
        setServiceName(e.target.value)
    }

    const handleLinerChange = (e) => {
        setSelectedLiner(e.target.value)
    }

    const handleServiceTemplateChange = (e) => {
        setSelectedServiceTemplate(e.target.value)
    }

    const onCloseDialog = () => {
        setServiceName('')
        setSelectedLiner(0)
        setSelectedServiceTemplate(0)
    }

    const handleSaveService=()=>{
        setSaving(true)
        Services.createService({
            name: serviceName,
            linerId: selectedLiner,
            templateId: selectedServiceTemplate
        })
            .then(response => {
                if (response.success) {
                    dispatch(createService(response.record))
                    setSaving(false)
                    onClose()
                }
            })
    }

    return (
        <Dialog
            open={open || false}
            fullScreen={fullScreen}
            onExited={onCloseDialog}
            classes={{
                paper: classes.dialog__paper
            }}
            fullWidth={true}
            maxWidth='sm'>
            <DialogTitle
                className={classes.dialog__title}>
                <div className={classes.dialog__title__container}>
                    <Typography variant='h5' color='inherit'>{service && service.id ? t('Edit Service') : t('Create Service')}</Typography>
                    { service && service.id &&
                        <IconButton
                            color='inherit'
                            onClick={onClose}>
                            <Close />
                        </IconButton>
                    }
                </div>
            </DialogTitle>
            <DialogContent classes={{
                root: classes.dialog__container
            }}>

                {service && service.id ? 

                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{padding: '16px'}}>
                            <Name/>
                        </Grid>
                        <Grid item xs={12} >
                            <Liner/>
                        </Grid>
                        <Grid item xs={12} >
                            <ServiceTemplates/>
                        </Grid>
                    </Grid>
                :
                    <Grid container spacing={4}>
                        <Grid item xs={9}>
                            <TextField
                                required={true}
                                className={classes.textfield}
                                placeholder={t('Escriba un nombre')}
                                value={serviceName || ''}
                                onChange={handleInputChange}
                                autoFocus
                                disabled={saving}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5} >
                            <FormControl variant="outlined" style={{ minWidth: '200px', marginRight: 10 }}>
                                <InputLabel id="operation-type-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}
                                    shrink>
                                    {t('Liner')}
                                </InputLabel>
                                <Select
                                    required={true}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    placeholder={t('Select Liner')}
                                    value={selectedLiner}
                                    onChange={handleLinerChange}
                                    disabled={saving}
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={50}>
                                    {liners.map(liner => {
                                        return (
                                            <MenuItem key={liner.linerId} value={liner.linerId}>{liner.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={7} >
                            <FormControl variant="outlined" style={{ minWidth: '320px', marginRight: 10 }}>
                                <InputLabel id="operation-type-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}
                                    shrink>
                                    {t('Service Template')}
                                </InputLabel>
                                <Select
                                    required={true}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    placeholder={t('Select User')}
                                    value={selectedServiceTemplate}
                                    onChange={handleServiceTemplateChange}
                                    disabled={saving}
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={120}>
                                    {servicesTemplates.map(temp => {
                                        return (
                                            <MenuItem key={temp.serviceTemplateId} value={temp.serviceTemplateId}>{temp.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                }

                {(!service || (service && !service.id)) && 
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>

                        <Button
                            disabled={saving}
                            onClick={onClose}>
                            {t('Cancel')}
                        </Button>

                        <Button
                            disabled={saving || !serviceName || !selectedLiner || !selectedServiceTemplate ? true : false}
                            variant='contained'
                            size='small'
                            color='primary'
                            className={classes.actions__save}
                            onClick={handleSaveService}>
                            {t('Guardar')}
                            {saving && (
                                <CircularProgress color='secondary'
                                    style={{ marginLeft: 5 }}
                                    size={15} />
                            )}
                        </Button>

                        
                    </div>
                }
            </DialogContent>
        </Dialog>
    )
}

export default compose(
    withMobileDialog(),
    withStyles(styles)
)(ServiceDetails)