import React, { useState, useEffect } from 'react'
import {
    TextField,
    InputAdornment,
    IconButton,
    Typography,
    CircularProgress,
    Button,
    makeStyles,
    Link,
    Grid,
} from '@material-ui/core'
import {
    Visibility,
    VisibilityOff
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import styles from './styles';
import auth from '../../authentication/auth';


const useStyles = makeStyles(styles)

export default function RequireNewPassword(props) {
    const { location, history, match } = props,
        url_language = match.params.lang;

    const classes = useStyles(),
        { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false)
    const [user, setUser] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [linkError, setLinkError] = useState(null);
    const [validatingLogin, setValidatingLogin] = useState(false);
    const [activeBackToLogin, setActiveBackToLogin] = useState(false)

    useEffect(() => {
        let isSuscribed = true;
        if (isSuscribed) {
            let params = new URLSearchParams(location.search)

            setValidatingLogin(true)

            if (params.get("email") && params.get('code')) {
                auth.signOut().then(() => {
                    auth.signIn({ email: params.get("email").trim().replace(' ', '+'), password: params.get('code') })
                        .then(user => {
                            setUser(user)

                            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                                setValidatingLogin(false)
                            }
                        })
                        .catch(err => {
                            let newError = ''

                            switch (err.error.code) {
                                case "NotAuthorizedException":
                                    setActiveBackToLogin(true)
                                    newError = t('The link has already been used. If I forget your password, go back to the login and recover it.')
                                    break;
                                case "InvalidParameterException":
                                    newError = t('An error has occurred with your user')
                                    break
                                case "UserNotFoundException":
                                    newError = t('User is not registered. Contact whit admin to create an account')

                                    break
                                default:
                                    break;
                            }
                            setValidatingLogin(false)
                            setLinkError(newError)

                        })
                }).catch(err => console.log(err));
            } else {
                setValidatingLogin(false)
                setLinkError(t('Invalid link'))
            }
        }
        return () => {
            isSuscribed = false;
        };
    }, [location.search, t, history, setUser])


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleInputChange = (event) => {
        event.persist();
        setNewPassword(event.target.value)
    }
    const validatePassword = (password) => {
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
            valid = false;
        if (password && password.match(passw)) {
            valid = true
        }
        return valid
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            saveNewPassword()
        }
    }

    const saveNewPassword = () => {
        setIsLoading(true)
        auth.completeNewPassword({
            user,
            newPassword: newPassword
        })
            .then(user => {
                setIsLoading(false)
                //history.push(`/${url_language}/recaladas`);
                history.push(`/${url_language}/tasks`);
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })
    }

    const backToLogin = () => {
        history.push(`/${url_language}/signIn`);
    }

    return (
        <div className={classes.root}>
            <Grid
                className={classes.grid}
                container
            >
                <Grid
                    className={classes.quoteWrapper}
                    item
                    lg={5}
                >
                    <div className={classes.quote}>
                        <div className={classes.quoteInner}>

                        </div>
                    </div>
                </Grid>
                <Grid
                    className={classes.content}
                    item
                    lg={7}
                    xs={12}
                >
                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            {validatingLogin &&
                                <div style={{ width: '100%', textAlign: 'center', marginTop: '32px' }}>
                                    <CircularProgress color='secondary'
                                        style={{ marginLeft: 5 }}
                                        size={36} />
                                </div>
                            }

                            {!validatingLogin &&
                                <form className={classes.form} autocomplete="off" >
                                    <img
                                        height="90"
                                        style={{ margin: '0 auto' }}
                                        alt="CTSA"
                                        className={classes.logoImage}
                                        src="/images/logos/logoCTSA.jpg"
                                    />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography
                                                className={classes.title}
                                                variant="h4"
                                            >
                                                {t('Enter your password to complete your account.')}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                className={classes.textField}
                                                label={t("Password")}
                                                name="newPassword"
                                                id='newPassword'
                                                onChange={handleInputChange}
                                                value={newPassword}
                                                onKeyDown={onKeyDown}
                                                helperText={newPassword && !validatePassword(newPassword) ? t('Uppercase, lowercase, numbers and be greater than 6 characters') : ''}
                                                type={showPassword ? 'text' : 'password'}
                                                disabled={isLoading ? true : false}
                                                variant="outlined"
                                                error={newPassword && !validatePassword(newPassword) ? true : false}
                                                autoComplete='off'
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputProps: {
                                                        autoCapitalize: 'off',
                                                        autoComplete: 'new-password',
                                                        spellCheck: 'false',
                                                        autoCorrect: "off"
                                                    },
                                                    endAdornment: (
                                                        <InputAdornment position="end" >
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                            {linkError && (
                                                <Typography
                                                    className={classes.submitError}
                                                    variant="body2"
                                                >
                                                    {linkError}
                                                </Typography>
                                            )}

                                            {activeBackToLogin &&
                                                <div className={classes.backToLogin__link__container}>
                                                    <Link
                                                        className={classes.backToLogin__link}
                                                        underline='always'
                                                        variant='button'
                                                        disabled={isLoading ? true : false}
                                                        onClick={backToLogin}>
                                                        {t('Back to sign in')}
                                                    </Link>
                                                </div>
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            {isLoading ? (
                                                <CircularProgress className={classes.progress} />
                                            ) : (
                                                    <>
                                                        <Button
                                                            className={classes.signInButton}
                                                            color="primary"
                                                            disabled={!newPassword || linkError || (newPassword && !validatePassword(newPassword))}
                                                            onClick={saveNewPassword}
                                                            size="large"
                                                            variant="contained"
                                                        >
                                                            {t('Continue')}
                                                        </Button>
                                                    </>
                                                )}

                                        </Grid>
                                    </Grid>
                                </form>
                            }
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
