export default theme => ({
    root: {

    },
    description__container: {
        // margin: `${theme.spacing(2)}px 0`,
    },
    actions: {
        marginTop: theme.spacing(),
        display: 'flex'
    },
    description__textfield: {
        width: '100%',
        backgroundColor: '#fff',
        padding: theme.spacing()
    },
    actions__save: {
        marginRight: 10
    },
})
