import React from 'react'
import {
    Paper,
    Card,
    Typography,
    Divider,
    Grid,
    makeStyles,
    // Link
} from '@material-ui/core'

import {
    AccessTime as AccessTimeIcon,
} from '@material-ui/icons';

let useStyles = makeStyles(theme => (
    {
        root: {
            padding: theme.spacing(2)
        },
        logoImage: {
            width: '400px',
            height: 'auto',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                margin: 'auto'
            }
        },
        mainPaper: {
            marginTop: theme.spacing(2),
            padding: theme.spacing()
        },
        rootCard: {
            maxWidth: '100%',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3)
        },
        imageWrapper: {
            height: '64px',
            width: '64px',
            margin: '0 auto',
            border: '1px solid #EDF0F2',
            borderRadius: '5px',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: '100%'
        },
        details: {},
        title: {
            fontSize: '18px',
            lineHeight: '21px',
            textAlign: 'center',
            marginTop: theme.spacing(2)
        },
        description: {
            lineHeight: '16px',
            height: theme.spacing(1) * 4,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            color: theme.palette.text.secondary,
            textAlign: 'center',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2)
        },
        stats: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: theme.spacing(1)
        },
        updateIcon: {
            color: theme.palette.text.secondary
        },
        updateText: {
            marginLeft: theme.spacing(1),
            color: theme.palette.text.secondary
        },
        downloadsIcon: {
            marginLeft: 'auto',
            color: theme.palette.text.secondary
        },
        downloadsText: {
            marginLeft: theme.spacing(1),
            color: theme.palette.text.secondary
        },
        bottonText: {
            padding: theme.spacing(1)
        },
        title__container: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
        }
    }
))

let sampleStatus = [
    {
        image: '/images/products/product_1.png',
        title: 'Bunkering',
        description: 'Procesando solicitud',
        time: 'Updated 1hr ago'
    },
    {
        image: '/images/products/product_2.png',
        title: 'Descarga',
        description: 'Solicitud procesada',
        time: 'Updated 2hr ago'
    },
    {
        image: '/images/products/product_3.png',
        title: 'Carga',
        description: 'Documentación recibida',
        time: 'Updated 1hr ago'
    },
]

export default function StatusPage() {
    const classes = useStyles()

    const renderCard = (status) => {
        return (
            <Card className={classes.rootCard}>
                <div className={classes.imageWrapper}>
                    {/* <Link to='/es/dashboard'> */}
                    <img
                        alt="Product"
                        className={classes.image}
                        src={status.image}
                    />
                    {/* </Link> */}
                </div>
                <div className={classes.details}>
                    <Typography
                        className={classes.title}
                        variant="h4"
                    >
                        {status.title}
                    </Typography>
                    <Typography
                        className={classes.description}
                        variant="body1"
                    >
                        {status.description}
                    </Typography>
                </div>
                <Divider />
                <div className={classes.stats}>
                    <AccessTimeIcon className={classes.updateIcon} />
                    <Typography
                        className={classes.updateText}
                        variant="body2"
                    >
                        {status.time}
                    </Typography>
                </div>
            </Card>)
    }
    return (
        <div className={classes.root}>
            <img src="/images/logos/logoCTSA.jpg" alt='CTSA Logo' className={classes.logoImage} />

            <div className={classes.title__container}>
                <Typography variant='h4'>Status solicitudes MN ADRIAN SCHULTE</Typography>
            </div>
            <Paper className={classes.mainPaper}>

                <Grid
                    container
                    spacing={3}
                >
                    {sampleStatus.map((stat, i) => (
                        <Grid
                            key={i}
                            item
                            lg={4}
                            md={6}
                            xs={12}
                        >
                            {renderCard(stat)}
                        </Grid>
                    ))}

                </Grid>
                <div className={classes.bottonText}>
                    <Typography variant='body2' color='textSecondary'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam id dui id nibh pulvinar feugiat sed quis urna. Curabitur aliquam, risus et iaculis commodo, neque augue porttitor libero, ut interdum massa tortor non arcu. Maecenas et quam at libero gravida feugiat at at neque. Nunc semper mi eu commodo pretium. Nullam metus dolor, blandit quis nunc tempor, varius imperdiet tortor. Mauris tristique vitae tortor aliquet consectetur. Duis id nibh imperdiet, consequat nibh at, pulvinar tortor. Morbi hendrerit efficitur lacinia. Aenean rutrum venenatis magna id feugiat.
                </Typography>
                </div>
            </Paper>
        </div>
    )
}
