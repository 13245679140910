import { green, red } from '@material-ui/core/colors';
export default theme => ({
  root: {},
  tableCellInner: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    cursor: 'pointer'
  },
  typeText: {
    marginLeft: theme.spacing(2),
  },
  table__header: {
    background: theme.palette.tableHeader,
  },
  tableRow: {
    height: '34px',
  },
  'taskRow--completed': {
    // backgroundColor: '#f8fafc',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    padding: '10px'
  },
  tableCell__header: {
    whiteSpace: 'nowrap',
    padding: '10px',
    color: '#000',
    textTransform: 'uppercase'
  },
  noTasks__container: {
    width: '100%',
    background: '#fff',
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  warningIcon: {
    color: 'red'
  },
  successIcon: {
    color: 'rgba(0,128,0,0.7)'
  },
  tasks__list__root: {
    backgroundColor: '#fff',
    borderTop: '1px solid #e1e4ea',
    padding: '0 0 60px 0'
  },
  nested: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: '1px solid #e1e4ea',
    // marginBottom: theme.spacing(1),
    padding: 0
  },

  //PROGRESS BAR
  progress__bar__leyend: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px'
    }
  },
  progress__bar__root: {
    width: '100%',
    backgroundColor: '#E4E7EB',
    height: 10,
    borderRadius: 3,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: 'auto',
      marginTop: 10
    }
  },
  progress__bar__completed: {
    backgroundColor: '#4ca64c',
    height: 10,
    borderRadius: 3,
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 0
  },
  progress__bar__due: {
    backgroundColor: '#ef5350',
    height: 10,
    borderRadius: 3,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1
  },
  complete__task: {
    color: green[400],
  },
  due__task: {
    color: red[400]
  }

});
