import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux'


// Views
import Dashboard from './views/Dashboard';
import ProductList from './views/ProductList';
import Recaladas from './views/Recaladas';
import Tasks from './views/Tasks';
import Emails from './views/Emails';
import Icons from './views/Icons';
import Account from './views/Account';
import Users from './views/Users';
import Settings from './views/Settings';
import Notifications from './views/Notifications'
import SignUp from './views/SignUp';
import SignIn from './views/SignIn';
import UnderDevelopment from './views/UnderDevelopment';
import NotFound from './views/NotFound';
import StatusPage from './views/StatusPage';
import ForgotPassword from './views/ForgotPasswordLandingPage';
import RequireNewPassword from './views/RequireNewPasswordLandingPage'
import { RecentRecaladasPage } from './views/RecentRecaladas';

//store
import store from './store'

//import Appointments from 'views/Appointments';
import './i18n'

export default function Routes(props) {
  return (
    <Provider store={store}>
      <Switch>
        <Redirect exact from="/" to="/es/signIn" />
        <Redirect exact from="/es" to="/es/signIn" />
        <Redirect exact from="/en" to="/en/signIn" />
        <Redirect exact from="/signIn" to="/es/signIn" />
        <Redirect exact from="/forgotPassword" to="/es/forgotPassword" />

        {/* *******************  TEMPORAL ************************/}
        {/* <Redirect exact from='/:lang/emails/:folder' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/emails/:folder/:emailId' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/account' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/users' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/settings/services' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/settings/services/:serviceId' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/settings/recaladas' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/settings/templates' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/settings/entities' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/settings/ships' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/settings/templates/:templateId' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/dashboard' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/recaladas' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/recaladas/:recaladaId' to="/:lang/CTG/tasks" />
        <Redirect exact from='/:lang/tasks' to="/:lang/CTG/tasks" /> */}
        {/**************** *  ************************ */}


        <Route exact path='/:lang/status' component={StatusPage} />
        <Route component={Dashboard} exact path="/:lang/:port/dashboard" />
        <Route component={RecentRecaladasPage} exact path="/:lang/:port/recentRecaladas" />
        <Route component={Recaladas} exact path="/:lang/:port/recaladas" />
        <Route component={Recaladas} exact path="/:lang/:port/recaladas/:recaladaId" />
        <Route component={ProductList} exact path="/:lang/reports" />
        <Route component={Tasks} exact path="/:lang/:port/tasks" />
        <Redirect
          exact
          from="/:lang/:port/emails"
          to={`/:lang/:port/emails/inbox`}
        />
        {/* <Route component={Emails} exact path="/:lang/:port/emails" /> */}
        <Route component={Emails} exact path="/:lang/:port/emails/:folder" />
        <Route component={Emails} exact path="/:lang/:port/emails/:folder/:emailId" />
        <Route component={Icons} exact path="/:lang/:port/icons" />
        <Route component={Account} exact path="/:lang/:port/account" />
        <Route component={Users} exact path="/:lang/:port/users" />
        <Route component={Settings} exact path="/:lang/:port/settings/services" />
        <Route component={Settings} exact path="/:lang/:port/settings/services/:serviceId" />
        <Route component={Settings} exact path="/:lang/:port/settings/recaladas" />
        <Route component={Settings} exact path="/:lang/:port/settings/entities" />
        <Route component={Settings} exact path="/:lang/:port/settings/entities/:entityId" />
        <Route component={Settings} exact path="/:lang/:port/settings/ships" />
        <Route component={Settings} exact path="/:lang/:port/settings/ships/:shipId" />
        <Route component={Settings} exact path="/:lang/:port/settings/templates" />
        <Route component={Settings} exact path="/:lang/:port/settings/templates/:templateId" />
        <Route component={Notifications} exact path="/:lang/:port/notifications" />
        <Route component={SignUp} exact path="/:lang/sign-up" />
        <Route component={SignIn} exact path="/:lang/signIn" />
        <Route component={SignIn} exact path="/:lang/requireNewPassword" />
        <Route component={SignIn} exact path="/:lang/forgotPassword" />
        <Route component={ForgotPassword} exact path="/:lang/recoverPassword" />
        <Route component={RequireNewPassword} exact path="/:lang/setNewPassword" />
        <Route component={UnderDevelopment} exact path="/:lang/:port/under-development" />
        <Route component={NotFound} exact path="/:lang/not-found" />
        <Redirect from='*' to="/es/not-found" />
      </Switch>
    </Provider>
  );
}
