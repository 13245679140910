import React, { useState } from 'react'
import {
    Dialog,
    makeStyles,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogActions,
    Typography,
    withMobileDialog,
    Grid
} from '@material-ui/core'
import {
    InsertDriveFile,
    Close
} from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import i18n from 'i18next'
import PDFViewer from 'mgr-pdf-viewer-react'
import {
    isMobile
} from "react-device-detect";

const supportedExtensions = {
    all: ['.png', '.jpg', '.bmp', '.gif', '.apng', '.ico', '.cur', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.svg', '.tif', '.tiff', '.webp', '.pdf'],
    images: ['.png', '.jpg', '.bmp', '.gif', '.apng', '.ico', '.cur', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.svg', '.tif', '.tiff', '.webp',],
    documents: ['.pdf'],
    others: []
}

const useStyles = makeStyles(theme => ({
    dialog__paper: {
        minWidth: '400px'
    },
    preview__image: {
        width: '100%'
    },
    closeButton: {
        // position: 'absolute',
        // top: 0,
        // right: theme.spacing(),
    },
    unaviable__container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 300,
        width: '100%',
        height: 200
    },
    preview__container__pdfviewer: {
        border: `solid thin ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[100],
        width: '100%'
    },
    image__container: {
        // minWidth: 300,
        minHeight: 200
    }
}))

function AttachmentPreview(props) {
    const classes = useStyles(),
        { width } = props
    const [loadingImage, setLoadingImage] = useState(true)
    let isImage = false,
        isPdf = false,
        isSupported = false;
    const imgLoaded = () => {
        setLoadingImage(false)
    }


    if (!props.selectedAttachment) return null
    let extension = `.${props.selectedAttachment.url.split('.')[props.selectedAttachment.url.split('.').length - 1]}`;

    if (supportedExtensions.all.indexOf(extension) >= 0) {
        isSupported = true;
        if (supportedExtensions.images.indexOf(extension) >= 0) {
            isImage = true;
        } else {
            isImage = false
        }
        isPdf = extension === '.pdf' ? true : false;
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.close}
            fullScreen={isMobile || width === 'xs' ? true : false}
            classes={{
                paper: classes.dialog__paper
            }}>
            <DialogTitle disableTypography={true}>
                <Grid container spacing={1} style={{alignItems: 'center'}}>
                    <Grid item xs={10} sm={11}>
                        <Typography noWrap>
                            {props.selectedAttachment.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <IconButton color="inherit"
                            onClick={props.close}
                            aria-label="Close"
                            className={classes.closeButton}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={isMobile || width === 'xs' ? {padding: '0'} : {}}>
                {!isSupported && (
                    <div className={classes.unaviable__container}>
                        <InsertDriveFile color='secondary' />
                        <Typography>
                            {i18n.t('Preview Unavailable')}
                        </Typography>
                    </div>
                )}
                {isSupported && (
                    <>
                        {isImage && (
                            <div className={classes.image__container}>
                                {loadingImage && (
                                    <Skeleton height={200} width={'100%'} />
                                )}

                                <img style={loadingImage ? { visibility: 'hidden' } : null}
                                    src={props.selectedAttachment.url}
                                    alt={props.selectedAttachment.name}
                                    className={classes.preview__image}
                                    onLoad={imgLoaded} />
                            </div>
                        )}
                        {isPdf && (
                            <PDFViewer document={{
                                url: props.selectedAttachment.url
                            }}
                                css={classes.preview__container__pdfviewer}
                                scale={isMobile || width === 'xs' ? 0.5 : 0.8} />
                        )}
                    </>
                )}
            </DialogContent>

            <DialogActions style={isMobile || width === 'xs' ? {marginBottom: '72px', padding: '16px 24px'} :{padding: '16px 24px'}}>
                <div />
                <a key={props.selectedAttachment.id}
                    href={props.selectedAttachment.url}
                    target='_blank'
                    rel="noopener noreferrer"
                    className={classes.attachment__link}
                >
                    <div className={classes.attachments__conponents}>
                        <Typography variant='body2' noWrap style={{color: '#0767DB'}}>{i18n.t('Download Attachment')}</Typography>
                    </div>
                </a>
            </DialogActions>
        </Dialog>
    )
}


export default (withMobileDialog()(AttachmentPreview))