export default theme => ({
    dialog__paper: {
        width: '100%',
        maxWidth: 600
    },
    dialog__title__container: {
        color: '#fff',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    dialog__title: {
        backgroundColor: theme.palette.primary.main,
    },
    dialog__container: {
        padding: theme.spacing(2)
    },
    loading__container: {
        height: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    description__container: {
        padding: theme.spacing(2)
    },
    textField__input: {
        padding: 10,
        width: '100%'
    },
    textField__area__input: {
        padding: 0,
        width: '100%'
    },
    textField_addornment__input: {
        padding: '10px 10px 10px 0'
    },
    dueHours__root: {
        display: 'flex',
        margin: '8px 0',
        alignItems: 'center'
    },
    dueHours__textfield__input: {
        padding: 10,
        width: 80,

    },
    dueHours__textfield: {
        padding: theme.spacing(),
        paddingLeft: 0,
        marginRight: 15,
        width: 80,
    },
    select__root: {
        padding: 10,
        marginTop: 1,
        marginRight: 15,
    },
    keyword__chip: {
        marginRight: 10,
        marginBottom: 10,
        marginTop: 10,
    },
    add__keyword__actions__container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: 200,
        marginBottom: 10,
        marginTop: 10,
    },
    keywords__container: {
        display: 'flex',
        alignItems: 'center',
    },
    actions: {
        marginTop: theme.spacing(),
        display: 'flex'
    },
    keywords__textfield: {
        padding: theme.spacing(),
        marginRight: 5
    },
    actions__save: {
        marginRight: 10
    },
})