import React, { useState, useEffect } from 'react'
import {
    Typography,
    TextField,
    Button,
    IconButton,
    CircularProgress,
    MenuItem,
    Select,
    withStyles
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Tasks } from 'services'

import styles from './styles'

import { useDispatch, useSelector } from 'react-redux'
import { updateTaskTemplateDueHours } from 'actions/taskTemplateActions'




const DueHours = props => {
    const { classes, taskTemplate } = props,
        { t } = useTranslation(),
        [timeOption, setTimeOption] = useState(taskTemplate ? (parseInt(taskTemplate.dueHours) > 0 ? 1 : 0) : 0),
        [hoursOrDaysOption, setHoursOrDaysOption] = useState(0),
        [dateReferenceOption, setDateReferenceOption] = useState(taskTemplate ? taskTemplate.dueDateReferenceId : 1),
        [hours, setHours] = useState(taskTemplate ? Math.abs(parseInt(taskTemplate.dueHours)) : ''),
        [showActions, setShowActions] = useState(false),
        [saving, setSaving] = useState(false),
        generalParameters = useSelector(state => state.generalParameters),
        dueDateReferences = generalParameters.dueDateReferences,
        dispatch = useDispatch();

    const beforeAfterOptions = [
        {
            value: 0,
            label: t('Before')
        },
        {
            value: 1,
            label: t('After')
        }
    ],
        hoursOrDays = [
            {
                value: 0,
                label: t('Horas')
            },
            {
                value: 1,
                label: t('Días')
            }
        ]



    useEffect(() => {
        let isSuscribed = true;
        if (taskTemplate && taskTemplate.dueHours !== undefined && isSuscribed) {
            let totalHours = Math.abs(parseInt(taskTemplate.dueHours)),
                rest = totalHours % 24;
            setDateReferenceOption(taskTemplate.dueDateReferenceId)
            if (totalHours < 72 || (totalHours >= 72 && rest !== 0)) {
                setHours(totalHours)
                setHoursOrDaysOption(0);
            } else {
                let days = parseInt(totalHours / 24);
                setHours(days)
                setHoursOrDaysOption(1);
            }
            setTimeOption(parseInt(taskTemplate.dueHours) > 0 ? 1 : 0)
        }
        return () => {
            isSuscribed = false;
        };
    }, [taskTemplate])

    if (!taskTemplate) return null;

    const handleChangeDateReferenceOption = e => {
        setDateReferenceOption(e.target.value);
    }
    const handleChangeOption = e => {
        setTimeOption(e.target.value);
    }
    const handleChangeHoursOrDayOption = e => {
        setHoursOrDaysOption(e.target.value);
    }

    const handleChangeHours = e => {
        setHours(e.target.value);
    }


    const handleSaveDueHours = () => {
        setSaving(true)
        let totalHours = hoursOrDaysOption === 0 ? hours : hours * 24;
        let dueHours = timeOption === 0 ? 0 - totalHours : totalHours;


        Tasks.updateTaskTemplateDueHours({
            taskTemplateId: taskTemplate.id,
            dueHours: dueHours,
            dueDateReferenceId: dateReferenceOption
        }).then(response => {
            if (response.success) {
                setHours(Math.abs(parseInt(response.record.dueHours)))
                setTimeOption(parseInt(response.record.dueHours) > 0 ? 1 : 0)
                dispatch(updateTaskTemplateDueHours({
                    ...response.record,
                    serviceTemplateId: taskTemplate.serviceTemplateId
                }))
            }
            setSaving(false)
            setShowActions(false);
        })
    }
    const handleCancel = () => {
        let totalHours = Math.abs(parseInt(taskTemplate.dueHours)),
            rest = totalHours % 24;
        if (totalHours < 72 || (totalHours >= 72 && rest !== 0)) {
            setHours(totalHours)
            setHoursOrDaysOption(0);
        } else {
            let days = parseInt(totalHours / 24);
            setHours(days)
            setHoursOrDaysOption(1);
        }
        setTimeOption(parseInt(taskTemplate.dueHours) > 0 ? 1 : 0)
        // setTimeOption(taskTemplate ? (parseInt(taskTemplate.dueHours) > 0 ? 1 : 0) : 0);
        // setHours(taskTemplate ? Math.abs(parseInt(taskTemplate.dueHours)) : '');
        setShowActions(false)
    }

    return (
        <div>
            {/* <Typography variant='h6'>{t('Due Hours')}</Typography> */}
            <div className={classes.root} >
                {showActions ? (
                    <TextField
                        value={hours}
                        onChange={handleChangeHours}
                        type='number'
                        InputProps={{
                            inputProps: {
                                min: 0,
                                className: classes.dueHours__textfield__input
                            }
                        }}
                        style={{ marginRight: 5, minWidth: 80 }}
                        className={classes.textfield}
                        placeholder={t('Horas')}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant='outlined'
                    />
                ) : (
                        <Typography onClick={() => {
                            setShowActions(true)
                        }} className={classes.label}
                            style={{ marginRight: 5 }}>{hours}</Typography>
                    )}
                {showActions ? (
                    <Select
                        disabled={saving}
                        value={hoursOrDaysOption}
                        onChange={handleChangeHoursOrDayOption}
                        variant='outlined'
                        style={{ marginRight: 5 }}
                        classes={{
                            root: classes.select__root
                        }}>
                        {hoursOrDays.map(option => {
                            return (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            )
                        })}
                    </Select>
                ) :
                    (<Typography onClick={() => {
                        setShowActions(true)
                    }}
                        className={classes.label}
                        style={{ marginRight: 5 }}>{hoursOrDaysOption === 0 ? t('Horas') : t('Días')}</Typography>)
                }
                {showActions ? (
                    <Select
                        value={timeOption}
                        onChange={handleChangeOption}
                        variant='outlined'
                        style={{ marginRight: 5 }}
                        classes={{
                            root: classes.select__root
                        }}>
                        {beforeAfterOptions.map(option => {
                            return (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            )
                        })}
                    </Select>
                ) : (
                        <Typography onClick={() => {
                            setShowActions(true)
                        }}
                            className={classes.label}
                            style={{ marginRight: 5 }}>{beforeAfterOptions.find(op => op.value === timeOption).label}</Typography>
                    )}

                <Typography onClick={() => {
                    setShowActions(true)
                }} className={classes.label}>{t('de')}</Typography>
                {showActions ? (
                    <Select
                        disabled={saving}
                        style={{ marginLeft: 5, marginRight: 5 }}
                        value={dateReferenceOption}
                        onChange={handleChangeDateReferenceOption}
                        variant='outlined'
                        classes={{
                            root: classes.select__root
                        }}>
                        {dueDateReferences.map(option => {
                            return (
                                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                            )
                        })}
                    </Select>
                ) :
                    (
                        <Typography onClick={() => {
                            setShowActions(true)
                        }}
                            className={classes.label}
                            style={{ marginLeft: 5, marginRight: 15 }}>
                            {dueDateReferences.find(dd => dd.id === dateReferenceOption).name}
                        </Typography>

                    )}

                <div className={classes.actions}>
                    {showActions && (
                        <>
                            <Button
                                disabled={saving}
                                variant='contained'
                                size='small'
                                color='primary'
                                onClick={handleSaveDueHours}
                                className={classes.actions__save}>
                                {t('Save')}
                                {saving && (
                                    <CircularProgress color='secondary'
                                        style={{ marginLeft: 5 }}
                                        size={15} />
                                )}
                            </Button>
                            <IconButton size="small"
                                onClick={handleCancel}>
                                <Close />
                            </IconButton>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(DueHours)