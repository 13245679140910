import React, { useState } from 'react'
import {
    MenuItem,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    withStyles,
    CircularProgress
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Services } from 'services'
import { isMobile } from 'react-device-detect'

import { useDispatch, useSelector } from 'react-redux'
import { updateService } from 'actions/serviceActions'

import styles from './styles'

const ServiceTemplates  = props => {
    const { classes } = props;
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false)
    const [showAddSection, setShowAddSection] = useState(false)
    const generalParameters = useSelector(state => state.generalParameters)
    const service = useSelector(state => state.service)
    const serviceTemplates = generalParameters.servicesTemplates;
    const [selectedServiceTemplate, setSelectedServiceTemplate] = useState(service && service.template ? service.template.id : 0)
    const dispatch = useDispatch();

    if (!service) return null;

    const handleSaveServiceTemplate = () => {
        setSaving(true)

        Services.updateService({
            serviceId: service.id,
            name: service.name,
            linerId: service.liner.id,
            templateId: selectedServiceTemplate
        }).then(response => {
            if (response.success) {
                dispatch(updateService({...response.record}))
            }
            setSaving(false)
            setShowAddSection(false);
        })
    }

    const handleChange = (e) => {
        if(e.target.value !== selectedServiceTemplate){
            setShowAddSection(true)
        } 
        setSelectedServiceTemplate(e.target.value)
    }

    const handleCancel = () => {
        setShowAddSection(false);
    }

    return (
        <div>
            <div className={classes.root}>
                <div className={isMobile ? '' : classes.add__user__actions__container}>
                    {serviceTemplates.length > 0 && (
                        <>
                            <FormControl variant="outlined" style={{ minWidth: '320px', marginRight: 10 }}>
                                <InputLabel id="operation-type-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}
                                    shrink>
                                    {t('Service Template')}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    placeholder={t('Select User')}
                                    value={selectedServiceTemplate}
                                    onChange={handleChange}
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={120}>
                                    {serviceTemplates.map(temp => {
                                        return (
                                            <MenuItem key={temp.serviceTemplateId} value={temp.serviceTemplateId}>{temp.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>



                            { showAddSection && 
                                <div style={isMobile ? {marginTop: '8px'} : {}}>
                                    <Button
                                        disabled={saving}
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        onClick={handleSaveServiceTemplate}
                                        className={classes.actions__save}>
                                        {t('Save')}
                                        {saving && (
                                            <CircularProgress color='secondary'
                                                style={{ marginLeft: 5 }}
                                                size={15} />
                                        )}
                                    </Button>
                                    <IconButton size="small"
                                        onClick={handleCancel}>
                                        <Close />
                                    </IconButton>
                                </div>
                            }
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(ServiceTemplates )