import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'

export const General = {
    getGeneralParameters: () => {
        return new Promise((resolve, reject) => {
            
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/generalParameters`;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    }
}