// Actions types

export const SET_RECALADAS = 'SET_RECALADAS'


//Actions
export function setRecaladas(recaladas) {
    return {
        type: SET_RECALADAS,
        recaladas
    }
}

