import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withTranslation } from 'react-i18next'
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core';

import { Attachment } from '@material-ui/icons';

// Material components
import {
  //Avatar,
  Checkbox,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography,
  TablePagination,
  FormControlLabel,
  List,
  ListItem,
  Divider,
  withMobileDialog
} from '@material-ui/core';

// Shared helpers
// import { getInitials } from 'helpers';

// Shared components
import { Portlet, PortletContent } from 'components';

// Component styles
import styles from './styles';

class EmailsTable extends Component {
  state = {
    selectedEmails: [],
    emails: []
  };

  componentDidMount() {
    this.setState({
      emails: this.props.emails
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      emails: nextProps.emails,
      selectedEmails: nextProps.selectedEmails
    })
  }

  handleSelectAll = event => {
    const { onSelect } = this.props,
      { emails } = this.state;

    let selectedEmails;

    if (event.target.checked) {
      selectedEmails = emails.map(email => email.emailId);
    } else {
      selectedEmails = [];
    }

    this.setState({ selectedEmails });

    onSelect(selectedEmails);
  };

  handleSelectOne = (e, id) => {
    e.stopPropagation()

    const { onSelect } = this.props;
    const { selectedEmails } = this.state;

    const selectedIndex = selectedEmails.indexOf(id);
    let newSelectedEmails = [];

    if (selectedIndex === -1) {
      newSelectedEmails = newSelectedEmails.concat(selectedEmails, id);
    } else if (selectedIndex === 0) {
      newSelectedEmails = newSelectedEmails.concat(selectedEmails.slice(1));
    } else if (selectedIndex === selectedEmails.length - 1) {
      newSelectedEmails = newSelectedEmails.concat(selectedEmails.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedEmails = newSelectedEmails.concat(
        selectedEmails.slice(0, selectedIndex),
        selectedEmails.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedEmails: newSelectedEmails });

    onSelect(newSelectedEmails);
  };

  formatedDate = (date) => {
    if (moment().isSame(moment(date), 'day')) {
      return moment(date).format('hh:mm a')
    } else {
      return moment(date).format('MMM DD')
    }
  }

  pagination = () => {
    const { width, rowsPerPage, page, totalEmailsCount } = this.props

    return (
      <TablePagination
        component={width === 'xs' || width === 'sm' ? 'li' : undefined}
        style={{ width: '100%' }}
        rowsPerPageOptions={[50, 20, 10, 5]}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage='Rows: '
        onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
        onChangePage={this.props.handleChangePage}
        count={totalEmailsCount}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} of ${count}`} />
    )
  }

  handleClickEmail = (e, emailId) => {
    e.stopPropagation()

    this.props.onSelect([emailId])
    this.props.openEmailDetails(emailId)
  }

  render() {
    const { classes, className, t, page, rowsPerPage, totalEmailsCount } = this.props;
    const { activeTab, selectedEmails, emails } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={classes.container}>
        <Hidden only={['xs', 'sm']}>
          <Portlet className={rootClassName}>
            <PortletContent noPadding>
              <PerfectScrollbar>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <Checkbox
                          checked={selectedEmails.length === emails.length}
                          color="primary"
                          indeterminate={
                            selectedEmails.length > 0 &&
                            selectedEmails.length < emails.length
                          }
                          onChange={this.handleSelectAll}
                        />
                        <Typography
                          className={classes.nameText}
                          variant="body1"
                        >
                          {t('Select All')}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}></TableCell>
                      <TableCell className={classes.tableCell}></TableCell>
                      <TableCell className={classes.tableCell}></TableCell>
                      <TableCell className={classes.tableCell}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {emails
                      .filter(email => {
                        if (activeTab === 1) {
                          return !email.returning;
                        }

                        if (activeTab === 2) {
                          return email.returning;
                        }

                        return email;
                      })
                      .slice(0, rowsPerPage)
                      .map(email => (
                        <TableRow
                          className={`${classes.tableRow} ${email.read ? classes['mail--read'] : classes['mail--unread']}`}
                          hover
                          key={email.emailId}
                          selected={selectedEmails.indexOf(email.emailId) !== -1}
                          onClick={e => this.handleClickEmail(e, email.emailId)}
                        >
                          <TableCell className={classes.tableCell}>
                            <div className={classes.tableCellInner}>
                              <Checkbox
                                checked={selectedEmails.indexOf(email.emailId) !== -1}
                                color="primary"
                                onClick={e =>
                                  this.handleSelectOne(e, email.emailId)
                                }
                                value="true"
                              />
                              <Typography
                                className={classes.nameText}
                                style={{
                                  fontWeight: email.read ? 'normal' : 'bold'
                                }}
                                variant="body1"
                              >
                                {email.senderWithFormat ? email.senderWithFormat.split('<')[0] : email.sender}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ maxWidth: 300 }}>
                            {email.ship && email.ship.name &&
                              <Typography color='secondary'>
                                {`${email.ship.name} ${email.itinerary && email.itinerary.name ? `V-${email.itinerary.name}` : ''} ${email.task && email.task.name ? ` / ${email.task.name}` : ''}`}
                              </Typography>
                            }
                            <Typography noWrap><span style={{
                              fontWeight: email.read ? 'normal' : 'bold'
                            }}>{email.subject}</span> - {email.bodyPlain}</Typography>
                          </TableCell>
                          <TableCell padding='none'>
                            {email.attachments.length > 0 && (
                              <Attachment />
                            )}
                          </TableCell>
                          <TableCell padding='none' align='center' style={{ paddingRight: 5 }}>
                            {this.formatedDate(email.timestamp)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </PerfectScrollbar>
              <TablePagination
                backIconButtonProps={{
                  'aria-label': 'Previous Page'
                }}
                component="div"
                count={totalEmailsCount}//emails.length}
                nextIconButtonProps={{
                  'aria-label': 'Next Page'
                }}
                onChangePage={this.props.handleChangePage}
                onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </PortletContent>
          </Portlet>
        </Hidden>

        <Hidden mdUp>
          <div className={classes.selectAll__list}>
            <FormControlLabel
              control={
                <Checkbox
                  value={selectedEmails.length === emails.length ? true : false}
                  onChange={this.handleSelectAll}
                  color="primary"
                  indeterminate={
                    selectedEmails.length > 0 &&
                    selectedEmails.length < emails.length
                  }
                />
              }
              label={t('Select All')}
            />
          </div>
          <List className={classes.list}>
            {emails.map(row => (
              <React.Fragment key={row.emailId}>
                <ListItem button
                  className={`${classes.listItem} ${row.read ? classes['mail--read'] : classes['mail--unread']}`}>
                  <div style={{
                    width: '100%',
                    position: 'relative'
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        style={{ padding: '0', marginRight: "4px" }}
                        checked={selectedEmails.indexOf(row.emailId) !== -1}
                        onChange={(e) => this.handleSelectOne(e, row.emailId)}
                        id={`${row.emailId}`}
                      />
                      <Typography noWrap
                        onClick={e => this.handleClickEmail(e, row.emailId)}
                        style={{
                          maxWidth: '80%'
                        }}><span style={{
                          fontWeight: row.read ? 'normal' : 'bold'
                        }}>{row.senderWithFormat ? row.senderWithFormat : row.sender}</span></Typography>
                    </div>
                    <div onClick={e => this.handleClickEmail(e, row.emailId)}>
                      <Typography noWrap
                        style={{
                          maxWidth: '80%'
                        }}><span style={{
                          fontWeight: row.read ? 'normal' : 'bold'
                        }} >{row.subject}</span></Typography>
                      <Typography noWrap style={{
                        maxWidth: '80%'
                      }}>{row.bodyPlain}</Typography>
                      {row.attachments.length > 0 && (
                        <Attachment style={{
                          position: 'absolute',
                          top: 0,
                          right: 4
                        }} />
                      )}
                      <Typography style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 4
                      }} noWrap>{(e) => this.formatedDate(row.timestamp)}</Typography>
                    </div>
                  </div>
                </ListItem>
                <Divider style={{ width: '100%', heigth: 5, margin: 0 }} />
              </React.Fragment>
            ))}
            {/* <Box style={{ width: '100%' }}> */}
            {this.pagination}
            {/* </Box> */}
          </List>
        </Hidden>
      </div>
    );
  }
}

EmailsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  emails: PropTypes.array.isRequired
};

EmailsTable.defaultProps = {
  emails: [],
  onSelect: () => { },
  onShowDetails: () => { }
};

export default compose(withTranslation(), withMobileDialog(), withStyles(styles))(EmailsTable);
