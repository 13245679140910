import React, { useState } from 'react'
import {
    Button,
    CircularProgress,
    makeStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Recaladas } from 'services'
import { closeRecalada as closeRecaladaAction } from 'actions/recaladaActions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
    root: {
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {

    }
}))


const CloseRecalada = props => {
    const { recalada } = props,
        [saving, setSaving] = useState(false),
        { t } = useTranslation(),
        dispatch = useDispatch(),
        classes = useStyles();

    const closeRecalada = () => {
        setSaving(true);
        if (!recalada.isClosed) {
            Recaladas.closeRecalada({
                recaladaId: recalada.recaladaId,
            }).then(result => {
                if (result.record) {
                    dispatch(closeRecaladaAction(result.record))
                }
                setSaving(false)
            })
        } else {
            Recaladas.openRecalada({
                recaladaId: recalada.recaladaId,
            }).then(result => {
                if (result.record) {
                    dispatch(closeRecaladaAction(result.record))
                }
                setSaving(false)
            })
        }
    }

    return (
        <Button
            variant='outlined'
            color='primary'
            className={classes.button}
            disabled={(saving) ? true : false}
            onClick={closeRecalada}>
            {!recalada.isClosed ? (
                t('Close Recalada')
            ) : (
                    t('Open Recalada')
                )}

            {saving && (
                <CircularProgress color='secondary'
                    style={{ marginLeft: 5 }}
                    size={15} />
            )}
        </Button>
    )
}
export default CloseRecalada;