import React, { useState } from 'react'
import {
    TextField,
    Button,
    withStyles,
    CircularProgress
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Message, UserAvatar } from '../'

import { useSelector, useDispatch } from 'react-redux';
import { addTaskMessage } from 'actions/tasksActions'
import { Tasks } from 'services'

import styles from './styles'

const Messages = props => {
    const { classes, task } = props;
    const [saving, setSaving] = useState(false)
    const [showNewMessageActions, setShowNewMessageActions] = useState(false);
    const [message, setMessage] = useState('')
    const account = useSelector((state) => state.account);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSaveMessage = () => {
        if (message) {
            setSaving(true)
            Tasks.addMessage({
                taskId: task.taskId,
                message,
                authorUserId: account.id
            }).then(messageUpdateResponse => {
                if (messageUpdateResponse.success) {
                    let newMessage = messageUpdateResponse.record;
                    dispatch(addTaskMessage(newMessage))
                }
                setMessage('')
                setSaving(false)
                setShowNewMessageActions(false)

            })
        }
    }

    return (
        <div >
            <div className={classes.message__container}>
                <UserAvatar user={account} />
                <div className={classes.message__addNew__container}>
                    <TextField
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        className={classes.message__addNew__textfield}
                        multiline
                        onBlur={() => {
                            if (!message) {
                                setShowNewMessageActions(false)
                            }
                        }}
                        onFocus={() => { setShowNewMessageActions(true) }}
                        placeholder={t('Escriba un mensaje')} />
                    {showNewMessageActions && (
                        <div className={classes.message__addNew__actions}>
                            <Button
                                disabled={!message || saving ? true : false}
                                variant='contained'
                                size='small'
                                color='primary'
                                onClick={handleSaveMessage}
                            >
                                {t('Guardar')}
                                {saving && (
                                    <CircularProgress color='secondary'
                                        style={{ marginLeft: 5 }}
                                        size={15} />
                                )}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            {task.messages && task.messages.length > 0 && task.messages.map((message, i) => (
                <Message message={message} key={i} />
            )
            )}
        </div>
    )
}
export default withStyles(styles)(Messages);