import React, { useState, useEffect } from 'react'

import {
    Button,
    Grid,
    Divider,
    makeStyles
} from '@material-ui/core'
import { AddAlert } from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import { Reminder, ReminderForm } from './';


import styles from './styles'


const useStyles = makeStyles(styles);

const RemindersList = props => {
    const classes = useStyles(),
        { taskTemplate } = props,
        { t } = useTranslation(),
        [showReminderForm, setShowReminderForm] = useState(false),
        [selectedReminder, setSelectedReminder] = useState(null),
        [reminders, setReminders] = useState(taskTemplate ? taskTemplate.reminders || [] : []);

    useEffect(() => {
        let isMounted = true;
        if (taskTemplate && taskTemplate.reminders && isMounted) {
            setReminders(taskTemplate.reminders)
        }
        return () => {
            isMounted = false
        }
    }, [taskTemplate])

    const handleSelect = (reminder) => {
        setSelectedReminder(reminder)
        setShowReminderForm(true)
    }
    const handleUnselect = () => {
        setSelectedReminder(null)
    }


    return (
        <div className={classes.reminders__root}>
            <div className={classes.reminders__list}>
                <Grid container spacing={1}>
                    {reminders.length > 0 && reminders.map((reminder, i) => (
                        <Grid item
                            xs={12}
                            key={i}>
                            <Reminder reminder={reminder}
                                taskTemplate={taskTemplate}
                                handleSelect={handleSelect}
                                onDeleteConfirmation={() => {
                                    handleUnselect()
                                    setShowReminderForm(false)
                                }} />
                        </Grid>
                    ))}
                    {!showReminderForm && (
                        <Grid item xs={12} style={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                            //reminders.length === 0 ? 'center' : 'flex-start'
                        }}>
                            <Button
                                color="primary"
                                className={classes.add__reminder__button}
                            // onClick={() => { setShowReminderForm(true) }}
                            >
                                {t('Add recipient')}
                            </Button>
                            {/* <Button
                                variant='contained'
                                color='primary'
                                onClick={
                                    () => {
                                        setShowReminderForm(true)
                                    }}>
                                {t('reminders_add_reminder')}
                            </Button> */}
                        </Grid>
                    )}

                    {showReminderForm && (
                        <Grid item xs={12}
                            style={{
                                display: 'flex',
                                //justifyContent: 'center'
                            }}>
                            <ReminderForm
                                unselectReminder={handleUnselect}
                                reminder={selectedReminder}
                                taskTemplate={taskTemplate}
                                onSave={() => {
                                    setShowReminderForm(false)
                                }}
                                onCancel={() => {
                                    handleUnselect()
                                    setShowReminderForm(false)
                                }} />
                        </Grid>
                    )}
                </Grid>

            </div>

            {/* {reminders.length > 0 && (
                <Divider style={{ marginTop: 15 }} />
            )} */}
        </div>
    )
}

export default RemindersList; 
