import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import {
    Button,
    CircularProgress,
    Link,
    TextField,
    Typography,
    InputAdornment,
    IconButton,
    withStyles,
    Grid
} from '@material-ui/core'
import styles from './styles';
import {
    Visibility, VisibilityOff
} from '@material-ui/icons'
import auth from '../../authentication/auth'



const ForgotPassword = (props) => {
    const { classes, t, credentials, history, location, match } = props;

    let url_language = match.params.lang;

    const [inputs, setInputs] = useState({ email: credentials && credentials.username ? credentials.credentials : '' })
    const [fetching, setFetching] = useState(false)
    const [validate, setValidate] = useState(false)
    const [validationErrors, setValidationErrors] = useState({})
    const [responseErrors, setResponseErrors] = useState(null)
    const [showPassword, setShowPassword] = useState(false)


    useEffect(() => {
        let params = new URLSearchParams(location.search)
        if (params.get("email") && params.get('recoverCode')) {
            setInputs({
                email: params.get("email").trim().replace(' ', '+'),
                code: params.get('recoverCode')
            })
        }
    }, [location, setInputs])

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleInputChange = event => {
        event.persist();

        setInputs({
            ...inputs,
            [event.target.name]: event.target.type !== 'checkbox' ? event.target.value : event.target.checked
        })
    }


    const validatePassword = (password) => {
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
            valid = false;

        if (password && password.match(passw)) {
            valid = true
        }

        return valid
    }

    const validateParameters = () => {
        let errors = {};

        if (!validatePassword(inputs.newPassword)) {
            errors.newPassword = t('Password must have upper case, lower case, numbers and at least 8 chars')
        }

        setValidationErrors(errors)

        if (Object.keys(errors).length > 0) {

            setValidationErrors(true)
            return false;
        }

        setValidate(false)

        return true
    }

    const onSubmit = e => {
        e.preventDefault();

        setResponseErrors(null)

        if (validateParameters()) {
            setFetching(true)

            auth.forgotPasswordSubmit({ username: inputs.email, code: inputs.code, newPassword: inputs.newPassword })
                .then(_ => {
                    auth.signIn({ email: inputs.email, password: inputs.newPassword })
                        .then(user => {
                            setFetching(false)

                            //history.push(`/${url_language}/recaladas`);
                            history.push(`/${url_language}/tasks`);
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
                .catch(err => {
                    setFetching(false)

                    //ExpiredCodeException
                    if (err.code === 'CodeMismatchException') {
                        setResponseErrors(t('Invalid code. Please try again'))
                    } else if (err.code === 'ExpiredCodeException') {
                        setResponseErrors(t('Expired link. Please resend email'))
                    } else {
                        setResponseErrors(t('Error sending password. Please try again'))
                    }
                })
        }
    }

    const backToSignIn = () => {
        history.push(`/${match.params.lang}/signIn`)
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            onSubmit(e)
        }
    }

    return (
        <div className={classes.root}>
            <Grid
                className={classes.grid}
                container
            >
                <Grid
                    className={classes.quoteWrapper}
                    item
                    lg={5}
                >
                    <div className={classes.quote}>
                        <div className={classes.quoteInner}>

                        </div>
                    </div>
                </Grid>
                <Grid
                    className={classes.content}
                    item
                    lg={7}
                    xs={12}
                >
                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            <form
                                className={classes.form}
                                onSubmit={onSubmit}
                                noValidate
                                autoComplete='false'
                            >
                                <img
                                    height="90"
                                    style={{ margin: '0 auto' }}
                                    alt="CTSA"
                                    className={classes.logoImage}
                                    src="/images/logos/logoCTSA.jpg"
                                />

                                <Typography
                                    className={classes.title}
                                    variant="h2"
                                >
                                    {t('Reset your password')}
                                </Typography>

                                <Typography
                                    className={classes.forgot__form__description}
                                    style={{ marginTop: '8px' }}
                                    variant="h6"
                                >
                                    {t(`Enter your new password`)}
                                </Typography>

                                <div className={classes.fields}>
                                    <TextField
                                        disabled={fetching}
                                        required
                                        fullWidth
                                        id="newPassword"
                                        label={t('New Password')}
                                        name="newPassword"
                                        autoComplete="off"
                                        variant="outlined"
                                        onKeyDown={onKeyDown}
                                        style={{ marginBottom: '8px' }}
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={handleInputChange}
                                        helperText={inputs.newPassword && !validatePassword(inputs.newPassword) ? t('Uppercase, lowercase, numbers and be greater than 6 characters') : ''}
                                        error={(validate && validationErrors.newPassword) || (inputs.newPassword && !validatePassword(inputs.newPassword)) ? true : false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputProps: {
                                                autoCapitalize: 'off',
                                                autoComplete: 'off',
                                                spellCheck: 'false',
                                                autoCorrect: "off"
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {validationErrors.newPassword && (
                                        <Typography className={classes.fieldError} variant='body2' color='error'>{validationErrors.newPassword}</Typography>
                                    )}
                                    {responseErrors && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {responseErrors}
                                        </Typography>
                                    )}


                                    <div style={{ textAlign: 'center' }}>
                                        <Button
                                            className={classes.signInButton}
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            size="large"
                                            disabled={fetching || (inputs.newPassword && !validatePassword(inputs.newPassword))}
                                            onClick={() => { }}>
                                            {t('Submit')}
                                            {fetching && (
                                                <CircularProgress
                                                    size={16}
                                                    style={{
                                                        marginLeft: '5px',
                                                        color: 'grey'
                                                    }} />
                                            )}
                                        </Button>
                                    </div>
                                </div>

                                <Link component='button'
                                    variant="body2"
                                    color="primary"
                                    style={{ marginTop: '16px', fontSize: '14px' }}
                                    onClick={backToSignIn}>
                                    {t('Back to Sign In')}
                                </Link>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default withTranslation()(withStyles(styles)(ForgotPassword))