import React from 'react';

// Material helpers
import { withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

// Material components
import { 
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
	IconButton,
	withWidth,
} from '@material-ui/core';
import { 
	Close,
} from '@material-ui/icons'
import { isMobile } from 'react-device-detect';
import UserForm from './UserForm'


// Component styles
const styles = theme => ({
  root: {},
  field: {
	margin: theme.spacing(3)
  },
  textField: {
	width: '420px',
	maxWidth: '100%',
	marginRight: theme.spacing(3),
	marginBottom: theme.spacing(1)
  },
  portletFooter: {
	paddingLeft: theme.spacing(3),
	paddingRight: theme.spacing(3),
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2)
  },
  dialog__paper: {
	},
	dialog__title__container: {
		color: '#fff',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	dialog__title: {
		backgroundColor: theme.palette.primary.main,
	},
	dialog__container: {
		padding: theme.spacing(2)
	},
	loading__container: {
		height: 300,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	description__container: {
		padding: theme.spacing(2)
	},
	link: {
		cursor: 'pointer',
		fontSize: '11px',
	},
	cancelLink: {
		color: '#828282'
	}
})

const Account = props => {
	const { classes, open, t, selectedUser, width } = props

	return (
		<Dialog
			open={open || false}
			fullScreen={width === 'xs' && isMobile ? true : false}
			classes={{
				paper: classes.dialog__paper
			}}
			maxWidth='xs'
			fullWidth={true}>
			<DialogTitle
				className={classes.dialog__title}>
				<div className={classes.dialog__title__container}>
					<Typography variant='h5' color='inherit'>{selectedUser ? t('Update user'): t('New user')}</Typography>
					<IconButton
						color='inherit'
						onClick={props.onClose}>
						<Close />
					</IconButton>
				</div>
			</DialogTitle>
			<DialogContent classes={{
				root: classes.dialog__container
			}}>
				<UserForm
					onClose={props.onClose}
					addUser={props.addUser}
					updateUser={props.updateUser}
					selectedUser={selectedUser}
				/>
			</DialogContent>
        </Dialog>
	);
}

function mapStateToProps(state, ownProps) {
    return {
        generalParameters: state.generalParameters,
        currentPort: state.currentPort
    }
}

export default connect(mapStateToProps, null)(
	compose(
		withStyles(styles),
		withTranslation(),
		withWidth()
	)(Account)
)
