export default theme => ({
    popover__content: {
        padding: 10,
        maxWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    popover__button: {
        marginBottom: 5,
        width: '100%',
    },
    popover__button__label: {
        justifyContent: 'flex-start'
    },
    swipeableViews__views: {
        overflow: 'hidden'
    }
})