import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'

export const Emails = {
    getEmails: (args) => {
        return new Promise((resolve, reject) => {
            let {
                search,
                recordCount,
                offset,
                folderId,
                type,
                recaladaId,
                portCode
            } = args;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/emails${recordCount ? `?recordCount=${recordCount}` : '?recordCount=50'}&offset=${offset ? offset : 0}&folderId=${folderId || 1}`;

                if (portCode) {
                    url += `&portCode=${portCode}`
                }

                if (search) {
                    url += `&search=${search}`
                }
                if (type) {
                    url += `&emailType=${type}`
                }
                if (recaladaId) {
                    url += `&recaladaId=${recaladaId}`
                }
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    changeEmailsFolder: (args) => {
        let { body, search } = args || {}; // {emailsIds, folderId, oldFolderId}

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/emails?recordCount=50`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                if (search) {
                    url += `&search=${search}`
                }

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    body: JSON.stringify(body),
                    method: 'PATCH',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    markEmail: (args) => {
        return new Promise((resolve, reject) => {
            let {
                read,
                folderId,
                emailId
            } = args;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/emails/${emailId}/markEmail?read=${read}&folderId=${folderId}`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    assignEmailToTask: ({ emailId, taskId, recaladaId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/emails/${emailId}/assignToTask`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                let body = {
                    taskId,
                    recaladaId
                };

                fetch(url, {
                    headers,
                    body: JSON.stringify(body),
                    method: 'PATCH',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    getUnreadEmailsCounters: () => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/emails/unreadCounters`;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    }
}