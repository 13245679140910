import React from 'react'
import {
    Typography,
    withStyles
} from '@material-ui/core'
import moment from 'moment'
import styles from './styles'
import { UserAvatar } from '../'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

const Message = (props) => {
    const { message, classes } = props;
    let user = message.author;
    return (
        <div className={classes.message__container}>
            <UserAvatar user={message.author} />
            <div className={classes.message__text__container}>
                <Typography color='secondary' variant='body2'>{`${user.firstName} ${user.lastName}`}</Typography>
                <Typography color='textSecondary' variant='body2'>{moment(message.timestamp).format('ddd DD MMMM HH:mm')}</Typography>
                <Typography color='textPrimary' variant='body1'>{message.message}</Typography>
            </div>
        </div>
    )
}

export default compose(withTranslation(), withStyles(styles))(Message)
