import {
    SET_TASK,
    CLEAR_TASK,
    UPDATE_TASK,
    ADD_TASK_MESSAGE,
    UPDATE_TASK_KEYWORDS,
    UPDATE_TASK_NAME,
    UPDATE_TASK_DESCRIPTION,
    UPDATE_TASK_DUE_HOURS,
    UPDATE_TASK_COMPLETE,
    UPDATE_TASK_ADD_USER,
    UPDATE_TASK_REMOVE_USER,
    SET_TASK_CHECKLIST,
    ADD_TASK_ATTACHMENTS,
    DELETE_TASK_ATTACHMENT
} from 'actions/tasksActions'

export default function task(state = {}, action) {
    switch (action.type) {
        case SET_TASK:
            return {
                ...action.task
            };
        case UPDATE_TASK:
            if (state && action.data && state.taskId === action.task.taskId) {
                return {
                    ...state,
                    ...action.task
                }
            }
            return state;
        case ADD_TASK_MESSAGE:
            if (state && action.data && state.taskId === action.message.taskId) {
                return {
                    ...state,
                    messages: [
                        { ...action.message },
                        ...state.messages
                    ]
                }
            }
            return state;
        case UPDATE_TASK_KEYWORDS:
            if (state && action.data && state.taskId === action.data.taskId) {
                return {
                    ...state,
                    keywords: [...action.data.keywords]
                }
            }
            return state;
        case ADD_TASK_ATTACHMENTS:
            if (state && action.data && state.taskId === action.data.taskId) {
                return {
                    ...state,
                    taskAttachments: [
                        ...state.taskAttachments,
                        ...action.data.attachments
                    ]
                }
            }
            return state;
        case DELETE_TASK_ATTACHMENT:
            if (state && action.data && state.taskId === action.data.taskId) {
                return {
                    ...state,
                    taskAttachments: state.taskAttachments.filter(a => a.taskAttachmentId !== action.data.taskAttachmentId)
                }
            }
            return state;
        case CLEAR_TASK:
            return null
        case UPDATE_TASK_NAME:
            if (state && action.data && state.taskId === action.data.taskId) {
                return {
                    ...state,
                    name: action.data.name
                }
            }
            return state;
        case UPDATE_TASK_DESCRIPTION:
            if (state && action.data && state.taskId === action.data.taskId) {
                return {
                    ...state,
                    description: action.data.description
                }
            }
            return state;
        case SET_TASK_CHECKLIST:
            if (state && action.data && state.taskId === action.data.taskId) {
                return {
                    ...state,
                    checklist: [...action.data.checklist]
                };
            }
            return state;
        case UPDATE_TASK_DUE_HOURS:
            if (state && action.data && state.taskId === action.data.taskId) {
                return {
                    ...state,
                    dueHours: action.data.dueHours
                }
            }
            return state;
        case UPDATE_TASK_COMPLETE:
            if (state && action.data && state.taskId === action.data.taskId) {
                return {
                    ...state,
                    complete: action.data.complete,
                    completeDateTime: action.data.completeDateTime,
                    completeUserId: action.data.completeUserId,
                    completeUser: {
                        id: action.data.completeUserId,
                        firstName: action.data.firstName,
                        lastName: action.data.lastName,
                    }
                }
            }
            return state;
        case UPDATE_TASK_ADD_USER:
            if (state && action.data && state.taskId === action.data.taskId) {
                return {
                    ...state,
                    responsiblesUsers: [
                        ...state.responsiblesUsers,
                        {
                            id: action.data.id,
                            firstName: action.data.firstName,
                            lastName: action.data.lastName,
                            email: action.data.email,
                            profileId: action.data.profileId
                        }
                    ]
                }
            }
            return state;
        case UPDATE_TASK_REMOVE_USER:
            if (state && action.data && state.taskId === action.data.taskId) {
                return {
                    ...state,
                    responsiblesUsers: state.responsiblesUsers.filter(r => r.id !== action.data.userId)
                }
            }
            return state;
        default:
            return state;
    }
}