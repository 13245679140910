export default theme => ({
    root: {

    },
    description__container: {
        borderRadius: 5,
        backgroundColor: '#f4f4f4',
        cursor: 'pointer',
        marginBottom: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(),
        display: 'flex'
    },
    description__textfield: {
        width: '100%',
        backgroundColor: '#fff',
        padding: theme.spacing()
    },
    actions__save: {
        marginRight: 10
    },
    checklist__list: {
        padding: 0
    },
    linearProgress__bar: {
        backgroundColor: '#4ca64c'
    },
    checkListText: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: 5,
        marginBottom: 5
    },
    add__subtask__button: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontWeight: 500,
    },
    list__icon: { fontSize: 16, width: 16, marginRight: 10 },
})
