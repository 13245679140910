import {
    CREATE_TASK_TEMPLATE,
    SET_TASK_TEMPLATE,
    SET_TASK_TEMPLATE_CHECKLIST,
    CLEAR_TASK_TEMPLATE,
    UPDATE_TASK_TEMPLATE_NAME,
    UPDATE_TASK_TEMPLATE_DESCRIPTION,
    UPDATE_TASK_TEMPLATE_KEYWORDS,
    UPDATE_TASK_TEMPLATE_ROLE,
    UPDATE_TASK_TEMPLATE_TYPE,
    UPDATE_TASK_TEMPLATE_DUEHOURS,
    DELETE_TASK_TEMPLATE_ATTACHMENT,
    ADD_TASK_TEMPLATE_ATTACHMENTS,
    CREATE_TASK_TEMPLATE_REMINDER,
    DELETE_TASK_TEMPLATE_REMINDER,
    UPDATE_TASK_TEMPLATE_REMINDER,
    UPDATE_TASK_TEMPLATE_REQUIRED_RESULT
} from 'actions/taskTemplateActions'

export default function taskTemplate(state = null, action) {
    switch (action.type) {
        case SET_TASK_TEMPLATE:
            return action.taskTemplate
        case CLEAR_TASK_TEMPLATE:
            return null;
        case CREATE_TASK_TEMPLATE:
            if (state && state.id === action.data.taskTemplateId) {
                return {
                    ...action.data
                };
            }
            return state;
        case UPDATE_TASK_TEMPLATE_NAME:
            if (state.id === action.data.taskTemplateId) {
                return {
                    ...state,
                    name: action.data.name
                };
            }
            return state;
        case UPDATE_TASK_TEMPLATE_REQUIRED_RESULT:
            if (state.id === action.data.taskTemplateId) {
                return {
                    ...state,
                    requiredResult: action.data.requiredResult
                };
            }
            return state;
        case UPDATE_TASK_TEMPLATE_DESCRIPTION:
            if (state.id === action.data.taskTemplateId) {
                return {
                    ...state,
                    description: action.data.description
                };
            }
            return state;
        case UPDATE_TASK_TEMPLATE_KEYWORDS:
            if (state.id === action.data.taskTemplateId) {
                return {
                    ...state,
                    keywords: [...action.data.keywords]
                };
            }
            return state;
        case SET_TASK_TEMPLATE_CHECKLIST:
            if (state.id === action.data.taskTemplateId) {
                return {
                    ...state,
                    checklist: [...action.data.checklist]
                };
            }
            return state;
        case UPDATE_TASK_TEMPLATE_ROLE:
            if (state.id === action.data.taskTemplateId) {
                return {
                    ...state,
                    roleId: action.data.roleId,
                    roleName: action.data.roleName
                };
            }
            return state;
        case UPDATE_TASK_TEMPLATE_TYPE:
            if (state.id === action.data.taskTemplateId) {
                return {
                    ...state,
                    taskTypeId: action.data.taskTypeId,
                    roleId: action.data.roleId,
                    roleName: action.data.roleName
                };
            }
            return state;
        case UPDATE_TASK_TEMPLATE_DUEHOURS:
            if (state.id === action.data.taskTemplateId) {
                return {
                    ...state,
                    dueHours: action.data.dueHours,
                    dueDateReferenceId: action.data.dueDateReferenceId
                };
            }
            return state;

        case DELETE_TASK_TEMPLATE_ATTACHMENT: {
            if (state.id === action.data.taskTemplateId) {
                return {
                    ...state,
                    attachments: state.attachments.filter(a => a.taskTemplateAttachmentId !== action.data.taskTemplateAttachmentId)
                };
            }
            return state;
        }
        case ADD_TASK_TEMPLATE_ATTACHMENTS: {
            if (state.id === action.data.taskTemplateId) {
                return {
                    ...state,
                    attachments: [
                        ...state.attachments,
                        ...action.data.attachments
                    ]
                };
            }
            return state;
        }
        case CREATE_TASK_TEMPLATE_REMINDER: {
            if (state.id === action.reminder.taskTemplateId) {
                return {
                    ...state,
                    reminders: state.reminders ? [
                        ...state.reminders,
                        { ...action.reminder }
                    ] : [
                            { ...action.reminder }
                        ]
                };
            }
            return state;
        }
        case UPDATE_TASK_TEMPLATE_REMINDER: {
            if (state.id === action.reminder.taskTemplateId) {
                let reminderIndex = state.reminders.findIndex(r => r.id === action.reminder.id);
                return {
                    ...state,
                    reminders: [
                        ...state.reminders.slice(0, reminderIndex),
                        { ...action.reminder },
                        ...state.reminders.slice(reminderIndex + 1)
                    ]
                };
            }
            return state;
        }
        case DELETE_TASK_TEMPLATE_REMINDER: {
            if (state.id === action.reminder.taskTemplateId) {
                return {
                    ...state,
                    reminders: state.reminders.filter(r => r.taskTemplateReminderId !== action.reminder.taskTemplateReminderId),
                };
            }
            return state;
        }
        default:
            return state;
    }
}