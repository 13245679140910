import { red, green } from '@material-ui/core/colors';

export default theme => ({
    dialog__paper: {
        width: '100%',
        maxWidth: 800,
        height: '90%',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
        }
    },
    dialog__title__container: {
        color: '#fff',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    dialog__title: {
        backgroundColor: theme.palette.primary.main,
    },
    dialog__container: {
        padding: theme.spacing(2)
    },
    loading__container: {
        height: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    description__container: {
        padding: theme.spacing(2)
    },
    dueReminders__container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center'
        }
    },
    reminders__button: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 16,
            alignSelf: 'flex-end'
        }
    },
    due__task: {
        color: red[400]
    },
    email__actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing()
        }
    },
    email__paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: theme.spacing(3)
    },
    email__sender__container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    email__sender__timestamp: {

    },
    email__sender: {
        fontWeight: 'bold',
        textAlign: 'left'
    },
    email__recipient: {

    },
    email__subject: {
        fontWeight: 'bold',
        textAlign: 'left'
    },
    email__body: {
        textAlign: 'left',
        whiteSpace: 'pre-line'
    },
    attachments__container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    attachment: {
        maxWidth: 150,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer'
    },
    attachments__conponents: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    search__root: {
        margin: '10px 0',
        maxWidth: 400,
        width: '100%',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    search__input: {
        width: '90%'
    },
    file__list__item: {
        cursor: 'pointer',
        border: 'solid thin transparent',
        '&:hover': {
            backgroundColor: '#eeeeee',
            boxShadow: '3px 3px #dddddd',
            border: 'solid thin #dddddd'
        }
    },
    assign__button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary
    },
    actionsButtons: {
        color: theme.palette.text.secondary
    },

    'duedate__container--duePast': {
        textAlign: 'center',
        borderRadius: 5,
        padding: 5,
        border: `2px solid red`
        // backgroundColor: '#ff8f80'
    },
    'duedate__container--dueSoon': {
        textAlign: 'center',
        borderRadius: 5,
        padding: 5,
        border: `2px solid orange`
        // backgroundColor: '#fff5cc'
    },
    'duedate__container--scheduled': {
        textAlign: 'center',
        borderRadius: 5,
        padding: 5,
        border: `2px solid #66788a`
        // backgroundColor: '#c1e4f7'
    },
    'duedate__container--done': {
        textAlign: 'center',
        borderRadius: 5,
        padding: 5,
        border: `2px solid ${green[400]}`
        // backgroundColor: '#c7e8ac'
    }
})