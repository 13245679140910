import React, { useState, useEffect } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
    Grid,
    withMobileDialog,
    CircularProgress,
    Button
} from '@material-ui/core'
import {
    useDispatch
} from 'react-redux';
import { setTask as setTaskAction, clearTask as clearTaskAction, updateTaskComplete } from 'actions/tasksActions'
import { ConfirmationDialog } from 'components'
import { useTranslation } from 'react-i18next'
import { green } from '@material-ui/core/colors';
import { CheckCircle } from '@material-ui/icons'
import { Tasks } from 'services'
import { CaptureResultPopup } from './'

export default function CaptureTaskResult(props) {
    const { task, } = props,
        dispatch = useDispatch(),
        { t } = useTranslation(),
        [openResultDialog, setOpenResultDialog] = useState(false),
        [completeTask, setCompleteTask] = useState(task ? task.complete : false),
        [openConfirmation, setOpenConfirmation] = useState(false);

    const handleUpdateAsComplete = (value) => {
        setCompleteTask(value)
        if (!value) {
            setOpenConfirmation(true)
        } else {
            setOpenResultDialog(true)
        }
    }
    const closeResultDialog = () => {
        setOpenResultDialog(false)
    }

    const updateAsComplete = () => {
        Tasks.updateTaskComplete({
            taskId: task.taskId,
            complete: completeTask ? 1 : 0
        })
            .then(response => {
                if (response.success) {
                    dispatch(updateTaskComplete(response.record))
                }
            })
    }

    return (
        <>
            <Button
                size='small'
                style={task.complete === 0 ? {} : { border: `1px solid ${green[400]}` }}
                variant={task.complete === 0 ? 'contained' : undefined}
                onClick={(e) => {
                    e.stopPropagation();
                    handleUpdateAsComplete(task.complete === 0 ? true : false);
                }}
                color='primary'>
                {task.complete === 0 ? null : <CheckCircle style={{ color: green[400], marginRight: '5px' }} />}
                {task.complete === 0 ?
                    t('Terminar')
                    :
                    t('Task complete')}
            </Button>

            <ConfirmationDialog
                handleClose={() => {
                    setOpenConfirmation(false)
                }}
                onConfirm={() => {
                    setOpenConfirmation(false)
                    updateAsComplete()
                }}
                message={completeTask ? t('Are you sure you wish to mark this task as done?') : t('Still not done? Are you sure you wish to return to edit this task?')}
                cancelLabel={t('Cancel')}
                confirmLabel={t('Continue')}
                open={openConfirmation}
            />

            <CaptureResultPopup
                open={openResultDialog}
                task={task}
                onClose={closeResultDialog} />
        </>
    )
}
