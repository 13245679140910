import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'

// Material components
import {
    TextField,
    MenuItem,
    withStyles
} from '@material-ui/core';

//Redux
import { connect } from 'react-redux'

const styles = theme => {
    return {
        select__root: {
            padding: '8px 16px',
        },
        select__label: {
            transform: 'translate(14px, 14px) scale(1)'
        }
    };
};

class TaskTypeFilter extends Component {

    state = {
        selectedType: '0'
    }

    handleChange = (e) => {
        this.setState({
            selectedType: e.target.value
        })
        this.props.onChange(e.target.value)
    };

    render() {
        const { t, classes, generalParameters } = this.props,
            { selectedType } = this.state;

        if (!generalParameters && !generalParameters.taskTypes) return null

        return (
            <div>
                <TextField
                    InputProps={{
                        inputProps: {
                            className: classes.select__root
                        }
                    }}
                    select
                    labe=''
                    value={selectedType}
                    onChange={this.handleChange}
                    variant='outlined'
                    style={{ width: '138px' }}
                >
                    <MenuItem value={0}>
                        <em>{t('All')}</em>
                    </MenuItem>
                    {generalParameters.taskTypes.map(type => (
                        <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                    ))}
                </TextField>
                {/* <FormControl variant="outlined" style={{ minWidth: '200px' }}>
                    <InputLabel id="operation-type-selector"
                        classes={{
                            root: classes.select__label
                        }}>
                        {t('Task Type')}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedType}
                        onChange={this.handleChange}
                        classes={{
                            root: classes.select__root
                        }}
                        labelWidth={120}>

                        <MenuItem value={0}>
                            <em>{t('All')}</em>
                        </MenuItem>

                        {generalParameters.taskTypes.map(type => {
                            return (
                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl> */}
            </div>
        );
    }
};

function mapStateToProps(state, ownProps) {
    return {
        generalParameters: state.generalParameters
    }
}


export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(TaskTypeFilter)));
