import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'
import store from 'store'

export const Recaladas = {
    getRecaladas: (args = {}) => {
        return new Promise((resolve, reject) => {
            let state = store.getState();
            let { search, port, from, to, emails, returnClosedRecaladas, getActiveRecaladas } = args;
            if (!port && state.currentPort && state.currentPort.code) {
                port = state.currentPort.code;
            }

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/recaladas?port=${port ? port : ''}`;
                if (emails) {
                    url += `&emails=${true}`
                }
                if (search) {
                    url += `&search=${search}`
                }
                if (from && to) {
                    url += `&from=${from}&to=${to}`
                }
                if (returnClosedRecaladas) {
                    url += `&returnClosedRecaladas=true`
                }
                if (getActiveRecaladas) {
                    url += `&getActiveRecaladas=true`
                }

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    getRecalada: (args = {}) => {
        return new Promise((resolve, reject) => {
            let { recaladaId } = args;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/recaladas/${recaladaId}`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    getRecaladaTasks: (args = {}) => {
        return new Promise((resolve, reject) => {
            let { recaladaId } = args;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                let url = `${config.apiGateway.url}/v1/recaladas/${recaladaId}/tasks`;
                var headers = new Headers();

                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    getRecaladaEmails: (args = {}) => {
        return new Promise((resolve, reject) => {
            let { recaladaId } = args;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                let url = `${config.apiGateway.url}/v1/recaladas/${recaladaId}/emails`;
                var headers = new Headers();

                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    getRecaladaDocuments: (args) => {
        return new Promise((resolve, reject) => {
            let { recaladaId } = args;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/recaladas/${recaladaId}/documents`;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    createRecalada: (args) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/recaladas`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = args;

                fetch(url, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateRecalada: (args) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/recaladas/${args.recaladaId}`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = args;

                fetch(url, {
                    method: 'PUT',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    closeRecalada: ({ recaladaId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/recaladas/${recaladaId}/close`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                fetch(url, {
                    method: 'PATCH',
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    openRecalada: ({ recaladaId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/recaladas/${recaladaId}/open`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                fetch(url, {
                    method: 'PATCH',
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    }
}