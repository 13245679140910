import React, { useState, useEffect } from 'react'
import {
    TextField,
    Button,
    IconButton,
    Typography,
    CircularProgress,
    withStyles
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import styles from './styles'
import { Tasks } from 'services'

import { useDispatch } from 'react-redux'
import { updateTaskName } from 'actions/tasksActions'

const Name = (props) => {
    const { task, classes } = props;
    const { t } = useTranslation()
    const [showActions, setShowActions] = useState(false);
    const [saving, setSaving] = useState(false)
    const [taskName, setTaskName] = useState(task ? task.name : '')
    const dispatch = useDispatch();

    useEffect(() => {
        let isSuscribed = true;
        if (!taskName && task && task.name && isSuscribed) {
            setTaskName(task.name)
        }
        return () => {
            isSuscribed = false;
        };
    }, [task, taskName])

    const handleNameClick = () => {
        //Deshabilitamos la capacidad de cambiar el titulo de la tarea
        setShowActions(task.taskTemplateId ? false : true)
        return;
    }

    const handleInputChange = (e) => {
        if (e.target.value.length <= 150) {
            setTaskName(e.target.value)
        }
    }
    const handleSaveName = () => {
        setSaving(true)
        Tasks.updateTaskName({ taskId: task.taskId, name: taskName })
            .then(response => {
                if (response.success) {
                    dispatch(updateTaskName(response.record))
                    setShowActions(false)
                    setSaving(false)
                }
            })
    }

    const handleCancel = () => {
        setShowActions(false)
        setTaskName(task.name)
    }

    return (
        <div>
            {!showActions && (
                <div className={classes.name__container} style={!task.taskTemplateId ? { cursor: 'pointer' } : {}}>
                    {task.code &&
                        <Typography style={{ fontSize: '20px', marginRight: '10px' }} component='span'>
                            {task.code}
                        </Typography>
                    }
                    <Typography variant='h4' onClick={handleNameClick}>{taskName}</Typography>
                </div>
            )}
            {
                showActions && (
                    <>
                        <TextField
                            className={classes.textfield}
                            placeholder={t('Escriba un nombre')}
                            value={taskName || ''}
                            onChange={handleInputChange}
                            autoFocus
                            disabled={saving}
                        />
                        <div className={classes.actions}>
                            <Button
                                disabled={saving || !taskName || (taskName && taskName === task.name) ? true : false}
                                variant='contained'
                                size='small'
                                color='primary'
                                className={classes.actions__save}
                                onClick={handleSaveName}>
                                {t('Guardar')}
                                {saving && (
                                    <CircularProgress color='secondary'
                                        style={{ marginLeft: 5 }}
                                        size={15} />
                                )}
                            </Button>
                            <IconButton size='small' onClick={handleCancel}>
                                <Close />
                            </IconButton>
                        </div>
                    </>
                )
            }
        </div >
    )
}
export default withStyles(styles)(Name)