import React, { useState, useEffect } from 'react'
import {
    TextField,
    Select,
    Grid,
    Button,
    Dialog,
    withMobileDialog,
    withStyles,
    Typography,
    IconButton,
    DialogTitle,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    CircularProgress,
    InputAdornment
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Recaladas } from 'services'
import { updateRecalada, createRecalada } from 'actions/recaladaActions'
import es from "date-fns/locale/es"
import en from "date-fns/locale/en-US"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CloseRecalada } from 'components/Recaladas'


import styles from './styles'

const dateColors = [
    '#0089ff',
    '#8500ff',
    '#ffc46d',
    '#32db64'
]

const EditRecalada = props => {
    const {
        fullScreen,
        classes,
        selectedRecalada,
        open,
    } = props,
        { t, i18n } = useTranslation(),
        [populated, setPopulated] = useState(false),
        [openDialgo, setOpenDialgo] = useState(open),
        [showValidations, setShowValidations] = useState(false),
        [invalidFields, setInvalidFields] = useState({}),
        [name, setName] = useState(''),
        [ETA, setETA] = useState(''),
        [departure, setDeparture] = useState(''),
        [finImpo, setFinImpo] = useState(''),
        [finOperaciones, setFinOperaciones] = useState(''),
        [selectedServiceId, setSelectedServiceId] = useState(0),
        [selectedShipId, setSelectedShipId] = useState(0),
        [selectedService, setSelectedService] = useState(null),
        [saving, setSaving] = useState(false),
        generalParameters = useSelector(state => state.generalParameters),
        currentPort = useSelector(state => state.currentPort),
        dispatch = useDispatch();

    if (i18n.language === 'en') {
        registerLocale("en", en);
    } else {
        registerLocale("es", es);
    }

    let services = generalParameters.services.filter(s => s.ships && s.ships.length > 0 && s.portCode === currentPort.code);


    useEffect(() => {
        let isMounted = true;
        if (selectedRecalada && isMounted && !populated) {
            setName(selectedRecalada.itineraryName)
            setSelectedServiceId(selectedRecalada.serviceId)
            setSelectedService(services.find(s => s.id === selectedRecalada.serviceId))
            setSelectedShipId(selectedRecalada.ship.id)
            setETA(moment(moment.utc(selectedRecalada.ETA).format()).format('YYYY-MM-DDTHH:mm'))
            setDeparture(selectedRecalada.departureDate ? moment(moment.utc(selectedRecalada.departureDate).format()).format('YYYY-MM-DDTHH:mm') : '')
            setFinImpo(selectedRecalada.endImpoDateTime ? moment(moment.utc(selectedRecalada.endImpoDateTime).format()).format('YYYY-MM-DDTHH:mm') : '')
            setFinOperaciones(selectedRecalada.endOperationsDateTime ? moment(moment.utc(selectedRecalada.endOperationsDateTime).format()).format('YYYY-MM-DDTHH:mm') : '')
            setPopulated(true)
        }
        return () => {
            isMounted = false;
        };
    }, [selectedRecalada, services, populated, setPopulated])

    useEffect(() => {
        setOpenDialgo(props.open)
    }, [props.open])

    const onClose = () => {
        props.onClose()
    }

    const handleChangeService = e => {
        setSelectedServiceId(e.target.value)
        if (e.target.value) {
            setSelectedService(services.find(s => s.id === e.target.value))
        } else {
            setSelectedService(null)
        }
    }
    const handleChangeShip = e => {
        setSelectedShipId(e.target.value)
    }

    const handleChangeName = e => {
        if (e.target.value.length <= 10) {
            setName(e.target.value)
        }
    }
    const handleChangeETA = value => {
        setETA(value)
    }
    const handleChangeDeparture = value => {
        setDeparture(value)
    }
    const handleChangeFinImpo = value => {
        setFinImpo(value)
    }
    const handleChangeFinOperaciones = value => {
        setFinOperaciones(value)
    }

    const onExited = () => {
        setName('')
        setETA('')
        setDeparture('')
        setFinImpo('')
        setFinOperaciones('')
        setSelectedServiceId(0)
        setSelectedShipId(0)
        setSelectedService(null)
        setSaving(false)
        setPopulated(false)
    }

    const handleCancel = () => {
        setOpenDialgo(false)
        onClose()
    }

    const validateParameters = () => {
        let valid = true;
        let fields = {};
        if (!selectedService) {
            valid = false;
            fields = {
                ...fields,
                service: true
            }
        }
        if (!selectedShipId) {
            valid = false;
            fields = {
                ...fields,
                ship: true
            }
        }
        if (!ETA) {
            valid = false;
            fields = {
                ...fields,
                eta: true
            }
        }
        // if (!finImpo) {
        //     valid = false;
        //     fields = {
        //         ...fields,
        //         finImpo: true
        //     }
        // }
        // if (!finOperaciones) {
        //     valid = false;
        //     fields = {
        //         ...fields,
        //         finOperaciones: true
        //     }
        // }
        // if (!departure) {
        //     valid = false;
        //     fields = {
        //         ...fields,
        //         departure: true
        //     }
        // }

        if (name.trim() === '') {
            valid = false;
            fields = {
                ...fields,
                name: true
            }
        }
        if (!valid) {
            setShowValidations(true)
            setInvalidFields(fields)
        } else {
            setShowValidations(false)
        }
        return valid;
    }
    const handleSave = () => {
        if (validateParameters()) {
            setSaving(true)
            if (selectedRecalada && selectedRecalada.recaladaId) {
                let parameters = {
                    recaladaId: selectedRecalada.recaladaId,
                    name,
                    shipId: selectedShipId,
                    serviceId: selectedServiceId,
                    portCode: currentPort.code,
                    ETA: moment.utc(moment(ETA).format()).format(),
                    departure: departure ? moment.utc(moment(departure).format()).format() : null,
                    endImpo: finImpo ? moment.utc(moment(finImpo).format()).format() : null,
                    endOperations: finOperaciones ? moment.utc(moment(finOperaciones).format()).format() : null,
                }
                Recaladas.updateRecalada(parameters)
                    .then(response => {
                        if (response.success) {
                            dispatch(updateRecalada(response.record))
                            setSaving(false)
                            setOpenDialgo(false)
                            onClose()
                        }
                    })
            } else {
                let parameters = {
                    name,
                    shipId: selectedShipId,
                    serviceId: selectedServiceId,
                    portCode: currentPort.code,
                    ETA: moment.utc(moment(ETA).format()).format(),
                    departure: departure ? moment.utc(moment(departure).format()).format() : null,
                    endImpo: finImpo ? moment.utc(moment(finImpo).format()).format() : null,
                    endOperations: finOperaciones ? moment.utc(moment(finOperaciones).format()).format() : null,
                }
                Recaladas.createRecalada(parameters)
                    .then(response => {
                        if (response.success) {
                            dispatch(createRecalada(response.record))
                            setSaving(false)
                            setOpenDialgo(false)
                            onClose()
                        }
                    })
            }

        }
    }

    const DatePickerCustomInput = ({ placeholderText, value, onClick, label, error, disabled, dateColor }) => (
        <TextField
            disabled={disabled}
            error={error}
            label={label}
            value={value}
            placeholder={placeholderText}
            classes={{
                root: classes.datePicker__input__root
            }}
            InputProps={{
                endAdornment: dateColor ? (<InputAdornment position='end'>
                    <div style={{ width: 15, height: 15, borderRadius: '50%', backgroundColor: dateColor }}></div>
                </InputAdornment>) : null,
                inputProps: {
                    className: classes.textField__input
                }
            }}
            InputLabelProps={{
                shrink: true
            }}
            variant='outlined'
            onClick={onClick} />
    );

    const setDateColorClass = date => {
        let className = undefined;
        if (moment(date).format('DD/MM/YYYY') === moment(ETA).format('DD/MM/YYYY')) {
            className = classes.eta__date__calendar;
        }
        if (moment(date).format('DD/MM/YYYY') === moment(finImpo).format('DD/MM/YYYY')) {
            className = classes.endImpo__date__calendar;
        }
        if (moment(date).format('DD/MM/YYYY') === moment(finOperaciones).format('DD/MM/YYYY')) {
            className = classes.endOperations__date__calendar;
        }
        if (moment(date).format('DD/MM/YYYY') === moment(departure).format('DD/MM/YYYY')) {
            className = classes.departure__date__calendar;
        }
        return className;
    }

    return (
        <div>
            <Dialog
                open={openDialgo}
                fullScreen={fullScreen}
                classes={{
                    paper: classes.dialog__paper
                }}
                onExited={onExited}
                maxWidth='xs'>
                <DialogTitle
                    className={classes.dialog__title}>
                    <div className={classes.dialog__title__container}>
                        <Typography variant='h5' color='inherit'>{selectedRecalada && selectedRecalada.recaladaId ? t('New recalada') : t('Edit recalada')} - {currentPort.name}</Typography>
                        <IconButton
                            color='inherit'
                            onClick={onClose} >
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent classes={{
                    root: classes.dialog__container
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl
                                className={classes.select__formControl}
                                variant="outlined">
                                <InputLabel id="operation-type-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}
                                    shrink>
                                    {t('Service')}
                                </InputLabel>
                                <Select
                                    error={showValidations && invalidFields.service}
                                    placeholder={t('Select Service')}
                                    value={selectedServiceId}
                                    onChange={handleChangeService}
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={80}
                                    disabled={saving || (selectedRecalada && selectedRecalada.recaladaId ? true : false)}>
                                    <MenuItem value={0}>
                                        <em>{t('Select Service')}</em>
                                    </MenuItem>
                                    {services.map(service => {
                                        return (
                                            <MenuItem key={service.id}
                                                value={service.id}>
                                                {service.liner.name} - {service.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                className={classes.select__formControl}
                                variant="outlined"
                                disabled={saving || (selectedRecalada && selectedRecalada.recaladaId ? true : (!selectedService ? true : false))}
                            >
                                <InputLabel id="operation-type-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}
                                    shrink>
                                    {t('Ship')}
                                </InputLabel>
                                <Select
                                    error={showValidations && invalidFields.ship}
                                    placeholder={t('Select Ship')}
                                    value={selectedShipId}
                                    onChange={handleChangeShip}
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={80}>
                                    <MenuItem value={0}>
                                        <em>{t('Select Ship')}</em>
                                    </MenuItem>
                                    {selectedService && selectedService.ships.map(ship => {
                                        return (
                                            <MenuItem key={ship.shipId}
                                                value={ship.shipId}>
                                                {ship.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <DatePicker
                                locale={i18n.language}
                                disabled={saving}
                                selected={ETA ? new Date(ETA) : null}
                                onChange={date => handleChangeETA(date)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="dd/MM/yyyy HH:mm"
                                dayClassName={setDateColorClass}
                                customInput={<DatePickerCustomInput
                                    disabled={saving}
                                    label={moment(ETA).isBefore(moment(), 'day') ? t('Arrival').toUpperCase() : t('ETA')}
                                    dateColor={dateColors[0]}
                                    error={showValidations && invalidFields.eta}
                                    placeholderText={i18n.language === 'en' ? "dd/mm/yyyy --:--" : "dd/mm/aaaa --:--"}
                                />}
                            />
                        </Grid>
                        {selectedRecalada && selectedRecalada.recaladaId && (
                            <>
                                <Grid item xs={6} sm={6}>
                                    <DatePicker
                                        disabled={saving}
                                        locale={i18n.language}
                                        selected={finImpo ? new Date(finImpo) : null}
                                        onChange={date => handleChangeFinImpo(date)}
                                        minDate={ETA ? new Date(ETA) : null}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        dayClassName={setDateColorClass}
                                        customInput={<DatePickerCustomInput
                                            disabled={saving}
                                            dateColor={dateColors[1]}
                                            label={t('Fin Importación')}
                                            error={showValidations && invalidFields.finImpo}
                                            placeholderText={i18n.language === 'en' ? "dd/mm/yyyy --:--" : "dd/mm/aaaa --:--"}
                                        />}
                                    />

                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <DatePicker
                                        locale={i18n.language}
                                        disabled={saving}
                                        selected={finOperaciones ? new Date(finOperaciones) : null}
                                        onChange={date => handleChangeFinOperaciones(date)}
                                        showTimeSelect
                                        minDate={ETA ? new Date(ETA) : null}
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        dayClassName={setDateColorClass}
                                        customInput={<DatePickerCustomInput
                                            disabled={saving}
                                            dateColor={dateColors[2]}
                                            label={t('Fin Operaciones')}
                                            error={showValidations && invalidFields.finOperaciones}
                                            placeholderText={i18n.language === 'en' ? "dd/mm/yyyy --:--" : "dd/mm/aaaa --:--"}
                                        />}
                                    />

                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <DatePicker
                                        locale={i18n.language}
                                        disabled={saving}
                                        selected={departure ? new Date(departure) : null}
                                        onChange={date => handleChangeDeparture(date)}
                                        showTimeSelect
                                        minDate={ETA ? new Date(ETA) : null}
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        dayClassName={setDateColorClass}
                                        customInput={<DatePickerCustomInput
                                            disabled={saving}
                                            dateColor={dateColors[3]}
                                            label={t('Zarpe')}
                                            error={showValidations && invalidFields.departure}
                                            placeholderText={i18n.language === 'en' ? "dd/mm/yyyy --:--" : "dd/mm/aaaa --:--"}
                                        />}
                                    />

                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={saving}
                                error={showValidations && invalidFields.name}
                                label={t('Name')}
                                value={name}
                                onChange={handleChangeName}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">V -</InputAdornment>,
                                    inputProps: {
                                        className: classes.textField_addornment__input
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant='outlined' />
                        </Grid>
                        {selectedRecalada && (
                            <Grid item xs={12} sm={6} style={{ justifyContent: 'center', display: 'flex' }}>
                                <CloseRecalada recalada={selectedRecalada} />
                            </Grid>
                        )}
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                disabled={saving}
                                onClick={handleCancel}>
                                {t('Cancel')}
                            </Button>
                            <Button
                                disabled={saving}
                                variant='contained'
                                color='primary'
                                onClick={handleSave}
                            >
                                {t('Save')}
                                {saving && (
                                    <CircularProgress color='secondary'
                                        style={{ marginLeft: 5 }}
                                        size={15} />
                                )}
                            </Button>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog >
        </div >
    )
}



export default compose(
    withMobileDialog(),
    withStyles(styles)
)(EditRecalada)