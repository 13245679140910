import {
    SET_SERVICE,
    CLEAR_SERVICE,
    UPDATE_SERVICE
} from 'actions/serviceActions'

export default function service(state = null, action) {
    switch (action.type) {
        case SET_SERVICE:
            return action.service
        case CLEAR_SERVICE:
            return null;
        case UPDATE_SERVICE:
            if (state.id === action.data.id) {
                return {
                    ...state,
                    ...action.data,
                };
            }
            return state
        default:
            return state;
    }
}