export default theme => ({
    root: {

    },
    attachments__container: {
        // padding: theme.spacing(),
        display: 'flex',
    },
    attachmentLink__container: {
        marginBottom: 10,
        display: 'flex',
        height: 'fit-content'
    },
    attachment__name__container: {
        marginLeft: 10
    },
    actions: {
        marginTop: theme.spacing(),
        display: 'flex'
    },
    keywords__textfield: {
        padding: theme.spacing(),
        marginRigth: 5
    },
    keyword__chip: {
        marginRight: 10,
        marginBottom: 10,
        marginTop: 10,
    },
    add__keyword__actions__container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 10,
        marginTop: 10,
    },
    link: {
        color: theme.palette.primary.main
    },
    attach__button: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontWeight: 500,
    },
    attach__input__file: {
        display: 'none'
    },
    attachment__item: {
        margin: 10
    },
    attachment__label__container: {
        display: 'flex', alignItems: 'center'
    },
    attachment__remove__button: {
        cursor: 'pointer',
        color: 'red',
        fontSize: '1.2em',
    },
    new_attachments__container: {
        display: 'flex',
        alignItems: 'center'
    },
    upload__button__container: { width: '100%', padding: 10 },
    attach__icon: { fontSize: 16, width: 16, marginRight: 10 },

})
