export default theme => ({
    root: {

    },
    attachments__container: {
        padding: theme.spacing(),
        borderRadius: 5,
        display: 'flex',
        // flexDirection: 'column',
        backgroundColor: '#f4f4f4',
        margin: `${theme.spacing(2)}px 0`,
        flexWrap: 'wrap',
    },
    attachmentLink__container: {
        marginBottom: 8,
        display: 'flex'
    },
    attachment__name__container: {
        marginLeft: 10
    },
    actions: {
        marginTop: theme.spacing(),
        display: 'flex'
    },
    keywords__textfield: {
        padding: theme.spacing(),
        marginRigth: 5
    },
    keyword__chip: {
        marginRight: 10,
        marginBottom: 10,
        marginTop: 10,
    },
    add__keyword__actions__container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 10,
        marginTop: 10,
    },
    link: {
        color: theme.palette.primary.main
    }
    , attach__button: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontWeight: 500,
    },
    attach__input__file: {
        display: 'none'
    },
    attachment__item: {
        margin: 10
    },
    attachment__label__container: {
        display: 'flex', alignItems: 'center'
    },
    attachment__remove__button: {
        cursor: 'pointer',
        color: 'red',
        fontSize: '1.2em',
    },
    new_attachments__container: {
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: 5,
        backgroundColor: '#f9f9f9',
        padding: 10,
        margin: '20px 0',
        border: 'solid thin #cdcdcd',
    },
    upload__button__container: { width: '100%', padding: 10 },
    attach__icon: { fontSize: 16, width: 16, marginRight: 10 },
    add__attachment__button: {
        textTransform: 'capitalize',
        border: `1px solid ${theme.palette.primary.main}`
    },
})
