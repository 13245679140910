// Actions types

export const CREATE_RECALADA = 'CREATE_RECALADA'
export const UPDATE_RECALADA = 'UPDATE_RECALADA'
export const SET_RECALADA = 'SET_RECALADA'
export const SET_RECALADA_EMAILS = 'SET_RECALADA_EMAILS'
export const SET_RECALADA_TASKS = 'SET_RECALADA_TASKS'
export const SET_RECALADA_DETAILS = 'SET_RECALADA_DETAILS'
export const SET_RECALADA_DOCUMENTS = 'SET_RECALADA_DOCUMENTS'
export const CLOSE_RECALADA = 'CLOSE_RECALADA'



//Actions
export function createRecalada(recalada) {
    return {
        type: CREATE_RECALADA,
        recalada
    }
}
export function updateRecalada(recalada) {
    return {
        type: UPDATE_RECALADA,
        recalada
    }
}
export function setRecalada(recalada) {
    return {
        type: SET_RECALADA,
        recalada
    }
}


export function setRecaladaEmails(emails) {
    return {
        type: SET_RECALADA_EMAILS,
        emails
    }
}

export function setRecaladaTasks(tasks) {
    return {
        type: SET_RECALADA_TASKS,
        tasks
    }
}

export function setRecaladaDocuments(documents) {
    return {
        type: SET_RECALADA_DOCUMENTS,
        documents
    }
}

export function setRecaladaDetails(details) {
    return {
        type: SET_RECALADA_DETAILS,
        details
    }
}

export function closeRecalada(details) {
    return {
        type: CLOSE_RECALADA,
        details
    }
}