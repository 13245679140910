import React from 'react';

// Material helpers
import { withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

// Material components
import { 
	Grid,
	CircularProgress
} from '@material-ui/core';
import { 
	Portlet,
	PortletContent,
	PortletHeader,
	PortletLabel
} from 'components'

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import UserForm from 'views/Users/UserForm';

// Component styles
const styles = theme => ({
	root: {
		padding: theme.spacing(1) * 4
	},
	progressWrapper: {
		paddingTop: '48px',
		paddingBottom: '24px',
		display: 'flex',
		justifyContent: 'center',
		minWidth: '500px'
	}
});

const Account = props => {
	const { classes, t, account } = props;

	return (
	  	<DashboardLayout title={t("Account")}>
			<div className={classes.root}>
				<Grid
					container
					spacing={4}
				>
					<Grid
						item
						lg={8}
						md={6}
						xl={8}
						xs={12}
					>
						<Portlet style={{width: 'min-content'}}>
							<PortletHeader>
								<PortletLabel
									subtitle={account ? account.profileName : ''}
									title={t("Profile")}
								/>
							</PortletHeader>
							<PortletContent>
								{ account && account.id ?
									<UserForm
										updateUser={props.updateUser}
										selectedUser={account}
										noAdminUser={true}
									/>
								:
									<div className={classes.progressWrapper}>
										<CircularProgress />
									</div>
								}
							</PortletContent>
						</Portlet>
			  		</Grid>
		  		</Grid>
			</div>
	 	</DashboardLayout>
	);
}

function mapStateToProps(state, ownProps) {
    return {
        account: state.account
    }
}

export default connect(mapStateToProps, null)(
  compose(
	withStyles(styles),
	withTranslation()
  )(Account))