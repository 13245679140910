import React, { useState, useEffect } from 'react'
import {
    withStyles,
    IconButton,
    List,
    Typography,
    ListItem,
    Button,
    Link,
    TextField,
    Divider
} from '@material-ui/core'
import { Close, List as ListIcon } from '@material-ui/icons'
import styles from './styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ConfirmationDialog } from 'components'


const CheckList = (props) => {
    const { classes, addChecklist, updateChecklist } = props;
    const taskTemplate = useSelector(state => (state.taskTemplate))
    const [checked, setChecked] = useState([])
    const [checkListTasks, setChecklistTasks] = useState(taskTemplate ? taskTemplate.checklist : [])
    const [selectedTask, setSelectedTask] = useState(null)
    const [content, setContent] = useState('')
    const [activeEditRow, setActiveEditRow] = useState(false)
    const [addNewItem, setAddNewItem] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        let isSuscribed = true;

        if (taskTemplate && taskTemplate.checklist && checked.length === 0 && isSuscribed) {
            let newChecked = [...checked]
            newChecked.push(0)

            taskTemplate.checklist.forEach(lt => {
                if (lt.done) {
                    newChecked.push(lt.id)
                    setChecked(newChecked)
                }
            })

            setChecklistTasks(taskTemplate.checklist)
        }

        return () => {
            isSuscribed = false;
        };
    }, [taskTemplate, checked])


    const deleteTask = (task) => {
        let newCheckListTasks = [...checkListTasks].filter(t => t.id !== task.id)
        setChecklistTasks(newCheckListTasks)
        addChecklist && addChecklist(newCheckListTasks)
        setActiveEditRow(false)
        setSelectedTask(null)
        setContent('')
        finishEdition(newCheckListTasks)
    }

    const onToggleEditTask = (e, task) => {
        e.preventDefault()
        setActiveEditRow(true)
        setSelectedTask(task)
        setContent(task.content)
    }

    const handleInputChange = e => {
        e.stopPropagation()
        setContent(e.target.value)
    }

    const handleCancel = () => {
        setActiveEditRow(false)
        setSelectedTask(null)
        setContent('')
    }

    const handleSave = () => {
        let checklistIndex = checkListTasks.findIndex(ch => ch.id === selectedTask.id),
            newCheckListTasks = [...checkListTasks]

        newCheckListTasks[checklistIndex].content = content
        setChecklistTasks(newCheckListTasks)
        addChecklist && addChecklist(newCheckListTasks)
        handleCancel()
        finishEdition(newCheckListTasks)
    }

    const handleAddItem = () => {
        setAddNewItem(true)
    }

    const handleCancelAdd = () => {
        setAddNewItem(false)
    }

    const handleSaveItem = () => {
        let newCheckListTasks = [...checkListTasks]

        const max = taskTemplate && taskTemplate.checklist && taskTemplate.checklist.length > 0 ? taskTemplate.checklist.reduce((prev, current) => {
            return (prev.id > current.id) ? prev : current
        }, { id: 0 }) : newCheckListTasks.reduce((prev, current) => {
            return (prev.id > current.id) ? prev : current
        }, { id: 0 })

        newCheckListTasks.push({
            id: max.id + 1,
            content
        })

        setChecklistTasks(newCheckListTasks)
        setContent('')
        addChecklist && addChecklist(newCheckListTasks)
        finishEdition(newCheckListTasks)
    }

    const finishEdition = (newCheckListTasks) => {
        updateChecklist && updateChecklist(newCheckListTasks);
    }


    return (
        <div>
            {checkListTasks && checkListTasks.length > 0 && (
                <div style={{
                    margin: '16px 0 8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography variant='h6' style={{ display: 'flex', alignItems: 'center' }} >
                        {t('Checklist')}
                    </Typography>
                </div>
            )}
            {checkListTasks && checkListTasks.length > 0 && (
                <div className={classes.description__container} >
                    <List className={classes.checklist__list}>
                        {checkListTasks.map((task, index) => {
                            return (
                                <ListItem key={task.id} dense button
                                    onClick={!activeEditRow ? (e) => onToggleEditTask(e, task) : null}
                                >

                                    {activeEditRow && selectedTask && selectedTask.id === task.id ?
                                        <div style={{ width: '100%' }}>
                                            <div>
                                                <TextField
                                                    multiline
                                                    className={classes.description__textfield}
                                                    value={content}
                                                    onChange={handleInputChange}
                                                    autoFocus
                                                    fullWidth
                                                />
                                            </div>
                                            <div style={{ marginTop: '8px', display: 'flex', justifyContent: 'space-between' }}>
                                                <div>
                                                    <Button
                                                        size='small'
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={handleSave}
                                                    >
                                                        {t('Save')}
                                                    </Button>
                                                    <IconButton edge="end" aria-label="comments" onClick={handleCancel}>
                                                        <Close />
                                                    </IconButton>
                                                </div>

                                                <Button
                                                    size='small'
                                                    style={{ color: 'red' }}
                                                    onClick={() => {
                                                        setSelectedTask(task);
                                                        setOpenConfirmation(true);
                                                    }}
                                                >
                                                    {t('Delete')}
                                                </Button>
                                            </div>
                                        </div>
                                        :
                                        <div className={classes.checkListText}>
                                            <Typography variant='body1'
                                                color='textSecondary'
                                                style={{ marginRight: 10 }}>
                                                {index + 1}.
                                            </Typography>
                                            <Typography variant='body1' style={{ fontSize: '16px' }}>
                                                {task.content}
                                            </Typography>
                                        </div>
                                    }
                                </ListItem>
                            )
                        })}
                    </List>
                </div>
            )}
            {!addNewItem ?
                <Link
                    variant='body1'
                    color="primary"
                    onClick={handleAddItem}
                    className={classes.add__subtask__button}
                >
                    <ListIcon className={classes.list__icon} />
                    {t('Add Subtask')}
                </Link>
                :
                <div style={{ width: '100%', padding: '4px' }} className={classes.description__container}>
                    <div>
                        <TextField
                            multiline
                            className={classes.description__textfield}
                            value={content}
                            // onBlur={handleBlurDescription}
                            onChange={handleInputChange}
                            autoFocus
                            fullWidth
                        // disabled={saving}
                        />
                    </div>
                    <div style={{ marginTop: '8px' }}>

                        <Button
                            size='small'
                            variant='contained'
                            color='primary'
                            onClick={handleSaveItem}
                        >
                            {t('Add')}
                        </Button>
                        <IconButton edge="end" aria-label="comments" onClick={handleCancelAdd}>
                            <Close />
                        </IconButton>
                    </div>
                </div>
            }
            <ConfirmationDialog
                handleClose={() => {
                    setOpenConfirmation(false)
                }}
                onConfirm={() => {
                    setOpenConfirmation(false)
                    deleteTask(selectedTask)
                }}
                message={t('Are you sure you wish to delete this item?')}
                cancelLabel={t('Cancel')}
                confirmLabel={t('Continue')}
                open={openConfirmation}
            />
            {checkListTasks && checkListTasks.length > 0 && (
                <Divider style={{ marginTop: 15 }} />
            )}
        </div>
    )
}

export default withStyles(styles)(CheckList)