import {
    SET_ACCOUNT
} from 'actions/accountActions'
import { SET_USERS_FILTER } from 'actions/usersFilters'

export default function usersFilter(state = [], action) {
    switch (action.type) {
        case SET_ACCOUNT:
            if (!state || (state && state.length === 0)) {
                return [action.account];
            }
            return state;
        case SET_USERS_FILTER:
            return [...action.filter];
        default:
            return state;
    }
}