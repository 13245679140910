import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next'

// Material components
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    withStyles
} from '@material-ui/core';

//Redux
import { connect } from 'react-redux'

const styles = theme => {
    return {
        select__root: {
            padding: '10px 16px'
        },
        select__label: {
            transform: 'translate(14px, 14px) scale(1)'
        }
    };
};

const UserPortFilter = props => {
    const { t, generalParameters, classes, currentPort } = props,
        [selectedPort, setSelectedPort] = useState(''),
        [ports, setPorts] = useState([])

    useEffect(() => {
        setSelectedPort(currentPort.code)
    }, [currentPort])


    useEffect(() => {
        if (generalParameters && generalParameters.ports) {
            setPorts(generalParameters.ports)
        }
    }, [generalParameters, generalParameters.ports])

    if (!generalParameters && !generalParameters.ports) return null

    const handleChange = (e) => {
        setSelectedPort(e.target.value)
        props.onChange(e.target.value)
    };

    return (
        <div >
            <FormControl variant="outlined" style={{ minWidth: '200px' }}>
                <InputLabel id="operation-type-selector"
                    classes={{
                        root: classes.select__label
                    }}>
                    {t('Port')}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedPort}
                    onChange={handleChange}
                    classes={{
                        root: classes.select__root
                    }}
                    labelWidth={45}>

                    <MenuItem value={'All'}>
                        <em>{t('All')}</em>
                    </MenuItem>

                    {ports.map(port => {
                        return (
                            <MenuItem key={port.portCode} value={port.portCode}>{port.portName}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        generalParameters: state.generalParameters,
        currentPort: state.currentPort
    }
}


export default connect(mapStateToProps, null)(withTranslation()(withStyles(styles)(UserPortFilter)));
