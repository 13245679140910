import React, { useState, useEffect } from 'react'

import {
    Grid,
    makeStyles,
} from '@material-ui/core'
import { Reminder, ReminderForm } from './';
import styles from './styles'

const useStyles = makeStyles(styles);

const RemindersList = props => {
    const classes = useStyles(),
        { task, showEmailDetail, setOpenMessagesDetails, setSelectedLog } = props,
        [showReminderForm, setShowReminderForm] = useState(false),
        [selectedReminder, setSelectedReminder] = useState(null),
        [reminders, setReminders] = useState(task ? task.reminders || [] : []);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (task.remindersSent && task.remindersSent.length > 0) {
                setReminders(task.remindersSent)
            } else {
                setReminders(task.reminders)
            }
        }
        return () => {
            isMounted = false
        }
    }, [task])

    const handleSelect = (reminder) => {
        setSelectedReminder(reminder)
        setShowReminderForm(true)
    }
    const handleUnselect = () => {
        setSelectedReminder(null)
    }

    return (
        <div className={classes.reminders__root}>
            <div className={classes.reminders__list}>
                <Grid container spacing={4}>
                    {reminders.map((reminder, i) => (
                        <Grid item
                            xs={12}
                            key={i}>
                            <Reminder reminder={reminder}
                                task={task}
                                showEmailDetail={showEmailDetail}
                                setSelectedLog={setSelectedLog}
                                setOpenMessagesDetails={setOpenMessagesDetails}
                                handleSelect={handleSelect}
                                onDeleteConfirmation={() => {
                                    handleUnselect()
                                    setShowReminderForm(false)
                                }} />
                        </Grid>
                    ))}

                    {showReminderForm && (
                        <Grid item xs={12}
                            style={{
                                display: 'flex',
                                //justifyContent: 'center'
                            }}>
                            <ReminderForm
                                unselectReminder={handleUnselect}
                                reminder={selectedReminder}
                                task={task}
                                onSave={() => {
                                    setShowReminderForm(false)
                                }}
                                onCancel={() => {
                                    handleUnselect()
                                    setShowReminderForm(false)
                                }} />
                        </Grid>
                    )}
                </Grid>

            </div>
        </div>
    )
}

export default RemindersList; 
