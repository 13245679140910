import React, { useState, useEffect } from 'react';

// Externals
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Material helpers
import {
	makeStyles,
	CircularProgress,
} from '@material-ui/core';

import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'


// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import Details from './Details';
import List from './List';
import stylesFile from './styles'

// Component styles
const styles = makeStyles(stylesFile);

const Entities = (props) => {
	const { generalParameters, match, currentPort, entities } = props,
		[selectedEntity, setSelectedEntity] = useState(null),
		[currentEntities, setCurrentEntities] = useState(entities ? entities[currentPort] : []),
		{ t, i18n } = useTranslation(),
		classes = styles();

	useEffect(() => {
		if (!match.params.entityId) {
			setSelectedEntity(null)
			setCurrentEntities(entities ? entities[currentPort] : [])
		}
		if (match.params && match.params.entityId && entities) {
			let newEntity = [...currentEntities].find(en => en.id === match.params.entityId)

			setSelectedEntity(newEntity)
		}
	}, [match, currentEntities, currentPort, entities])

	const handleSelectedEntity = (entity) => {
		if (entity) {
			props.history.push(`/${i18n.language}/${currentPort.code}/settings/entities/${entity.id}`)
		} else {
			props.history.push(`/${i18n.language}/${currentPort.code}/settings/entities`)
		}
		setSelectedEntity(entity)
	}



	return (
		<DashboardLayout
			title={selectedEntity ? `${t('Entity')} > ${selectedEntity.name}` : t('Entity')}
			showBackMenuButton={selectedEntity ? true : false}
			goBackUrl={`/${i18n.language}/${currentPort ? currentPort.code : match.params.port}/settings/entities`}
		>
			<div className={isMobile ? classes.mobile_root : classes.root}>
				{generalParameters.loading ? (
					<div style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: 300
					}} >
						<CircularProgress />
					</div>
				) : (
						<div style={{ position: 'relative' }}>
							{selectedEntity ?
								<Details
									selectedEntity={selectedEntity}
								/>
								:
								<List
									setSelectedEntity={handleSelectedEntity}
									selectedEntity={selectedEntity}
								/>
							}
						</div>
					)}

			</div>
		</DashboardLayout>
	);
}

function mapStateToProps(state) {
	return {
		generalParameters: state.generalParameters,
		currentPort: state.currentPort,
		entities: state.entities
	}
}

export default connect(mapStateToProps, null)(withRouter(Entities));
