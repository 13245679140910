import React, { useState, useEffect } from 'react'
import {
    MenuItem,
    FormControl,
    Select,
    withStyles,
    Typography,
    Button,
    IconButton
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'


import styles from './styles'

const Entity = props => {
    const { classes, taskTemplate } = props,
        { t } = useTranslation(),
        entities = useSelector(state => state.generalParameters.entities),
        [showAddSection, setShowAddSection] = useState(false),
        currentPort = useSelector(state => state.currentPort),
        currentEntities = entities[currentPort.code],
        [selectedEntities, setSelectedEntities] = useState(taskTemplate && taskTemplate.entityId ? [taskTemplate.entityId] : []);

    useEffect(() => {
        let isSuscribed = true;
        if (taskTemplate && taskTemplate.entity && isSuscribed) {
            setSelectedEntities(taskTemplate.entity)
        }

        return () => {
            isSuscribed = false;
        };
    }, [taskTemplate])

    if (!taskTemplate) return null;


    const handleChangeEntity = (e) => {
        setSelectedEntities(e.target.value)
        setShowAddSection(true)
    }

    const handleSaveEntities = () => {
        alert('Implementando')
        // Tasks.updateTaskTemplateEntities({
        //     taskTemplateId: taskTemplate.id,
        //     keywords: newKeywords
        // })
        //     .then(updtateResponse => {
        //         if (updtateResponse.success) {
        //             dispatch(updateTaskTemplateKeywords({
        //                 serviceTemplateId: taskTemplate.serviceTemplateId,
        //                 taskTemplateId: taskTemplate.id,
        //                 keywords: newKeywords
        //             }))
        //         }
        //     })
    }

    const handleCancel = () => {
        setShowAddSection(false)
    }

    return (
        <>
            <Typography variant='h6'>{t('Entities')}</Typography>
            <div className={classes.root}>
                <FormControl variant="outlined" style={{ width: '100%', marginRight: 10 }}>
                    <Select
                        labelId="task-type-selector"
                        placeholder={t('Select entity')}
                        value={selectedEntities}
                        multiple
                        onChange={handleChangeEntity}
                        variant='outlined'
                        classes={{
                            root: classes.select__root
                        }}
                        labelWidth={0}
                        fullWidth={true}
                    >
                        {currentEntities.map(type => {
                            return (
                                <MenuItem key={type.entityId} value={type.entityId}>{type.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </div>

            {showAddSection && (
                <div className={classes.manage__entity__actions__container}>
                    <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        onClick={handleSaveEntities}
                        className={classes.actions__save}
                    >{t('Agregar')}</Button>
                    <IconButton size="small"
                        onClick={handleCancel}>
                        <Close />
                    </IconButton>
                </div>
            )}
        </>
    )
}

export default withStyles(styles)(Entity)