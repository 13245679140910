export const CREAETE_SERVICE_TEMPLATE = 'CREAETE_SERVICE_TEMPLATE'
export const UPDATE_SERVICE_TEMPLATE_NAME = 'UPDATE_SERVICE_TEMPLATE_NAME'

export function createServiceTemplate(data) {
    return {
        type: CREAETE_SERVICE_TEMPLATE,
        data
    }
}

export function updateServiceTemplateName(data) {
    return {
        type: UPDATE_SERVICE_TEMPLATE_NAME,
        data
    }
}

