import React, { useState } from 'react'
import {
    Typography,
    makeStyles,
    Link,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { longDateFormat } from 'lib'
import { Warning } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        marginBottom: -1,
        width: '100%',
        paddingTop: 5,
        paddingBottom: 5,
        border: 'solid thin #cdcdcd'
    },
    dialog__paper: {
        width: '100%'
    },
    firstColumn: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 10,
        marginRight: 20,
        [theme.breakpoints.down('xs')]: {
            marginRight: 10,
            flex: 1
        }
    },
    secondColumn: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: 350,
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        }
    },
    recalada__text__part: {
        marginRight: 5,
    },
    link: {
        cursor: 'pointer'
    }
}))

const ActiveRecaladaRow = props => {
    const { recalada,
        //tasks
    } = props,
        classes = useStyles(),
        { t } = useTranslation(),
        [openDialog, setOpenDialog] = useState(false);

    // const filterByRecalada = (recaladaId) => {
    //     props.filterByRecalada && props.filterByRecalada(recaladaId);
    // }

    if (!recalada) return null;


    return (
        <>
            <div className={classes.root}>
                <Typography variant='body1' className={`${classes.firstColumn}`}>
                    <Link className={classes.link}
                        //href={`./recaladas/${recalada.recaladaId}`}
                        onClick={() => {
                            setOpenDialog(true)
                        }}>
                        {`${recalada.ship.name} V-${recalada.itineraryName}`}
                    </Link>
                </Typography>
                <div className={`${classes.secondColumn}`}>
                    <Typography variant='body2'
                        color='textSecondary'
                        style={{
                            color: moment(recalada.ETA).isBefore(moment(), 'day') ? 'green' : (moment(recalada.ETA).isAfter(moment(), 'day') ? '#0d87af' : 'grey')
                        }}
                        className={classes.recalada__text__part}>
                        {moment(recalada.ETA).isBefore(moment(), 'day') ? t('Arrived') : t('ETA')} {longDateFormat(recalada.ETA)}
                    </Typography>
                    {recalada.departureDate && (
                        <Typography variant='body2'
                            color='textSecondary'
                            style={{
                                color: moment(recalada.departureDate).isBefore(moment(), 'day') ? 'green' : (moment(recalada.departureDate).isAfter(moment(), 'day') ? '#0d87af' : 'grey')
                            }}
                            className={classes.recalada__text__part}>
                            {recalada.departureDate ? (`${moment(recalada.departureDate).isBefore(moment()) ? t('Zarpó') : t('ETD')} ${longDateFormat(recalada.departureDate)}`) : ''}
                        </Typography>
                    )}
                </div>
                {/* {recalada.tasksCounters && recalada.tasksCounters.totalDueTasks > 0 && (
                    <Warning className={classes.warningIcon}
                        style={{
                            color: 'red',
                            marginRight: 10,
                            width: 18,
                            hwight: 18
                        }} />
                )} */}
                {/* <Typography variant='body1' className={classes.recalada__text__part}
                style={{ flex: 1 }}>
                {tasks.length > 0 && (
                    <Link className={classes.link}
                        onClick={() => { filterByRecalada(recalada.recaladaId) }}>
                        {tasks.length}
                    </Link>
                )}
            </Typography> */}
            </div>
            <Dialog
                onClose={() => {
                    setOpenDialog(false)
                }}
                classes={{
                    paper: classes.dialog__paper
                }}
                maxWidth='xs'
                open={openDialog}>
                <DialogContent>
                    <Typography variant='h5' style={{ marginBottom: 10 }}>
                        {`${recalada.ship.name} V-${recalada.itineraryName}`}
                    </Typography>
                    {recalada.tasksCounters && recalada.tasksCounters.totalTasks > 0 && (
                        <Typography>
                            {t('{{tasks}} total tasks', { tasks: recalada.tasksCounters.totalTasks })}
                        </Typography>
                    )}
                    {recalada.tasksCounters && recalada.tasksCounters.totalAboutToDueTasks > 0 && (
                        <Typography>
                            {t('{{tasks}} tasks due soon', { tasks: recalada.tasksCounters.totalAboutToDueTasks })}
                        </Typography>
                    )}
                    {recalada.tasksCounters && recalada.tasksCounters.totalDoneTasks > 0 && (
                        <Typography>
                            {t('{{tasks}} tasks done', { tasks: recalada.tasksCounters.totalDoneTasks })}
                        </Typography>
                    )}
                    {recalada.tasksCounters && recalada.tasksCounters.totalDueTasks > 0 && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>
                                {t('{{tasks}} tasks past due', { tasks: recalada.tasksCounters.totalDueTasks })}
                            </Typography>
                            <Warning className={classes.warningIcon} style={{ color: 'red', marginLeft: 5 }} />
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenDialog(false)
                        }}>
                        {t('Close')}
                    </Button>
                    <Button
                        onClick={() => {
                            props.history.push(`./recaladas/${recalada.recaladaId}`)
                        }}
                        color='primary'>
                        {t('Go to Recalada')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default withRouter((ActiveRecaladaRow))