import React, { useState, useEffect } from 'react'
import {
    TextField,
    Button,
    IconButton,
    Typography,
    CircularProgress,
    withStyles
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import styles from './styles'
import { Services } from 'services'
import { useDispatch, useSelector } from 'react-redux'
import { updateService } from 'actions/serviceActions'

const Name = (props) => {
    const { classes } = props;
    const { t } = useTranslation()
    const service = useSelector(state => state.service)
    const [showActions, setShowActions] = useState(false);
    const [saving, setSaving] = useState(false)
    const [serviceName, setServiceName] = useState(service ? service.name : '')
    const dispatch = useDispatch();

    useEffect(() => {
        let isSuscribed = true;
        if (!serviceName && service && service.name && isSuscribed) {
            setServiceName(service.name)
        }
        return () => {
            isSuscribed = false;
        };
    }, [service, serviceName])

    if (!service) return null;

    const handleNameClick = () => {
        setShowActions(true)
    }

    const handleInputChange = (e) => {
        setServiceName(e.target.value)
    }

    const handleSaveName = () => {
        setSaving(true)
        Services.updateService({
            serviceId: service.id,
            name: serviceName,
            linerId: service.liner.id,
            templateId: service.template.id
        })
            .then(response => {
                if (response.success) {
                    dispatch(updateService({...response.record}))
                    setShowActions(false)
                    setSaving(false)
                }
            })
    }

    const handleCancel = () => {
        setShowActions(false)
        setServiceName(service.name)
    }

    return (
        <div>
            {!showActions && (
                <div className={classes.name__container} onClick={handleNameClick}>
                    <Typography variant='h4'>{serviceName}</Typography>
                </div>
            )}
            {showActions && (
                <>
                    <TextField
                        className={classes.textfield}
                        placeholder={t('Escriba un nombre')}
                        value={serviceName || ''}
                        onChange={handleInputChange}
                        autoFocus
                        disabled={saving}
                    />
                    <div className={classes.actions}>
                        <Button
                            disabled={saving || !serviceName || (serviceName && serviceName === service.name) ? true : false}
                            variant='contained'
                            size='small'
                            color='primary'
                            className={classes.actions__save}
                            onClick={handleSaveName}>
                            {t('Guardar')}
                            {saving && (
                                <CircularProgress color='secondary'
                                    style={{ marginLeft: 5 }}
                                    size={15} />
                            )}
                        </Button>
                        <IconButton size='small' onClick={handleCancel}>
                            <Close />
                        </IconButton>
                    </div>
                </>
            )}
        </div>
    )
}

export default withStyles(styles)(Name)