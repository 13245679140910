import React, { useEffect, useState } from 'react';

// Material helpers
import { withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'


// Material components
import {
    TextField,
    Typography,
    IconButton,
    withWidth,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Link,
    CircularProgress,
    InputAdornment
} from '@material-ui/core';
import {
    Visibility,
    VisibilityOff
} from '@material-ui/icons'
import { Users } from '../../services';
import { setAccount } from 'actions/accountActions'
import randomstring from "randomstring"



// Component styles
const styles = theme => ({
    root: {},
    field: {
        margin: theme.spacing(3)
    },
    textField: {
        width: '420px',
        maxWidth: '100%',
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    loading__container: {
        height: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    description__container: {
        padding: theme.spacing(2)
    },
    link: {
        cursor: 'pointer',
        fontSize: '11px',
    },
    cancelLink: {
        color: '#828282'
    }
})

const UserForm = props => {
    const { classes, t, selectedUser, generalParameters, noAdminUser, account, currentPort } = props,
        [firstName, setFirstName] = useState(selectedUser ? selectedUser.firstName : ''),
        [lastName, setLastName] = useState(selectedUser ? selectedUser.lastName : ''),
        [email, setEmail] = useState(selectedUser ? selectedUser.email : ''),
        [password, setPassword] = useState(''),
        [port, setPort] = useState(selectedUser ? selectedUser.portCode : currentPort ? currentPort.code : ''),
        [ports, setPorts] = useState([]),
        [profiles, setProfiles] = useState([]),
        [taskTypes, setTaskTypes] = useState([]),
        [roles, setRoles] = useState([]),
        [profile, setProfile] = useState(selectedUser ? selectedUser.profileId : 1),
        [department, setDepartment] = useState(''),
        [role, setRole] = useState(selectedUser ? selectedUser.roleId : null),
        [saving, setSaving] = useState(false),
        [validating, setValidating] = useState(false),
        [errorMessage, setErrorMessage] = useState(null),
        [changePassword, setShowChangePassword] = useState(false),
        [showCurrentPassword, setShowCurrentPassword] = useState(false),
        dispatch = useDispatch();


    useEffect(() => {
        if (generalParameters && generalParameters.ports) {
            setPorts(generalParameters.ports)
        }

        if (generalParameters && generalParameters.profiles) {
            setProfiles(generalParameters.profiles)
        }

        if (generalParameters && generalParameters.taskTypes) {
            setTaskTypes(generalParameters.taskTypes)
            if (!department && selectedUser) {
                setDepartment(selectedUser.taskTypeId ? generalParameters.taskTypes.find(type => type.id === selectedUser.taskTypeId).id : '')
            }
        }

        if (generalParameters && generalParameters.roles) {
            setRoles(generalParameters.roles)
        }

    }, [generalParameters, generalParameters.ports, generalParameters.profiles, department, generalParameters.taskTypes, generalParameters.roles, selectedUser])

    const validateEmail = (email) => {
        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleChangeEmail = e => {
        setEmail(e.target.value)
    };

    const handleChangeName = e => {
        setFirstName(e.target.value)
    };

    const handleChangeLastName = e => {
        setLastName(e.target.value)
    };

    const handleChangePort = (e) => {
        setPort(e.target.value)
    };

    const handleChangeProfile = (e) => {
        setProfile(e.target.value)
    };

    const handleChangeDepartment = (e) => {
        setDepartment(e.target.value)
    };

    const handleChangeRole = (e) => {
        setRole(e.target.value)
    };

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const validatePassword = (password) => {
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
            valid = false

        if (password && password.match(passw)) {
            valid = true
        }

        return valid
    }

    const handleSaveUSer = () => {
        setValidating(true)
        setErrorMessage(null)

        if (((changePassword && password && !validatePassword(password)) || !firstName || !lastName || (!noAdminUser && (!port || !profile)) || (!selectedUser && !email))) {
            return
        }

        if (profile === 2 && !role) {
            return
        }

        setValidating(false)
        setSaving(true)

        let newUser = {
            firstName,
            lastName,
            email,
            profileId: profile,
            roleId: role,
            portCode: port
        }


        if (!selectedUser) {
            let a = randomstring.generate({
                length: 2,
                charset: 'alphabetic',
                capitalization: 'uppercase'
            }),
                b = randomstring.generate({
                    length: 4,
                    charset: 'alphabetic',
                    capitalization: 'lowercase'
                }),
                c = randomstring.generate({
                    length: 4,
                    charset: 'numeric'
                });


            let newPassword = a + b + c
            newUser.password = encodeURIComponent(newPassword)

            Users.createUser({ user: newUser })
                .then(response => {
                    if (response.success) {
                        setSaving(false)

                        props.addUser(response.data.record)
                        props.onClose && props.onClose()
                    } else {
                        if (response.errors.code === 'UsernameExistsException') {
                            setErrorMessage(t('An account with the given email already exists.'))
                        }
                        setSaving(false)
                    }

                }).catch(err => {
                    setSaving(false)
                })
        } else {
            newUser.id = selectedUser.id

            if (password) {
                newUser.password = encodeURIComponent(password)
            }

            Users.updateUser({ user: newUser })
                .then(response => {
                    if (response.success) {
                        setSaving(false)

                        if (account.id === selectedUser.id) {
                            dispatch(setAccount(response.data.record))
                        }

                        props.updateUser && props.updateUser(response.data.record)
                        props.onClose && props.onClose()
                    } else {
                        setSaving(false)
                    }

                }).catch(err => {
                    setSaving(false)
                })
        }
    }

    const showPasswordField = () => {
        if (changePassword) {
            setPassword('')
        }
        setShowChangePassword(!changePassword)
    }

    const handleClickShowCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword)
    }

    return (
        <div>
            <form
                autoComplete="off"
                noValidate
            >
                <div className={classes.field}>
                    {selectedUser &&
                        <div style={{ margin: '24px 0' }}>
                            <Typography variant='body1'>
                                {selectedUser.email}
                            </Typography>
                        </div>
                    }

                    {!noAdminUser &&
                        <FormControl
                            variant="outlined"
                            margin="dense"
                            required
                            error={validating && !port}
                            style={{ minWidth: '200px', marginBottom: '8px' }}>
                            <InputLabel id="operation-type-selector"
                                classes={{
                                    root: classes.select__label
                                }}>
                                {t('Port')}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={port}

                                onChange={handleChangePort}
                                classes={{
                                    root: classes.select__root
                                }}
                                labelWidth={45}>

                                {ports.map(port => {
                                    return (
                                        <MenuItem key={port.portCode} value={port.portCode}>{port.portName}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    }

                    <TextField
                        className={classes.textField}
                        label={t('First name')}
                        margin="dense"
                        required
                        error={validating && !firstName}
                        value={firstName}
                        variant="outlined"
                        onChange={handleChangeName}
                    />

                    <TextField
                        className={classes.textField}
                        label={t("Last name")}
                        margin="dense"
                        required
                        error={validating && !lastName}
                        value={lastName}
                        variant="outlined"
                        onChange={handleChangeLastName}
                    />

                    {!selectedUser &&
                        <TextField
                            className={classes.textField}
                            label={t('Email')}
                            margin="dense"
                            type='email'
                            required
                            value={email}
                            helperText={errorMessage ? errorMessage : ''}
                            error={(validating && !email) || (email && !validateEmail(email)) || errorMessage}
                            variant="outlined"
                            onChange={handleChangeEmail}
                        />
                    }

                    {!noAdminUser && <>
                        <FormControl
                            variant="outlined"
                            margin="dense"
                            required
                            error={validating && !profile}
                            style={{ minWidth: '200px', marginBottom: '8px' }}>
                            <InputLabel id="operation-type-selector"
                                classes={{
                                    root: classes.select__label
                                }}>
                                {t('Profile')}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={profile}

                                onChange={handleChangeProfile}
                                classes={{
                                    root: classes.select__root
                                }}
                                labelWidth={45}>

                                {profiles.map(pro => {
                                    return (
                                        <MenuItem key={pro.profileId} value={pro.profileId}>{pro.profileName}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        {profile === 2 && <>
                            <br />
                            <FormControl
                                variant="outlined"
                                margin="dense"
                                required
                                error={validating && !department && profile === 2}
                                style={{ minWidth: '150px', marginRight: '8px' }}>
                                <InputLabel id="operation-department-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}>
                                    {t('Department')}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={department}

                                    onChange={handleChangeDepartment}
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={100}>

                                    {taskTypes.map(type => {
                                        return (
                                            <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                margin="dense"
                                required
                                error={validating && !role && profile === 2}
                                disabled={!department}
                                style={{ width: '200px', marginBottom: '8px' }}>
                                <InputLabel id="operation-department-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}>
                                    {t('Position')}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={role}

                                    onChange={handleChangeRole}
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={60}>

                                    {roles.filter(role => role.taskTypeId === department).map(ro => {
                                        return (
                                            <MenuItem key={ro.roleId} value={ro.roleId}>{ro.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </>}
                    </>}
                </div>

                {selectedUser && account && account.id === selectedUser.id &&
                    <div className={classes.field}>
                        <Link
                            variant='button'
                            underline='always'
                            className={`${classes.link} ${changePassword ? classes.cancelLink : ''}`}
                            onClick={showPasswordField}>
                            {changePassword ? t('Cancel change') : t('Change Password')}
                        </Link>

                        {changePassword &&
                            <TextField
                                className={classes.textField}
                                label={t('New password')}
                                margin="dense"
                                type={showCurrentPassword ? 'text' : 'password'}
                                style={{ marginTop: '16px' }}
                                helperText={password && !validatePassword(password) ? t('Uppercase, lowercase, numbers and be greater than 6 characters') : ''}
                                required
                                error={(validating && !password) || (password && !validatePassword(password))}
                                value={password}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowCurrentPassword}
                                            >
                                                {showCurrentPassword ? <VisibilityOff className={classes.user__form__password__show_password_icon} /> : <Visibility className={classes.user__form__password__show_password_icon} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handleChangePassword}
                            />
                        }
                    </div>
                }
            </form>

            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '24px' }}>
                {!noAdminUser ?
                    <Button
                        disabled={saving}
                        onClick={props.onClose}>
                        {t('Cancel')}
                    </Button>
                    :
                    <div />
                }

                <Button
                    disabled={(changePassword && (!password || !validatePassword(password))) || saving || !firstName || !lastName || (!noAdminUser && (!port || !profile)) || !validateEmail(email) || (!selectedUser && !email) ? true : false}
                    variant='contained'
                    size='small'
                    color='primary'
                    className={classes.actions__save}
                    onClick={handleSaveUSer}>
                    {t('Guardar')}
                    {saving && (
                        <CircularProgress color='secondary'
                            style={{ marginLeft: 5 }}
                            size={15} />
                    )}
                </Button>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        generalParameters: state.generalParameters,
        currentPort: state.currentPort,
        account: state.account
    }
}

export default connect(mapStateToProps, null)(
    compose(
        withStyles(styles),
        withTranslation(),
        withWidth()
    )(UserForm)
)
