import React, { useState } from 'react';

// Externals
import 'moment/locale/es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { compose } from 'recompose'


// Material helpers
import {
    withStyles,
    withWidth
} from '@material-ui/core';

import {
    ExpandMore as ExpandMoreIcon
} from '@material-ui/icons'

// Material components
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    List,
    ListItem,
    Divider,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary
} from '@material-ui/core';

// Shared components
import { Portlet, PortletContent } from 'components';

//Redux
import { connect } from 'react-redux'

// Component styles
import styles from './styles';

function TemplatesList(props) {
    const { classes, width, ships, setSelectedShip, generalParameters } = props,
        liners = generalParameters && generalParameters.liners ? generalParameters.liners : [],
        [expanded, setExpanded] = useState(false),
        { t } = useTranslation();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (liners.legth === 0) return null

    return (
        <div>
            {liners.map((liner, i) => (
                <ExpansionPanel key={i}
                    expanded={expanded === liner.linerId}
                    onChange={handleChange(liner.linerId)}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`liner-${i}-content`}
                        id={`liner-${i}-header`}
                    >
                        <Typography className={classes.heading}>{liner.name}</Typography>
                        <Typography className={classes.secondaryHeading}>{`${ships.filter(sh => sh.linerId === liner.linerId).length} ${t('Ships')}`}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {(width !== 'xs' && !isMobile) ? (
                            <Portlet style={{ width: '100%' }}>
                                <PortletContent noPadding>
                                    <PerfectScrollbar>
                                        <Table>
                                            <TableHead>
                                                <TableRow className={classes.recalada__header}>
                                                    <TableCell
                                                        align="left"
                                                        className={classes.tableCell__header}>
                                                        {t('Name')}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        className={classes.tableCell__header}>
                                                        {t('Type')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {ships.filter(sh => sh.linerId === liner.linerId)
                                                    .map((ship, i) => (
                                                        <TableRow
                                                            className={classes.tableRow}
                                                            onClick={() => setSelectedShip(ship)}
                                                            hover
                                                            key={i}
                                                        >
                                                            <TableCell className={classes.tableCell}>
                                                                <Typography
                                                                    variant="body1"
                                                                >
                                                                    <strong>{ship.name}</strong>
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell} style={{ width: '300px' }}>
                                                                <Typography
                                                                    variant="body1"
                                                                >
                                                                    {ship.type}
                                                                </Typography>
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </PerfectScrollbar>
                                </PortletContent>
                            </Portlet>
                        ) : (
                                <List
                                    classes={{ root: classes.list__paper }}>
                                    {ships.filter(sh => sh.linerId === liner.linerId)
                                        .map((ship, i) => (
                                            <React.Fragment key={i}>
                                                <ListItem key={i}
                                                    className={classes.list__item__card}
                                                // onClick={() => setSelectedEntity(entity)}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                    >
                                                        <strong>{ship.name}</strong>
                                                    </Typography>
                                                    <br />
                                                    <Typography
                                                        variant="body1"
                                                    >
                                                        {ship.type}
                                                    </Typography>
                                                </ListItem>
                                                <Divider style={{ width: '100%' }} />
                                            </React.Fragment>
                                        ))}
                                </List>
                            )}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

            ))}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        currentPort: state.currentPort,
        entities: state.entities,
        generalParameters: state.generalParameters
    }
}

export default compose(
    connect(mapStateToProps, null),
    withStyles(styles),
    withWidth())(TemplatesList);
