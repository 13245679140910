import React, { useState, useEffect } from 'react';

// Externals
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Material helpers
import {
  makeStyles,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  TextField
} from '@material-ui/core';

import { Edit, Close } from '@material-ui/icons'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'

import { Services } from 'services'
import { updateServiceTemplateName } from 'actions/serviceTemplateActions'
import { useDispatch } from 'react-redux'


// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import Details from './Details';
import List from './List';

import { CreateServiceTemplate, CreateTaskTemplate } from 'components'

import stylesFile from './styles'
// Component styles
const styles = makeStyles(stylesFile);



const ServiceTemplateName = (props) => {
  const { selectedTemplate } = props;
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false)
  const [templateName, setTemplateName] = useState(selectedTemplate.name)
  const [saving, setSaving] = useState(false)
  const classes = styles();
  const dispatch = useDispatch();

  const handleInputChange = e => {
    if (e.target.value.length <= 45) {
      setTemplateName(e.target.value)
    }
  }

  const handleSaveName = () => {
    setSaving(true)

    Services.updateServiceTemplateName({
      serviceTemplateId: selectedTemplate.serviceTemplateId,
      name: templateName
    }).then(response => {
      if (response.success) {
        dispatch(updateServiceTemplateName(response.record))
        setShowEdit(false)
        setSaving(false)
      }
    })

  }
  const handleCancel = () => {
    setTemplateName(selectedTemplate.name)
    setShowEdit(false)
  }

  return (
    <div className={classes.title__edit__container}>
      {!showEdit && (
        <>
          <Typography variant='h4'>{selectedTemplate.name}</Typography>
          <IconButton size='small'
            style={{ marginLeft: 10 }}
            onClick={() => {
              setShowEdit(true)
            }}>
            <Edit />
          </IconButton>
        </>
      )}


      {showEdit && (
        <>
          <TextField
            className={classes.textfield}
            placeholder={t('Escriba un nombre')}
            value={templateName || ''}
            onChange={handleInputChange}
            autoFocus
            disabled={saving}
          />
          <div className={classes.actions}>
            <Button
              disabled={saving || !templateName || (templateName && templateName === selectedTemplate.name) ? true : false}
              variant='contained'
              size='small'
              color='primary'
              className={classes.actions__save}
              onClick={handleSaveName}>
              {t('Guardar')}
              {saving && (
                <CircularProgress color='secondary'
                  style={{ marginLeft: 5 }}
                  size={15} />
              )}
            </Button>
            <IconButton size='small' onClick={handleCancel}>
              <Close />
            </IconButton>
          </div>
        </>
      )}
    </div>)
}




function Templates(props) {
  const { generalParameters, match, currentPort } = props,
    [selectedTemplate, setSelectedTemplate] = useState(null),
    { t, i18n } = useTranslation(),
    classes = styles();

  useEffect(() => {
    if (!match.params.templateId) {
      setSelectedTemplate(null)
    }
    if (match.params && match.params.templateId && generalParameters && generalParameters.servicesTemplates) {
      let newTemplate = generalParameters.servicesTemplates.find(tem => tem.serviceTemplateId === parseInt(match.params.templateId))

      if (match.params.port && match.params.port === newTemplate.portCode) {
        setSelectedTemplate(newTemplate)
      } else {
        setSelectedTemplate(null)
      }
    }
  }, [match, generalParameters, generalParameters.servicesTemplates])

  const handleSelectedTemplate = (template) => {
    if (template) {
      props.history.push(`/${i18n.language}/${currentPort.code}/settings/templates/${template.serviceTemplateId}`)
    } else {
      props.history.push(`/${i18n.language}/${currentPort.code}/settings/templates`)
    }
    setSelectedTemplate(template)
  }



  return (
    <DashboardLayout
      title={selectedTemplate ? `${t('Templates')} > ${selectedTemplate.name}` : t('Templates')}
      showBackMenuButton={selectedTemplate ? true : false}
      goBackUrl={`/${i18n.language}/${currentPort ? currentPort.code : match.params.port}/settings/templates`}
    >
      <div className={isMobile ? classes.mobile_root : classes.root}>
        {generalParameters.loading ? (
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300
          }} >
            <CircularProgress />
          </div>
        ) : (
            <div style={{ position: 'relative' }}>

              <div className={classes.header__container}>
                {selectedTemplate ?
                  <ServiceTemplateName selectedTemplate={selectedTemplate} />
                  : <div />}

                {!selectedTemplate &&
                  <CreateServiceTemplate />
                }
              </div>
              {selectedTemplate &&
                <div className={classes.header__container}
                  style={{ flexDirection: 'row-reverse' }}>
                  <CreateTaskTemplate
                    serviceTemplate={selectedTemplate}
                    serviceTemplateId={selectedTemplate.serviceTemplateId} />
                </div>
              }


              {selectedTemplate ?
                <Details
                  selectedTemplate={selectedTemplate}
                />
                :
                <List
                  setSelectedTemplate={handleSelectedTemplate}
                  selectedTemplate={selectedTemplate}
                />
              }
            </div>
          )}

      </div>
    </DashboardLayout>
  );
}

function mapStateToProps(state) {
  return {
    generalParameters: state.generalParameters,
    currentPort: state.currentPort
  }
}

export default connect(mapStateToProps, null)(withRouter(Templates));
