import React, { useState } from 'react'
import {
    MenuItem,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    withStyles,
    CircularProgress
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Services } from 'services'
import { useDispatch, useSelector } from 'react-redux'
import { updateService } from 'actions/serviceActions'


import styles from './styles'

const Liner = props => {
    const { classes } = props;
    const service = useSelector(state => state.service)
    const generalParameters = useSelector(state => state.generalParameters)
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false)
    const [showAddSection, setShowAddSection] = useState(false)
    const liners = generalParameters.liners;
    const [selectedLiner, setSelectedLiner] = useState(service && service.liner.id ? service.liner.id : 0)
    const dispatch = useDispatch();

    if (!service) return null;

    const handleSaveLiner = () => {
        setSaving(true)

        Services.updateService({
            serviceId: service.id,
            name: service.name,
            linerId: selectedLiner,
            templateId: service.template.id
        }).then(response => {
            if (response.success) {
                dispatch(updateService({...response.record}))
            }
            setSaving(false)
            setShowAddSection(false);
        })
    }
    const handleChange = (e) => {
        if(e.target.value !== selectedLiner){
            setShowAddSection(true);
        } 
        setSelectedLiner(e.target.value)
    }
    const handleCancel = () => {
        setShowAddSection(false);
    }

    return (
        <div>
            <div className={classes.root}>
                <div className={classes.add__user__actions__container}>
                    {liners.length > 0 && (
                        <>

                            <FormControl variant="outlined" style={{ minWidth: '200px', marginRight: 10 }}>
                                <InputLabel id="operation-type-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}
                                    shrink>
                                    {t('Liner')}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    placeholder={t('Select Liner')}
                                    value={selectedLiner}
                                    onChange={handleChange}
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={50}>
                                    {liners.map(liner => {
                                        return (
                                            <MenuItem key={liner.linerId} value={liner.linerId}>{liner.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                                    
                            {showAddSection && <>
                                <Button
                                    disabled={saving}
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    onClick={handleSaveLiner}
                                    className={classes.actions__save}>
                                    {t('Save')}
                                    {saving && (
                                        <CircularProgress color='secondary'
                                            style={{ marginLeft: 5 }}
                                            size={15} />
                                    )}
                                </Button>
                                <IconButton size="small"
                                    onClick={handleCancel}>
                                    <Close />
                                </IconButton>
                            </>}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(Liner)