import React from 'react'
import {
    withStyles,
    Typography,
    IconButton,
    Grid,
    Dialog,
    DialogContent,
    Divider,
} from '@material-ui/core'
import {
    OpenInNew,
    ArrowBackIosOutlined
} from '@material-ui/icons'
import { isMobile } from 'react-device-detect'
import styles from './styles';
import compose from 'recompose/compose'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Attachments } from '../../components'

const AutomaticMessage = props => {
    const { selectedLog, goBack, classes, open, task } = props,
        { t } = useTranslation();

    const formatedDate = (date) => {
        if (moment().isSame(moment(date), 'day')) {
            return moment(date).format('hh:mm a')
        } else {
            return moment(date).format('MMM DD')
        }
    }

    return (
        <Dialog
            open={open}
            classes={{
                paper: classes.dialog__paper
            }}
            maxWidth='sm'
            fullScreen={isMobile ? true : false}
            onExited={goBack}>

            <DialogContent classes={{ root: classes.dialog__container }} style={{ padding: !isMobile ? '16px 24px' : '16px 16px 48px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <IconButton
                            onClick={goBack}
                            variant="text"
                        >
                            <ArrowBackIosOutlined />
                        </IconButton>
                    </Grid>

                    {/* EMAIL RESPONSE  */}

                    <Grid item xs={12}>
                        {selectedLog.messageResponse && selectedLog.messageResponse.responseEmail ?
                            <div className={classes.email__paper}>
                                <div className={classes.email__sender__container}>
                                    <Typography variant='body1' color='textPrimary' className={classes.email__sender}>
                                        {selectedLog.messageResponse.responseEmail.senderWithFormat}
                                    </Typography>
                                    <Typography variant='body2' color='textPrimary' className={classes.email__sender__timestamp}>
                                        {formatedDate(selectedLog.messageResponse.timestamp)}
                                    </Typography>
                                </div>
                                <Typography variant='body1' color='textPrimary' className={classes.email__recipient}>
                                    {t('To')} {selectedLog.messageResponse.responseEmail.recipient}
                                </Typography>
                                <br />
                                <Typography variant='h6' className={classes.email__subject}>
                                    {selectedLog.messageResponse.responseEmail.subject}
                                </Typography>
                                <br />
                                <br />
                                <Typography variant='body1' className={classes.email__body}
                                    dangerouslySetInnerHTML={{ __html: selectedLog.messageResponse.responseEmail.bodyPlain ? selectedLog.messageResponse.responseEmail.bodyPlain : selectedLog.messageResponse.responseEmail.bodyHtml }} >
                                </Typography>

                                <div style={{ marginTop: '24px' }}>
                                    {selectedLog.messageResponse.responseEmail.attachments && selectedLog.messageResponse.responseEmail.attachments.map((attachment, i) => (
                                        <div key={i} className={classes.attachmentLink__container}>
                                            <a key={i} href={attachment.url}
                                                target='_blank'
                                                className={classes.link}
                                                rel="noopener noreferrer" >
                                                <OpenInNew style={{
                                                    width: 18,
                                                    height: 18
                                                }} />
                                            </a>
                                            <div className={classes.attachment__name__container}>
                                                <Typography variant='body1' color='textPrimary'>{attachment.name}</Typography>
                                                <Typography variant='body2' color='textSecondary'>{moment.utc(attachment.timestamp).format('MMMM DD hh:mm a')}</Typography>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            :
                            <div className={classes.email__paper}>
                                <Typography variant='h6' className={classes.email__subject}>
                                    {t('Waiting response')}
                                </Typography>
                            </div>
                        }
                    </Grid>
                    <Divider style={{ height: '8px', color: '#0767DB' }} />
                    {/* SENT EMAIL */}

                    <Grid item xs={12} style={{ background: '#f0f0f0a1' }}>
                        <Typography variant='body1' color='textSecondary' style={{ padding: '0 18px', textAlign: 'center' }}
                            className={classes.email__sender}>
                            {t('Sent message')}
                        </Typography>
                        <div className={classes.email__paper}>
                            <div className={classes.email__sender__container}>
                                <Typography variant='body1' color='textSecondary' className={classes.email__sender}>
                                    {selectedLog.message.sender}
                                </Typography>
                                <Typography variant='body2' color='textSecondary' className={classes.email__sender__timestamp}>
                                    {formatedDate(selectedLog.message.timestamp)}
                                </Typography>
                            </div>
                            <Typography variant='body1' color='textSecondary' className={classes.email__recipient}>
                                {t('To')} {selectedLog.message.recipient}
                            </Typography>
                            <br />
                            <Typography variant='h6' color='textSecondary' className={classes.email__subject}>
                                {selectedLog.message.subject}
                            </Typography>
                            <br />
                            <br />
                            <Typography variant='body1' color='textSecondary' className={classes.email__body}
                                dangerouslySetInnerHTML={{ __html: selectedLog.message.body }} >
                            </Typography>
                        </div>

                        <div style={{ margin: '24px' }}>
                            <Attachments task={task} />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    )
}

export default compose(
    withStyles(styles)
)(AutomaticMessage)
