export default theme => ({
    root: {

    },
    userAvatar: {
        borderRadius: '50%',
        backgroundColor: '#f3f3f3',
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(),
        cursor: 'pointer'
    },

})
