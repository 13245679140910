import React from 'react'
import {
    Grid,
    Typography,
    Paper
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { green } from '@material-ui/core/colors';
import {
    AssignmentIndOutlined,
    OpenInNew
} from '@material-ui/icons'
import theme from 'theme';
import {
    RemindersList
} from './components';
import { CaptureTaskResult } from './components/Result'
import { parseHumandFrendlyDueDate } from 'lib'
import moment from 'moment'
import { taskDueToday } from 'lib'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'

export default function FirstTab(props) {

    const { setOpenMessagesDetails,
        showEmailDetail,
        setSelectedLog,
        classes
    } = props,
        task = useSelector(state => (state.task)),
        { t } = useTranslation();

    const taskDate = parseHumandFrendlyDueDate({
        ETA: task.ETA,
        endImpoDateTime: task.endImpoDateTime,
        endOperationsDateTime: task.endOperationsDateTime,
        departureDate: task.departureDate
    }, task.dueHours, task.dueDateReferenceId, true)


    const assignToUser = () => {
        return (
            <Grid item container spacing={1}>
                <Grid item xs={2} sm={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <AssignmentIndOutlined style={{ height: '25px', width: '25px', color: task.complete ? green[400] : '#66788A', margin: '8px' }} />
                </Grid>
                <Grid item xs={12} sm={7} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='body1'
                        color={!task.complete && (task.isAssignToUser || ((!task.reminders || task.reminders.length === 0) && (!task.remindersSent || task.remindersSent.length === 0))) ? 'textPrimary' : 'textSecondary'}
                        style={{ whiteSpace: 'pre-line', fontSize: '16px' }}>
                        {task.responsiblesUsers.map(user => { return `${user.firstName} ${user.lastName}` }).join(', ')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>

                    <Typography variant='body2' color='textSecondary' style={{ fontSize: '14px', lineHeight: 'inherit', textAlign: isMobile ? 'left' : 'right' }}>
                        {task.complete === 0 && (!task.reminders || task.reminders.length === 0) && task.isAssignToUser === 0 &&
                            t('')
                        }

                        {task.complete === 0 && task.reminders && task.reminders.length > 0 && (!task.remindersSent || task.remindersSent.length === 0) &&
                            t('El sistema enviará un correo cuando se tenga que atender ésta tarea')
                        }

                        {task.complete === 0 && task.reminders && task.reminders.length > 0 && task.remindersSent && task.remindersSent.length > 0 && task.isAssignToUser === 0 &&
                            t('El sistema enviará un correo cuando se tenga que atender ésta tarea')
                        }
                    </Typography>

                    {task.complete === 1 &&
                        <Typography variant='body2' color='textSecondary'
                            style={{ fontSize: '16px', color: green[400], textAlign: isMobile ? 'left' : 'right' }}>
                            {t('Completed')}
                        </Typography>
                    }

                    {task.isAssignToUser === 1 && task.complete === 0 &&
                        <Typography variant='body2' color='textSecondary'
                            style={{ fontSize: '14px', color: '#0767DB', lineHeight: 'inherit', textAlign: isMobile ? 'left' : 'right' }}>
                            {t('Se recibieron todas las respuestas. Esperando consolidación')}
                        </Typography>
                    }


                    <div style={{ display: 'flex', marginTop: '8px', justifyContent: isMobile ? 'flex-start' : 'flex-end' }}>
                        <Typography
                            color={!task.complete && (task.isAssignToUser === 1 || ((!task.reminders || task.reminders.length === 0) && (!task.remindersSent || task.remindersSent.length === 0))) ? 'textPrimary' : 'textSecondary'}
                            variant='body1'
                            style={{ whiteSpace: 'pre-line', fontSize: '14px', textTransform: 'capitalize' }}>
                            {task.complete === 1 && task.completeDateTime ?
                                moment(task.completeDateTime).format('MMMM DD hh:mm a').replace(/^\w/, c => c.toUpperCase()) :
                                task.isAssignToUser === 1 && task.assignToUserDateTime ?
                                    moment(task.assignToUserDateTime).format('MMMM DD hh:mm a').replace(/^\w/, c => c.toUpperCase()) :
                                    task.complete === 0 && (!task.reminders || task.reminders.length === 0) && (!task.remindersSent || task.remindersSent.length === 0) ?
                                        `${taskDate.day || ''} ${taskDate.time || ''}` : ''
                            }
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        )
    }

    const renderRemindersList = () => {
        return (
            <RemindersList
                showEmailDetail={showEmailDetail}
                setSelectedLog={setSelectedLog}
                setOpenMessagesDetails={setOpenMessagesDetails}
                task={task} />
        )
    }

    // const recivedResponse = task.statusLog && task.statusLog.length > 0
    //     ? task.statusLog.filter(log => log.messageResponse && log.messageResponse.responseEmail && log.messageResponse.responseEmail.attachments.length > 0) : [],
    //     recivedResponseWithAttachments = recivedResponse.length > 0 ? recivedResponse.map(att => ({
    //         id: att.messageResponse.id,
    //         attachments: att.messageResponse.responseEmail.attachments,
    //         sender: att.messageResponse.responseEmail.senderWithFormat
    //     })) : []

    // console.log(recivedResponseWithAttachments)

    return (
        <Grid item container spacing={3}>

            {(task.reminders && task.reminders.length > 0) && <>

                {task.remindersSent && task.remindersSent.length > 0 && !task.complete && !task.isAssignToUser ?
                    <Paper style={{ width: '100%', marginBottom: 10, padding: '10px 5px', background: '#d8f5d4c7' }}>
                        {renderRemindersList()}
                    </Paper>
                    :
                    <Grid item xs={12}>
                        <div style={{
                            backgroundColor: '#efefef',
                            borderRadius: 5,
                            padding: '10px 5px',
                            marginBottom: 10,
                        }}>
                            {renderRemindersList()}
                        </div>
                    </Grid>
                }

            </>}

            {!task.complete && (task.isAssignToUser || ((!task.reminders || task.reminders.length === 0) && (!task.remindersSent || task.remindersSent.length === 0) &&
                (taskDueToday({
                    ETA: task.ETA,
                    endImpoDateTime: task.endImpoDateTime,
                    endOperationsDateTime: task.endOperationsDateTime,
                    departureDate: task.departureDate
                }, task.dueHours, task.dueDateReferenceId)))) ?
                <Paper style={{ width: '100%', marginBottom: 10, padding: '10px 5px', background: '#d8f5d4c7' }}>
                    {assignToUser()}
                </Paper>
                :
                <Grid item xs={12}>
                    <div style={{
                        backgroundColor: '#efefef',
                        padding: '10px 5px',
                        marginBottom: 10,
                        borderRadius: '5px'
                    }}>
                        {assignToUser()}
                    </div>
                </Grid>
            }


            <Grid item xs={12} >
                <div style={{ width: '100%', marginTop: 8, display: 'flex', justifyContent: 'space-between' }}>
                    <CaptureTaskResult task={task} />
                </div>
            </Grid>
        </Grid>
    )
}
