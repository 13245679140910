import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import {
    Divider,
    BottomNavigation,
    BottomNavigationAction,
    withStyles,
    withWidth,
    Badge
} from '@material-ui/core';
import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import {
    DirectionsBoat,
    CalendarToday,
    Email,
} from '@material-ui/icons';
//Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as EmailsActions from 'actions/emailsActions'



// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(1) * 4,

    },
    company: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1) * 0.5
    },
    bottomNavigation__container: {
        display: 'block',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        marginTop: 50
    }
});

class BottomTabsNav extends Component {
    state = {
        value: this.props.match.path.indexOf('/:lang/:port/tasks') >= 0 ? 1 :
            (this.props.match.path.indexOf('/:lang/:port/emails') >= 0 ? 2 :
                (this.props.match.path.indexOf('/:lang/:port/recaladas') >= 0 ? 0 : -1))
    }
    render() {
        const { classes, width, t, i18n, emailsCounters, currentPort } = this.props,
            { value } = this.state;
        let lang = i18n.language;

        if (!currentPort) return null

        return (
            <>
                {(isMobile || width === 'xs') && (
                    <div className={classes.bottomNavigation__container}>
                        <Divider />
                        <BottomNavigation
                            value={value}
                            onChange={(event, newValue) => {
                                this.setState({ value: newValue });
                            }}
                            showLabels>
                            <BottomNavigationAction
                                component={NavLink}
                                to={`/${lang}/${currentPort.code}/recaladas`}
                                label={t('Ships')}
                                icon={<DirectionsBoat />} />
                            <BottomNavigationAction
                                component={NavLink}
                                to={`/${lang}/${currentPort.code}/tasks`}
                                label={t('Tasks')}
                                icon={<CalendarToday />} />
                            <BottomNavigationAction
                                component={NavLink}
                                to={`/${lang}/${currentPort.code}/emails/inbox`}
                                label={t("Emails")}
                                icon={
                                    <Badge badgeContent={emailsCounters.totalUnreadEmails} color="primary">
                                        <Email />
                                    </Badge>
                                } />
                        </BottomNavigation>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = ({ emailsCounters, currentPort }) => ({ emailsCounters, currentPort });
const mapDispatchToProps = (dispatch) => ({
    EmailsActions: bindActionCreators(EmailsActions, dispatch),
})

export default
    connect(mapStateToProps, mapDispatchToProps)(
        compose(
            withWidth(),
            withRouter,
            withTranslation(),
            withStyles(styles)
        )(BottomTabsNav)
    )