export default theme => (
    {
        dialog__paper: {
            width: '100%',
            maxWidth: 400
        },
        dialog__title__container: {
            color: '#fff',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        dialog__title: {
            backgroundColor: theme.palette.primary.main,
        },
        dialog__container: {
            padding: theme.spacing(2)
        },
        loading__container: {
            height: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        description__container: {
            padding: theme.spacing(2)
        },
        people__list__container: {
            height: '200px',
            overflow: 'auto'
        },
        people__actions__container: {
            display: 'flex',
            paddingTop: theme.spacing(),
            justifyContent: 'space-between'
        },
        people__filter__button: {
            marginLeft: '5px',
            minWidth: '100px'
        },
        filter__option: {
            padding: theme.spacing()
        },
        'filter__option--selected': {

        },
        select__root: {
            padding: '8px 32px 8px 10px'
        },
    })