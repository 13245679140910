import { combineReducers } from 'redux'
import recaladas from './recaladas'
import recalada from './recalada'
import emailsCounters from './emailsCounters'
import account from './account'
import task from './task'
import tasks from './tasks'
import generalParameters from './generalParameters'
import currentPort from './currentPort'
import taskTemplate from './taskTemplate'
import service from './service'
import usersFilter from './usersFilter'
import entities from './entities'


const rootReducer = combineReducers({
    recaladas,
    recalada,
    emailsCounters,
    generalParameters,
    account,
    task,
    tasks,
    currentPort,
    taskTemplate,
    service,
    usersFilter,
    entities
})

export default rootReducer