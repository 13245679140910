import React from 'react'
import {
    Snackbar,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarNotification = props => {
    const { open, onClose, message, type } = props;

    const handleClose = () => {
        onClose()
    }

    return (
        <Snackbar open={open}>
            <Alert onClose={handleClose} severity={type}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarNotification;