import React, { useState, useEffect } from 'react'
import {
    Box,
    makeStyles,
    Link
} from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';


const useStyles = makeStyles(theme => ({
    footer__language__selector: {
        display: 'flex',
        width: 'auto',
        justifyContent: 'space-between'
    },
    'link__language--selected': {
        cursor: 'default',
        marginLeft: 10,
        color: theme.palette.text.primary
    },
    link__language: {
        marginLeft: 10,
        cursor: 'pointer'
    }
}))

export default function FooterLanguageSelector() {
    const [url_language, setUrl_language] = useState('es'),
        classes = useStyles(),
        { i18n, t } = useTranslation();
    let { match, history, location } = useReactRouter();

    useEffect(() => {
        let isSuscribed = true;
        let url_language = match.params.lang;
        if (isSuscribed) {
            i18n.init({ lng: url_language })
            setUrl_language(url_language)
        }
        return () => {
            isSuscribed = false
        };
    }, [i18n, match.params.lang])

    const changeLanguage = (newLng) => {
        i18n.changeLanguage(newLng)
        let newUrl = newLng === 'es' ? location.pathname.replace(`/en/`, `/es/`) : location.pathname.replace(`/es/`, `/en/`)
        history.push(newUrl)
        setUrl_language(newLng)
    }

    return (
        <Box className={classes.footer__language__selector}>
            <Link
                variant='button'
                underline={url_language === 'es' ? 'none' : 'always'}
                className={url_language === 'es' ? classes['link__language--selected'] : classes.link__language}
                disabled={url_language === 'es' ? true : false}
                onClick={() => changeLanguage('es')}>{t('Spanish')}</Link>
            <Link
                variant='button'
                underline={url_language === 'en' ? 'none' : 'always'}
                className={url_language === 'en' ? classes['link__language--selected'] : classes.link__language}
                disabled={url_language === 'en' ? true : false}
                onClick={() => changeLanguage('en')}>{t('English')}</Link>
        </Box>
    )
}
