export default theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: 8
    }
  },
  row: {
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1
  },
  deleteButton: {
    color: theme.palette.danger.main,
    marginRight: theme.spacing(1)
  },
  importButton: {
    marginLeft: theme.spacing(1)
  },
  importIcon: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginLeft: theme.spacing(1)
  },
  exportIcon: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 5
    },
    [theme.breakpoints.down('sm')]: {
      flex: 1
    }
  },
  filter__option: {
    padding: theme.spacing()
  },
  'filter__option--selected': {

  },
  filterDateButton: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 150
    }
  },
  select__root: {
    padding: '8px 32px 8px 10px'
  },
  select__type__root: {
    padding: '6px 32px 6px 10px',
  }
});
