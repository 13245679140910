import React, { useState } from 'react'
import {
    Button,
    withStyles,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { RecaladaDetailsPopup } from 'components'

import styles from './styles'

const CreateRecalada = () => {
    const { t } = useTranslation(),
        [openDialgo, setOpenDialgo] = useState(false);

    const handleCreateRecalada = () => {
        setOpenDialgo(true)
    }
    const onClose = () => {
        setOpenDialgo(false)
    }
    return (
        <div>
            <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={handleCreateRecalada}
            >
                {t('New recalada')}
            </Button>

            <RecaladaDetailsPopup
                open={openDialgo}
                onClose={onClose} />
        </div>
    )
}



export default compose(
    withStyles(styles)
)(CreateRecalada)