import React, { useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    List,
    ListItem,
    Divider,
    makeStyles,
    withWidth,
    Dialog,
    IconButton,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core'
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Warning,
    Schedule,
    CheckCircle,
    Close
} from '@material-ui/icons';
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as recaladasActions from 'actions/recaladasActions'
import * as portActions from 'actions/portActions'
import * as recaladaActions from 'actions/recaladaActions'
import { longDateFormat } from 'lib'
import { green } from '@material-ui/core/colors';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing()}px 0`
        }
    },
    content: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing()
        }
    },
    dialog__paper: {
        width: '100%'
    },
    progressWrapper: {
        paddingTop: '48px',
        paddingBottom: '24px',
        display: 'flex',
        justifyContent: 'center'
    },
    noRecaladas__container: {
        width: '100%',
        background: '#fff',
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    //Table
    tableRow: {
        height: '34px',
        cursor: 'pointer'
    },
    'tableRow--closed': {
        backgroundColor: '#f5f5f5',
        color: '#fff !important',
        '& > td,div, a, span': {
            color: '#828282 !important',
        }
    },
    tableCell: {
        whiteSpace: 'nowrap',
        padding: '10px'
    },
    tableCell__header: {
        whiteSpace: 'nowrap',
        padding: '10px',
        color: '#000',
        textTransform: 'uppercase'
    },
    tableCellInner: {
        display: 'flex',
        alignItems: 'center'
    },
    tableCell__ships__header: {
        whiteSpace: 'nowrap',
        padding: '10px',
        color: '#828282'
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        display: 'inline-flex',
        fontSize: '14px',
        fontWeight: 500,
        height: '36px',
        width: '36px'
    },
    nameText: {
        display: 'inline-block',
        marginLeft: theme.spacing(2),
        fontWeight: 500,
        cursor: 'pointer'
    },
    closedLabel: {
        marginLeft: theme.spacing(2),
    },
    unreadEmailsText: {
        marginLeft: theme.spacing(2),
    },
    nested: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        border: '1px solid #c4c4c4',
        marginBottom: theme.spacing(1),
        padding: 0
    },
    recalada__header: {
        background: theme.palette.tableHeader,
    },
    ships__header: {
        background: '#f5f5f5'
    },
    'mail--read': {
        backgroundColor: 'rgba(239,239,239,0.3)'
    },
    'mail--unread': {
        backgroundColor: 'unset'
    },
    warningIcon: {
        color: 'red'
    },
    successIcon: {
        color: 'green'
    },
    list__paper: {
        width: '100%',
        backgroundColor: '#fff',
        padding: 0
    },
    list__item__card: {
        padding: 10,
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    'list__item__card--warning': {
        backgroundColor: 'rgba(255, 0, 0, 0.1)'
    },
    'list__item__card--success': {
        // backgroundColor: 'rgba(0, 255, 0, 0.1)'
    },
    list__item__card__icon__container: {
        // position: 'absolute',
        // right: theme.spacing(1),
        // top: theme.spacing(1)
    },
    list__item__dueDate: {
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'column'
    },
    tasks__container: {
        marginBottom: theme.spacing(),
    },
    tasks__indicator__container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
    },
    dialog__title: {
        height: 50,
        padding: '4px 16px',
        backgroundColor: theme.palette.primary.main,
    },
    dialog__title__container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    textField: {
        marginTop: 2,
        minWidth: 120,
        maxWidth: '100%',
        width: '100%'
    },
    dialog__content: {
        padding: theme.spacing(3)
    },
    title__content: {
        margin: '8px 0'
    }
}))
const RecaladasTable = (props) => {
    const { recaladas, isLoading, portName, portCode, width, history } = props,
        [openDialog, setOpenDialog] = useState(false),
        [selectedRecalada, setSelectedRecalada] = useState(null),
        classes = useStyles(),
        { t } = useTranslation(),
        ports = useSelector(state => state.generalParameters.ports || []),
        dispatch = useDispatch();

    const selectRecalada = (recalada) => {
        let port = ports.find(p => p.portCode === portCode);

        dispatch(recaladasActions.setRecaladas(recaladas))
        dispatch(recaladaActions.setRecalada(recalada))

        history.push(`/${props.match.params.lang}/${portCode}/recaladas/${recalada.recaladaId}`)

        dispatch(portActions.setCurrentPort({ code: port.portCode, name: port.portName }))

    }

    return (
        <div>
            {recaladas && recaladas.length === 0 && !isLoading &&
                <div className={classes.noRecaladas__container}>
                    <Typography variant="h6">
                        {t('There are no recaladas')} {t('en')} {portName}
                    </Typography>
                </div>
            }
            {!isLoading && ((recaladas && recaladas.length > 0)) &&
                <>
                    {(width !== 'xs' && !isMobile) ? (
                        <PerfectScrollbar>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 20
                            }}>
                                <Typography variant="h5">
                                    Recaladas {portName}
                                </Typography>
                            </div>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.recalada__header}>
                                        <TableCell
                                            align="left"
                                            className={classes.tableCell__header}
                                            style={{ paddingLeft: '24px' }}>
                                            {t('Ship')}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={classes.tableCell__header}>
                                            {t('Liner')}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={classes.tableCell__header}>
                                            {t('ETA')}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ paddingRight: '24px' }}
                                            className={classes.tableCell__header}>
                                            {t('Status')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {recaladas
                                        .map((recalada, i) => (
                                            <TableRow
                                                className={`${classes.tableRow} ${recalada.isClosed ? classes['tableRow--closed'] : ''}`}
                                                hover
                                                key={i}
                                                onClick={() => selectRecalada(recalada)}
                                            >
                                                <TableCell className={classes.tableCell}>
                                                    <div className={classes.tableCellInner}>
                                                        <Link to="#">
                                                            <Typography
                                                                className={classes.nameText}
                                                                variant="body1"
                                                            >
                                                                {recalada.ship.name} V-{recalada.itineraryName}
                                                            </Typography>

                                                        </Link>
                                                    </div>
                                                    {!!recalada.isClosed && (
                                                        <Typography variant='body2'
                                                            className={classes.closedLabel}
                                                            color='textSecondary'>
                                                            {t('ClosedRecalada')}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    <Typography
                                                        variant="body1"
                                                    >
                                                        {recalada.linerName}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {longDateFormat(recalada.ETA)}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell}
                                                    style={{ textAlign: 'center' }}
                                                    padding="checkbox">

                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setSelectedRecalada(recalada)
                                                            setOpenDialog(true)
                                                        }}
                                                        color='inherit'>
                                                        {recalada.tasksCounters.totalDueTasks ?
                                                            <Warning style={{ color: 'red' }} />
                                                            : <>
                                                                {recalada.tasksCounters.totalAboutToDueTasks &&
                                                                    <Schedule style={{ color: 'orange' }} />
                                                                }
                                                            </>}
                                                    </IconButton>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </PerfectScrollbar>
                    ) : (
                            <List
                                classes={{
                                    root: classes.list__paper
                                }}>
                                {recaladas
                                    .map((recalada, i) => (
                                        <React.Fragment key={i}>
                                            <ListItem key={i}
                                                onClick={() => selectRecalada(recalada)}
                                                className={`${classes.list__item__card} ${recalada.isClosed ? classes['tableRow--closed'] : ''}`}
                                            >
                                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography variant="body1" >
                                                            {`${recalada.linerName} - `}<strong>{recalada.ship ? recalada.ship.name : ''} V-{recalada.itineraryName}</strong>
                                                        </Typography>
                                                        {!!recalada.isClosed && (
                                                            <Typography variant='body2'
                                                                color='textSecondary'>
                                                                {t('ClosedRecalada')}
                                                            </Typography>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className={classes.list__item__dueDate}>
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setSelectedRecalada(recalada)
                                                            setOpenDialog(true)
                                                        }}
                                                        color='inherit'>
                                                        {recalada.tasksCounters.totalDueTasks ?
                                                            <Warning style={{ color: 'red' }} />
                                                            : <>
                                                                {recalada.tasksCounters.totalAboutToDueTasks &&
                                                                    <Schedule style={{ color: 'orange' }} />
                                                                }
                                                            </>}
                                                    </IconButton>
                                                    <Typography color='textSecondary' variant='body2'>
                                                        {longDateFormat(recalada.ETA)}
                                                    </Typography>
                                                </div>

                                            </ListItem>
                                            <Divider style={{ width: '100%' }} />
                                        </React.Fragment>
                                    ))}
                            </List>
                        )}
                </>
            }

            {selectedRecalada &&
                <Dialog
                    onClose={() => {
                        setOpenDialog(false)
                        setSelectedRecalada(null)

                    }}
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    maxWidth='xs'
                    open={openDialog}>
                    <DialogContent>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                            <Typography variant='h5'>
                                {`${selectedRecalada.ship.name} V-${selectedRecalada.itineraryName}`}
                            </Typography>
                            <IconButton
                                color='textSecondary'
                                onClick={() => {
                                    setOpenDialog(false)
                                    setSelectedRecalada(null)
                                }}
                            >
                                <Close />
                            </IconButton>
                        </div>

                        <div className={classes.tasks__container} style={{ width: '220px' }}>
                            <div className={classes.tasks__indicator__container}>
                                <Warning style={{ width: '14px', color: 'red', marginRight: '6px' }} />

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    <Typography variant='body1' color='textSecondary'>
                                        {t('Tasks past due')}
                                    </Typography>
                                    <Typography variant='body1' color='textSecondary'>
                                        {selectedRecalada.tasksCounters.totalDueTasks ? selectedRecalada.tasksCounters.totalDueTasks : 0}
                                    </Typography>
                                </div>
                            </div>

                            <div className={classes.tasks__indicator__container}>
                                <Schedule style={{ width: '14px', color: 'orange', marginRight: '6px' }} />

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    <Typography variant='body1' color='textSecondary'>
                                        {t('Tasks due soon')}
                                    </Typography>
                                    <Typography variant='body1' color='textSecondary'>
                                        {selectedRecalada.tasksCounters.totalAboutToDueTasks ? selectedRecalada.tasksCounters.totalAboutToDueTasks : 0}
                                    </Typography>
                                </div>
                            </div>

                            <div className={classes.tasks__indicator__container}>
                                <CheckCircle style={{ width: '14px', color: green[400], marginRight: '6px' }} />

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    <Typography variant='body1' color='textSecondary'>
                                        {t('Tasks done')}
                                    </Typography>
                                    <Typography variant='body1' color='textSecondary'>
                                        {selectedRecalada.tasksCounters.totalDoneTasks ? selectedRecalada.tasksCounters.totalDoneTasks : 0}
                                    </Typography>
                                </div>
                            </div>

                            <div className={classes.tasks__indicator__container}>
                                <div style={{ width: '23px' }} />

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    <Typography variant='body1' color='textSecondary'>
                                        {t('Total task')}
                                    </Typography>
                                    <Typography variant='body1' color='textSecondary'>
                                        {selectedRecalada.tasksCounters.totalTasks ? selectedRecalada.tasksCounters.totalTasks : 0}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                props.history.push(`./recaladas/${selectedRecalada.recaladaId}`)
                            }}
                            color='primary'>
                            {t('Go to Recalada')}
                        </Button>
                    </DialogActions>
                </Dialog>
            }

        </div >

    )
}

export default withWidth()(RecaladasTable)
