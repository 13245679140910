import React, { useState, useEffect } from 'react'
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormControl,
    withStyles,
} from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import styles from './styles'
import { Tasks } from 'services'

import { useDispatch, useSelector } from 'react-redux'
import { updateTaskTemplateRequiredResult } from 'actions/taskTemplateActions'

const RequiredResult = (props) => {
    const { classes } = props;
    const taskTemplate = useSelector(state => (state.taskTemplate))
    const { t } = useTranslation(),
        [requiredResult, setRequiredResult] = useState(taskTemplate ? taskTemplate.requiredResult ? true : false : false),
        [saving, setSaving] = useState(false),
        dispatch = useDispatch();

    useEffect(() => {
        let isSuscribed = true;
        if (requiredResult === null && taskTemplate && taskTemplate.requiredResult && isSuscribed) {
            setRequiredResult(taskTemplate.requiredResult)
        }
        return () => {
            isSuscribed = false;
        };
    }, [taskTemplate, requiredResult])

    if (!taskTemplate) return null;


    const handleChangeRequiredResult = (event) => {
        setRequiredResult(event.target.checked)
        saveRequiredResult(event.target.checked)
    }

    const saveRequiredResult = (result) => {
        setSaving(true)

        Tasks.updateTaskTemplateRequiredResult({
            taskTemplateId: taskTemplate.id,
            requiredResult: result ? 1 : 0
        })
            .then(response => {
                if (response.success) {
                    dispatch(updateTaskTemplateRequiredResult({
                        ...response.record,
                        serviceTemplateId: taskTemplate.serviceTemplateId
                    }))
                    setSaving(false)
                }
            })
    }



    return (
        <div style={{ position: 'relative' }}>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup disabled={saving}>
                    <FormControlLabel
                        control={<Checkbox checked={requiredResult}
                            onChange={handleChangeRequiredResult} name="requiredResult"
                        />}
                        label={t('The task require documents or text as a result to be done')}//{t('Required result')}
                    />
                </FormGroup>
            </FormControl>
        </div>
    )
}
export default withStyles(styles)(RequiredResult)