import 'whatwg-fetch';
import config from 'config';
import auth from 'authentication/auth'

export const Ships = {
    getShips: (args = {}) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/ships`;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    getShipInfo: (args = {}) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/ships/${args.IMO}`;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    createShip: (args = {}) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/ships`;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    body: JSON.stringify(args.ship),
                    method: "POST"
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateShip: (args = {}) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/ships/${args.ship.id}`;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    headers,
                    body: JSON.stringify(args.ship),
                    method: "PUT"
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    }
}