import { createStore, compose } from 'redux'
import rootReducer from './reducers/rootReducer'
import i18n from 'i18next'


const defaultState = {
    recaladas: null,
    recalada: {
        emails: null,
        tasks: null,
        documents: null,
        detalles: null
    },
    emailsCounters: {},
    account: null,
    task: null,
    currentPort: null,
    generalParameters: {
        loading: true,
        beforeAfterOptions: [
            {
                value: 0,
                label: i18n.t('Before')
            },
            {
                value: 1,
                label: i18n.t('After')
            }
        ],
        hoursOrDays: [
            {
                value: 0,
                label: i18n.t('Horas')
            },
            {
                value: 1,
                label: i18n.t('Días')
            }
        ]
    },
    usersFilter: [],
    tasks: null,
    taskTemplate: null,
    service: null
}

const enhancers = compose(
    window.window.__REDUX_DEVTOOLS_EXTENSION__ ? window.window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
);


const store = createStore(
    rootReducer,
    defaultState,
    enhancers);
export default store;