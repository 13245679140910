import React, { useState, useEffect } from 'react'
import {
    Typography,
    Button,
    withStyles,
    TextField,
    CircularProgress,
    IconButton
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import styles from './styles'
import { useTranslation } from 'react-i18next'
import { Tasks } from 'services'

import { useDispatch, useSelector } from 'react-redux'
import { updateTaskTemplateDescription } from 'actions/taskTemplateActions'

const Description = (props) => {
    const { classes } = props;
    const taskTemplate = useSelector(state => (state.taskTemplate))
    const [saving, setSaving] = useState(false)
    const [descriptionMessage, setDescriptionMessage] = useState(taskTemplate ? taskTemplate.description : '');
    const [showActions, setShowActions] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        let isSuscribed = true;
        if (taskTemplate && taskTemplate.description && isSuscribed) {
            setDescriptionMessage(taskTemplate.description)
        }
        return () => {
            isSuscribed = false;
        };
    }, [taskTemplate])


    const handleSHowActions = () => {
        setShowActions(true)
    }
    const handleBlurDescription = () => {
        if (taskTemplate.description === descriptionMessage) {
            setShowActions(false)
        }
    }
    const handleInputChange = e => {
        setDescriptionMessage(e.target.value)
    }

    const handleSaveDescription = () => {
        setSaving(true)
        Tasks.updateTaskTemplateDescription({
            taskTemplateId: taskTemplate.id,
            description: descriptionMessage
        })
            .then(response => {
                if (response.success) {
                    dispatch(updateTaskTemplateDescription({
                        ...response.record,
                        serviceTemplateId: taskTemplate.serviceTemplateId
                    }))
                    setShowActions(false)
                    setSaving(false)
                }
            })
    }
    const handleCancel = () => {
        setShowActions(false)
    }

    return (
        <div>
            <div className={classes.description__container} onClick={handleSHowActions}>
                {!showActions && (
                    <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>{
                        descriptionMessage ? descriptionMessage : t('Sin descripción')}
                    </Typography>
                )}

                {showActions && (
                    <>
                        <TextField
                            multiline
                            className={classes.description__textfield}
                            placeholder={t('Escriba una descripción')}
                            value={descriptionMessage || ''}
                            onBlur={handleBlurDescription}
                            onChange={handleInputChange}
                            autoFocus
                            disabled={saving}
                        />
                        <div className={classes.actions}>
                            <Button
                                disabled={saving || !descriptionMessage || (descriptionMessage && descriptionMessage === taskTemplate.description) ? true : false}
                                variant='contained'
                                size='small'
                                color='primary'
                                className={classes.actions__save}
                                onClick={handleSaveDescription}>
                                {t('Guardar')}
                                {saving && (
                                    <CircularProgress color='secondary'
                                        style={{ marginLeft: 5 }}
                                        size={15} />
                                )}
                            </Button>
                            <IconButton size='small' onClick={handleCancel}>
                                <Close />
                            </IconButton>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default withStyles(styles)(Description)