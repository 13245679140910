import React, { useState, useEffect } from 'react'
import {
    Checkbox,
    Typography,
    Popover,
    withStyles,
    // IconButton,
    Grid,
    Button,
    Divider,
    CircularProgress,
    Input
} from '@material-ui/core'
import { ConfirmationDialog } from 'components'
import { Info } from '@material-ui/icons'
import moment from "moment";
import MaskedInput from 'react-text-mask';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import styles from './styles'
import { useTranslation } from 'react-i18next';

import { Tasks } from 'services'
import { useDispatch, useSelector } from 'react-redux'
import { updateTaskComplete, updateTaskDueHours } from 'actions/tasksActions'

import {
    parseHumandFrendlyDueHours,
    parseHumandFrendlyDueDate,
    taskPastDue,
    getRefDateDefined,
    //parseHumandFrendlyDate 
} from 'lib'

registerLocale('es', es)

function TextMaskDate(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            placeholder='dd/mm/yyyy'
            showMask
        />
    );
}

function TextMaskHour(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-2]/, /\d/, ':', /[0-5]/, /\d/]}
            placeholderChar={'\u2000'}
            placeholder='HH:MM'
            showMask
        />
    );
}



const DueDate = (props) => {

    const { classes, recalada, fetching } = props;
    const task = useSelector(state => (state.task))
    const { t, i18n } = useTranslation();
    const [saving, setSaving] = useState(false)
    const [invalid, setInvalid] = useState({})
    const [checked, setChecked] = useState(task && task.complete ? true : false)
    const [dueDate, setDueDate] = useState(task && task.dueHours ? new Date(moment(recalada.ETA).add(task.dueHours, 'hours').format()) : new Date())
    const [date, setDate] = useState(moment(dueDate).format('DD/MM/YYYY'))
    const [time, setTime] = useState(task && task.dueHours ? moment(recalada.ETA).add(task.dueHours, 'hours').format('HH:mm') : moment().add(1, 'days').format('HH:mm'))
    const [openDateSelector, setOpenDateSelector] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const dispatch = useDispatch();

    useEffect(() => {
        let isSuscribed = true;
        if (task && isSuscribed) {
            setChecked(task.complete ? true : false)
            setDueDate(new Date(moment(recalada.ETA).add(task.dueHours, 'hours').format()))
            setDate(moment(recalada.ETA).add(task.dueHours, 'hours').format('DD/MM/YYYY'))
            setTime(moment(recalada.ETA).add(task.dueHours, 'hours').format('HH:mm'))
        }
        return () => {
            isSuscribed = false;
        };
    }, [recalada.ETA, task, task.dueDate, task.complete])

    const taskDue = !checked && taskPastDue(recalada, task.dueHours, task.dueDateReferenceId);

    const handleCheck = () => {
        setOpenConfirmation(true)
    }

    const confirmCheck = () => {
        let updatedTask = {
            ...task,
            complete: !checked
        }
        setChecked(!checked);
        Tasks.updateTaskComplete({
            taskId: updatedTask.taskId,
            complete: updatedTask.complete
        })
            .then(response => {
                if (response.success) {
                    dispatch(updateTaskComplete(response.record))
                }
            })
    }

    const changeDateTextField = (e) => {
        setDate(e.target.value)
        let splitted = e.target.value.split('/')
        let dateChanged = moment(`${splitted[2]}-${splitted[1]}-${splitted[0]} ${time}`)
        if (dateChanged && dateChanged.isValid()) {
            setDueDate(new Date(dateChanged.format()))
        }
    }
    const changeTimeTextField = (e) => {
        setTime(e.target.value)
        let splitted = date.split('/')
        let dateChanged = moment(`${splitted[2]}-${splitted[1]}-${splitted[0]} ${e.target.value}`)
        if (dateChanged && dateChanged.isValid()) {
            setDueDate(new Date(dateChanged.format()))
        }
    }

    const validateDateFormat = () => {
        let splitted = date.split('/')
        let dateChanged = moment(`${splitted[2]}-${splitted[1]}-${splitted[0]}`)
        if (dateChanged && dateChanged.isValid()) {
            setInvalid({
                ...invalid,
                date: false
            })
            return true;
        }
        setInvalid({
            ...invalid,
            date: true
        })
        return false;
    }
    const validateTimeFormat = () => {
        let dateChanged = moment(`${moment().format('YYYY-MM-DD')} ${time}`)
        if (dateChanged && dateChanged.isValid()) {
            setInvalid({
                ...invalid,
                time: false
            })
            return true;
        }
        setInvalid({
            ...invalid,
            time: true
        })
        return false;
    }

    const handleChangeCalendarDate = date => {
        setDueDate(date)
        setDate(moment(date).format('DD/MM/YYYY'))
    }

    const handleClosePopover = () => {
        setOpenDateSelector(false)
        setAnchorEl(null)
    }
    const handleSave = () => {
        if (!validateDateFormat() || !validateTimeFormat()) {
            return;
        }

        setSaving(true)

        let dueHours = moment(dueDate).diff(moment(recalada.ETA), 'hours');

        Tasks.updateTaskDueHours({
            taskId: task.taskId,
            dueHours: dueHours
        })
            .then(response => {
                if (response.success) {
                    dispatch(updateTaskDueHours(response.record))
                    setSaving(false)
                    handleClosePopover()
                }
            })
    }

    let { dateDefined } = getRefDateDefined(recalada, task.dueDateReferenceId);

    if (fetching) return null;

    if (task.parentTaskId && !task.parentTaskComplete) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <Info style={{ color: '#ffce00', marginRight: 5 }} />
                <Typography variant='h6'>{t('You must finish task {{parentTaskName}} before this task.', { parentTaskName: task.parentTaskName })}</Typography>
            </div>
        )
    } else {

        return (
            <>
                {task.isAutomaticTask === 1 ?
                    <div style={{ margin: '0 10px 10px', position: 'absolute', left: 0 }}>
                        <Typography variant='body1' color='textPrimary' style={{ marginRight: 5, textTransform: 'capitalize' }}>
                            {parseHumandFrendlyDueDate(recalada, task.dueHours, task.dueDateReferenceId)}
                        </Typography>
                    </div>
                    :
                    <div className={`${classes.root} ${task.complete ? classes['root--complete'] : (taskDue ? classes['root--due'] : '')}`}>

                        {!dateDefined && task.isAutomaticTask === 0 && (
                            <div style={{ margin: 10, textAlign: 'center' }}>
                                <Typography variant='body1' color='textPrimary' style={{ marginRight: 5 }}>
                                    {parseHumandFrendlyDueDate(recalada, task.dueHours, task.dueDateReferenceId)}
                                </Typography>
                            </div>
                        )}


                        {dateDefined && task.isAutomaticTask === 0 && (
                            <>
                                <Checkbox
                                    checked={checked}
                                    color="default"
                                    onChange={handleCheck}
                                    style={{
                                        margin: '5px 10px 5px 5px',
                                    }}
                                    classes={{
                                        root: checked ? classes.checkbox__root : ''
                                    }} />

                                <div style={{ margin: 10, textAlign: 'center' }}>
                                    <Typography variant='body2' color='textSecondary'>
                                        {parseHumandFrendlyDueHours(task.dueHours, task.dueDateReferenceId)}
                                    </Typography>
                                    <div style={{
                                        marginTop: 5
                                    }}>
                                        <Typography variant='body1' color='textPrimary' style={{ marginRight: 5 }}>
                                            {parseHumandFrendlyDueDate(recalada, task.dueHours, task.dueDateReferenceId)}
                                        </Typography>
                                    </div>
                                </div>
                                {checked &&
                                    <div>
                                        <div className={classes.task__done__label} style={{ textAlign: 'center' }}>
                                            <Typography component='div'
                                                variant='body2'
                                                color='inherit' style={{ margin: 3 }}>
                                                {t('REALIZADA')}
                                            </Typography>
                                            {/* {task.completeDateTime && (
                                <Typography component='div' variant='body2' color='inherit' style={{ margin: 3 }}>
                                    {parseHumandFrendlyDate(task.completeDateTime)}
                                </Typography>
                            )} */}
                                        </div>
                                    </div>
                                }
                                {taskDue && (
                                    <div className={classes.task__pastdue__label}>
                                        <Typography component='div' variant='body2' color='inherit' style={{ margin: 3 }}>
                                            {t('VENCIDA')}
                                        </Typography>
                                    </div>
                                )}
                                {/* <IconButton
                    onClick={e => {
                        setOpenDateSelector(true)
                        setAnchorEl(e.target)
                    }}>
                    <ExpandMore />
                </IconButton> */}
                            </>
                        )}
                    </div>
                }
                <Popover open={openDateSelector}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={handleClosePopover}>
                    <div className={classes.popover__content}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h5'
                                    color='textSecondary'
                                    style={{
                                        textAlign: 'center'
                                    }}>
                                    {t('Cambiar fecha de vencimiento')}
                                </Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    disabled={saving}
                                    onChange={changeDateTextField}
                                    value={date}
                                    id="formatted-date"
                                    inputComponent={TextMaskDate}
                                    error={invalid.date ? true : false}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    disabled={saving}
                                    onChange={changeTimeTextField}
                                    value={time}
                                    id="formatted-hour"
                                    inputComponent={TextMaskHour}
                                    error={invalid.time ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.datepicker__container}>
                                <DatePicker
                                    disabled={saving}
                                    selected={dueDate}
                                    onChange={handleChangeCalendarDate}
                                    inline
                                    locale={i18n.language}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button color='primary'
                                    variant='contained'
                                    size='small'
                                    disabled={saving}
                                    onClick={handleSave}>
                                    {t('Guardar')}
                                    {saving && (
                                        <CircularProgress color='secondary'
                                            style={{ marginLeft: 5 }}
                                            size={15} />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Popover>

                <ConfirmationDialog
                    handleClose={() => {
                        setOpenConfirmation(false)
                    }}
                    onConfirm={() => {
                        setOpenConfirmation(false)
                        confirmCheck()
                    }}
                    message={i18n.t('Are you sure you wish to mark this task as {{doneUndone}}?', { doneUndone: checked ? t('Undone') : t('Done') })}
                    cancelLabel={i18n.t('Cancel')}
                    confirmLabel={i18n.t('Continue')}
                    open={openConfirmation}
                />

            </>
        )
    }
}


export default withStyles(styles)(DueDate)