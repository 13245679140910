import React from 'react'
import { useSelector } from 'react-redux'

import {
    Typography,
    IconButton,
    Grid,
    Divider
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { green } from '@material-ui/core/colors';
import {
    MailOutline,
    Drafts,
} from '@material-ui/icons'
import moment from 'moment'

export default function ThirdTab(props) {
    const { setOpenMessagesDetails,
        setSelectedLog } = props,
        task = useSelector(state => (state.task)),
        { t } = useTranslation();

    return (
        <Grid item container spacing={3}>
            {(!task.statusLog || task.statusLog.length === 0) && task.isAssignToUser === 0 && task.complete === 0 &&
                <Grid item xs={12}>
                    <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                        <strong>{t('No action has been taken on this task')}</strong>
                    </Typography>
                </Grid>
            }


            {task.statusLog && task.statusLog.length > 0 && <>
                <Grid item xs={12}>
                    <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                        <strong>{t('Status')}</strong>
                    </Typography>

                    {task.statusLog.map((log, i) => {
                        let nonResponsive = task.statusLog.find(taskL => taskL.message.id === log.message.id && taskL.reminder && taskL.reminder.id && taskL.reminder.status === 4) && i + 1 > task.reminders.length

                        return (
                            <Grid container key={i} item spacing={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={2} sm={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography style={{ fontSize: '20px', marginRight: '10px' }} component='span'>
                                        {log.messageResponse ?
                                            <IconButton
                                                color='inherit'
                                                onClick={() => {
                                                    if (log.messageResponse) {
                                                        setOpenMessagesDetails(true)
                                                        setSelectedLog(log)
                                                    }
                                                }}>
                                                <Drafts style={{ color: green[400], height: '25px', width: '25px' }} />
                                            </IconButton>
                                            :
                                            <IconButton
                                                color='inherit'
                                                onClick={() => {
                                                    // if (nonResponsive) {
                                                    setOpenMessagesDetails(true)
                                                    setSelectedLog(log)
                                                    // }
                                                }}>
                                                <MailOutline style={{ color: nonResponsive ? 'red' : '#66788A', height: '25px', width: '25px' }} />
                                            </IconButton>
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} sm={3} style={{ padding: '4px 16px 4px 8px' }}>
                                    <Typography variant='body1'
                                        style={{ whiteSpace: 'pre-line', color: log.messageResponse ? green[400] : nonResponsive ? 'red' : '#66788A' }} >
                                        {log.title}
                                    </Typography>

                                    <Typography variant='body1'
                                        style={{ whiteSpace: 'pre-line', fontSize: '12px', color: log.messageResponse ? green[400] : nonResponsive ? 'red' : '#66788A' }}>
                                        {moment(log.timestamp).format('MMMM DD hh:mm a').replace(/^\w/, c => c.toUpperCase())}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Typography variant='body1'
                                        color={log.messageResponse || nonResponsive ? 'textPrimary' : 'textSecondary'}
                                        style={{ whiteSpace: 'pre-line' }}>
                                        {log.entityName}
                                    </Typography>
                                    <Typography variant='body1'
                                        color={log.messageResponse || nonResponsive ? 'textPrimary' : 'textSecondary'}
                                        style={{ whiteSpace: 'pre-line' }}>
                                        {log.recipient}
                                    </Typography>
                                </Grid>
                                {
                                    isMobile &&
                                    <Grid item xs={12}>
                                        <Divider style={{ width: '100%' }} />
                                    </Grid>
                                }
                            </Grid>
                        )
                    })}
                </Grid>
            </>}

            {
                task.isAssignToUser === 1 && <>
                    {!isMobile &&
                        <Grid item xs={2} sm={1} />
                    }
                    <Grid item xs={4} sm={3} style={{ padding: '4px 16px 4px 8px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant='body1' style={{ whiteSpace: 'pre-line', fontSize: '12px' }}>
                            {moment(task.assignToUserDateTime).format('MMMM DD hh:mm a').replace(/^\w/, c => c.toUpperCase())}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sm={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                            {`${t('Assign to')} ${task.responsiblesUsers.map(user => { return `${user.firstName} ${user.lastName}` }).join(',')} `}
                        </Typography>
                    </Grid>
                </>
            }

            {
                isMobile &&
                <Grid item xs={12}>
                    <Divider style={{ width: '100%' }} />
                </Grid>
            }

            {
                task.complete === 1 && task.completeUserId && <>
                    {!isMobile &&
                        <Grid item xs={2} sm={1} />
                    }
                    <Grid item xs={4} sm={3} style={{ padding: '4px 16px 4px 8px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant='body1' style={{ whiteSpace: 'pre-line', fontSize: '12px' }}>
                            {moment(task.completeDateTime).format('MMMM DD hh:mm a').replace(/^\w/, c => c.toUpperCase())}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sm={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                            {`${t('Completado')} por ${task.completeUserFirstName || ''} ${task.completeUserLastName || ''}`}
                        </Typography>
                    </Grid>
                </>
            }

        </Grid >
    )
}
