import React, { useState, useEffect } from 'react'
import {
    Chip,
    Avatar,
    MenuItem,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    Grid,
    withStyles,
    CircularProgress,
    Typography
} from '@material-ui/core'
import { Add, Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Tasks } from 'services'
import { isMobile } from 'react-device-detect'
import { ConfirmationDialog } from 'components'

import { useDispatch, useSelector } from 'react-redux'
import { updateTaskAddUser, updateTaskRemoveUser } from 'actions/tasksActions'


import styles from './styles'

const Users = props => {
    const { classes, task } = props;
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false)
    const [showAddSection, setShowAddSection] = useState(false)
    const generalParameters = useSelector(state => state.generalParameters)
    const [selectedUser, setSelectedUser] = useState(0)
    const [deleteUser, setDeleteUser] = useState(null)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [responsibleUsers, setResponsibleUsers] = useState(task.responsiblesUsers || [])
    const dispatch = useDispatch();

    const users = generalParameters.users.filter(user =>
        task.responsiblesUsers && task.responsiblesUsers.length > 0 ? (task.responsiblesUsers.find(u => u.id !== user.id) ? true : false) : true
    )

    useEffect(() => {
        let isSuscribed = true;
        if (task && task.responsiblesUsers && isSuscribed) {
            setResponsibleUsers(task.responsiblesUsers)
        }
        return () => {
            isSuscribed = false;
        };
    }, [task])

    if (!task) return null;

    const handleDelete = () => {
        let user = deleteUser;
        setSaving(true)
        Tasks.updateTaskRemoveUser({
            taskId: task.taskId,
            userId: user.id
        }).then(response => {
            if (response.success) {
                dispatch(updateTaskRemoveUser(response.record))
            }
            setDeleteUser(null)
            setSaving(false)
            setShowAddSection(false);
        })
    }

    const handleConfirmDelete = (user) => {
        setDeleteUser(user)
        setOpenConfirmation(true)
    }

    const showAddUserSection = () => {
        setShowAddSection(true);
    }
    const hideAddUserSection = () => {
        setSelectedUser(0);
        setShowAddSection(false);
    }
    const handleAddUser = () => {
        setSaving(true)
        let userToAdd = users.find(u => u.id === selectedUser)
        Tasks.updateTaskAddUser({
            taskId: task.taskId,
            userId: userToAdd.id
        }).then(response => {
            if (response.success) {
                dispatch(updateTaskAddUser(response.record))
            }
            setSaving(false)
            setShowAddSection(false);
        })
    }
    const handleChange = (e) => {
        setSelectedUser(e.target.value)
    }

    return (
        <div>
            <div className={classes.root} style={isMobile ? { margin: 0 } : {}}>
                <Grid container spacing={2}>
                    {responsibleUsers.map((user, i) => (
                        <Grid item key={i}>
                            <Typography variant='body1' >
                                {`${user.firstName} ${user.lastName}`}
                            </Typography>
                            {/* {task.complete === 1 ?
                                <Chip
                                    key={i}
                                    avatar={<Avatar>
                                        {user.firstName[0].toUpperCase()}
                                    </Avatar>}
                                    label={`${user.firstName} ${user.lastName}`}
                                    disabled={true}
                                    className={classes.userChip}
                                />
                                :
                                <Chip
                                    key={i}
                                    avatar={<Avatar>
                                        {user.firstName[0].toUpperCase()}
                                    </Avatar>}
                                    label={`${user.firstName} ${user.lastName}`}
                                    disabled={saving}
                                    onDelete={() => { handleConfirmDelete(user) }}
                                    className={classes.userChip}
                                />
                            } */}
                        </Grid>
                    ))}
                    {/* <Grid item>
                        <div className={classes.add__user__actions__container}>
                            {!showAddSection && task.complete === 0 && (
                                <>
                                    {responsibleUsers.length === 0 ? (
                                        <Button
                                            color='primary'
                                            size="small"
                                            onClick={showAddUserSection}>
                                            {t('Add responsible')}
                                        </Button>
                                    ) :
                                        (
                                            <IconButton size="small"
                                                onClick={showAddUserSection}>
                                                <Add color='primary' />
                                            </IconButton>
                                        )}
                                </>
                            )}
                            {showAddSection && users.length > 0 && (
                                <>

                                    <FormControl variant="outlined" style={{ minWidth: '200px', marginRight: 10 }}>
                                        <InputLabel id="operation-type-selector"
                                            classes={{
                                                root: classes.select__label
                                            }}
                                            shrink>
                                            {t('User')}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            placeholder={t('Select User')}
                                            value={selectedUser}
                                            onChange={handleChange}
                                            classes={{
                                                root: classes.select__root
                                            }}
                                            labelWidth={120}>
                                            <MenuItem value={0}>
                                                <em>{t('Select User')}</em>
                                            </MenuItem>
                                            {users.map(user => {
                                                return (
                                                    <MenuItem key={user.id} value={user.id}>{user.firstName} {user.lastName}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>




                                    <Button
                                        disabled={saving}
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        className={classes.actions__save}
                                        onClick={handleAddUser}
                                    >
                                        {t('Agregar')}
                                        {saving && (
                                            <CircularProgress color='secondary'
                                                style={{ marginLeft: 5 }}
                                                size={15} />
                                        )}
                                    </Button>
                                    <IconButton size="small"
                                        onClick={hideAddUserSection}>
                                        <Close />
                                    </IconButton>
                                </>
                            )}
                        </div>
                    </Grid>
                 */}
                </Grid>
            </div>
            <ConfirmationDialog
                handleClose={() => {
                    setDeleteUser(null)
                    setOpenConfirmation(false)
                }}
                onConfirm={() => {
                    setOpenConfirmation(false)
                    handleDelete()
                }}
                message={t('Are you sure you wish to remove responsable?')}
                cancelLabel={t('Cancel')}
                confirmLabel={t('Continue')}
                open={openConfirmation}
            />
        </div>
    )
}

export default withStyles(styles)(Users)