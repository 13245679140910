import React, { useState, useEffect } from 'react'
import {
    Typography,
    withStyles,
    Button,
    CircularProgress,
    IconButton
} from '@material-ui/core'
import styles from './styles'
import { OpenInNew, AttachFile, RemoveCircleOutline } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import { ConfirmationDialog } from 'components'
import { Tasks } from 'services'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTaskTemplateAttachment, addTaskTemplateAttachments } from 'actions/taskTemplateActions'

const Attachments = props => {
    const { classes, taskTemplate } = props,
        [attachments, setAttachments] = useState(taskTemplate && taskTemplate.attachments ? taskTemplate.attachments : null),
        [attachButtonLabels, setAttachButtonLabels] = useState([]),
        [filesAttachments, setFilesAttachments] = useState([]),
        [selectedAttachment, setSelectedAttachment] = useState(null),
        [openConfirmation, setOpenConfirmation] = useState(false),
        [saving, setSaving] = useState(false),
        { t } = useTranslation(),
        account = useSelector((state) => state.account),
        dispatch = useDispatch();

    useEffect(() => {
        if (taskTemplate && taskTemplate.attachments && (!attachments || (attachments && attachments.length !== taskTemplate.attachments.length))) {
            setAttachments(taskTemplate.attachments)
        }
    }, [attachments, taskTemplate])

    if (!taskTemplate) return null;

    const handleChooseFile = (e) => {
        e.stopPropagation()
        let attachButtonLabelsNew = [],
            attachButtonLabelsCopy = [...attachButtonLabels],
            files = e.target.files;

        if (files.length > 1) {
            attachButtonLabelsNew = Object.keys(files).map((key, i) => {
                return files[key].name
            })
        } else {
            attachButtonLabelsNew = [e.target.value.split("\\").pop()]
        }

        setFilesAttachments(files)
        setAttachButtonLabels(attachButtonLabelsCopy.concat(attachButtonLabelsNew))
    }
    const saveAttachments = () => {
        if (filesAttachments.length > 0) {
            setSaving(true)

            Tasks.uploadTaskTemplateAttachments({
                taskTemplateId: taskTemplate.id,
                filesAttachments,
                userId: account.id
            })
                .then(response => {
                    if (response.success) {
                        dispatch(addTaskTemplateAttachments({
                            taskTemplateId: taskTemplate.id,
                            serviceTemplateId: taskTemplate.serviceTemplateId,
                            ...response.record
                        }))
                        setSaving(false)
                        setSaving(false)
                        setSelectedAttachment(null)
                        setFilesAttachments([])
                        setAttachButtonLabels([])
                    }
                })
        }
    }

    const deleteAttachment = () => {
        setSaving(true)
        Tasks.deleteTaskTemplateAttachment({
            taskTemplateId: selectedAttachment.taskTemplateId,
            taskTemplateAttachmentId: selectedAttachment.taskTemplateAttachmentId
        }).then(response => {
            if (response.success) {
                dispatch(deleteTaskTemplateAttachment({
                    serviceTemplateId: taskTemplate.serviceTemplateId,
                    ...selectedAttachment
                }))
            }
            setSaving(false)
            setSelectedAttachment(null)
        })
    }

    const deleteUploadingAttachment = index => {
        let attachments = [...attachButtonLabels],
            newFilesAttachments = [...filesAttachments];

        attachments.splice(index, 1);
        newFilesAttachments.splice(index, 1);
        setFilesAttachments(newFilesAttachments)
        setAttachButtonLabels(attachments)
    }

    const confirmDeleteAttachment = (attachment) => {
        setSelectedAttachment(attachment)
        setOpenConfirmation(true)
    }

    return (
        <div>
            {attachments && attachments.length > 0 && (
                <div className={classes.attachments__container}
                    style={isMobile ? { margin: 0 } : {}}>
                    {attachments.map((attachment, i) => (
                        <div key={i} className={classes.attachmentLink__container}>
                            <a key={i} href={`https://${escape(attachment.attachmentUrl.replace('https://', ''))}`}
                                target='_blank'
                                className={classes.link}
                                rel="noopener noreferrer" >
                                <OpenInNew style={{
                                    width: 18,
                                    height: 18
                                }} />
                            </a>
                            <div className={classes.attachment__name__container}>
                                <Typography variant='body1' color='textPrimary'>{attachment.attachmentName}</Typography>
                                <Typography variant='body2' color='textSecondary'>{moment.utc(attachment.timestamp).format('MMMM DD hh:mm a')}</Typography>
                            </div>
                            <IconButton
                                disabled={saving}
                                style={{ marginLeft: '4px' }}
                                onClick={() => confirmDeleteAttachment(attachment)}>
                                {saving && selectedAttachment && selectedAttachment.taskTemplateAttachmentId === attachment.taskTemplateAttachmentId ? (
                                    <CircularProgress color='secondary'
                                        style={{ marginLeft: 5 }}
                                        size={15} />
                                ) : (
                                        <RemoveCircleOutline
                                            className={classes.attachment__remove__button} />

                                    )}
                            </IconButton>

                        </div>

                    ))}
                </div>

            )}
            <div style={{ width: '100%' }}>
                {attachButtonLabels.length > 0 && (
                    <div className={classes.new_attachments__container}>
                        {attachButtonLabels.map((label, i) => {
                            return (
                                <div className={classes.attachment__item} key={i}>
                                    <Typography
                                        className={classes.attachment__label__container} noWrap>
                                        <RemoveCircleOutline
                                            disabled={saving}
                                            onClick={() => deleteUploadingAttachment(i)}
                                            style={{ marginRight: 4, marginLeft: 0 }}
                                            className={classes.attachment__remove__button} />
                                        {label}
                                    </Typography>
                                </div>
                            )
                        })}
                        <div className={classes.upload__button__container}>
                            <Button
                                disabled={saving}
                                variant='contained'
                                color='primary'
                                size="small"
                                onClick={saveAttachments}
                            >
                                {t('Upload')}
                                {saving && (
                                    <CircularProgress color='secondary'
                                        style={{ marginLeft: 5 }}
                                        size={15} />
                                )}
                            </Button>
                        </div>
                    </div>
                )}

                <input
                    disabled={saving}
                    accept="image/*, application/pdf, .gif, .jpg, .png, .doc, .txt, .rtf, .xls, .xlsx"
                    className={classes.attach__input__file}
                    id="text-button-file"
                    multiple
                    type="file"
                    onChange={handleChooseFile}
                />
                <label htmlFor="text-button-file">
                    <Button
                        disabled={saving}
                        color="primary"
                        component="span"
                        className={classes.add__attachment__button}
                    >
                        {t("Add Attachment")}
                    </Button>
                </label>


            </div>

            <ConfirmationDialog
                handleClose={() => {
                    setOpenConfirmation(false)
                    setSelectedAttachment(null)
                }}
                onConfirm={() => {
                    setOpenConfirmation(false)
                    deleteAttachment()
                }}
                message={t('Are you sure you wish to delete this attachment?')}
                cancelLabel={t('Cancel')}
                confirmLabel={t('Continue')}
                open={openConfirmation}
            />


        </div>
    )
}
export default withStyles(styles)(Attachments)