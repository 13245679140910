import React, { useState, useEffect, useCallback } from 'react';

// Externals
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Material helpers
import {
    makeStyles,
    Grid,
    CircularProgress,
} from '@material-ui/core';

import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'


// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';
import { Ships as ShipsService } from 'services'

// Custom components
import Details from './Details';
import List from './List';
import stylesFile from './styles'

// Component styles
const styles = makeStyles(stylesFile);

const Ships = (props) => {
    const { match, currentPort } = props,
        [selectedShip, setSelectedShip] = useState(null),
        [ships, setShips] = useState(null),
        [fetching, setFetching] = useState(true),
        { t, i18n } = useTranslation(),
        classes = styles();

    const getShips = useCallback(() => {
        if (currentPort) {
            setFetching(true)
            ShipsService.getShips({
                port: currentPort.code
            }).then(response => {
                if (response.success) {
                    setShips(response.data)
                    setFetching(false)
                }
            })
        }
    }, [currentPort]);

    useEffect(() => {
        getShips()
    }, [getShips])

    const handleSelectedShip = (ship) => {
        // if (ship) {
        //     props.history.push(`/${i18n.language}/${currentPort.code}/settings/ships/${ship.id}`)
        // } else {
        //     props.history.push(`/${i18n.language}/${currentPort.code}/settings/ships`)
        // }
        setSelectedShip(ship)
    }

    const onCloseDetails = () => {
        setSelectedShip(null)
    }

    const changeShipInfo = (ship) => {
        let shipIndex = ships.findIndex(sh => sh.IMO === ship.IMO),
            newShips = [...ships]

        newShips[shipIndex] = ship

        setShips(newShips)

    }

    return (
        <DashboardLayout
            title={selectedShip ? `${t('Ship')} > ${selectedShip.name}` : t('Ship')}
            showBackMenuButton={selectedShip ? true : false}
            goBackUrl={`/${i18n.language}/${currentPort ? currentPort.code : match.params.port}/settings/ships`}
        >
            <div className={isMobile ? classes.mobile_root : classes.root}>
                {fetching ? (
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 300
                    }} >
                        <CircularProgress />
                    </div>
                ) : (
                        <div style={{ position: 'relative' }}>
                            {selectedShip &&
                                <Details
                                    selectedShip={selectedShip}
                                    onClose={onCloseDetails}
                                    changeShipInfo={changeShipInfo}
                                />
                            }

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8} >
                                    <List
                                        setSelectedShip={handleSelectedShip}
                                        selectedShip={selectedShip}
                                        ships={ships}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )}

            </div>
        </DashboardLayout>
    );
}

function mapStateToProps(state) {
    return {
        currentPort: state.currentPort
    }
}

export default connect(mapStateToProps, null)(withRouter(Ships));
