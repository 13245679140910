export default {
    en: {
        translation: {
            'Buque': 'Ship',
            'Tarea': 'Task',
            'Seleccionar': 'Select',
            'Agregar': 'Add',
            'Guardar': 'Save',
            'Adjuntos': 'Attachments',
            'Sin Adjuntos': 'No attachments',
            'REALIZADA': 'DONE',
            'VENCIDA': 'PAST DUE',
            'Sin Correos': 'No emails',

            //Task template
            'Horas': 'Hours',
            'Días': 'Days',
            'del ETA': 'ETA',
            'de': '',
            'Ninguna': 'None',
            'Tarea Previa': 'Previous Task',

            //Recalada
            'Fin Importación': 'End Import',
            'Fin Operaciones': 'End Operations',
            'Zarpe': 'Departure',

            //Due date
            'hourslabel': 'hours',
            'beforelabel': 'before',
            'dayslabel': 'days',
            'afterlabel': 'after',
            'attimelabel': 'At the time',
            'oflabel': 'of',

            //Users Filter
            'UsersLabel': 'Users',
            'peopleFilterTile': 'Filter by users',
            'peopleFilterEveryone': 'Everyone',
            'peopleFilterUserSelected': 'user selected',
            'peopleFilterUsersSelected': 'users selected',
            'peopleFilterButtonLabel': 'Filter',
            'peopleFilterAllUsersSelected': 'All users',

            //TASKS:
            'taskTableType': 'Type',
            taskTemplateAttachments: 'Task template attachments',
            taskEmailsAttachments: 'Emails attachments',
            taskUserAttachments: 'User attachments',

            //TasksReminders:
            'reminder_hours_before': 'hours before',
            'reminder_hours_after': 'hours after',
            'reminder_days_before': 'days before',
            'reminder_days_after': 'days after',
            'reminder_same_time': 'At due date',
            'reminders_empty': 'There are no reminders.',
            'reminders_department': 'Department',
            'reminders_add_reminder': 'Add Reminder',
            'ClosedRecalada': 'Closed',


            'ETD': 'ETD',
            'Zarpó': 'Left port',

            //Detalle de tarea > tabs
            'Descripción': 'Description',
            'Log': 'Log',
            'Notas': 'Notes',

            'Terminar tarea': 'Finish task',
            'Por favor introduzca el resltado de la tarea': 'Please enter results for task'
        },
    },
    es: {
        translation: {
            //Menu
            'Ships': 'Buques',
            'Appointments': 'Citas',
            'Emails': 'Correos',
            'Inbox': 'Entrantes',
            'Archive': 'Archivo',
            'Trash': 'Papelera',
            'Reports': 'Reportes',
            'Account': 'Cuenta',
            'Users': 'Usuarios',
            'Settings': 'Configuración',
            'Services': 'Servicios',
            'Service': 'Servicio',
            'Itineraries': 'Itinerarios',
            'Templates': 'Plantillas',
            'Notifications': 'Notificaciones',
            'Support': 'Soporte',
            'Client support': 'Soporte al Cliente',
            'Logout': 'Salir',

            //Language
            'Spanish': 'Español',
            'English': 'Ingles',

            //General
            'Export': 'Exportar',
            'New': 'Nuevo',

            //Login
            'Log In': 'Entrar',
            'Email address': 'Correo electónico',
            'Password': 'Contraseña',
            'Forgot Password?': '¿Olvidaste tu contraseña?',
            'Recover Password': 'Recuperar Contraseña',
            'Sign in now': 'Entrar ahora',
            'Wrong email and password': 'Correo electónico y contraseña incorrectos',
            'Complete your account information': 'Completa la información de tu cuenta',
            'Enter your name and a new password to complete your account.': 'Introduce tu nombre y una nueva contraseña para completar tu cuenta',
            'Continue': 'Continuar',
            'Back to sign in': 'Volver al login',
            'Name': 'Nombre',
            'An invitation will be sent to the email of the new user': 'Le será enviada una invitación al correo del nuevo usuario',
            'Your name is required': 'Tu nombre es requerido',
            'Password must have upper case, lower case, numbers and at least 6 chars': 'La contraseña debe tener mayúsculas, minúsculas, números y al menos 6 caracteres',
            'Enter a valid email': 'Introduce un correo electrónico válido',
            'Enter your password': 'Introduce tu contraseña',
            'Didn\'t you receive the email?': '¿No recibiste el correo?',
            'Resend email': 'Reenviar correo',
            'Resend email in': 'Reenviar correo en',
            'second': 'segundos',
            'Invalid link': 'Enlace inválido',
            'Password is required': 'Contraseña requerida',
            'Enter your code': 'Introduce el código',
            'User is not registered. Contact whit admin to create an account': 'Usuario no registrado. Contacte con un administrador para crear una cuenta',
            'Uncaught error': 'Error inesperado',
            'Invalid code. Please try again': 'Código Invalido. Por favor intente nuevamente',
            'Error checking code. Please try again': 'Error comprobando codigo. Por favor intente nuevamente',
            'Enter your email and we will send you instructions to recover your password': 'Introduce tu correo y te enviaremos las instrucciones de recuperación de contraseña',
            'Send instructions': 'Enviar instrucciones',
            'Enter your new password': 'Introduzca su nueva contraseña',
            'New Password': 'Nueva Contraseña',
            'Submit': 'Enviar',
            'An error has occurred with your user': 'Ha ocurrido un error con su usuario',
            'The link has already been used. If I forget your password, go back to the login and recover it.': 'El enlace ya ha sido utilizado. Si olvido su contraseña, vuelva al login y recupérela.',
            'Reset your password': 'Reestablecimiento de contraseña',
            'We have sent you an email with instructions for resetting your password': 'Te hemos enviado un correo con las instrucciones para el reestablecimiento de tu contraseña',
            'Expired link. Please resend email': 'Link expirado. Por favor reenvie el correo',
            'Enter your password to complete your account.': 'Introduzca su contraseña para completar su cuenta',

            //Emails
            'Select All': 'Todos',
            'Assign email to task': 'Asignar correo a tarea',
            'Cancel': 'Cancelar',
            'Save': 'Guardar',
            'Folder': 'Carpeta',
            'Move to trash': 'Mover a la papelera',
            'Move to inbox': 'Restaurar',
            'Are you sure you wish to move this email to trash?': 'Estás seguro que deseas mover el correo a la papelera?',
            'Unassigned to recalada': 'No asignados a recalada',
            'Search email': 'Buscar correo',

            //Recaladas
            'Search ship': 'Buscar buque',
            'There are no recaladas': 'No hay recaladas para los próximos días',
            'There are no recaladas between {{from}} and {{to}}': 'No hay recaladas entre {{from}} y {{to}}',
            'Ship': 'Buque',
            'Port': 'Puerto',
            'ETA': 'ETA',
            'Arrival': 'Arribo',
            'Arrived': 'Arribó',
            'time': 'hora',
            'Tasks': 'Tareas',
            'emails': 'correos',
            'Liner': 'Naviera',
            'Last Port': 'Último puerto',
            'Select Ship': 'Seleccione el Buque',
            'Select Service': 'Seleccione el servicio',
            'Estimated Time of Arrival to': 'Fecha estimada de arribo a',
            '{{emailsCount}} Unread Emails': '{{emailsCount}} Correos no leídos',
            '{{tasks}} tasks past due': '{{tasks}} tareas vencidas',
            '{{tasks}} tasks due soon': '{{tasks}} tareas vencen pronto',
            '{{tasks}} total tasks': "{{tasks}} total de tareas",
            '{{tasks}} tasks done': '{{tasks}} tareas listas',
            'Tasks past due': 'Tareas vencidas',
            'Tasks due soon': 'Tareas vencen pronto',
            'Tasks done': 'Tareas completadas',
            'Total task': 'Total de tareas',
            'Close': 'Cerrar',
            'Go to Recalada': 'Ir a la recalada',
            'The system will send an email': 'El Sistema enviará un correo',
            'Sent - Waiting for response': 'Enviado - Esperando respuesta',
            'Response received': 'Respuesta recibida',
            'Reminder {{retryCount}} - Waiting for response': 'Recordatorio {{retryCount}} - Esperando respuesta',
            'Waiting for response': 'Esperando respuesta',
            'Will be assigned': 'Será asignada',
            'Assigned': 'Asignada',
            'Due soon': 'Vence pronto',
            'Past due': 'Vencida',

            //Recalada DETAILS
            'Vessel Particulars': 'Vessel Particulars',
            'Voyage Information': 'Voyage Information',
            'IMO': 'OMI',
            'Type': 'Tipo',
            'MMSI': 'MSSI',
            'Call Sign': 'Distintivo de llamada Marítimo',
            'Flag': 'Bandera',
            'Gross Tonnage': 'Arqueo Bruto',
            'Summer DWT': 'Tonelaje de peso muerto',
            'Length Overall x Breadth Extreme': 'Longitud total y ancho extremo',
            'Year Build': 'Año de construcción',
            'Importación': 'Importación',
            'Exportación': 'Exportación',
            'Operations': 'Operaciones',


            //Ship Details
            'Documents': 'Documentos',
            'Recalada Documents': 'Documentos de la recalada',
            'Details': 'Detalles',
            'Add Ship': 'Agregar Buque',
            'Status': 'Estatus',
            'No tasks': 'No hay tareas',
            'No Emails': 'No hay correos',
            'No documents': 'No hay documentos',
            'Task': 'Tarea',
            'User': 'Usuario',
            'Responsibles': 'Responsables',
            'Due date': 'Vence',
            'user': 'usuario',
            'Add Document': 'Subir documento',
            'Email Address': 'Correo Electrónico',
            'Date': 'Fecha',
            'Source': 'Fuente',
            'Add Task': 'Nueva Tarea',
            'Today': 'Hoy',
            'Select User': 'Seleccione usuario',
            'Select Type': 'Seleccione typo',
            'Select': 'Seleccione',
            'Description': 'Descripción',

            //Operations type
            'Task Type': 'Tipo',
            'All': 'Todos',

            //Ports
            'Change Port': 'Cambiar Puerto',

            //TASK DETAILS:
            'Keywords': 'Palabras clave',
            'Add Keywords': 'Agregar Palabras Clave',
            'Add Attachments': 'Agregar Adjuntos',
            'Add Reminders': 'Agregar Recordatorios',
            'Add Checklist': 'Agregar Checklist',
            'Checklist': 'Sub tareas',
            'No task': 'Sin tarea asignada',
            'Recaladas': 'Recaladas',
            'Task Templates': 'Plantillas de Tareas',
            'Task Template': 'Plantilla de Tarea',
            'Add responsible': 'Agregar responsable',
            'Delete': 'Eliminar',
            'Sent message': 'Mensaje enviado automáticamente',
            'Add Subtask': 'Agregar Subtarea',
            'Add': 'Agregar',
            'Save changes': 'Guardar cambios',
            'Results': 'Resultados',
            'Task complete': 'Tarea completada',
            'Waiting requirements': 'Esperando pre requisitos',
            'Waiting response': 'Esperando respuesta',
            'Pre requirements': 'Pre requisitos',
            'Required result': 'Resultado requerido',
            'The task require documents or text as a result to be done': 'La tarea requiere documentos u otra información como resultado para marcarse como lista',
            'Are you sure you wish to delete this item?': '¿Seguro deseas eliminar esta tarea?',
            'Update as completed': 'Actualizar como terminada',
            'Ask for information': 'Solicitar información',
            'Task emails': 'Correos recibidos',
            'Must update': 'Debe actualizar',
            'Assign to': 'Asignada a',
            'Send Message': 'Enviar mensaje',
            'Send Messages': 'Enviar mensajes',
            'Message': 'Mensaje',
            'To': 'Para:',
            'Resent reminder': 'Reenviar recordatorio',
            'The following message will be sent automatically': 'Este mensaje será enviado de manera automática',

            //Task reminders
            'due date': 'del vencimiento',
            'Title': 'Título',
            'Create Reminder': 'Crear Recordatorio',
            'Reminders': 'Recordatorios',
            'reminder_hours_before': 'horas antes',
            'reminder_hours_after': 'horas después',
            'reminder_days_before': 'días antes',
            'reminder_days_after': 'días después',
            'reminder_same_time': 'Al vencer',
            'reminders_empty': 'No hay recordatorios.',
            'reminders_department': 'Departamento',
            'reminders_add_reminder': 'Agregar Recordatorio',


            'New sercice': 'Nuevo servicio',
            'New recalada': 'Registrar recalada',
            'Edit recalada': 'Editar recalada',
            'New service template': 'Nueva plantilla de servicio',
            'Template': 'Plantilla',
            'New task': 'Nueva tarea',

            'Role': 'Rol',
            'Due Hours': 'Vencimiento',
            'hours before': 'horas antes de',
            'hours after': 'horas después de',
            'days before': 'días antes de',
            'days after': 'días después de',
            'at the time of': 'al momento de',

            'hourslabel': 'horas',
            'beforelabel': 'antes',
            'dayslabel': 'dias',
            'afterlabel': 'después',
            'attimelabel': 'al momento',
            'oflabel': 'de',


            'Responsible': 'Responsable',
            'Before': 'Antes',
            'After': 'Despues',
            'No!': '¡No!',
            'Delete it!': 'Eliminar',
            'Are you sure you wish to delete this task?': '¿Seguro deseas eliminar esta tarea?',
            'This task is being used in another service template.': 'Esta tarea esta siendo utilizada por otra plantilla de servicio. ',
            'This template is linked with a task and cannot be removed': 'Este template esta asociado con una tarea y no puede ser eliminado',

            'Task template created': 'Plantilla de tarea creada',
            'Task created': 'Tarea creada',

            'Add Attachment': 'Agregar Adjunto',
            'Upload': 'Subir',
            'Are you sure you wish to delete this attachment?': '¿Seguro deseas eliminar este archivo adjunto?',

            //TASKS:
            'There are no tasks': 'No hay tareas',
            'today': 'hoy',
            'Set liner': 'Seleccionar naviera',
            'Select liner': 'Seleccionar naviera',
            'Service Template': 'Plantilla de Servicio',
            'New service': 'Nuevo servicio',
            'Set Service Template': 'Seleccionar Plantilla',
            'Edit Service': 'Editar Servicio',
            'Create Service': 'Crear Servicio',
            'There are no task templates': 'No hay plantillas de tareas',
            'Are you sure you wish to mark this task as done?': '¿Está seguro que desea terminar esta tarea?',
            'Yes, complete': 'Si, completar',
            'No, cancel': 'No, cancelar',
            'undone': 'no realizada',
            'done': 'realizada',
            'Still not done? Are you sure you wish to return to edit this task?': '¿Aún no terminas esta tarea? ¿Estás seguro de querer regresar a editarla?',
            'for {{user1}}': 'para {{user1}}',
            'for {{user1}} and {{user2}}': 'para {{user1}} y {{user2}}',
            'for the {{usersLength}} selected users': 'para los {{usersLength}} usuarios seleccionados',
            'Completed': 'Completada',
            'Past Due': 'Vencidas',
            'Total': 'Total',
            'Pending': 'Pendiente',
            'Are you sure you wish to remove responsable?': 'Seguro que deseas eliminar el responsable?',
            'taskTableType': 'Tipo',
            'You must finish task {{parentTaskName}} before this task.': 'Debes finalizar la tarea {{parentTaskName}} antes de esta tarea.',
            'taskTemplateAttachments': 'Archivos de plantilla',
            'taskEmailsAttachments': 'Archivos de emails',
            'taskUserAttachments': 'Archivos de usuario',
            'Task template details': 'Detalle de un task template',
            'Task Name': 'Nombre',
            'No action has been taken on this task': 'No se ha realizado alguna acción sobre esta tarea',
            'Are you sure you wish to remove this reminder?': '¿Estás seguro de querer eliminar este recordatorio?',
            'Add recipient': 'Agregar destinatario',
            'Recipients': 'Destinatarios',
            'Si no se ha recibido respuesta': 'Si no se ha recibido respuesta',
            'Assign to ': 'Asignar a ',


            //USERS
            'Username': 'Username',
            'Email': 'Correo',
            'There are no users': 'No hay usuarios registrados',
            'New user': 'Nuevo usuario',
            'Update user': 'Editar usuario',
            'First name': 'Nombre',
            'Last name': 'Apellido',
            'Temporary password': 'Contraseña temporal',
            'Profile': 'Perfil',
            'Position': 'Cargo',
            'Department': 'Departamento',
            'Cancel change': 'Cancelar cambio',
            'Change Password': 'Cambiar Contraseña',
            'Login info': 'Credenciales de inicio de sesión',
            'An account with the given email already exists.': 'Ya existe un usuario con este correo',
            'Uppercase, lowercase, numbers and be greater than 6 characters': 'Mayúsculas, minúsculas, numeros y mas de 6 dígitos',

            //Users Filter
            'UsersLabel': 'Usuarios',
            'peopleFilterTile': 'Filtrar por usuario',
            'peopleFilterEveryone': 'Todos los usuarios',
            'peopleFilterUserSelected': 'usuario seleccionado',
            'peopleFilterUsersSelected': 'usuarios seleccionados',
            'peopleFilterButtonLabel': 'Filtar',
            'peopleFilterAllUsersSelected': 'Todos los usuarios',

            //ENTITIES
            'Entities': 'Entidades',
            'Entity': 'Entidad',
            'Company': 'Compañía',
            'Contacts': 'Contactos',
            'Select entity': 'Seleccione',

            //SHIPS
            'Get Ship Detail': 'Obtener y guardar detalles del buque',
            'Save Details': 'Guardar detalles',
            'The information of this ship has not been saved': 'No se ha guardado informacion de este buque',
            'Upcoming Ships': 'Próximos Buques',

            //Close Recalada
            'Close Recalada': 'Cerrar Recalada',
            'Open Recalada': 'Abrir Recalada',
            'ClosedRecalada': 'Cerrada',

            //Admin dashboard
            'Active Recaladas': 'Recaladas Activas',
            'ETA / Arrive': 'ETA / Arribo',
            'ETD / Departure': 'ETD / Zarpe',
            'In port': 'En puerto',
            'In transit': 'En tránsito',
            'Done': 'Completada',
        }
    }
}