import React, { useState, useEffect } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    withStyles,
    Typography,
    IconButton,
    Grid,
    withMobileDialog,
    CircularProgress,
    Tabs,
    Tab,
} from '@material-ui/core'
import {
    Close,
    CheckCircle,
    Warning,
    Schedule
} from '@material-ui/icons'
import styles from './styles';
import compose from 'recompose/compose'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { green } from '@material-ui/core/colors';
import {
    Messages,
    Name,
    AutomaticMessage,
    Users,
    ReminderPreview
} from './components';
import {
    useDispatch
} from 'react-redux';
import { useSelector } from 'react-redux'
import { Tasks } from 'services'
import { setTask as setTaskAction, clearTask as clearTaskAction, updateTaskComplete } from 'actions/tasksActions'
import { formatRecaladaTaskImportantDate, taskDueStatus, taskDueSoon, taskPastDue } from 'lib'
import { parseHumandFrendlyDueDate, parseHumandFrendlyDueHours2 } from 'lib'
import { ConfirmationDialog } from 'components'

import FirstTab from './FirstTab'
import SecondTab from './SecondTab'
import ThirdTab from './ThirdTab'


const TaskDetails = props => {
    const dispatch = useDispatch(),
        { open, onClose, classes, fullScreen, selectedTask } = props,
        task = useSelector(state => (state.task)),
        [taskFetched, setTaskFetched] = useState(false),
        [fetching, setFetching] = useState(false),
        [completeTask, setCompleteTask] = useState(task ? task.complete : false),
        // [openResponseDetails, setOpenResponseDetails] = useState(false),
        [openMessagesDetails, setOpenMessagesDetails] = useState(false),
        [selectedReminder, setSelectedReminder] = useState(null),
        [selectedLog, setSelectedLog] = useState(null),
        [openConfirmation, setOpenConfirmation] = useState(false),
        [openPreviewReminders, setOpenPreviewReminders] = useState(false),
        [selectedTab, setSelectedTab] = useState(0),
        { t } = useTranslation(),
        recalada = useSelector(state => (state.recalada));

    useEffect(() => {
        if (!taskFetched && !fetching && selectedTask) {
            setFetching(true)
            dispatch(setTaskAction({ ...selectedTask }))
            Tasks.getTask({ taskId: selectedTask.taskId })
                .then(taskUpdatedResponse => {
                    let taskUpdated = taskUpdatedResponse.data.record;
                    setFetching(false)
                    setTaskFetched(true)
                    setCompleteTask(taskUpdatedResponse.data.record.complete)
                    dispatch(setTaskAction({ ...taskUpdated }))
                })
        }

    }, [taskFetched, fetching, selectedTask, dispatch, task])


    const handleExit = () => {
        setTaskFetched(false);
        setFetching(false);
        dispatch(clearTaskAction())
        setOpenMessagesDetails(false)
        setSelectedLog(null)
        setOpenPreviewReminders(false)
        setSelectedReminder(null)
        setSelectedTab(0)
    }

    // const getTaskInfo = () => {
    //     setFetching(true)
    //     Tasks.getTask({ taskId: task.taskId })
    //         .then(taskUpdatedResponse => {
    //             let taskUpdated = taskUpdatedResponse.data.record;
    //             setFetching(false)
    //             setTaskFetched(true)
    //             setCompleteTask(taskUpdatedResponse.data.record.complete)
    //             dispatch(setTaskAction({ ...taskUpdated }))
    //         })
    // }

    const handleUpdateAsComplete = (value) => {
        setCompleteTask(value)
        setOpenConfirmation(true)
    }

    const updateAsComplete = () => {
        Tasks.updateTaskComplete({
            taskId: task.taskId,
            complete: completeTask ? 1 : 0
        })
            .then(response => {
                if (response.success) {
                    dispatch(updateTaskComplete(response.record))
                }
            })
    }

    const goBack = () => {
        setOpenMessagesDetails(false)
        setSelectedLog(null)
    }

    const showEmailDetail = (reminder) => {
        setOpenPreviewReminders(true)
        setSelectedReminder(reminder)
    }

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    }

    return (
        <>
            <Dialog
                open={open || false}
                fullScreen={fullScreen}
                classes={{
                    paper: classes.dialog__paper
                }}
                maxWidth='md'
                onExited={handleExit}>
                <DialogTitle
                    className={classes.dialog__title}>
                    <div className={classes.dialog__title__container}>
                        <div style={{ width: '42px' }} />

                        <div style={{ textAlign: 'center' }}>
                            <Typography variant='h5' color='inherit'>{recalada && recalada.recaladaId ? `${recalada.ship ? recalada.ship.name : ''} V-${recalada.itineraryName}` : ''}</Typography>
                            {recalada && recalada.recaladaId && (
                                <Typography variant='h6' color='inherit'>{formatRecaladaTaskImportantDate(recalada, task ? task.dueDateReferenceId : 1)}</Typography>
                            )}
                        </div>

                        <IconButton
                            color='inherit'
                            onClick={onClose}>
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialog__container }} style={{ padding: !isMobile ? '16px 24px' : '16px 16px 48px' }}>
                    <div>
                        {task && (
                            <Grid container spacing={3} style={{ position: 'relative' }}>
                                <Grid item xs={12} sm={8} style={{ textAlign: 'left' }}>
                                    <Name task={task} />
                                    <Users task={task} />
                                </Grid>
                                <Grid item xs={6} sm={4} style={{ textAlign: 'right' }}>
                                    <div className={!task.complete ?
                                        classes[`duedate__container--${taskDueStatus(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId)}`] :
                                        classes['duedate__container--done']}
                                    >
                                        <Typography variant='body1' style={{
                                            marginRight: 5,
                                            textTransform: 'capitalize', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                            color: task.complete === 1 ? green[400] :
                                                taskDueSoon(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) ? 'orange' :
                                                    taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) ? 'red' : 'inherit'
                                        }}>
                                            {task.complete === 1 ?
                                                <CheckCircle style={{ width: '17px', height: '17px', marginRight: '4px', color: green[400] }} />
                                                : <>
                                                    {taskDueSoon(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) ?
                                                        <Schedule style={{ width: '17px', height: '17px', marginRight: '4px', color: 'orange' }} />
                                                        : <>
                                                            {taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) ?
                                                                <Warning style={{ width: '17px', height: '17px', marginRight: '4px', color: 'red' }} />
                                                                :
                                                                <Schedule style={{ width: '17px', height: '17px', marginRight: '4px' }} />
                                                            }
                                                        </>}
                                                </>}
                                            {task.complete === 1 ? t('Completed') :
                                                taskDueSoon(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) ? t('Due soon') :
                                                    taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) ? t('Past due') :
                                                        ''
                                            }
                                        </Typography>
                                        <Typography variant='body1' color='textPrimary' style={{ marginRight: 5, textTransform: 'capitalize' }}>
                                            {`${parseHumandFrendlyDueDate(recalada, task.dueHours, task.dueDateReferenceId, true).day || ''}`}
                                        </Typography>
                                        <Typography variant='body1' color='textPrimary' style={{ marginRight: 5, textTransform: 'capitalize' }}>
                                            {`${parseHumandFrendlyDueDate(recalada, task.dueHours, task.dueDateReferenceId, true).time || ''} (${parseHumandFrendlyDueHours2(task.dueHours, task.dueDateReferenceId)})`}
                                        </Typography>
                                    </div>
                                </Grid>

                                {/* <div style={{
                                    borderBottom: 'solid thin #ededed',
                                    padding: '24px 10px 10px',
                                }} /> */}
                                <div
                                    style={{
                                        borderBottom: 'solid thin #ededed',
                                        marginBottom: 24,
                                        width: '100%',
                                        position: 'relative'
                                    }}>
                                    <Tabs
                                        value={selectedTab}
                                        style={{
                                            marginBottom: 0
                                        }}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        onChange={handleChangeTab} >
                                        <Tab label={t('Tarea')} style={{
                                            border: 'solid thin #ededed'
                                        }} />
                                        <Tab label={t('Descripción')} style={{
                                            border: 'solid thin #ededed'
                                        }} />
                                        <Tab label={t('Log')} style={{
                                            border: 'solid thin #ededed'
                                        }} />
                                        <Tab label={t('Notas')} style={{
                                            border: 'solid thin #ededed'
                                        }} />
                                    </Tabs>

                                    {/* <div style={{ position: 'absolute', right: '8px', top: '8px' }}>
                                        <IconButton
                                            color='inherit'
                                            onClick={getTaskInfo}
                                        >
                                            <Refresh style={{ width: '26px', height: '26px', color: '#828282' }} />
                                        </IconButton>
                                    </div> */}
                                </div>



                                {fetching ?

                                    <Grid item xs={12} >
                                        <div className={classes.loading__container} >
                                            <CircularProgress style={{ fontSize: '20px' }} />
                                        </div>
                                    </Grid>

                                    : <>

                                        {/* Task tab */}
                                        {selectedTab === 0 && (
                                            <FirstTab {...props}
                                                recalada={recalada}
                                                setSelectedLog={setSelectedLog}
                                                setOpenMessagesDetails={setOpenMessagesDetails}
                                                showEmailDetail={showEmailDetail}
                                                handleUpdateAsComplete={handleUpdateAsComplete} />
                                        )}


                                        {/* Description Tab */}
                                        {selectedTab === 1 && (
                                            <SecondTab {...props} task={task} />
                                        )}

                                        {selectedTab === 2 && (
                                            <ThirdTab {...props}
                                                setOpenMessagesDetails={setOpenMessagesDetails}
                                                setSelectedLog={setSelectedLog}
                                            />
                                        )}
                                        {selectedTab === 3 && (
                                            <Grid item xs={12}>
                                                <Messages task={task} />
                                            </Grid>
                                        )}
                                    </>}
                            </Grid>
                        )}

                        {!task && (
                            <div className={classes.loading__container}>
                                <CircularProgress />
                            </div>
                        )}
                    </div>

                </DialogContent>
            </Dialog>


            {/* Mostrar correos enviados y respondidos por el robot */}
            {openMessagesDetails &&
                <AutomaticMessage
                    open={openMessagesDetails}
                    selectedLog={selectedLog}
                    task={task}
                    goBack={goBack}
                />
            }

            {/* Reminders que no han sido enviados */}
            {openPreviewReminders &&
                <ReminderPreview
                    open={openPreviewReminders}
                    selectedReminder={selectedReminder}
                    task={task}
                    goBack={() => {
                        setOpenPreviewReminders(false)
                    }}
                />
            }

            <ConfirmationDialog
                handleClose={() => {
                    setOpenConfirmation(false)
                }}
                onConfirm={() => {
                    setOpenConfirmation(false)
                    updateAsComplete()
                }}
                message={completeTask ? t('Are you sure you wish to mark this task as done?') : t('Still not done? Are you sure you wish to return to edit this task?')}
                cancelLabel={t('Cancel')}
                confirmLabel={t('Continue')}
                open={openConfirmation}
            />
        </>
    )
}

export default compose(
    withMobileDialog(),
    withStyles(styles)
)(TaskDetails)
