import React, { Component } from 'react';
import {
	CircularProgress,
	Typography,
	withStyles
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';

import { Tasks } from 'services';
import { TasksToolbar, TasksTable } from './components';

import TaskDetails from 'components/TaskDetails';
import VesselInfo from 'components/VesselInfo';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as tasksActions from 'actions/tasksActions'
import * as recaladaActions from 'actions/recaladaActions'
import * as usersFiltersActions from 'actions/usersFilters'
// import { Recaladas } from 'services'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { ActiveRecaladas } from 'components/Recaladas'

// Component styles
import styles from './style';
import moment from 'moment'

let timeout = null

class TasksList extends Component {
	signal = true;

	state = {
		isLoading: true,
		limit: 10,
		error: null,
		from: undefined,
		to: undefined,
		search: '',
		fetching: false,
		selectedTask: null,
		selectedRecalada: null,
		showTaskDetails: false,
		filterType: 0,
		showShipDetails: false,
		taskType: null,
		selectedRecaladaIdFilter: null,
		selectedShip: null
	};

	componentDidMount() {
		this.signal = true;
		this.getTasks();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.currentPort && this.props.currentPort && nextProps.currentPort.code !== this.props.currentPort.code) {
			this.setState({
				port: nextProps.currentPort.code
			}, () => {
				this.getTasks();
			})
		}
	}

	componentWillUnmount() {
		this.signal = false;
	}

	getTasks = () => {
		let { search, port, fetching, taskType } = this.state,
			{ currentPort,
				//match,  i18n 
			} = this.props;

		if (fetching) return;

		this.setState({
			isLoading: true,
			fetching: true
		});

		if (!port && currentPort) {
			port = currentPort.code
		}

		let now = moment()

		Tasks.listTask({
			search,
			portCode: port,
			from: moment.utc(moment(now.startOf('day')).format()).format(),
			to: moment.utc(moment(now.endOf('day')).format()).format(),
			taskType
		})
			.then(response => {
				if (response.success) {
					this.props.tasksActions.setTasks(response.records)
				}
				this.setState({
					isLoading: false,
					fetching: false
				})
			}).catch(err => {
				console.log(err)
				this.setState({
					isLoading: false,
					fetching: false
				})
			})
	}

	handleSelect = selectedTask => {

		this.props.tasksActions.setTask(selectedTask)
		this.props.recaladaActions.setRecalada({
			...selectedTask.recalada,
			ship: selectedTask.ship
		})
		this.setState({
			selectedTask: selectedTask,
			showTaskDetails: true
		})

		// Recaladas.getRecalada({
		// 	recaladaId: selectedTask.recalada.recaladaId
		// })
		// 	.then(response => {
		// 		if (response.success) {
		// 			this.props.recaladaActions.setRecalada(response.record)
		// 		}

		// 	})
	};

	onShipSelect = (selectedTask) => {
		this.setState({
			showShipDetails: true,
			selectedRecalada: selectedTask
		})
	}

	handleCloseTask = () => {
		this.props.recaladaActions.setRecalada(null)
		this.setState({
			selectedTask: null,
			showTaskDetails: false
		})
	}

	handleSearch = (e) => {
		this.setState({
			search: e.target.value
		})

		if (timeout) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(() => {
			this.getTasks()

			clearTimeout(timeout);
			timeout = null;
		}, 700)
	}
	handleChangeFilter = (filter) => {
		this.setState({
			from: filter.from.format('YYYY-MM-DD'),
			to: filter.to.format('YYYY-MM-DD')
		}, () => {
			this.getTasks();
		})
	}
	handleFilterByStatus = (filter) => {
		this.setState({
			filterType: filter
		})
	}

	renderTasks() {
		const { classes, tasks } = this.props;
		const { isLoading, error, filterType, selectedRecaladaIdFilter } = this.state;

		if (isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress />
				</div>
			);
		}

		if (error) {
			return <Typography variant="h6">{error}</Typography>;
		}

		return (
			<TasksTable
				onSelect={this.handleSelect}
				onShipSelect={this.onShipSelect}
				filterType={filterType}
				tasks={tasks}
				selectedRecaladaIdFilter={selectedRecaladaIdFilter}
			/>
		);
	}

	handleTypeFilter = (type) => {

		this.setState({
			taskType: type ? type : null
		}, () => {
			this.getTasks()
		})

	}
	filterByRecalada = (recaladaId) => {
		const { recaladas, generalParameters, currentPort } = this.props;
		this.setState({
			selectedRecaladaIdFilter: recaladaId,
			selectedShip: `${recaladas.find(r => r.recaladaId === recaladaId).ship.name} V-${recaladas.find(r => r.recaladaId === recaladaId).itineraryName}`
		}, () => {
			var selected = [...generalParameters.users.filter(u => u.portCode === currentPort.code)];
			this.props.usersFiltersActions.setUsersFilter(selected)
		})
	}
	clearShip = () => {
		this.setState({
			selectedRecaladaIdFilter: null,
			selectedShip: null
		}, () => {
			const { generalParameters, currentPort } = this.props;
			var selected = [...generalParameters.users.filter(u => u.portCode === currentPort.code)];
			this.props.usersFiltersActions.setUsersFilter(selected)
		})
	}

	render() {

		const { classes, t, tasks } = this.props;
		const {
			selectedTask,
			showTaskDetails,
			showShipDetails,
			selectedRecalada,
			selectedShip,
			fetching,
			isLoading
		} = this.state;

		return (
			<>
				<DashboardLayout title={t('Tasks')}>

					<ActiveRecaladas
						filterByRecalada={this.filterByRecalada}
						tasks={tasks}
					/>

					<div className={classes.root}>
						<TasksToolbar
							handleSearch={this.handleSearch}
							handleChangeFilter={this.handleChangeFilter}
							handleFilterByStatus={this.handleFilterByStatus}
							handleTypeFilter={this.handleTypeFilter}
							selectedShip={selectedShip}
							clearShip={this.clearShip}
						/>

						<div className={classes.content}>
							{this.renderTasks()}
						</div>
					</div>
				</DashboardLayout>

				<TaskDetails
					selectedTask={selectedTask}
					open={showTaskDetails}
					onClose={this.handleCloseTask} />

				<VesselInfo
					selectedShip={selectedRecalada ? {
						...selectedRecalada.recalada,
						ship: selectedRecalada.ship
					} : null}
					open={showShipDetails}
					onClose={() => {
						this.setState({
							showShipDetails: false,
							selectedRecalada: null
						})
					}}
				/>
			</>
		);
	}
}

const mapStateToProps = ({ tasks, currentPort, generalParameters, recaladas }) => ({
	tasks,
	currentPort,
	generalParameters,
	recaladas
});
const mapDispatchToProps = (dispatch) => ({
	tasksActions: bindActionCreators(tasksActions, dispatch),
	recaladaActions: bindActionCreators(recaladaActions, dispatch),
	usersFiltersActions: bindActionCreators(usersFiltersActions, dispatch)
});


export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
	withTranslation()
)(TasksList);
