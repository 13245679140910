export default theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    justifyContent: 'space-between'
  },
  options__container: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  spacer: {
    flexGrow: 1
  },
  deleteButton: {
    color: theme.palette.danger.main
  },
  actionsButtons: {
    color: theme.palette.text.secondary
  },
  importButton: {
    marginLeft: theme.spacing(1)
  },
  importIcon: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginLeft: theme.spacing(1)
  },
  exportIcon: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  textField: {
    marginTop: 2,
    minWidth: 120,
    maxWidth: '100%',
    marginRight: theme.spacing(1)
  },
});
