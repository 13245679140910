import { SET_CURRENT_PORT, CLEAR_CURRENT_PORT } from 'actions/portActions'
import { SET_ACCOUNT } from 'actions/accountActions'

export default function currentPort(state = {}, action) {
    switch (action.type) {
        case SET_ACCOUNT:
            if (!state || (state && !state.code)) {
                return {
                    code: action.account.portCode,
                    name: action.account.portName
                };
            } 
            return state;
        case SET_CURRENT_PORT:
            return { ...action.port }
        case CLEAR_CURRENT_PORT: 
            return null
        default:
            return state;
    }
}