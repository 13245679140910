import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';


// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { longDateFormat } from 'lib'
import { green } from '@material-ui/core/colors';

// Material helpers
import { withStyles, withWidth } from '@material-ui/core';

// Material components
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	List,
	ListItem,
	Divider,
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Button,
	DialogActions
} from '@material-ui/core';

import {
	Close,
	Warning,
	Schedule,
	CheckCircle
} from '@material-ui/icons';

// Shared helpers
// import { getInitials } from 'helpers';

// Shared components
import { Portlet, PortletContent } from 'components';

// Component styles
import styles from './styles';

//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as recaladasActions from '../../../../actions/recaladasActions'
import * as recaladaActions from '../../../../actions/recaladaActions'

class ShipsTable extends Component {
	state = {
		rowsPerPage: 10,
		page: 0,
		selectedRecaladaIndex: null,
		selectedChip: null,
		openDialog: false,
		selectedRecalada: null
	};
	componentDidUpdate = () => {
		moment.locale(this.props.i18n.language);
	}

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	selectRecalada = (index) => {
		const { selectedRecaladaIndex } = this.state,
			{ recaladas, i18n, history, currentPort } = this.props;

		let lang = i18n.language;

		if (index !== selectedRecaladaIndex) {
			this.props.recaladaActions.setRecalada(recaladas[index])
			history.push(`/${lang}/${currentPort.code}/recaladas/${recaladas[index].recaladaId}`)
		}

		this.setState({
			selectedRecaladaIndex: index === selectedRecaladaIndex ? null : index
		})
	}

	handleClickEmail = (emails, emailId) => {
		this.props.openEmailDetails(emails, emailId)
	}

	selectShip = (ship) => {
		this.setState({
			selectedChip: ship
		})
	}

	setOpenDialog = (value) => {
		this.setState({
			openDialog: value
		})
	}

	setSelectedRecalada = (recalada) => {
		this.setState({
			selectedRecalada: recalada
		})
	}

	render() {
		const { classes, className, recaladas, t, width, emailsCounters, ships, currentPort } = this.props;
		const {
			selectedRecaladaIndex,
			selectedChip,
			selectedRecalada,
			openDialog
		} = this.state;

		const rootClassName = classNames(classes.root, className);

		return (
			<>
				{(width !== 'xs' && !isMobile) ? (
					<Portlet className={rootClassName}>
						<PortletContent noPadding>
							{recaladas && recaladas.length > 0 &&
								<PerfectScrollbar>
									<Table>
										<TableHead>
											<TableRow className={classes.recalada__header}>
												<TableCell
													align="left"
													className={classes.tableCell__header}
													style={{ paddingLeft: '24px' }}>
													{t('Ship')}
												</TableCell>
												<TableCell
													align="center"
													className={classes.tableCell__header}>
													{t('Liner')}
												</TableCell>
												<TableCell
													align="center"
													className={classes.tableCell__header}>
													{t('ETA')}
												</TableCell>
												<TableCell
													align="center"
													style={{ paddingRight: '24px' }}
													className={classes.tableCell__header}>
													{t('Status')}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{recaladas
												.map((recalada, i) => (
													<TableRow
														className={`${classes.tableRow} ${recalada.isClosed ? classes['tableRow--closed'] : ''}`}
														hover
														key={i}
														selected={selectedRecaladaIndex === i}
														onClick={() => this.selectRecalada(i)}
													>
														<TableCell className={classes.tableCell}>
															<div className={classes.tableCellInner}>
																<Link to="#">
																	<Typography
																		className={classes.nameText}
																		variant="body1"
																	>
																		{recalada.ship.name} V-{recalada.itineraryName}
																	</Typography>

																</Link>
															</div>
															{!!recalada.isClosed && (
																<Typography variant='body2'
																	className={classes.closedLabel}
																	color='textSecondary'>
																	{t('ClosedRecalada')}
																</Typography>
															)}
														</TableCell>
														<TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
															<Typography
																variant="body1"
															>
																{recalada.linerName}
															</Typography>
														</TableCell>
														<TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
															{longDateFormat(recalada.ETA)}
														</TableCell>
														<TableCell
															className={classes.tableCell}
															style={{ textAlign: 'center' }}
															padding="checkbox">
															<IconButton
																onClick={(e) => {
																	e.stopPropagation()
																	this.setSelectedRecalada(recalada)
																	this.setOpenDialog(true)
																}}
																color='inherit'>
																{recalada.tasksCounters.totalDueTasks ?
																	<Warning style={{ color: 'red' }} />
																	: <>
																		{recalada.tasksCounters.totalAboutToDueTasks &&
																			<Schedule style={{ color: 'orange' }} />
																		}
																	</>}
															</IconButton>
															{/* <Typography
																variant='body2'
																color='textSecondary'
																style={{
																	marginLeft: 28
																}}>{emailsCounters && emailsCounters.unreadEmailsByRecaladaId && emailsCounters.unreadEmailsByRecaladaId[recalada.recaladaId] > 0 ? t('{{emailsCount}} Unread Emails', { emailsCount: emailsCounters.unreadEmailsByRecaladaId[recalada.recaladaId] }) : null}
															</Typography> */}
														</TableCell>
													</TableRow>
												))}
										</TableBody>
									</Table>
								</PerfectScrollbar>
							}

							{ships && ships.length > 0 &&
								<PerfectScrollbar>
									<Table style={{ marginTop: 50 }} size='small'>
										<TableHead>
											<TableRow className={classes.ships__header}>
												<TableCell
													align="left"
													className={classes.tableCell__ships__header}
													style={{ paddingLeft: '24px' }}>
													{t('Ship')}
												</TableCell>
												<TableCell
													align="left"
													className={classes.tableCell__ships__header}>
													{t('Liner')}
												</TableCell>
												<TableCell
													align="center"
													className={classes.tableCell__ships__header}>
													{t('ETA')}
												</TableCell>
												<TableCell
													align="center"
													style={{ paddingRight: '24px' }}
													className={classes.tableCell__ships__header}>
													{t('Last Port')}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{ships
												.map((ship, i) => (
													<TableRow
														className={classes.tableRow}
														key={i}
														onClick={() => this.selectShip(ship)}
													>
														<TableCell className={classes.tableCell__ships}>
															<Link to="#">
																<Typography
																	variant='body2'
																	color='textSecondary'
																	style={{ marginLeft: '8px' }}
																// className={classes.nameText}
																>
																	{ship.name}
																</Typography>

															</Link>
														</TableCell>
														<TableCell className={classes.tableCell__ships}>
															<Typography
																variant='body2'
																color='textSecondary'>
																{ship.linerName}
															</Typography>
														</TableCell>
														<TableCell className={classes.tableCell__ships} style={{ textAlign: 'center' }}>
															<Typography
																variant='body2'
																color='textSecondary'>
																{ship.ETA ? longDateFormat(ship.ETA) : ''}
															</Typography>
														</TableCell>
														<TableCell
															className={classes.tableCell__ships}
															style={{ color: '#999', paddingRight: '24px', textAlign: 'center' }}
															padding="checkbox"
														>
															<Typography
																variant='body2'
																color='textSecondary'>
																{ship.currentLocation ? ship.currentLocation : ''}
															</Typography>
														</TableCell>
													</TableRow>
												))}
										</TableBody>
									</Table>
								</PerfectScrollbar>
							}
						</PortletContent>
					</Portlet>
				) : (<>
					<List
						classes={{
							root: classes.list__paper
						}}>
						{recaladas
							.map((recalada, i) => (
								<React.Fragment key={i}>
									<ListItem key={i}
										onClick={() => this.selectRecalada(i)}
										className={`${classes.list__item__card} ${recalada.isClosed ? classes['tableRow--closed'] : ''}`}
									>
										<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<Typography variant="body1" >
													<strong>{recalada.ship ? recalada.ship.name : ''} V-{recalada.itineraryName}</strong>
												</Typography>
												{!!recalada.isClosed && (
													<Typography variant='body2'
														color='textSecondary'>
														{t('ClosedRecalada')}
													</Typography>
												)}
												<Typography variant='body1'>{recalada.linerName}</Typography>
											</div>

										</div>
										<div className={classes.list__item__dueDate}>
											<IconButton
												onClick={(e) => {
													e.stopPropagation()
													this.setSelectedRecalada(recalada)
													this.setOpenDialog(true)
												}}
												color='inherit'>
												{recalada.tasksCounters.totalDueTasks ?
													<Warning style={{ color: 'red' }} />
													: <>
														{recalada.tasksCounters.totalAboutToDueTasks &&
															<Schedule style={{ color: 'orange' }} />
														}
													</>}
											</IconButton>
											<Typography color='textSecondary' variant='body2'>
												{moment(recalada.ETA).format('dddd DD MMMM HH:mm')}
											</Typography>
										</div>
										{/* <Typography
												variant='body2'
												color='textSecondary'>{emailsCounters && emailsCounters.unreadEmailsByRecaladaId && emailsCounters.unreadEmailsByRecaladaId[recalada.recaladaId] > 0 ? t('{{emailsCount}} Unread Emails', { emailsCount: emailsCounters.unreadEmailsByRecaladaId[recalada.recaladaId] }) : null}
											</Typography>
											
											<div className={classes.list__item__card__icon__container}>
												{recalada.tasksCounters && (
													<div className={classes.tasks__container}>
														{recalada.tasksCounters.totalDueTasks > 0 && (
								<div className={classes.tasks__indicator__container}>
									<Warning className={classes.warningIcon} style={{ color: 'red', marginRight: 5 }} />
									<Typography variant='h6' color='textSecondary'>
										{t('{{tasks}} tasks past due', { tasks: recalada.tasksCounters.totalDueTasks })}
									</Typography>
								</div>
														)}
														{recalada.tasksCounters.totalAboutToDueTasks > 0 && (
															<div className={classes.tasks__indicator__container}>
																<Warning className={classes.warningIcon} style={{ color: 'orange', marginRight: 5 }} />
																<Typography variant='body1' color='textSecondary'>{t('{{tasks}} tasks due soon', { tasks: recalada.tasksCounters.totalAboutToDueTasks })}</Typography>
															</div>
														)}
													</div>
												)}
											</div> */}
									</ListItem>
									<Divider style={{ width: '100%' }} />
								</React.Fragment>
							))}
					</List>


					<div variant="body1" className={classes.tableCell__ships__header}
						style={{
							height: '40px',
							border: 'solid thin #ababab',
						}}>
					</div>

					<List
						classes={{
							root: classes.list__paper
						}}>
						{ships
							.map((ship, i) => (
								<React.Fragment key={i}>
									<ListItem key={i}
										onClick={() => this.selectShip(ship)}
										className={classes.list__item__card}
									>
										<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<Typography variant="body2" color='textSecondary'>
													<strong>{ship.name}</strong> - {ship.linerName}
												</Typography>
											</div>
										</div>
										{ship.currentLocation &&
											<Typography
												variant='body2'
												style={{ margin: '4px 0 8px' }}
												color='textSecondary'>
												<strong>{t('Last Port')}</strong>{` ${ship.currentLocation}`}
											</Typography>
										}
										<div className={classes.list__item__dueDate}>
											<Typography
												variant='body2'
												color='textSecondary'>
												{ship.ETA ? moment(ship.ETA).format('dddd DD MMMM HH:mm') : ''}
											</Typography>
										</div>

									</ListItem>
									<Divider style={{ width: '100%' }} />
								</React.Fragment>
							))}
					</List>

				</>)
				}


				{
					selectedChip &&
					<Dialog
						open={selectedChip ? true : false}
						classes={{
							paper: classes.dialog__paper
						}}
						fullScreen={width !== 'xs' && !isMobile ? false : true}
						maxWidth='xs'>
						<DialogTitle className={classes.dialog__title}>
							<div className={classes.dialog__title__container}>
								<Typography variant='h5' color='inherit'>
									{`${selectedChip.linerName} - ${selectedChip.name} `}
								</Typography>
								<IconButton
									onClick={() => {
										this.setState({
											selectedChip: null
										})
									}}
									color='inherit'>
									<Close />
								</IconButton>
							</div>
						</DialogTitle>
						<DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12}>
									<Typography variant='body1'
										style={{ display: 'flex', flexDirection: width !== 'xs' && !isMobile ? 'row' : 'column' }}>
										<strong style={{ marginRight: '6px' }}>
											{`${t('Estimated Time of Arrival to')} ${currentPort.name}`}</strong>
										<span style={{ paddingLeft: '10px' }}>
											{selectedChip.ETA ? moment(selectedChip.ETA).format('MMMM DD hh:mm a') : ''}
										</span>
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12}>
									<Typography variant='body1' className={classes.title__content}>
										<strong style={{ marginRight: '6px' }}>{t('IMO')}</strong>
										<span style={{ paddingLeft: '10px' }}>{selectedChip.IMO}</span>
									</Typography>

									{selectedChip.MMSI && <>
										<Typography variant='body1' className={classes.title__content}>
											<strong style={{ marginRight: '6px' }}>{t('MMSI')}</strong>
											<span style={{ paddingLeft: '10px' }}>{selectedChip.MMSI}</span>
										</Typography>
										<Typography variant='body1' className={classes.title__content}>
											<strong style={{ marginRight: '6px' }}>{t('Type')}</strong>
											<span style={{ paddingLeft: '10px' }}>{selectedChip.type}</span>
										</Typography>
										<Typography variant='body1' className={classes.title__content}>
											<strong style={{ marginRight: '6px' }}>{t('Flag')}</strong>
											<span style={{ paddingLeft: '10px' }}>{selectedChip.flag}</span>
										</Typography>
										<Typography variant='body1' className={classes.title__content}>
											<strong style={{ marginRight: '6px' }}>{t('Summer DWT')}</strong>
											<span style={{ paddingLeft: '10px' }}>{selectedChip.summerDWT}</span>
										</Typography>
										<Typography variant='body1' className={classes.title__content}>
											<strong style={{ marginRight: '6px' }}>{t('Length Overall x Breadth Extreme')}</strong>
											<span style={{ paddingLeft: '10px' }}>{`${selectedChip.lengthOverall}m x ${selectedChip.breadthExtreme}m`}</span>
										</Typography>
										<Typography variant='body1' className={classes.title__content}>
											<strong style={{ marginRight: '6px' }}>{t('Year Built')}</strong>
											<span style={{ paddingLeft: '10px' }}>{selectedChip.yearBuild}</span>
										</Typography>
									</>}
								</Grid>
							</Grid>

							<div style={{ textAlign: 'center', marginTop: '32px' }}>
								<Button
									color="primary"
									size="small"
									variant="contained"
									onClick={() => alert('Aqui se podra crear una recalada')}
								>
									{t('New recalada')}
								</Button>
							</div>
						</DialogContent>
					</Dialog>
				}


				{selectedRecalada &&
					<Dialog
						onClose={() => {
							this.setOpenDialog(false)
							this.setSelectedRecalada(null)
						}}
						classes={{
							paper: classes.dialog__paper
						}}
						maxWidth='xs'
						open={openDialog}>
						<DialogContent>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
								<Typography variant='h5'>
									{`${selectedRecalada.ship.name} V-${selectedRecalada.itineraryName}`}
								</Typography>
								<IconButton
									color='textSecondary'
									onClick={() => {
										this.setOpenDialog(false)
										this.setSelectedRecalada(null)
									}}
								>
									<Close />
								</IconButton>
							</div>

							<div className={classes.tasks__container} style={{ width: '220px' }}>
								<div className={classes.tasks__indicator__container}>
									<Warning style={{ width: '14px', color: 'red', marginRight: '6px' }} />

									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
										<Typography variant='body1' color='textSecondary'>
											{t('Tasks past due')}
										</Typography>
										<Typography variant='body1' color='textSecondary'>
											{selectedRecalada.tasksCounters.totalDueTasks ? selectedRecalada.tasksCounters.totalDueTasks : 0}
										</Typography>
									</div>
								</div>

								<div className={classes.tasks__indicator__container}>
									<Schedule style={{ width: '14px', color: 'orange', marginRight: '6px' }} />

									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
										<Typography variant='body1' color='textSecondary'>
											{t('Tasks due soon')}
										</Typography>
										<Typography variant='body1' color='textSecondary'>
											{selectedRecalada.tasksCounters.totalAboutToDueTasks ? selectedRecalada.tasksCounters.totalAboutToDueTasks : 0}
										</Typography>
									</div>
								</div>

								<div className={classes.tasks__indicator__container}>
									<CheckCircle style={{ width: '14px', color: green[400], marginRight: '6px' }} />

									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
										<Typography variant='body1' color='textSecondary'>
											{t('Tasks done')}
										</Typography>
										<Typography variant='body1' color='textSecondary'>
											{selectedRecalada.tasksCounters.totalDoneTasks ? selectedRecalada.tasksCounters.totalDoneTasks : 0}
										</Typography>
									</div>
								</div>

								<div className={classes.tasks__indicator__container}>
									<div style={{ width: '23px' }} />

									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
										<Typography variant='body1' color='textSecondary'>
											{t('Total task')}
										</Typography>
										<Typography variant='body1' color='textSecondary'>
											{selectedRecalada.tasksCounters.totalTasks ? selectedRecalada.tasksCounters.totalTasks : 0}
										</Typography>
									</div>
								</div>
							</div>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => {
									this.props.history.push(`./recaladas/${selectedRecalada.recaladaId}`)
								}}
								color='primary'>
								{t('Go to Recalada')}
							</Button>
						</DialogActions>
					</Dialog>
				}
			</>
		);
	}
}

ShipsTable.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired,
	onSelect: PropTypes.func,
	recaladas: PropTypes.array.isRequired
};

ShipsTable.defaultProps = {
	onSelect: () => { },
};

function mapDispatchToProps(dispatch) {
	return {
		recaladasActions: bindActionCreators(recaladasActions, dispatch),
		recaladaActions: bindActionCreators(recaladaActions, dispatch)
	}
}


function mapStateToProps(state) {
	return {
		recaladas: state.recaladas,
		emailsCounters: state.emailsCounters,
		currentPort: state.currentPort
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withRouter(withTranslation()(withStyles(styles)(ShipsTable)))));
