export default theme => ({
    name__container: {
        cursor: 'pointer'
    },
    textfield: {
        width: '100%',
        color: theme.palette.text.primary,
        backgroundColor: '#fff',
        padding: theme.spacing()
    },
    actions: {
        marginTop: theme.spacing(),
        display: 'flex'
    },
    actions__save: {
        marginRight: 10
    },
    description__textfield: {
        width: '100%',
        backgroundColor: '#fff',
        padding: theme.spacing()
    },
})