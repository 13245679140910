import React, { useState } from 'react'
import {
    TextField,
    InputAdornment,
    IconButton,
    Typography,
    CircularProgress,
    Button,
    makeStyles,
    Link,
    Grid,
} from '@material-ui/core'
import {
    Visibility,
    VisibilityOff
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import styles from './styles';
import auth from '../../authentication/auth';


const useStyles = makeStyles(styles)

export default function RequireNewPassword(props) {
    const classes = useStyles(),
        { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false)
    const [inputs, setInputs] = useState({})
    const [isValid, setIsValid] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [validationErrors, setValidationErrors] = useState({})


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({
            ...inputs,
            [event.target.name]: event.target.value
        }))
    }
    const validatePassword = (password) => {
        let errors = [];
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
            upper = /[A-Z]/,
            lower = /[a-z]/,
            number = /[0-9]/, valid = false;
        if (password && password.match(passw)) {
            valid = true
        } else {
            if (!password) {
                errors.push(t('Password is required'))
            } else {
                let showPasswordError = false;
                if (!password) {
                    showPasswordError = true;
                } else {
                    if (!password.match(upper) || !password.match(lower) || !password.match(number) || (password.length < 8 || password.length > 20)) {
                        showPasswordError = true;
                    }
                }
                if (showPasswordError) {
                    errors.push(t('Password must have upper case, lower case, numbers and at least 6 chars'))
                }
            }
        }
        return valid
    }

    const validate = () => {
        let errors = {};
        // if (!inputs.name || (inputs.name && inputs.name.trim() === '')) {
        //     errors.name = t('Your name is required')
        // }
        if (!validatePassword(inputs.newPassword)) {
            errors.password = t('Password must have upper case, lower case, numbers and at least 6 chars')
        }

        setValidationErrors(errors)
        if (Object.keys(errors).length > 0) {
            setIsValid(true);
            return false;
        }
        setIsValid(false);
        return true;
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            saveNewPassword()
        }
    }

    const saveNewPassword = () => {
        const { history, match } = props,
            url_language = match.params.lang;


        if (validate()) {
            setIsLoading(true)
            auth.completeNewPassword({
                user: props.authData,
                newPassword: inputs.newPassword,
                name: inputs.name
            })
                .then(user => {
                    setIsLoading(false)
                    props.onStateChange('signedIn', user);
                    history.push(`/${url_language}/dashboard`);
                })
                .catch(err => {
                    console.log(err)
                    setIsLoading(false)
                })
        }
    }

    const backToLogin = () => {
        props.onStateChange('signIn')
    }

    if (props.authState !== 'requireNewPassword') return null


    return (
        <form className={classes.form} autocomplete="off" >
            <img
                height="90"
                style={{ margin: '0 auto' }}
                alt="CTSA"
                className={classes.logoImage}
                src="/images/logos/logoCTSA.jpg"
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        className={classes.title}
                        variant="h4"
                    >
                        {t('Enter your password to complete your account.')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        label={t("Name")}
                        name="name"
                        id='name'
                        onChange={handleInputChange}
                        value={inputs.name || ''}
                        disabled={isLoading ? true : false}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        label={t("Password")}
                        name="newPassword"
                        id='newPassword'
                        onChange={handleInputChange}
                        onKeyDown={onKeyDown}
                        type={showPassword ? 'text' : 'password'}
                        value={inputs.newPassword || ''}
                        disabled={isLoading ? true : false}
                        variant="outlined"
                        error={!isValid && validationErrors.password ? true : false}
                        autoComplete='off'
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            inputProps: {
                                autoCapitalize: 'off',
                                autoComplete: 'new-password',
                                spellCheck: 'false',
                                autoCorrect: "off"
                            },
                            endAdornment: (
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    {validationErrors.password && (
                        <Typography className={classes.error__label} variant='body2' color='error'>{validationErrors.password}</Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {isLoading ? (
                        <CircularProgress className={classes.progress} />
                    ) : (
                            <>
                                <Button
                                    className={classes.signInButton}
                                    color="primary"
                                    disabled={!isValid}
                                    onClick={saveNewPassword}
                                    size="large"
                                    variant="contained"
                                >
                                    {t('Continue')}
                                </Button>
                                <div className={classes.backToLogin__link__container}>
                                    <Link
                                        className={classes.backToLogin__link}
                                        underline='always'
                                        variant='button'
                                        disabled={isLoading ? true : false}
                                        onClick={backToLogin}>
                                        {t('Back to sign in')}
                                    </Link>
                                </div>
                            </>
                        )}

                </Grid>
            </Grid>
        </form>
    )
}
