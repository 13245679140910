import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next'
import {
	withStyles, TextField
} from '@material-ui/core';
import { Button, IconButton } from '@material-ui/core';
import {
	Delete as DeleteIcon, MoveToInbox,
	// Archive,
	Refresh
} from '@material-ui/icons';
import { SearchInput, ConfirmationDialog } from 'components';
import { Recaladas } from 'services';
import styles from './styles';

//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as recaladasActions from 'actions/recaladasActions'

class EmailsToolbar extends Component {
	state = {
		fetching: true,
		shipsRecaladas: [],
		openConfirmation: false,
		selectedRecalada: 0
	}


	componentDidMount = () => {
		this.getEmailsRecaladas()
	}

	getEmailsRecaladas = (port = null) => {
		const { currentPort } = this.props;
		if (currentPort || port) {
			Recaladas.getRecaladas({
				emails: true,
				port: port ? port : currentPort.code
			})
				.then(response => {
					if (response.success) {
						this.props.recaladasActions.setRecaladas(response.data.records.recaladas)
						this.setState({
							shipsRecaladas: response.data.records.recaladas.map(lf => ({
								...lf,
								label: `${lf.shipName} V-${lf.itineraryName}`,
								value: lf.recaladaId,
								id: lf.recaladaId,
							}))
						})
					}
					this.setState({
						fetching: false
					})
				})
		}
	}

	componentWillReceiveProps = nextProps => {
		if (!this.props.currentPort && nextProps.currentPort && this.state.shipsRecaladas.length === 0) {
			this.getEmailsRecaladas(nextProps.currentPort.code)
		} else if (this.props.currentPort && nextProps.currentPort && this.props.currentPort.code !== nextProps.currentPort.code) {
			this.getEmailsRecaladas(nextProps.currentPort.code)
			this.setState({ selectedRecalada: 0 })
		}
	}

	handleSelectRecaladaChange = (e) => {
		const { shipsRecaladas } = this.state;
		if (e.target.value === '-1') {
			this.props.handleSelectRecalada && this.props.handleSelectRecalada({ id: -1 })
		} else {
			let shipRecalada = shipsRecaladas.find(s => parseInt(s.id) === parseInt(e.target.value));
			this.props.handleSelectRecalada && this.props.handleSelectRecalada(shipRecalada ? shipRecalada : null)
		}
		this.setState({ selectedRecalada: e.target.value })
	}

	cancelConfirmation = () => {
		this.setState({
			openConfirmation: false
		})
	}

	onConfirm = () => {
		this.setState({
			openConfirmation: true
		})
	}

	render() {
		const { t, classes, className, selectedEmails, match, changing } = this.props,
			{ shipsRecaladas, fetching, openConfirmation, selectedRecalada } = this.state;

		let folder = match.params.folder ? match.params.folder : null

		const rootClassName = classNames(classes.root, className);

		return (
			<div className={rootClassName}>
				<div className={classes.row}>
					<div className={classes.options__container}>
						<SearchInput
							className={classes.searchInput}
							placeholder={t("Search email")}
							onChange={this.props.handleSearch}
							disabled={fetching}
						/>

						<TextField
							disabled={fetching}
							value={selectedRecalada}
							className={classes.textField}
							SelectProps={{ native: true }}
							select
							variant='outlined'
							label={t('Buque')}
							margin="dense"
							placeholder={t('Seleccionar')}
							InputLabelProps={{
								shrink: true
							}}
							onChange={this.handleSelectRecaladaChange}
						>

							<option
								value={0}
							>
								{t('All')}
							</option>
							<option
								value='-1'
							>
								{t('Unassigned to recalada')}
							</option>
							{shipsRecaladas.map(option => (
								<option
									key={option.value}
									value={option.value}
								>
									{option.label}
								</option>
							))}
						</TextField>

						{selectedEmails.length > 0 && <>
							{folder !== 'inbox' &&
								<Button
									className={classes.actionsButtons}
									disabled={changing}
									onClick={() => this.props.changeFolder(1)}
								>
									<MoveToInbox />
									<div style={{ fontSize: '12px', margin: '2px 0 0 3px' }}>{t("Move to inbox")}</div>
								</Button>
							}

							{
								// folder !== 'archive' &&
								// 	<IconButton
								// 		className={classes.actionsButtons}
								// 		disabled={changing}
								// 		onClick={() => this.props.changeFolder(2)}
								// 	>
								// 		<Archive />
								// 	</IconButton>
							}

							{folder !== 'trash' &&
								<Button
									className={classes.actionsButtons}
									disabled={changing}
									onClick={this.onConfirm}
								>
									<DeleteIcon />
									<div style={{ fontSize: '12px', margin: '4px 0 0 3px' }}>{t("Move to trash")}</div>
								</Button>
							}
						</>}
					</div>
					<IconButton
						className={classes.actionsButtons}
						onClick={this.props.handleReload}>
						<Refresh />
					</IconButton>
				</div>

				<ConfirmationDialog
					handleClose={this.cancelConfirmation}
					onConfirm={() => {
						this.setState({
							openConfirmation: false
						})

						this.props.changeFolder(3)
					}}
					message={t('Are you sure you wish to move this email to trash?')}
					cancelLabel={t('Cancel')}
					confirmLabel={t('Move to trash')}
					open={openConfirmation}
				/>
			</div>
		);
	}
}

EmailsToolbar.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired,
	selectedEmails: PropTypes.array
};

EmailsToolbar.defaultProps = {
	selectedEmails: []
};

function mapDispatchToProps(dispatch) {
	return {
		recaladasActions: bindActionCreators(recaladasActions, dispatch),
	}
}

function mapStateToProps(state, ownProps) {
	return {
		recaladas: state.recaladas,
		currentPort: state.currentPort
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(compose(withTranslation(), withRouter, withStyles(styles))(EmailsToolbar));
