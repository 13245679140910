export default theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 8px'
    }
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  spacer: {
    flexGrow: 1
  },
  deleteButton: {
    color: theme.palette.danger.main,
    marginRight: theme.spacing(1)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  importIcon: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  exportIcon: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  filter__option: {
    padding: theme.spacing()
  },
  'filter__option--selected': {

  }
});
