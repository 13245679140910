import React, { useState, useEffect } from 'react'
import {
    MenuItem,
    Button,
    IconButton,
    FormControl,
    Select,
    withStyles,
    Typography,
    CircularProgress
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Tasks } from 'services'

import { useDispatch, useSelector } from 'react-redux'
import { updateTaskTemplateRole } from 'actions/taskTemplateActions'


import styles from './styles'

const UsersRoles = props => {
    const { classes, taskTemplate } = props;
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false)
    const [showAddSection, setShowAddSection] = useState(false)
    const generalParameters = useSelector(state => state.generalParameters)
    const roles = generalParameters.roles;
    const [selectedUserRole, setSelectedUserRole] = useState(taskTemplate && taskTemplate.roleId ? taskTemplate.roleId : 0)
    const dispatch = useDispatch();


    useEffect(() => {
        let isSuscribed = true;
        if (taskTemplate && taskTemplate.roleId && isSuscribed) {
            setSelectedUserRole(taskTemplate.roleId)
        }
        return () => {
            isSuscribed = false;
        };
    }, [taskTemplate])

    if (!taskTemplate) return null;

    const handleSaveUserRole = () => {
        setSaving(true)

        Tasks.updateTaskTemplateRole({
            taskTemplateId: taskTemplate.id,
            roleId: selectedUserRole
        }).then(response => {
            if (response.success) {
                dispatch(updateTaskTemplateRole({
                    ...response.record,
                    serviceTemplateId: taskTemplate.serviceTemplateId
                }))
            }
            setSaving(false)
            setShowAddSection(false);
        })
    }
    const handleChange = (e) => {
        if (e.target.value !== taskTemplate.roleId) {
            setShowAddSection(true);
        } else {
            setShowAddSection(false);
        }
        setSelectedUserRole(e.target.value)
    }
    const handleCancel = () => {
        setShowAddSection(false);
        setSelectedUserRole(taskTemplate.roleId)
    }

    return (
        <div>
            <Typography variant='h6'>{t('Responsible')}</Typography>
            <div className={classes.root}>
                <div className={classes.add__user__actions__container}>


                    <FormControl variant="outlined" style={{ minWidth: '200px', marginRight: 10 }}>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            placeholder={t('Select User')}
                            value={selectedUserRole}
                            onChange={handleChange}
                            classes={{
                                root: classes.select__root
                            }}
                            labelWidth={0}>
                            {roles.map(rol => {
                                return (
                                    <MenuItem key={rol.roleId} value={rol.roleId}>{rol.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>


                    {showAddSection && roles.length > 0 && (
                        <>
                            <Button
                                disabled={saving}
                                variant='contained'
                                size='small'
                                color='primary'
                                onClick={handleSaveUserRole}
                                className={classes.actions__save}>
                                {t('Save')}
                                {saving && (
                                    <CircularProgress color='secondary'
                                        style={{ marginLeft: 5 }}
                                        size={15} />
                                )}
                            </Button>
                            <IconButton size="small"
                                onClick={handleCancel}>
                                <Close />
                            </IconButton>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(UsersRoles)