export const SET_CURRENT_PORT = 'SET_CURRENT_PORT'
export const CLEAR_CURRENT_PORT = 'CLEAR_CURRENT_PORT'

export function setCurrentPort(port) {
    return {
        type: SET_CURRENT_PORT,
        port
    }
}

export function clearCurrentPort(){
    return {
        type: CLEAR_CURRENT_PORT
    }
}