export const CREATE_TASK_TEMPLATE = 'CREATE_TASK_TEMPLATE'
export const SET_TASK_TEMPLATE = 'SET_TASK_TEMPLATE'
export const CLEAR_TASK_TEMPLATE = 'CLEAR_TASK_TEMPLATE'
export const UPDATE_TASK_TEMPLATE_NAME = 'UPDATE_TASK_TEMPLATE_NAME'
export const UPDATE_TASK_TEMPLATE_DESCRIPTION = 'UPDATE_TASK_TEMPLATE_DESCRIPTION'
export const UPDATE_TASK_TEMPLATE_KEYWORDS = 'UPDATE_TASK_TEMPLATE_KEYWORDS'
export const UPDATE_TASK_TEMPLATE_ROLE = 'UPDATE_TASK_TEMPLATE_ROLE'
export const UPDATE_TASK_TEMPLATE_TYPE = 'UPDATE_TASK_TEMPLATE_TYPE'
export const UPDATE_TASK_TEMPLATE_PARENT = 'UPDATE_TASK_TEMPLATE_PARENT'
export const UPDATE_TASK_TEMPLATE_DUEHOURS = 'UPDATE_TASK_TEMPLATE_DUEHOURS'
export const DELETE_TASK_TEMPLATE_ATTACHMENT = 'DELETE_TASK_TEMPLATE_ATTACHMENT'
export const ADD_TASK_TEMPLATE_ATTACHMENTS = 'ADD_TASK_TEMPLATE_ATTACHMENTS'
export const CREATE_TASK_TEMPLATE_REMINDER = 'CREATE_TASK_TEMPLATE_REMINDER'
export const DELETE_TASK_TEMPLATE_REMINDER = 'DELETE_TASK_TEMPLATE_REMINDER'
export const UPDATE_TASK_TEMPLATE_REMINDER = 'UPDATE_TASK_TEMPLATE_REMINDER'
export const SET_TASK_TEMPLATE_CHECKLIST = 'SET_TASK_TEMPLATE_CHECKLIST'
export const UPDATE_TASK_TEMPLATE_REQUIRED_RESULT = 'UPDATE_TASK_TEMPLATE_REQUIRED_RESULT'


export function createTaskTemplate(data) {
    return {
        type: CREATE_TASK_TEMPLATE,
        data
    }
}
export function setTaskTemplate(taskTemplate) {
    return {
        type: SET_TASK_TEMPLATE,
        taskTemplate
    }
}

export function clearTaskTemplate() {
    return {
        type: CLEAR_TASK_TEMPLATE,
    }
}

export function updateTaskTemplateName(data) {
    return {
        type: UPDATE_TASK_TEMPLATE_NAME,
        data
    }
}
export function updateTaskTemplateDescription(data) {
    return {
        type: UPDATE_TASK_TEMPLATE_DESCRIPTION,
        data
    }
}
export function updateTaskTemplateKeywords(data) {
    return {
        type: UPDATE_TASK_TEMPLATE_KEYWORDS,
        data
    }
}
export function updateTaskTemplateRole(data) {
    return {
        type: UPDATE_TASK_TEMPLATE_ROLE,
        data
    }
}
export function updateTaskTemplateType(data) {
    return {
        type: UPDATE_TASK_TEMPLATE_TYPE,
        data
    }
}
export function updateTaskTemplateParentTask(data) {
    return {
        type: UPDATE_TASK_TEMPLATE_PARENT,
        data
    }
}
export function updateTaskTemplateDueHours(data) {
    return {
        type: UPDATE_TASK_TEMPLATE_DUEHOURS,
        data
    }
}


export function addTaskTemplateAttachments(data) {
    return {
        type: ADD_TASK_TEMPLATE_ATTACHMENTS,
        data
    }
}

export function deleteTaskTemplateAttachment(data) {
    return {
        type: DELETE_TASK_TEMPLATE_ATTACHMENT,
        data
    }
}

export function createTaskTemplateReminder(reminder) {
    return {
        type: CREATE_TASK_TEMPLATE_REMINDER,
        reminder
    }
}
export function deleteTaskTemplateReminder(reminder) {
    return {
        type: DELETE_TASK_TEMPLATE_REMINDER,
        reminder
    }
}
export function updateTaskTemplateReminder(reminder) {
    return {
        type: UPDATE_TASK_TEMPLATE_REMINDER,
        reminder
    }
}

export function setTaskTemplateChecklist(data) {
    return {
        type: SET_TASK_TEMPLATE_CHECKLIST,
        data
    }
}


export function updateTaskTemplateRequiredResult(data) {
    return {
        type: UPDATE_TASK_TEMPLATE_REQUIRED_RESULT,
        data
    }
}

