import React, { Component } from 'react';
// Externals
import { Chart } from 'react-chartjs-2';
// Material helpers
import { ThemeProvider } from '@material-ui/styles';
// ChartJS helpers
import { chartjs } from './helpers';
// Theme
import theme from './theme';
// Styles
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import { BrowserRouter } from 'react-router-dom'
// Routes
import Routes from './Routes';

// Configure ChartJS
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}
