import {
    SET_ACCOUNT,
    CLEAR_ACCOUNT
} from 'actions/accountActions'

export default function account(state = {}, action) {
    switch (action.type) {
        case SET_ACCOUNT:
            if(!state || (state && state.id !== action.account.id)){
                return { ...action.account };
            }
            
            return state
        case CLEAR_ACCOUNT:
            return null;
        default:
            return state;
    }
}