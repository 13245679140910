import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next'
import {
	CircularProgress,
	Typography,
	TextField,
	withMobileDialog,
	withStyles
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Emails } from 'services';
import { EmailsToolbar, EmailsTable, EmailDetails } from './components';
import { animateScroll as scroll } from 'react-scroll'

import { connect } from 'react-redux'


import {
	isMobile
} from "react-device-detect";

// Component styles
import styles from './style';

const folders = [{
	id: 1,
	name: 'inbox'
},
{
	id: 2,
	name: 'archive'
},
{
	id: 3,
	name: 'trash'
},];

let timeout = null

class EmailsList extends Component {
	signal = true;

	state = {
		isLoading: true,
		limit: 10,
		selectedEmails: [],
		emails: [],
		error: null,
		folderId: 1,
		rowsPerPage: 25,
		page: 0,
		selectedMenu: null,
		search: '',
		selectedEmail: null,
		changing: false,
		selectedRecalada: null,
	};

	getEmails = ({ portCode } = {}) => {
		const { folderId, rowsPerPage, page, search, selectedRecalada } = this.state,
			{ match, currentPort } = this.props;



		if ((currentPort && currentPort.code) || portCode) {

			this.setState({ isLoading: true })

			Emails.getEmails({
				folderId,
				recordCount: rowsPerPage,
				offset: (page) * rowsPerPage,
				search,
				portCode: portCode ? portCode : currentPort.code,
				recaladaId: selectedRecalada && selectedRecalada.id ? selectedRecalada.id : null
			})
				.then(response => {
					let newEmails = response.data.records.emails,
						newFolder = folders.find(f => f.id === folderId);

					this.setState({
						isLoading: false,
						emails: newEmails,
						unreadCounters: response.data.records.unreadCounters,
						totalCounters: response.data.records.totalCounters,
						selectedMenu: newFolder
					})

					if (match.params && match.params.emailId) {
						this.setState({
							selectedEmail: newEmails.find(e => `${e.emailId}` === match.params.emailId)
						})
					}

					// if (props.match.params.emailId) {
					// 	let email = newEmails.find(e => parseInt(e.emailId) === parseInt(props.match.params.emailId));
					// 	handleOpenEmail(null, email)
					// } else {
					// 	handleCloseEmail()
					// }
				}).catch(err => {
					console.log(err)
					this.setState({
						isLoading: false
					})
				})
		}
	}

	componentDidMount() {
		const { match } = this.props

		if (match.params.folder) {
			let newFolder = folders.find(f => f.name === match.params.folder.toLowerCase());
			this.setState({
				folderId: newFolder.id
			}, () => {
				this.getEmails();
			})
		} else {
			this.getEmails();
		}
		this.signal = true;
	}

	componentWillReceiveProps(nextProps) {
		const { match } = nextProps,
			{ folderId } = this.state;

		if (!folderId || (folderId && match.params.folder && folders.find(f => f.id === folderId).name !== match.params.folder)) {
			let newFolder = folders.find(f => f.name === match.params.folder.toLowerCase());

			this.setState({
				folderId: newFolder.id
			}, () => {
				this.setState({
					selectedEmail: null
				})
				this.getEmails();
			})
		}

		if (match.params.folder && folders.find(f => f.id === folderId).name === match.params.folder && !match.params.emailId && this.props.match.params.emailId) {
			this.setState({
				selectedEmail: null
			})
		}

		if (!this.props.currentPort && nextProps.currentPort && nextProps.currentPort.code) {
			this.getEmails({ portCode: nextProps.currentPort.code });
		} else if (this.props.currentPort && nextProps.currentPort && this.props.currentPort.code !== nextProps.currentPort.code) {
			this.getEmails({ portCode: nextProps.currentPort.code });
		}
	}

	componentWillUnmount() {
		this.signal = false;
	}

	handleSelectEmails = selectedEmails => {
		this.setState({ selectedEmails });
	};

	renderEmails() {
		const { classes, t } = this.props;
		const { isLoading, emails, error, page, rowsPerPage, folderId, selectedEmails } = this.state;

		if (isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress />
				</div>
			);
		}

		if (error) {
			return <Typography variant="h6">{error}</Typography>;
		}

		if (emails.length === 0) {
			return (
				<div style={{ textAlign: 'center', height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Typography variant="h6">{t("No Emails")}</Typography>
				</div>
			)
		}
		let totalEmails = this.state.totalCounters.totalInbox;

		if (folderId === 2) {
			totalEmails = this.state.totalCounters.totalArchive;
		} else if (folderId === 3) {
			totalEmails = this.state.totalCounters.totalDelete;
		}

		return (
			<EmailsTable
				onSelect={this.handleSelectEmails}
				emails={emails}
				handleChangePage={this.handleChangePage}
				handleChangeRowsPerPage={this.handleChangeRowsPerPage}
				page={page}
				selectedEmails={selectedEmails}
				rowsPerPage={rowsPerPage}
				openEmailDetails={this.openEmailDetails}
				totalEmailsCount={totalEmails}
			/>
		);
	}

	handleChangePage = (event, page) => {
		this.setState({ page }, this.getEmails);
	};

	handleChangeRowsPerPage = event => {
		this.setState({
			rowsPerPage: event.target.value,
			page: (event.target.value * this.state.page) + event.target.value > this.state.emails.length ? parseInt(this.state.emails.length / event.target.value) : this.state.page
		}, this.getEmails);
	};

	handleSearch = (e) => {
		this.setState({
			search: e.target.value
		})

		if (timeout) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(() => {
			this.getEmails()

			clearTimeout(timeout);
			timeout = null;
		}, 700)
	}

	changeFolder = (foldId) => {
		this.setState({
			changing: true
		})

		const { selectedEmails, search, folderId } = this.state;

		Emails.changeEmailsFolder({
			body: {
				emailsIds: selectedEmails,
				folderId: foldId,
				oldFolderId: folderId,
				search
			}
		})
			.then(response => {
				this.setState({
					selectedEmails: [],
					changing: false,
					emails: response.data.records.emails
				})
			}).catch(err => {
				this.setState({
					changing: false
				})
				console.log(err);
			})
	}
	scrollToTop = () => {
		scroll.scrollToTop({ duration: 400 })
	}
	openEmailDetails = (emailId) => {
		const { match, history, currentPort } = this.props,
			{ folderId, emails } = this.state;

		this.scrollToTop();

		let newFolder = folders.find(f => f.id === folderId);

		history.push(`/${match.params.lang}/${currentPort.code}/emails/${newFolder.name.toLowerCase()}/${emailId}`);

		this.setState({
			selectedEmail: emails.find(e => e.emailId === emailId)
		})
	}

	closeEmailDetail = () => {
		const { match, history, currentPort } = this.props,
			{ folderId } = this.state;

		let newFolder = folders.find(f => f.id === folderId);

		history.push(`/${match.params.lang}/${currentPort.code}/emails/${newFolder.name.toLowerCase()}`);

		this.setState({
			selectedEmail: null,
			selectedEmails: []
		})
	}

	updateEmails = (email) => {
		const { emails } = this.state

		let newEmails = [...emails],
			index = emails.findIndex(e => e.emailId === email.emailId)

		newEmails[index] = email

		this.setState({
			emails: newEmails
		})
	}
	handleSelectRecalada = (recalada) => {
		const { selectedRecalada } = this.state;
		if ((!selectedRecalada && recalada)
			|| (selectedRecalada && !recalada)
			|| (selectedRecalada && recalada && selectedRecalada.id !== recalada.id)) {
			this.setState({
				selectedRecalada: recalada
			}, () => {
				this.getEmails();
			})
		}
	}

	handleChangeEmailsFolder = (e) => {
		const { history, i18n, match } = this.props;
		history.push(`/${i18n.language}/${match.params.port}/emails/${e.target.value}`)
	}

	render() {
		const { classes, t, history, match, width } = this.props;
		const { selectedEmails, selectedEmail, folderId, changing } = this.state;

		let folder = folders.find(f => f.id === folderId);

		return (
			<DashboardLayout title={t("Emails")}>
				{(isMobile || width === 'xs' || width === 'md') && folder && (
					<>
						{width === 'md' ? (
							<Typography variant='h5' style={{
								padding: '24px 0 0 24px',
								textAlign: 'left'
							}}>
								{t(folder.name.charAt(0).toUpperCase() + folder.name.slice(1))}
							</Typography>
						) : (
								<div style={{
									padding: '20px 0 0 16px',
									textAlign: 'left'
								}}>
									<TextField
										className={classes.textField}
										SelectProps={{ native: true }}
										select
										variant='outlined'
										label={t('Folder')}
										margin="dense"
										placeholder={t('Seleccionar')}
										InputLabelProps={{
											shrink: true
										}}
										onChange={this.handleChangeEmailsFolder}
									>
										{folders.map(option => (
											<option
												key={option.id}
												value={option.name}
											>
												{t(option.name.charAt(0).toUpperCase() + option.name.slice(1))}
											</option>
										))}
									</TextField>
								</div>
							)}


					</>
				)}

				<div className={width === 'xs' && isMobile && selectedEmail ? '' : classes.root} style={{ position: 'relative' }}>
					<div className={classes.overlay} style={{ display: changing ? 'block' : 'none' }} />
					{changing &&
						<div style={{ textAlign: 'center' }}>
							<CircularProgress />
						</div>
					}

					{!selectedEmail && <>
						<EmailsToolbar
							loading={this.state.isLoading}
							handleReload={this.getEmails}
							selectedEmails={selectedEmails}
							handleSearch={this.handleSearch}
							changeFolder={this.changeFolder}
							changing={changing}
							handleSelectRecalada={this.handleSelectRecalada}
						/>
						<div className={classes.content}>{this.renderEmails()}</div>
					</>}

					{selectedEmail &&
						<EmailDetails
							history={history}
							match={match}
							folderId={folderId}
							selectedEmail={selectedEmail}
							back={this.closeEmailDetail}
							updateEmails={this.updateEmails}
							changeFolder={this.changeFolder}
							folders={folders} />
					}
				</div>
			</DashboardLayout>
		);
	}
}

EmailsList.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired
};



const mapDispatchToProps = (dispatch) => ({})


const mapStateToProps = (state) => ({
	currentPort: state.currentPort
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
	withTranslation(),
	withMobileDialog(),
	withStyles(styles)
)(EmailsList);
