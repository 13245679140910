import React, { useState } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    withStyles,
    Typography,
    IconButton,
    Grid,
    withMobileDialog,
    Divider,
    Button
} from '@material-ui/core'

import {
    Warning,
    Event,
    AssignmentIndOutlined
} from '@material-ui/icons'

import { Tasks } from 'services'
import { Close } from '@material-ui/icons'
import styles from './styles';
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { clearTaskTemplate } from 'actions/taskTemplateActions'
import { deleteTaskTemplate } from 'actions/generalActions'
import { setTaskTemplateChecklist } from 'actions/taskTemplateActions'
import { ConfirmationDialog } from 'components'
import { reminderFrendlyDueHours } from 'lib'

import {
    Name,
    Description,
    DueHours,
    ParentTask,
    TaskTypeAndResponsible,
    Attachments,
    RemindersList,
    CheckList
} from './components'


const TaskTemplateDetails = props => {

    const {
        open,
        onClose,
        classes,
        selectedTaskTemplate,
        fullScreen,
    } = props;
    const { t } = useTranslation();
    const [openConfirmation, setOpenConfirmation] = useState(false),
        [deleting, setDeleting] = useState(false),
        [deleteTemplateMessage, setDeleteTemplateMessage] = useState(''),
        [errorMessage, setErrorMessage] = useState(''),
        dispatch = useDispatch();

    const handleDelete = () => {
        setDeleting(true)

        Tasks.deleteTaskTemplate({
            taskTemplateId: selectedTaskTemplate.id
        })
            .then(response => {
                if (response.success) {
                    dispatch(clearTaskTemplate())
                    dispatch(deleteTaskTemplate({ taskTemplateId: selectedTaskTemplate.id, serviceTemplateId: selectedTaskTemplate.serviceTemplateId }))
                    setOpenConfirmation(false)

                    setDeleting(false)

                    onClose()
                } else {
                    setErrorMessage(t(response.errors.USER_MESSAGE))
                    setOpenConfirmation(false)
                    setDeleting(false)
                }
                setDeleteTemplateMessage('')
            })
    }


    const onExited = () => {
        setOpenConfirmation(false);
        setDeleting(false);
        setDeleteTemplateMessage('');
        setErrorMessage('');
    }

    const updateChecklist = (checklist) => {
        Tasks.updateTaskTemplateChecklist({
            taskTemplateId: selectedTaskTemplate.id,
            checklist
        })
            .then(response => {
                if (response.success) {
                    dispatch(setTaskTemplateChecklist({
                        taskTemplateId: selectedTaskTemplate.id,
                        serviceTemplateId: selectedTaskTemplate.serviceTemplateId,
                        checklist
                    }))
                }
            })
    }

    // const confirmDeleteTemplate=()=>{
    //     let servicesTemplatesUsed = [...generalParameters.servicesTemplates].filter(st => st.taskTemplates.filter(tt => tt.id === selectedTaskTemplate.id).length > 0)

    //     if(servicesTemplatesUsed.length > 1){
    //         setDeleteTemplateMessage(t('This task is being used in another service template.'))
    //         setOpenConfirmation(true)
    //     } else {
    //         setOpenConfirmation(true)
    //     }
    // }

    return (
        <div>
            <Dialog
                open={open || false}
                fullScreen={fullScreen}
                classes={{
                    paper: classes.dialog__paper
                }}
                maxWidth='md'
                onExited={onExited}>
                <DialogTitle
                    className={classes.dialog__title}>
                    <div className={classes.dialog__title__container}>
                        <div style={{ width: '42px' }} />
                        <Typography variant='h5' color='inherit'>
                            {selectedTaskTemplate && selectedTaskTemplate.name ? `${selectedTaskTemplate.code ? `${selectedTaskTemplate.code} ` : ''} ${selectedTaskTemplate.name}` : t('Task Template')}
                        </Typography>
                        <IconButton
                            color='inherit'
                            onClick={onClose}>
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent classes={{
                    root: classes.dialog__container
                }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Name taskTemplate={selectedTaskTemplate} />
                        </Grid>
                        <Grid item xs={12}
                            className={classes.dueReminders__container}
                            style={{ padding: 0 }}>
                            <DueHours taskTemplate={selectedTaskTemplate} />
                        </Grid>

                        {selectedTaskTemplate && selectedTaskTemplate.inputs && selectedTaskTemplate.inputs.length > 0 &&
                            <Grid item xs={12}>
                                <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                                    <strong>{t('Pre requirements')}</strong>
                                </Typography>

                                {selectedTaskTemplate.inputs && selectedTaskTemplate.inputs.length > 0 && selectedTaskTemplate.inputs.map(input => {
                                    return (
                                        <Typography variant='body1' style={{ whiteSpace: 'pre-line' }} >
                                            {input.name}
                                        </Typography>
                                    )
                                })}
                            </Grid>
                        }

                        <Grid item xs={12} >
                            <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                                <strong>{t('Description')}</strong>
                            </Typography>
                            <Description taskTemplate={selectedTaskTemplate} />
                        </Grid>

                        {selectedTaskTemplate && selectedTaskTemplate.outputs && selectedTaskTemplate.outputs.length > 0 &&
                            <Grid item xs={12}>
                                <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                                    <strong>{t('Must update')}</strong>
                                </Typography>
                                <div style={{ marginLeft: '8px' }}>
                                    {selectedTaskTemplate.outputs.map(out => {
                                        return (
                                            <Typography variant='body1' style={{ whiteSpace: 'pre-line' }} >
                                                {` - ${out.name}`}
                                            </Typography>
                                        )
                                    })}
                                </div>
                            </Grid>
                        }
                        <Grid item xs={12} sm={8}>
                            <TaskTypeAndResponsible taskTemplate={selectedTaskTemplate} />
                        </Grid>
                        <Grid item xs={12}>
                            <ParentTask taskTemplate={selectedTaskTemplate} />
                        </Grid>

                        {selectedTaskTemplate && selectedTaskTemplate.reminders.length > 0 && <>
                            <Grid item xs={12}>
                                <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                                    <strong>{t('Recipients')}</strong>
                                </Typography>
                                <RemindersList taskTemplate={selectedTaskTemplate} />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider style={{ width: '100%', margin: '12px 0' }} />
                            </Grid>
                        </>}


                        {selectedTaskTemplate && selectedTaskTemplate.reminderRecurrency.length > 0 && <>
                            <Grid item xs={12}>
                                <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                                    <strong>{t('Si no se ha recibido respuesta')}</strong>
                                </Typography>
                                <Grid container item spacing={1}>
                                    {selectedTaskTemplate.reminderRecurrency.map((reminder, i) => (
                                        <Grid item
                                            xs={12}
                                            key={i}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <IconButton style={{
                                                    cursor: "pointer",
                                                    color: '#66788A'
                                                }}>
                                                    {reminder.type === 'Resend' ?
                                                        <Event style={{ height: '25px', width: '25px' }} />
                                                        :
                                                        <AssignmentIndOutlined style={{ height: '25px', width: '25px' }} />
                                                    }
                                                </IconButton>
                                                <Typography variant='body1' color='textPrimary'>
                                                    {reminder.type === 'Resend' ? `${t('Enviar recordatorio')}` : `${t('Assign to ')} ${reminder.firstName} ${reminder.lastName}`}
                                                </Typography>
                                                <Typography variant='body1' color='textSecondary' style={{ marginLeft: '14px' }}> {reminderFrendlyDueHours(reminder.dueHours)}</Typography>
                                            </div>
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Button
                                            color="primary"
                                            className={classes.add__reminder__button}
                                        >
                                            {t('reminders_add_reminder')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider style={{ width: '100%', margin: '12px 0' }} />
                            </Grid>
                        </>}


                        {/* <Grid item xs={12} >
                            <CheckList updateChecklist={updateChecklist} />
                        </Grid> */}

                        <Grid item xs={12} >
                            <Typography variant='body1' style={{ whiteSpace: 'pre-line', marginBottom: '6px' }}>
                                <strong>{t('Adjuntos')}</strong>
                            </Typography>
                            <Attachments taskTemplate={selectedTaskTemplate} />
                        </Grid>

                        <Grid item xs={12}>
                            {errorMessage &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Warning style={{ color: 'orange', marginRight: '8px' }} />
                                    <Typography variant='body1' color='error'>{errorMessage}</Typography>
                                </div>
                            }
                        </Grid>

                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* <Button
                                disabled={deleting}
                                style={{color: 'red'}}
                                onClick={confirmDeleteTemplate}>
                                {t('Delete')}
                            </Button> */}

                            <div />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>


            <ConfirmationDialog
                handleClose={() => {
                    setOpenConfirmation(false)
                }}
                onConfirm={() => {
                    handleDelete()
                }}
                loading={deleting}
                message={deleteTemplateMessage ? `${deleteTemplateMessage} \n ${t('Are you sure you wish to delete this task?')}` : t('Are you sure you wish to delete this task?')}
                cancelLabel={t('No!')}
                confirmLabel={t('Delete it!')}
                open={openConfirmation}
            />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        generalParameters: state.generalParameters,
    }
}

export default connect(mapStateToProps, null)(compose(
    withMobileDialog(),
    withStyles(styles)
)(TaskTemplateDetails))