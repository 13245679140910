export const SET_SERVICE = 'SET_SERVICE'
export const CLEAR_SERVICE = 'CLEAR_SERVICE'
export const UPDATE_SERVICE = 'UPDATE_SERVICE'
export const CREATE_SERVICE = 'CREATE_SERVICE'

export function setService(service) {
    return {
        type: SET_SERVICE,
        service
    }
}

export function clearService() {
    return {
        type: CLEAR_SERVICE,
    }
}

export function updateService(data) {
    return {
        type: UPDATE_SERVICE,
        data
    }
}

export function createService(data) {
    return {
        type: CREATE_SERVICE,
        data
    }
}