import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    Grid,
    TextField,
    CircularProgress,
    withMobileDialog,
    withStyles,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Link
} from '@material-ui/core'


import { Close, AttachFile, RemoveCircleOutline } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { useDispatch, useSelector } from 'react-redux'
import { Tasks } from 'services'

import { createTaskTemplate as createTaskTemplateAction } from 'actions/taskTemplateActions'
import styles from './styles'
import { SnackbarNotification } from 'components'
import { CheckList } from '../TaskTemplateDetails/components'
import { CustomSelect } from 'components/Common'



const CreateTaskTemplate = (props) => {
    const { fullScreen, classes, serviceTemplateId, serviceTemplate } = props,
        [openDialgo, setOpenDialgo] = useState(false),
        [showValidations, setShowValidations] = useState(false),
        [invalidFields, setInvalidFields] = useState({}),
        [saving, setSaving] = useState(false),
        [name, setName] = useState(''),
        [hours, setHours] = useState(0),
        [description, setDescription] = useState(''),
        [selectedEntities, setSelectedEntities] = useState(['']),
        [requiredResult, setRequiredResult] = useState(false),
        [checklist, setChecklist] = useState([]),
        [timeOption, setTimeOption] = useState(0),
        [hoursOrDaysOption, setHoursOrDaysOption] = useState(0),
        [dateReferenceOption, setDateReferenceOption] = useState(1),
        [selectedUserRole, setSelectedUserRole] = useState(0),
        [selectedType, setSelectedType] = useState(0),
        [selectedPreviousTask, setSelectedPreviousTask] = useState(0),
        [showNotification, setShowNotification] = useState(false),
        [notificationMessage, setShowNotificationMessage] = useState(''),
        [attachButtonLabels, setAttachButtonLabels] = useState([]),
        [filesAttachments, setFilesAttachments] = useState([]),
        { t } = useTranslation(),
        generalParameters = useSelector(state => state.generalParameters),
        currentPort = useSelector(state => state.currentPort),
        roles = generalParameters.roles,
        users = generalParameters.users,
        // entities = generalParameters.entities,
        // currentEntities = entities[currentPort.code],
        taskTypes = generalParameters.taskTypes,
        dueDateReferences = generalParameters.dueDateReferences,
        account = useSelector((state) => state.account),
        dispatch = useDispatch();

    const beforeAfterOptions = [
        {
            value: 0,
            label: t('Before')
        },
        {
            value: 1,
            label: t('After')
        }
    ],
        hoursOrDays = [
            {
                value: 0,
                label: t('Horas')
            },
            {
                value: 1,
                label: t('Días')
            }
        ]

    const handleCreateTaskTemplate = () => {
        setOpenDialgo(true)
    }
    const onClose = () => {
        setOpenDialgo(false)
    }
    const onExited = () => {
        setName('')
        setDescription('')
        setShowValidations(false)
        setInvalidFields({})
        setHours(0)
        setTimeOption(0)
        setHoursOrDaysOption(0)
        setSelectedUserRole(0)
        setRequiredResult(false)
        setSelectedType(0)
        setSelectedPreviousTask(0)
        setSaving(false)
        setChecklist([])
        setFilesAttachments([])
        setAttachButtonLabels([])
        setSelectedEntities([''])
    }

    const handleChangeName = e => {
        if (e.target.value.length <= 150) {
            setName(e.target.value)
        }
    }
    const handleChangeDescription = e => {
        setDescription(e.target.value)
    }
    const handleChangeHours = e => {
        setHours(e.target.value);
    }
    const handleChangeOption = e => {
        setTimeOption(e.target.value);
    }
    const handleChangeHoursOrDayOption = e => {
        setHoursOrDaysOption(e.target.value);
    }
    const handleChangeDateReferenceOption = e => {
        setDateReferenceOption(e.target.value);
    }

    // const handleChangeEntity = (e) => {
    //     setSelectedEntities(e.target.value)
    // }

    const handleChangeType = (e) => {
        setSelectedType(e.target.value)
    }
    const handleChangeSelectedPreviousTask = (e) => {
        if (e.target.value) {
            setSelectedPreviousTask(e.target.value)
        } else {
            setSelectedPreviousTask(0)
        }
    }
    const handleChangeUserRole = (e) => {
        setSelectedUserRole(e.target.value)
    }

    const handleCancel = () => {
        setOpenDialgo(false)
    }


    const validateParameters = () => {
        let valid = true;
        let fields = {};
        if (name.trim() === '') {
            valid = false;
            fields = {
                ...fields,
                name: true
            }
        }
        if (!selectedUserRole) {
            valid = false;
            fields = {
                ...fields,
                role: true
            }
        }
        if (!selectedType) {
            valid = false;
            fields = {
                ...fields,
                type: true
            }
        }
        if (!valid) {
            setShowValidations(true)
            setInvalidFields(fields)
        } else {
            setShowValidations(false)
        }
        return valid;
    }


    const handleSave = () => {
        if (validateParameters()) {
            setSaving(true)
            let totalHours = hoursOrDaysOption === 0 ? hours : hours * 24;
            let dueHours = timeOption === 0 ? 0 - totalHours : totalHours;
            let parameters = {
                serviceTemplateId,
                name,
                description,
                dueHours,
                roleId: selectedUserRole,
                keywords: [],
                checklist,
                entities: selectedEntities,
                requiredResult: requiredResult ? 1 : 0,
                taskTypeId: selectedType,
                dueDateReferenceId: dateReferenceOption,
                perviousTaskId: selectedPreviousTask,
                filesAttachments,
                userId: account.id
            }
            Tasks.createTaskTemplate(parameters)
                .then(response => {
                    if (response.success) {
                        dispatch(createTaskTemplateAction(response.record))
                        setSaving(false)
                        setOpenDialgo(false)
                        setShowNotification(true)
                        setShowNotificationMessage(t('Task template created'))
                        setTimeout(closeNotification, 2500)
                    }
                })
        }
    }

    const closeNotification = () => {
        setShowNotification(false)
        setShowNotificationMessage('')
    }

    const handleChooseFile = (e) => {
        e.stopPropagation()
        let attachButtonLabelsNew = [],
            attachButtonLabelsCopy = [...attachButtonLabels],
            files = e.target.files;

        if (files.length > 1) {
            attachButtonLabelsNew = Object.keys(files).map((key, i) => {
                return files[key].name
            })
        } else {
            attachButtonLabelsNew = [e.target.value.split("\\").pop()]
        }

        setFilesAttachments(files)
        setAttachButtonLabels(attachButtonLabelsCopy.concat(attachButtonLabelsNew))
    }

    const deleteAttachment = index => {

        let attachments = [...attachButtonLabels],
            newFilesAttachments = [...filesAttachments];

        attachments.splice(index, 1);
        newFilesAttachments.splice(index, 1);
        setFilesAttachments(newFilesAttachments)
        setAttachButtonLabels(attachments)
    }

    const addChecklist = (checklist) => {
        setChecklist(checklist)
    }

    let totalHours = hoursOrDaysOption === 0 ? hours : hours * 24;
    let dueHours = timeOption === 0 ? 0 - totalHours : totalHours;

    let validParentsTemplates = serviceTemplate ? serviceTemplate.taskTemplates.filter(t => t.dueHours <= dueHours) : []

    let currentUsers = users.filter(user => user.portCode === currentPort.code),
        rolesIds = currentUsers.map(rol => (rol.roleId)),
        currentRoles = roles.filter(rol => rolesIds.includes(rol.roleId))

    return (
        <div>
            <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={handleCreateTaskTemplate}
            >
                {t('New task')}
            </Button>

            <Dialog
                open={openDialgo}
                fullScreen={fullScreen}
                classes={{
                    paper: classes.dialog__paper
                }}
                onExited={onExited}
                maxWidth='xs'>
                <DialogTitle
                    className={classes.dialog__title}>
                    <div className={classes.dialog__title__container}>
                        <Typography variant='h5' color='inherit'>
                            {t('New task')} - {currentPort.name}
                        </Typography>
                        <IconButton
                            color='inherit'
                            onClick={onClose} >
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent classes={{
                    root: classes.dialog__container
                }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <TextField
                                disabled={saving}
                                required
                                error={showValidations && invalidFields.name}
                                label={t('Task Name')}
                                autoFocus
                                value={name}
                                onChange={handleChangeName}
                                style={{
                                    width: '100%'
                                }}
                                InputProps={{
                                    inputProps: {
                                        className: classes.textField__input
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant='outlined' />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' color='textSecondary'>{t('Due Hours')}</Typography>
                            <div className={classes.dueHours__root}>
                                <TextField
                                    disabled={saving}
                                    value={hours}
                                    onChange={handleChangeHours}
                                    type='number'
                                    style={{
                                        minWidth: 80,
                                        marginRight: 10
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            className: classes.dueHours__textfield__input
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    placeholder={t('Horas')}
                                    variant='outlined'
                                />
                                <Select
                                    disabled={saving}
                                    value={hoursOrDaysOption}
                                    onChange={handleChangeHoursOrDayOption}
                                    variant='outlined'
                                    style={{ marginRight: 10 }}
                                    classes={{
                                        root: classes.select__root
                                    }}>
                                    {hoursOrDays.map(option => {
                                        return (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <Select
                                    disabled={saving}
                                    value={timeOption}
                                    onChange={handleChangeOption}
                                    variant='outlined'
                                    style={{ marginLeft: 5, marginRight: 5 }}
                                    classes={{
                                        root: classes.select__root
                                    }}>
                                    {beforeAfterOptions.map(option => {
                                        return (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <Typography className={classes.label} style={{ marginRight: 10 }}>{t('de')}</Typography>
                                <Select
                                    disabled={saving}
                                    style={{ marginRight: 10 }}
                                    value={dateReferenceOption}
                                    onChange={handleChangeDateReferenceOption}
                                    variant='outlined'
                                    classes={{
                                        root: classes.select__root
                                    }}>
                                    {dueDateReferences.map(option => {
                                        return (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                disabled={saving}
                                error={showValidations && invalidFields.description}
                                label={t('Description')}
                                value={description}
                                multiline
                                onChange={handleChangeDescription}
                                style={{
                                    width: '100%',
                                }}
                                InputProps={{
                                    inputProps: {
                                        className: classes.textField__area__input
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant='outlined' />
                        </Grid>


                        <Grid item xs={12} sm={5}>
                            <FormControl required variant="outlined" style={{ minWidth: '200px', width: '100%', marginRight: 10 }}>
                                <InputLabel id="task-type-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}
                                    shrink>
                                    {t('Task Type')}
                                </InputLabel>
                                <Select
                                    disabled={saving}
                                    error={showValidations && invalidFields.type}
                                    labelId="task-type-selector"
                                    placeholder={t('Select Rol')}
                                    value={selectedType}
                                    onChange={handleChangeType}
                                    variant='outlined'
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={80}
                                >
                                    <MenuItem key={0} value={0}>{t("Select")}</MenuItem>
                                    {taskTypes.map(type => {
                                        return (
                                            <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <FormControl
                                required
                                variant="outlined"
                                style={{
                                    minWidth: '200px', width: '100%',
                                    marginRight: 10
                                }}
                                disabled={saving}
                            >
                                <InputLabel id="role-type-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}
                                    shrink>
                                    {t('Responsible')}
                                </InputLabel>
                                <Select
                                    error={showValidations && invalidFields.role}
                                    labelId="role-type-selector"
                                    placeholder={t('Select User')}
                                    value={selectedUserRole}
                                    onChange={handleChangeUserRole}
                                    variant='outlined'
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={100}
                                >
                                    <MenuItem key={0} value={0}>{t("Select")}</MenuItem>
                                    {currentRoles.map(rol => {
                                        return (
                                            <MenuItem key={rol.roleId} value={rol.roleId}>{rol.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* <Grid item xs={12}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <InputLabel id="task-type-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}
                                    shrink>
                                    {t('Entities')}
                                </InputLabel>
                                <Select
                                    labelId="task-type-selector"
                                    placeholder={t('Select entity')}
                                    value={selectedEntities}
                                    multiple
                                    onChange={handleChangeEntity}
                                    variant='outlined'
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={80}
                                >
                                    {currentEntities.map(type => {
                                        return (
                                            <MenuItem key={type.entityId} value={type.entityId}>{type.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid> */}


                        {/* Tarea predecesora */}
                        {validParentsTemplates.length > 0 && (
                            <Grid item xs={12}>
                                <CustomSelect options={validParentsTemplates ?
                                    [{
                                        label: t('Ninguna'),
                                        value: 0
                                    },
                                    ...validParentsTemplates.map((e) => ({
                                        "label": e.name,
                                        "value": e.id
                                    }))] : []}
                                    placeholder={t('Ninguna')}
                                    label={t('Tarea Previa')}
                                    value={selectedPreviousTask}
                                    fullWidth={false}
                                    inputProps={{
                                        className: classes.select__root
                                    }}
                                    required={true}
                                    disabled={saving}
                                    onSelect={(e) => {
                                        handleChangeSelectedPreviousTask({
                                            target: {
                                                value: e ? e.value : 0
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                        )}

                        {/* <Grid item xs={12}>
                            <RemindersList />
                        </Grid> */}

                        {/* <Grid item xs={12}>
                            <CheckList
                                addChecklist={addChecklist}
                            />
                        </Grid> */}

                        {/* Archivos Adjuntos */}
                        <Grid item xs={12}>
                            <label htmlFor="text-button-file">
                                <Link
                                    disabled={saving}
                                    variant='body1'
                                    color="primary"
                                    className={classes.attach__button}
                                >
                                    <AttachFile style={{ fontSize: 20 }} />
                                    {t("Add Attachment")}
                                </Link>
                            </label>
                            <input
                                disabled={saving}
                                accept="image/*, application/pdf, .gif, .jpg, .png, .doc, .txt, .rtf, .xls, .xlsx"
                                className={classes.attach__input__file}
                                id="text-button-file"
                                multiple
                                type="file"
                                onChange={handleChooseFile}
                            />
                            <div className={classes.attachments__container}>
                                {attachButtonLabels.map((label, i) => {
                                    return (
                                        <div className={classes.attachment__item} key={i}>
                                            <Typography
                                                className={classes.attachment__label__container} noWrap>
                                                <RemoveCircleOutline
                                                    disabled={saving}
                                                    onClick={() => deleteAttachment(i)}
                                                    className={classes.attachment__remove__button} />
                                                {label}
                                            </Typography>
                                        </div>
                                    )
                                })}
                            </div>

                        </Grid>


                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                disabled={saving}
                                onClick={handleCancel}>
                                {t('Cancel')}
                            </Button>
                            <Button
                                disabled={saving}
                                variant='contained'
                                color='primary'
                                onClick={handleSave}
                            >
                                {t('Save')}
                                {saving && (
                                    <CircularProgress color='secondary'
                                        style={{ marginLeft: 5 }}
                                        size={15} />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <SnackbarNotification
                open={showNotification && notificationMessage ? true : false}
                onClose={closeNotification}
                message={notificationMessage}
                type='success' />
        </div>
    )
}

export default compose(
    withMobileDialog(),
    withStyles(styles)
)(CreateTaskTemplate)