import { green, red } from '@material-ui/core/colors';
export default theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        border: `solid thin`,
        borderColor: '#cdcdcd',
        borderRadius: 5,
        width: 'fit-content',
        padding: '0 5px',
        backgroundColor: '#f3f3f3',
        // marginBottom: theme.spacing(2),
        // marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    'root--complete': {
        borderColor: green[100],
    },
    'root--due': {
        borderColor: red[100],
    },
    task__done__label: {
        backgroundColor: green[400],
        color: '#fff',
        padding: 3,
        margin: 5
    },
    task__pastdue__label: {
        backgroundColor: red[400],
        color: '#fff',
        margin: 5
    },
    checkbox__root: {
        color: green[400],
    },
    popover__content: {
        padding: 20,
        maxWidth: 300,

    },
    datePicker__container: {
        display: 'flex',
        justifyContent: 'center'
    },
})
