import React from 'react'
import {
    IconButton,
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    Button,
    makeStyles,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Close } from '@material-ui/icons';


//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from 'actions/generalActions'
import * as portActions from 'actions/portActions'

const useStyles = makeStyles(theme => {
    return {
        assignTask__button__container: {
            display: 'flex', alignItems: 'center'
        },
        assignTask__shipname: {
            marginRight: 5
        },
        dialog__paper: {
            width: '100%'
        },
        dialog__title: {
            height: 50,
            padding: '4px 16px',
            backgroundColor: theme.palette.primary.main,
        },
        dialog__title__container: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: theme.palette.getContrastText(theme.palette.primary.main)
        },
        textField: {
            marginTop: 2,
            minWidth: 120,
            maxWidth: '100%',
            width: '100%'
        },
        dialog__content: {
            padding: theme.spacing(3)
        },
        assignTask__button: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff'
        }
    }
})


function ChangePortDialog(props) {
    const { history, account, generalParameters, location, currentPort } = props
    const { t } = useTranslation()
    const classes = useStyles()

    if (!account || (generalParameters && !generalParameters.ports)) return null;

    const handleSelectPortChange = (port) => {
        let newUrl = location.pathname.replace(`/${currentPort.code}/`, `/${port.code}/`)
            
        history.push(newUrl)
        props.portActions.setCurrentPort && props.portActions.setCurrentPort(port)
        props.handleCloseDialog()
    }

    return (
        <Dialog
            open={props.openDialog}
            classes={{
                paper: classes.dialog__paper
            }}
            fullScreen={isMobile ? true : false}
            maxWidth='xs'>
            <DialogTitle className={classes.dialog__title}>
                <div className={classes.dialog__title__container}>
                    <Typography variant='h5' color='inherit'>
                        {t('Change Port')}
                    </Typography>
                    <IconButton
                        onClick={props.handleCloseDialog}
                        color='inherit'>
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {props.generalParameters.ports.map(port => {
                    return (
                        <Button
                            key={port.portCode}
                            style={{ marginTop: '16px' }}
                            disabled={port.portCode === props.currentPort.code}
                            variant='contained'
                            onClick={() => handleSelectPortChange({
                                code: port.portCode,
                                name: port.portName
                            })}
                            color='primary'>
                            {port.portName}
                        </Button>
                    )
                })}
            </DialogContent>
        </Dialog>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        portActions: bindActionCreators(portActions, dispatch)
    }
}


function mapStateToProps(state, ownProps) {
    return {
        account: state.account,
        generalParameters: state.generalParameters,
        currentPort: state.currentPort
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(ChangePortDialog);