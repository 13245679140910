import React, { useState } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Typography,
    Button,
    Link,
    Grid,
    TextField,
    CircularProgress
} from '@material-ui/core';
import {
    useDispatch
} from 'react-redux';
import { updateTaskComplete } from 'actions/tasksActions'
import {
    AttachFile,
    RemoveCircleOutline
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Tasks } from 'services'

const useStyes = makeStyles(theme => ({
    dialog__paper: {
        width: '100%'
    },
    textField_addornment__input: {
        padding: 10
    },
    attach__button: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontWeight: 500,
    },
    attach__input__file: {
        display: 'none'
    },
    attachment__item: {
        margin: 10
    },
    attachment__label__container: {
        display: 'flex', alignItems: 'center'
    },
    attachment__remove__button: {
        cursor: 'pointer',
        color: 'red',
        fontSize: '1.2em',
    },
}))

export default function CaptureResultPopup(props) {
    const { open, onClose, task } = props,
        { t } = useTranslation(),
        [saving, setSaving] = useState(false),
        [attachButtonLabels, setAttachButtonLabels] = useState([]),
        [filesAttachments, setFilesAttachments] = useState([]),
        [resultText, setResultText] = useState(''),
        dispatch = useDispatch(),
        classes = useStyes();



    const handleChooseFile = (e) => {
        e.stopPropagation()
        let attachButtonLabelsNew = [],
            attachButtonLabelsCopy = [...attachButtonLabels],
            files = e.target.files;

        if (files.length > 1) {
            attachButtonLabelsNew = Object.keys(files).map((key, i) => {
                return files[key].name
            })
        } else {
            attachButtonLabelsNew = [e.target.value.split("\\").pop()]
        }

        setFilesAttachments(files)
        setAttachButtonLabels(attachButtonLabelsCopy.concat(attachButtonLabelsNew))
    }
    const deleteUploadingAttachment = index => {
        let attachments = [...attachButtonLabels],
            newFilesAttachments = [...filesAttachments];

        attachments.splice(index, 1);
        newFilesAttachments.splice(index, 1);
        setFilesAttachments(newFilesAttachments)
        setAttachButtonLabels(attachments)
    }

    const handleCancel = () => {
        onClose();
    }
    const updateAsComplete = () => {
        setSaving(true);
        Tasks.updateTaskComplete({
            taskId: task.taskId,
            complete: 1,
            filesAttachments,
            resultText
        })
            .then(response => {
                setSaving(false)
                onClose()
                if (response.success) {
                    dispatch(updateTaskComplete(response.record))
                }
            })
    }
    const onExited = () => {
        setAttachButtonLabels([])
        setFilesAttachments([])
        setResultText('')
    }

    let taskNeedOutputs = task.outputs && task.outputs.length > 0;

    return (
        <Dialog
            classes={{
                paper: classes.dialog__paper
            }}
            maxWidth={taskNeedOutputs ? 'sm' : 'xs'}
            open={open}
            onExited={onExited}>
            {taskNeedOutputs ? (
                <DialogTitle>
                    {t('Terminar tarea')}
                </DialogTitle>
            ) : (
                    null
                )}

            <DialogContent>
                <Grid container spacing={2}>
                    {taskNeedOutputs ? (
                        <>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    {t("Por favor agrega los siguientes requisitos para terminar la tarea")}
                                </Typography>
                                <div style={{ padding: 8 }}>
                                    {task.outputs.map(o => (
                                        <Typography variant='body1'>
                                            {o.name},
                                        </Typography>
                                    ))}
                                </div>
                            </Grid>
                            {
                                task.outputs.filter(o => o.type === 1).length > 0 && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            variant='outlined'
                                            InputProps={{
                                                inputProps: {
                                                    className: classes.textField_addornment__input
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={resultText}
                                            onChange={e => { setResultText(e.target.value) }}
                                            label={t('Resultado')} />
                                    </Grid>
                                )
                            }
                            < Grid item xs={12} >
                                <label htmlFor="text-button-file">
                                    <Link
                                        disabled={saving}
                                        variant='body1'
                                        color="primary"
                                        className={classes.attach__button}
                                    >
                                        <AttachFile className={classes.attach__icon} />
                                        {t("Add Attachment")}
                                    </Link>
                                </label>
                                <input
                                    disabled={saving}
                                    accept="image/*, application/pdf, .gif, .jpg, .png, .doc, .txt, .rtf, .xls, .xlsx"
                                    className={classes.attach__input__file}
                                    id="text-button-file"
                                    multiple
                                    type="file"
                                    onChange={handleChooseFile}
                                />
                            </Grid>
                            {
                                attachButtonLabels.length > 0 && (
                                    <Grid item xs={12}>
                                        <div className={classes.new_attachments__container}>
                                            {attachButtonLabels.map((label, i) => {
                                                return (
                                                    <div className={classes.attachment__item} key={i}>
                                                        <Typography
                                                            className={classes.attachment__label__container} noWrap>
                                                            <RemoveCircleOutline
                                                                disabled={saving}
                                                                onClick={() => deleteUploadingAttachment(i)}
                                                                style={{ marginRight: 4, marginLeft: 0 }}
                                                                className={classes.attachment__remove__button} />
                                                            {label}
                                                        </Typography>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Grid>
                                )
                            }
                        </>

                    ) :
                        (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant='body1'>
                                        {t('Are you sure you wish to mark this task as done?')}
                                    </Typography>
                                </Grid>
                            </>
                        )}

                    <Grid item xs={12}
                        style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            disabled={saving}
                            onClick={handleCancel}>
                            {taskNeedOutputs ? t('Cancel') : t('No, cancel')}
                        </Button>
                        <Button
                            disabled={saving}
                            onClick={() => {
                                updateAsComplete()
                            }}
                            color='primary'
                            variant='contained'>
                            {taskNeedOutputs ? t('Save') : t("Yes, complete")}
                            {saving && (
                                <CircularProgress color='secondary'
                                    style={{ marginLeft: 5 }}
                                    size={15} />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>


        </Dialog >
    )
}
