import React, { useEffect } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    withStyles,
    Typography,
    IconButton,
    withMobileDialog,
} from '@material-ui/core'
import {
    Close,
} from '@material-ui/icons'
import styles from './styles';
import compose from 'recompose/compose'
import { useDispatch, useSelector } from 'react-redux'
import Details from '../../views/Recaladas/components/RecaladaDetails/Details'
import { setRecalada } from 'actions/recaladaActions'


const VesselInfo = props => {
    const { open, onClose, classes, selectedShip, fullScreen } = props,
        recalada = useSelector(state => state.recalada),
        dispatch = useDispatch();

    useEffect(() => {
        if (!recalada.recaladaId && selectedShip) {
            dispatch(setRecalada(selectedShip))
        }
    })

    const onCloseDialog = () => {
        dispatch(setRecalada(null))
        onClose()
    }

    if (!selectedShip || !recalada.recaladaId) return null

    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            classes={{
                paper: classes.dialog__paper
            }}
            maxWidth='md'>
            <DialogTitle
                className={classes.dialog__title}>
                <div className={classes.dialog__title__container}>
                    <div>
                        <Typography variant='h5' color='inherit'>{recalada.ship.name} V-{recalada.itineraryName}</Typography>
                    </div>

                    <IconButton
                        color='inherit'
                        onClick={onCloseDialog}>
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent classes={{
                root: classes.dialog__container
            }}>

                <Details />

            </DialogContent>
        </Dialog>
    )
}

export default compose(
    withMobileDialog(),
    withStyles(styles)
)(VesselInfo)
