export default theme => ({
  root: {},
  tableRow: {
    height: '34px',
    cursor: 'pointer'
  },
  'tableRow--closed': {
    backgroundColor: '#f5f5f5',
    color: '#fff !important',
    '& > td,div, a, span': {
      color: '#828282 !important',
    }
  },
  tableCell: {
    whiteSpace: 'nowrap',
    padding: '10px'
  },
  tableCell__header: {
    whiteSpace: 'nowrap',
    padding: '10px',
    color: '#000',
    textTransform: 'uppercase'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  tableCell__ships__header: {
    whiteSpace: 'nowrap',
    padding: '10px',
    color: '#828282'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    cursor: 'pointer'
  },
  closedLabel: {
    marginLeft: theme.spacing(2),
  },
  unreadEmailsText: {
    marginLeft: theme.spacing(2),
  },
  nested: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #c4c4c4',
    marginBottom: theme.spacing(1),
    padding: 0
  },
  recalada__header: {
    background: theme.palette.tableHeader,
  },
  ships__header: {
    background: '#f5f5f5'
  },
  'mail--read': {
    backgroundColor: 'rgba(239,239,239,0.3)'
  },
  'mail--unread': {
    backgroundColor: 'unset'
  },
  warningIcon: {
    color: 'red'
  },
  successIcon: {
    color: 'green'
  },
  list__paper: {
    width: '100%',
    backgroundColor: '#fff',
    padding: 0
  },
  list__item__card: {
    padding: 10,
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  'list__item__card--warning': {
    backgroundColor: 'rgba(255, 0, 0, 0.1)'
  },
  'list__item__card--success': {
    // backgroundColor: 'rgba(0, 255, 0, 0.1)'
  },
  list__item__card__icon__container: {
    // position: 'absolute',
    // right: theme.spacing(1),
    // top: theme.spacing(1)
  },
  list__item__dueDate: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column'
  },
  tasks__container: {
    marginBottom: theme.spacing(),
  },
  tasks__indicator__container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(),
    justifyContent: 'center'
  },
  dialog__paper: {
    width: '100%'
  },
  dialog__title: {
    height: 50,
    padding: '4px 16px',
    backgroundColor: theme.palette.primary.main,
  },
  dialog__title__container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  textField: {
    marginTop: 2,
    minWidth: 120,
    maxWidth: '100%',
    width: '100%'
  },
  dialog__content: {
    padding: theme.spacing(3)
  },
  title__content: {
    margin: '8px 0'
  }
});
