import React, { useState, useEffect } from 'react';
//{ useState } 
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect'

import {
    withStyles,
    Grid,
    Tabs,
    Tab,
    Typography,
    Divider,
    Paper,
    TextField,
    InputAdornment,
    Button
} from '@material-ui/core';
import { Edit } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// import { CloseRecalada } from 'components/Recaladas'

// Component styles
import styles from './styles';

//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as recaladaActions from 'actions/recaladaActions'
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"
import en from "date-fns/locale/en-US"

const dateColors = [
    '#0089ff',
    '#8500ff',
    '#ffc46d',
    '#32db64'
]


const ShipDetails = props => {
    const { recalada, classes } = props,
        { t, i18n } = useTranslation(),
        [currentTab, setCurrentTab] = useState(0),
        [currentSection, setCurrentSection] = useState(null),
        [departure, setDeparture] = useState(''),
        [finImpo, setFinImpo] = useState(''),
        [ETA, setETA] = useState(''),
        [editDates, setEditDates] = useState(false),
        [finOperaciones, setFinOperaciones] = useState('');

    if (i18n.language === 'en') {
        registerLocale("en", en);
    } else {
        registerLocale("es", es);
    }

    const tabsOptions = [
        t('Recalada'),
        t('Ship'),
        t('Operations')
        // t('Importación'),
        // t('Exportación')
    ]

    useEffect(() => {
        let isMounted = true;
        if (recalada && isMounted) {
            setETA(moment(moment.utc(recalada.ETA).format()).format('YYYY-MM-DDTHH:mm'))
            setDeparture(recalada.departureDate ? moment(moment.utc(recalada.departureDate).format()).format('YYYY-MM-DDTHH:mm') : '')
            setFinImpo(recalada.endImpoDateTime ? moment(moment.utc(recalada.endImpoDateTime).format()).format('YYYY-MM-DDTHH:mm') : '')
            setFinOperaciones(recalada.endOperationsDateTime ? moment(moment.utc(recalada.endOperationsDateTime).format()).format('YYYY-MM-DDTHH:mm') : '')
        }

        if (recalada && recalada.detailsSections && !currentSection) {
            setCurrentSection(recalada.detailsSections.find(section => section.name.toLowerCase() === tabsOptions[currentTab].toLowerCase()))
        }

        return () => {
            isMounted = false;
        };
    }, [recalada, currentSection, currentTab, tabsOptions])


    const handleChangeTab = (e, tab) => {
        setCurrentTab(tab)
        setCurrentSection(recalada.detailsSections.find(section => section.name.toLowerCase() === tabsOptions[tab].toLowerCase()))
    }

    const handleChangeDeparture = value => {
        setDeparture(value)
    }
    const handleChangeFinImpo = value => {
        setFinImpo(value)
    }
    const handleChangeFinOperaciones = value => {
        setFinOperaciones(value)
    }

    const DatePickerCustomInput = ({ placeholderText, value, onClick, label, error, disabled, dateColor }) => (
        <TextField
            disabled={disabled}
            error={error}
            label={label}
            value={value}
            placeholder={placeholderText}
            classes={{
                root: classes.datePicker__input__root
            }}
            InputProps={{
                endAdornment: dateColor ? (<InputAdornment position='end'>
                    <div style={{ width: 15, height: 15, borderRadius: '50%', backgroundColor: dateColor }}></div>
                </InputAdornment>) : null,
                inputProps: {
                    className: classes.textField__input
                }
            }}
            InputLabelProps={{
                shrink: true
            }}
            variant='outlined'
            onClick={onClick} />
    );

    const setDateColorClass = date => {
        let className = undefined;
        if (moment(date).format('DD/MM/YYYY') === moment(ETA).format('DD/MM/YYYY')) {
            className = classes.eta__date__calendar;
        }
        if (moment(date).format('DD/MM/YYYY') === moment(finImpo).format('DD/MM/YYYY')) {
            className = classes.endImpo__date__calendar;
        }
        if (moment(date).format('DD/MM/YYYY') === moment(finOperaciones).format('DD/MM/YYYY')) {
            className = classes.endOperations__date__calendar;
        }
        if (moment(date).format('DD/MM/YYYY') === moment(departure).format('DD/MM/YYYY')) {
            className = classes.departure__date__calendar;
        }
        return className;
    }

    if (!recalada) return null

    return (
        <div className={classes.details__container}
            style={isMobile ? { paddingBottom: '90px' } : {}}>
            {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
                <CloseRecalada recalada={recalada} />
            </div> */}
            <Tabs
                style={{ marginBottom: '10px' }}
                value={currentTab}
                indicatorColor="inherit"
                onChange={handleChangeTab}
                aria-label="disabled tabs example"
                classes={{
                    root: classes.ship__tabs
                }}
            >
                {tabsOptions.map(tabLabel => (
                    <Tab classes={{
                        selected: classes.selected__tab
                    }}
                        label={tabLabel}
                    />
                ))}
            </Tabs>

            <Paper>
                {currentTab === 0 &&
                    <Grid container spacing={1} style={{ padding: '24px' }}>
                        <Grid item xs={12} style={{ margin: '16px 0' }}>
                            <Typography variant='h5' color='primary'>
                                <strong>{t('Voyage Information')}</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>{recalada.linerName}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>

                            <Typography variant='h6'>{`${recalada.ship.name} V-${recalada.itineraryName}`}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='body1' className={classes.title__content}>
                                <strong>{moment(recalada.ETA).isAfter(moment(), 'day') ? t('ETA') : t('Arribo')}</strong><span style={{ paddingLeft: '10px' }}>{` ${moment(recalada.ETA).format('dddd DD MMMM HH:mm')}`}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='body1' className={classes.title__content}>
                                <strong>{moment(finImpo).isAfter(moment(), 'day') ? t('Fecha estimada fin importación') : t('Fin importación')}</strong><span style={{ paddingLeft: '10px' }}>{moment(finImpo).format('dddd DD MMMM HH:mm')}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='body1' className={classes.title__content}>
                                <strong>{moment(finOperaciones).isAfter(moment(), 'day') ? t('Fecha estimada fin operaciones') : t('Fin operaciones')}</strong><span style={{ paddingLeft: '10px' }}>{moment(finOperaciones).format('dddd DD MMMM HH:mm')}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='body1' className={classes.title__content}>
                                <strong>{moment(departure).isAfter(moment(), 'day') ? t('Fecha estimada zarpe') : t('Zarpe')}</strong><span style={{ paddingLeft: '10px' }}>{moment(departure).format('dddd DD MMMM HH:mm')}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                }

                {currentTab === 1 && <>
                    <Grid container spacing={1} style={{ padding: '24px' }}>
                        <Grid container item xs={12} sm={6} spacing={1} >

                            <Grid item xs={12} style={{ margin: '16px 0' }}>
                                <Typography variant='h5' color='primary'>
                                    <strong>{t('Vessel Particulars')}</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography variant='body1' className={classes.title__content}>
                                    <strong style={{ marginRight: '6px' }}>{t('IMO')}</strong>
                                    <span style={{ paddingLeft: '10px' }}>{recalada.ship.IMO}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography variant='body1' className={classes.title__content}>
                                    <strong style={{ marginRight: '6px' }}>{t('Name')}</strong>
                                    <span style={{ paddingLeft: '10px' }}>{recalada.ship.name}</span>
                                </Typography>
                            </Grid>
                            {recalada.ship.MMSI && <>
                                <Grid item xs={12} >
                                    <Typography variant='body1' className={classes.title__content}>
                                        <strong style={{ marginRight: '6px' }}>{t('MMSI')}</strong>
                                        <span style={{ paddingLeft: '10px' }}>{recalada.ship.MMSI ? recalada.ship.MMSI : ''}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant='body1' className={classes.title__content}>
                                        <strong style={{ marginRight: '6px' }}>{t('Type')}</strong>
                                        <span style={{ paddingLeft: '10px' }}>{recalada.ship.type ? recalada.ship.type : ''}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant='body1' className={classes.title__content}>
                                        <strong style={{ marginRight: '6px' }}>{t('Flag')}</strong>
                                        <span style={{ paddingLeft: '10px' }}>{recalada.ship.flag ? recalada.ship.flag : ''}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant='body1' className={classes.title__content}>
                                        <strong style={{ marginRight: '6px' }}>{t('Summer DWT')}</strong>
                                        <span style={{ paddingLeft: '10px' }}>{recalada.ship.summerDWT ? `${recalada.ship.summerDWT}ton` : ''}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant='body1' className={classes.title__content}>
                                        <strong style={{ marginRight: '6px' }}>{t('Length Overall x Breadth Extreme')}</strong>
                                        <span style={{ paddingLeft: '10px' }}>{recalada.ship.lengthOverall && recalada.ship.breadthExtreme ? `${recalada.ship.lengthOverall}m x ${recalada.ship.breadthExtreme}m` : ''}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant='body1' className={classes.title__content}>
                                        <strong style={{ marginRight: '6px' }}>{t('Year Build')}</strong>
                                        <span style={{ paddingLeft: '10px' }}>{recalada.ship.yearBuild ? recalada.ship.yearBuild : ''}</span>
                                    </Typography>
                                </Grid>
                            </>}
                        </Grid>

                        {recalada.ship.imageUrl &&
                            <Grid container item xs={12} sm={6} spacing={1} style={{ paddingTop: '24px' }}>
                                <div>
                                    <img src={recalada.ship.imageUrl} alt={recalada.ship.name} style={{ width: '96%' }}></img>
                                </div>
                            </Grid>
                        }

                    </Grid>
                    <Divider />

                    {currentSection && currentSection.categories.map(category => (<>
                        <Grid container spacing={3} item xs={12} style={{ padding: '24px' }}>
                            <Grid item xs={12} style={{ margin: '16px 0' }}>
                                <Typography variant='h5' color='primary'>
                                    <strong>{category.name}</strong>
                                </Typography>
                            </Grid>
                            {category.documents.map(doc => (
                                <Grid item xs={12} sm={6}>
                                    <Typography variant='body1' >
                                        {doc.name}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                        <Divider />
                    </>))}

                </>}

                {
                    currentTab === 2 && <>
                        {currentSection && currentSection.categories.map(category => (
                            <Grid container spacing={3} item xs={12} style={{ padding: '24px' }}>
                                <Grid item xs={12} style={{ margin: '16px 0' }}>
                                    <Typography variant='h5' color='primary'>
                                        <strong>{category.name}</strong>
                                    </Typography>
                                </Grid>
                                {category.documents.map(doc => (
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body1'>
                                            {doc.name}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </>
                }

            </Paper>
        </div >
    )
}


function mapDispatchToProps(dispatch) {
    return {
        recaladaActions: bindActionCreators(recaladaActions, dispatch),
    }
}


function mapStateToProps(state) {
    return {
        recalada: state.recalada,
        currentPort: state.currentPort
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ShipDetails)))
