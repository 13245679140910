import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next'


// Material helpers
import { withStyles } from '@material-ui/core';

// Component styles
import styles from './styles';
import { CreateRecalada } from 'components'


import CalendarDateSelector from './CalendarDateSelector'

class ShipsToolbar extends Component {
    render() {
        const { classes, className } = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                <div className={classes.row}>
                    <CalendarDateSelector {...this.props} />
                    <span className={classes.spacer} />
                    <CreateRecalada />
                </div>
            </div>
        );
    }
}

ShipsToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedRecaladas: PropTypes.array
};

ShipsToolbar.defaultProps = {
    selectedRecaladas: []
};

export default withTranslation()(withStyles(styles)(ShipsToolbar));
