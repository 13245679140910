import React from 'react'
import {
    Grid,
    withStyles,
    Typography,
    IconButton,
    withMobileDialog,
    Dialog,
    DialogContent
} from '@material-ui/core'
import {
    ArrowBackIosOutlined
} from '@material-ui/icons'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import styles from './styles';
import compose from 'recompose/compose'


const TaskDetails = props => {
    const { goBack, classes, selectedReminder, task, open } = props,
        { t } = useTranslation();

    const fillPlaceholders = (text, placeholders) => {
        let keys = Object.keys(placeholders);
        keys.forEach(k => {
            var key = k.replace('[', '\\[').replace(']', '\\]'),
                rex = new RegExp(key, "g");
            text = text.replace(rex, placeholders[k])
        })
        return text;
    }

    const title = fillPlaceholders(selectedReminder.title, {
        '[SHIP]': task.shipName,
        '[VOYAGE]': task.itineraryName,
        '[TASK]': task.name,
        '[ENTITY]': selectedReminder.entityName
    })

    const body = fillPlaceholders(selectedReminder.body, {
        '[SHIP]': task.shipName, '[VOYAGE]': task.itineraryName,
        '[TASK]': task.name,
        '[ENTITY]': selectedReminder.entityName,
        '[DESCRIPTION]': task.description
    })

    return (
        <Dialog
            open={open}
            classes={{
                paper: classes.dialog__paper
            }}
            maxWidth='sm'
            fullScreen={isMobile ? true : false}
            onExited={goBack}>

            <DialogContent classes={{ root: classes.dialog__container }} style={{ padding: !isMobile ? '16px 24px' : '16px 16px 48px' }}>

                <Grid constainer spacing={4}>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            onClick={goBack}
                            variant="text"
                        >
                            <ArrowBackIosOutlined />
                        </IconButton>
                    </Grid>

                    <Grid item xs={12}>
                        {selectedReminder && <>
                            <div className={classes.email__paper}>
                                <Typography variant='body1' color='textPrimary' className={classes.email__recipient}>
                                    {t('To')} {` ${selectedReminder.entityName}`}
                                </Typography>
                                <br />
                                <Typography variant='h6' className={classes.email__subject}>
                                    {title}
                                </Typography>
                                <br />
                                <br />
                                <Typography variant='body1' className={classes.email__body} dangerouslySetInnerHTML={{ __html: body }} />
                            </div>
                        </>}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default compose(
    withMobileDialog(),
    withStyles(styles)
)(TaskDetails)
