import React from 'react';

// Custom components
import { Services, Recaladas, Templates, Entities, Ships } from './components';


export default function Settings(props) {
  const { location, match } = props;

  const parts = location.pathname.split('/'),
    url = parts[parts.length - 1];

  return (
    <div>
      {url === 'services' &&
        <Services />
      }

      {url === 'ships' &&
        <Ships />
      }

      {url === 'recaladas' &&
        <Recaladas />
      }

      {url === 'entities' &&
        <Entities />
      }

      {(url === 'templates' || (match.params && match.params.templateId)) &&
        <Templates />
      }
    </div>
  );
}

