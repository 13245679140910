export default [
  {
    id: 'DEV738089',
    title: 'Inventario',
    description:
      '',
    imageUrl: '/images/products/product_1.png',
    totalDownloads: '20',
    price: '10',
    status: 'published',
    createdAt: '27/03/2019'
  },
  {
    id: 'DEV795381',
    title: 'Reparaciones',
    description:
      'Medium is an online publishing platform developed by Evan Williams, and launched in August 2012.',
    imageUrl: '/images/products/product_2.png',
    totalDownloads: '10',
    price: '27',
    status: 'archived',
    createdAt: '31/03/2019'
  },
  {
    id: 'DEV654476',
    title: 'Proyecciones',
    description:
      'Lyft is an on-demand transportation company based in San Francisco, California.',
    imageUrl: '/images/products/product_4.png',
    totalDownloads: '6',
    price: '28',
    status: 'draft',
    createdAt: '04/04/2019'
  },
  {
    id: 'DEV958347',
    title: 'Estimados',
    description:
      'GitHub is a web-based hosting service for version control of code using Git.',
    imageUrl: '/images/products/product_5.png',
    totalDownloads: '3',
    price: '32',
    status: 'published',
    createdAt: '04/04/2019'
  },
  {
    id: 'DEV773559',
    title: 'Análisis',
    description:
      '',
    imageUrl: '/images/products/product_6.png',
    totalDownloads: '5',
    price: '32',
    status: 'published',
    createdAt: '04/04/2019'
  }
];
