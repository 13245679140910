import Amplify, { Auth, Cache } from 'aws-amplify';
import authParams from './authParams';
import store from 'store'
import config from 'config';
import { setAccount, clearAccount } from 'actions/accountActions'
import { clearCurrentPort } from 'actions/portActions'
Amplify.configure(authParams.cognito);


const auth = {
    signIn: (args, cb) => {
        let email = args.email,
            password = args.password;

        let promise = new Promise((resolve, reject) => {
            Auth.signIn(email, password)
                .then(user => {
                    Cache.setItem('CTSA:authtoken', user.Username)

                    if (user.attributes && user.signInUserSession) {
                        auth.getAccount({ id: user.attributes.sub, jwtToken: user.signInUserSession.idToken.jwtToken })
                            .then(account => {
                                cb && cb(user);
                                resolve(user);
                                if (account.status === 'success') {
                                    store.dispatch(setAccount({
                                        ...account.data.record,
                                        cognito: { ...user }
                                    }))
                                }
                            })
                    } else {
                        cb && cb(user);
                        resolve(user);
                    }
                })
                .catch(err => {
                    cb && cb({
                        success: false,
                        error: err
                    });
                    reject({
                        success: false,
                        error: err
                    });
                });
        })
        return promise;
    },
    signOut: (cb) => {
        let promise = new Promise((resolve, reject) => {
            Auth.signOut()
                .then(data => {
                    Cache.removeItem('CTSA:authtoken');
                    cb && cb(data);
                    resolve(data);
                    store.dispatch(clearAccount())
                    store.dispatch(clearCurrentPort())
                })
                .catch(err => {
                    cb && cb({
                        success: false,
                        error: err
                    });
                    reject({
                        success: false,
                        error: err
                    });
                });
        });
        return promise;
    },
    getAuthenticatedUser: (cb) => {
        let promise = new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    if (!user.attributes) {
                        user.attributes = {
                            given_name: user.first_name || user.given_name,
                            family_name: user.last_name || user.family_name
                        }
                    }
                    auth.getAccount({ id: user.attributes.sub, jwtToken: user.signInUserSession.idToken.jwtToken })
                        .then(account => {
                            cb && cb(user);
                            resolve(user);
                            if (account.status === 'success') {
                                store.dispatch(setAccount({
                                    ...account.data.record,
                                    cognito: { ...user }
                                }))
                            }
                        })
                })
                .catch(err => {
                    cb && cb({
                        success: false,
                        error: err
                    });
                    reject({
                        success: false,
                        error: err
                    });
                });
        });
        return promise;
    },
    verifiedContact: (args, cb) => {
        let promise = new Promise((resolve, reject) => {
            Auth.verifiedContact(args)
                .then(data => {
                    cb && cb(data);
                    resolve(data);
                })
                .catch(err => {
                    cb && cb({
                        success: false,
                        error: err
                    });
                    reject({
                        success: false,
                        error: err
                    });
                });
        });
        return promise;
    },
    federatedSignIn: ({ provider }, cb) => {
        return new Promise((resolve, reject) => {
            Auth.federatedSignIn({ provider })
                .then(data => {
                    cb && cb(data)
                    resolve(data)
                })
                .catch(err => {
                    cb && cb(err)
                    reject(err)
                })
        })
    },
    completeNewPassword: ({ user, newPassword, name }, cb) => {
        return new Promise((resolve, reject) => {
            Auth.completeNewPassword(
                user,
                newPassword,
                {
                    name: name,
                }
            ).then(user => {
                resolve(user);
                cb && cb(user)
            }).catch(e => {
                reject(e);
                cb && cb(e);
            });
        })
    },
    forgotPassword: (args, cb) => {
        return new Promise((resolve, reject) => {
            Auth.forgotPassword(args.username)
                .then(data => {
                    cb && cb(data)
                    resolve(data)
                })
                .catch(err => {
                    cb && cb(err)
                    reject(err)
                })
        })
    },
    forgotPasswordSubmit: (args, cb) => {
        return new Promise((resolve, reject) => {
            Auth.forgotPasswordSubmit(args.username, args.code, args.newPassword)
                .then(data => {
                    cb && cb(data)
                    resolve(data)
                })
                .catch(err => {
                    cb && cb(err)
                    reject(err)
                })
        })
    },
    resendEmailConfirmationCode: (cb) => {
        return new Promise((resolve, reject) => {
            Auth.verifyCurrentUserAttribute('email')
                .then(() => {
                    resolve();
                    cb && cb();
                }).catch((e) => {
                    console.log('failed with error', e);
                    reject(e);
                    cb && cb(e);
                });
        })
    },
    getAccount: ({ id, jwtToken }) => {
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/account/${id}`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", jwtToken);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    }
};
export default auth; 