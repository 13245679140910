export default theme => ({
  root: {},
  tableRow: {
    height: '34px',
    cursor: 'pointer'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    padding: '0px 5px'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    cursor: 'pointer'
  },
  'mail--read': {
    backgroundColor: 'rgba(239,239,239,0.3)'
  },
  'mail--unread': {
    backgroundColor: 'unset'
  },
  selectAll__list: {
    display: 'flex', width: '100%', marginLeft: '10px'
  },
  list: {
    backgroundColor: '#fff',
    // padding: 8,
    marginBottom: theme.spacing(5)
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  container: {
    backgroundColor: '#FFF'
  }
});
