import React, { useState, useEffect } from 'react';

// Material helpers
import { 
	withStyles, 
	Typography, 
	CircularProgress,
} from '@material-ui/core';
import compose from 'recompose/compose';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import UsersTable from './UsersTable';
import { Users } from '../../services';
import UserDetails from './UserDetails';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(1) * 4
  },
  noUsers__container: {
    width: '100%',
    background: '#fff',
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  }
});

const UsersView = props => {
	const { classes, t } = props,
		[fetching, setFetching] = useState(false),
		[users, setUsers] = useState(null),
		[openUserDetails, setOpenUserDetails] = useState(false),
		[selectedUser, setSelectedUser] = useState(null)

	useEffect(()=>{
		let isSuscribed = true;
		if(!users){
			setFetching(true)
		
			Users.getUsers()
				.then(response => {
					if (isSuscribed) {
						setUsers(response.data.records)
						setFetching(false)
					}
				}).catch(err => {
					setFetching(false)
				})
		}
		return () => {
            isSuscribed = false;
        };
	}, [users])

	const handleOpenUserDetails =(user)=>{
		setSelectedUser(user)
		setOpenUserDetails(true)
	}

	const handleAddUser =(user)=>{
		let newUsers = [...users]
		newUsers.push(user)

		setUsers(newUsers)
	}

	const handleUpdateUser =(user)=>{
		let newUsers = [...users],
			userIndex = newUsers.findIndex(u => user.id === u.id)
		
		if(userIndex >= 0){ 
			newUsers[userIndex] = user
		}

		setUsers(newUsers)
	}

    return (
      <DashboardLayout title={t("Users")}>
        <div className={classes.root}>
			<div className={classes.content}>
				{fetching && 
					<div className={classes.progressWrapper}>
						<CircularProgress />
					</div>
				}

				{!fetching && users && users.length === 0 && 
					<div className={classes.noUsers__container}>
						<Typography variant="h6">{t('There are no users')}</Typography>
					</div>
				}

				{!fetching && users && users.length > 0 &&
					<UsersTable 
						users={users}
						openUserDetail={handleOpenUserDetails}
					/>
				}
			</div>

			{openUserDetails &&
				<UserDetails
					open={openUserDetails}
					addUser={handleAddUser}
					updateUser={handleUpdateUser}
					selectedUser={selectedUser}
					onClose={()=>{
						setOpenUserDetails(false)
					}}
				/>
			}
        </div>
      </DashboardLayout>
    );
}

const mapStateToProps = ({ currentPort }) => ({
	currentPort
});

export default connect(mapStateToProps, null)(
  compose(
    withStyles(styles),
    withTranslation()
  )(UsersView)
)