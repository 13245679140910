import React, { useState } from 'react'
import {
    IconButton,
    Popover,
    Button,
    makeStyles
} from '@material-ui/core'
import {
    AlarmAdd,
    MoreVert,
    AttachFile,
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import styles from './taskMoreOptionsStyles';
const useStyles = makeStyles(styles);


const TasksMoreOptions = (props) => {
    const { optionalSections,
        hideAttachments,
        setOptionalSections,
        setShowReminders } = props,
        { t } = useTranslation(),
        [openMoreOptions, setOpenMoreOptions] = useState(false),
        [anchorEl, setAnchorEl] = useState(null),
        classes = useStyles();

    const hideOptions = () => {
        setOpenMoreOptions(false);
        setAnchorEl(null);
    }
    const activateSection = section => {
        let newOptionalSections = {
            ...optionalSections
        }
        newOptionalSections[section] = true;
        setOptionalSections(newOptionalSections);
        if (section === 'reminders') {
            setShowReminders(true)
        }
        hideOptions()
    }
    return <>
        <IconButton
            color={!openMoreOptions ? 'default' : 'primary'}
            onClick={(e) => {
                setOpenMoreOptions(true)
                setAnchorEl(e.target)
            }}>
            <MoreVert />
        </IconButton>
        <Popover
            anchorEl={anchorEl}
            onClose={hideOptions}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={openMoreOptions}>
            <div className={classes.popover__content}>
                <Button
                    color='primary'
                    //{optionalSections.reminders ? 'textSecondary' : 'primary'}
                    className={classes.popover__button}
                    classes={{
                        label: classes.popover__button__label
                    }}
                    onClick={() => activateSection('reminders')}>
                    <AlarmAdd style={{ fontSize: 20, marginRight: 5 }} />
                    {t('Add Reminders')}
                </Button>
                {!hideAttachments && (
                    <Button
                        color={optionalSections.attachments ? 'textSecondary' : 'primary'}
                        //disabled={optionalSections.attachments}
                        className={classes.popover__button}
                        classes={{
                            label: classes.popover__button__label
                        }}
                        onClick={() => activateSection('attachments')}>
                        <AttachFile style={{ fontSize: 20, marginRight: 5 }} />
                        {t('Add Attachments')}
                    </Button>
                )}
            </div>
        </Popover>
    </>
}

export default TasksMoreOptions;