import {
    SET_TASKS,
    CREATE_TASK,
    CLEAR_TASKS,
    UPDATE_TASK,
    UPDATE_TASK_KEYWORDS,
    UPDATE_TASK_NAME,
    UPDATE_TASK_DESCRIPTION,
    UPDATE_TASK_DUE_HOURS,
    UPDATE_TASK_COMPLETE,
    UPDATE_TASK_ADD_USER,
    UPDATE_TASK_REMOVE_USER,
    UPDATE_TASK_REQUIRED_RESULT,
    ADD_TASK_ATTACHMENTS,
    DELETE_TASK_ATTACHMENT
} from 'actions/tasksActions'

export default function tasks(state = {}, action) {
    let taskIndex = -1;
    switch (action.type) {
        case SET_TASKS:
            return action.tasks;
        case CLEAR_TASKS:
            return null;
        case CREATE_TASK:
            if (state && state.length > 0) {
                return [
                    { ...action.data },
                    ...state
                ];
            }
            return state;
        case UPDATE_TASK:
            if (state && state.length > 0) {
                taskIndex = state.findIndex(t => t.taskId === action.task.taskId)
                if (taskIndex >= 0) {
                    return [
                        ...state.slice(0, taskIndex),
                        {
                            ...action.task
                        },
                        ...state.slice(taskIndex + 1)
                    ]
                }
            }
            return state;
        case UPDATE_TASK_KEYWORDS:
            if (state && state.length > 0) {
                taskIndex = state.findIndex(t => t.taskId === action.data.taskId)
                if (taskIndex >= 0) {
                    return [
                        ...state.slice(0, taskIndex),
                        {
                            ...state[taskIndex],
                            keywords: [...action.data.keywords]
                        },
                        ...state.slice(taskIndex + 1)
                    ]
                }
            }
            return state;
        case ADD_TASK_ATTACHMENTS:
            if (state && state.length > 0) {
                taskIndex = state.findIndex(t => t.taskId === action.data.taskId)
                if (taskIndex >= 0) {
                    return [
                        ...state.slice(0, taskIndex),
                        {
                            ...state[taskIndex],
                            taskAttachments: [
                                ...state[taskIndex].taskAttachments,
                                ...action.data.attachments
                            ]
                        },
                        ...state.slice(taskIndex + 1)
                    ]
                }
            }
            return state;
        case DELETE_TASK_ATTACHMENT:
            if (state && state.length > 0) {
                taskIndex = state.findIndex(t => t.taskId === action.data.taskId)
                if (taskIndex >= 0) {
                    return [
                        ...state.slice(0, taskIndex),
                        {
                            ...state[taskIndex],
                            taskAttachments: state[taskIndex].taskAttachments.filter(a => a.taskAttachmentId !== action.data.taskAttachmentId)
                        },
                        ...state.slice(taskIndex + 1)
                    ]
                }
            }
            return state;
        case UPDATE_TASK_NAME:
            if (state && state.length > 0) {
                taskIndex = state.findIndex(t => t.taskId === action.data.taskId)
                if (taskIndex >= 0) {
                    return [
                        ...state.slice(0, taskIndex),
                        {
                            ...state[taskIndex],
                            name: action.data.name
                        },
                        ...state.slice(taskIndex + 1)
                    ]
                }
            }
            return state;
        case UPDATE_TASK_DESCRIPTION:
            if (state && state.length > 0) {
                taskIndex = state.findIndex(t => t.taskId === action.data.taskId)
                if (taskIndex >= 0) {
                    return [
                        ...state.slice(0, taskIndex),
                        {
                            ...state[taskIndex],
                            description: action.data.description
                        },
                        ...state.slice(taskIndex + 1)
                    ]
                }
            }
            return state;
        case UPDATE_TASK_REQUIRED_RESULT:
            if (state && state.length > 0) {
                taskIndex = state.findIndex(t => t.taskId === action.data.taskId)
                if (taskIndex >= 0) {
                    return [
                        ...state.slice(0, taskIndex),
                        {
                            ...state[taskIndex],
                            requiredResult: action.data.requiredResult,
                            resultContent: action.data.resultContent
                        },
                        ...state.slice(taskIndex + 1)
                    ]
                }
            }
            return state;
        case UPDATE_TASK_DUE_HOURS:
            if (state && state.length > 0) {
                taskIndex = state.findIndex(t => t.taskId === action.data.taskId)
                if (taskIndex >= 0) {
                    return [
                        ...state.slice(0, taskIndex),
                        {
                            ...state[taskIndex],
                            dueHours: action.data.dueHours
                        },
                        ...state.slice(taskIndex + 1)
                    ]
                }
            }
            return state;
        case UPDATE_TASK_COMPLETE:
            if (state && state.length > 0) {
                taskIndex = state.findIndex(t => t.taskId === action.data.taskId)
                if (taskIndex >= 0) {
                    return [
                        ...state.slice(0, taskIndex),
                        {
                            ...state[taskIndex],
                            complete: action.data.complete,
                            completeDateTime: action.data.completeDateTime,
                            completeUserId: action.data.completeUserId,
                            completeUser: {
                                id: action.data.completeUserId,
                                firstName: action.data.firstName,
                                lastName: action.data.lastName,
                            }
                        },
                        ...state.slice(taskIndex + 1)
                    ].map(tsk => ({
                        ...tsk,
                        parentTaskComplete: tsk.parentTaskId === action.data.taskId ? action.data.complete : tsk.parentTaskComplete
                    }))
                }
            }
            return state;
        case UPDATE_TASK_ADD_USER:
            if (state && state.length > 0) {
                taskIndex = state.findIndex(t => t.taskId === action.data.taskId)
                if (taskIndex >= 0) {
                    return [
                        ...state.slice(0, taskIndex),
                        {
                            ...state[taskIndex],
                            responsiblesUsers: [
                                ...state[taskIndex].responsiblesUsers,
                                {
                                    id: action.data.id,
                                    firstName: action.data.firstName,
                                    lastName: action.data.lastName,
                                    email: action.data.email,
                                    profileId: action.data.profileId
                                }
                            ]
                        },
                        ...state.slice(taskIndex + 1)
                    ]
                }
            }
            return state;
        case UPDATE_TASK_REMOVE_USER:
            if (state && state.length > 0) {
                taskIndex = state.findIndex(t => t.taskId === action.data.taskId)
                if (taskIndex >= 0) {
                    return [
                        ...state.slice(0, taskIndex),
                        {
                            ...state[taskIndex],
                            responsiblesUsers: state[taskIndex].responsiblesUsers.filter(r => r.id !== action.data.userId),
                        },
                        ...state.slice(taskIndex + 1)
                    ]
                }
            }
            return state;
        default:
            return state;
    }
}