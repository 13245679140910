import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    Grid,
    TextField,
    CircularProgress,
    withMobileDialog,
    withStyles

} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { useDispatch, useSelector } from 'react-redux'
import { Services } from 'services'

import { createServiceTemplate } from 'actions/serviceTemplateActions'


import styles from './styles'

const CreateServiceTemplate = (props) => {
    const { fullScreen, classes } = props,
        [openDialgo, setOpenDialgo] = useState(false),
        [showValidations, setShowValidations] = useState(false),
        [invalidFields, setInvalidFields] = useState({}),
        [saving, setSaving] = useState(false),
        [name, setName] = useState(''),
        { t } = useTranslation(),
        currentPort = useSelector(state => state.currentPort),
        dispatch = useDispatch();

    const handleCreateSerivceTemplate = () => {
        setOpenDialgo(true)
    }
    const onClose = () => {
        setOpenDialgo(false)
    }
    const onExited = () => {
        setName('')
        setSaving(false)
    }

    const handleChangeName = e => {
        if (e.target.value.length <= 45) {
            setName(e.target.value)
        }
    }

    const handleCancel = () => {
        setOpenDialgo(false)
    }


    const validateParameters = () => {
        let valid = true;
        let fields = {};

        if (name.trim() === '') {
            valid = false;
            fields = {
                ...fields,
                name: true
            }
        }
        if (!valid) {
            setShowValidations(true)
            setInvalidFields(fields)
        } else {
            setShowValidations(false)
        }
        return valid;
    }


    const handleSave = () => {
        if (validateParameters()) {
            setSaving(true)
            let parameters = {
                name,
                portCode: currentPort.code
            }
            Services.createServiceTemplate(parameters)
                .then(response => {
                    if (response.success) {
                        dispatch(createServiceTemplate(response.record))
                        setSaving(false)
                        setOpenDialgo(false)
                    }
                })
        }
    }
    return (
        <div style={{ padding: '8px' }}>
            <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={handleCreateSerivceTemplate}
            >
                {t('New service template')}
            </Button>

            <Dialog
                open={openDialgo}
                fullScreen={fullScreen}
                classes={{
                    paper: classes.dialog__paper
                }}
                onExited={onExited}
                maxWidth='xs'>
                <DialogTitle
                    className={classes.dialog__title}>
                    <div className={classes.dialog__title__container}>
                        <Typography variant='h5' color='inherit'>{t('New service template')} - {currentPort.name}</Typography>
                        <IconButton
                            color='inherit'
                            onClick={onClose} >
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent classes={{
                    root: classes.dialog__container
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                error={showValidations && invalidFields.name}
                                label={t('Name')}
                                value={name}
                                onChange={handleChangeName}
                                style={{
                                    width: '100%'
                                }}
                                InputProps={{
                                    inputProps: {
                                        className: classes.textField__input
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant='outlined' />
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                disabled={saving}
                                onClick={handleCancel}>
                                {t('Cancel')}
                            </Button>
                            <Button
                                disabled={saving}
                                variant='contained'
                                color='primary'
                                onClick={handleSave}
                            >
                                {t('Save')}
                                {saving && (
                                    <CircularProgress color='secondary'
                                        style={{ marginLeft: 5 }}
                                        size={15} />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default compose(
    withMobileDialog(),
    withStyles(styles)
)(CreateServiceTemplate)