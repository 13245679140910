import React from 'react'
import {
    Typography,
    IconButton,
    Grid
} from '@material-ui/core'
import theme from 'theme';
import { useTranslation } from 'react-i18next'
import { MailOutline, Drafts, OpenInNew } from '@material-ui/icons'
import { green } from '@material-ui/core/colors';
import { reminderFrendlyDate } from 'lib'
import { isMobile } from 'react-device-detect'
import moment from 'moment'

// import styles from './styles'
const imagesExt = ['jpg', 'png', 'jpeg', 'gif', 'bmp', 'svg']

const Reminder = props => {
    const { reminder, showEmailDetail, task, setOpenMessagesDetails, setSelectedLog } = props,
        { t } = useTranslation();

    const reminderDueDate = reminderFrendlyDate({
        ETA: task.ETA,
        endImpoDateTime: task.endImpoDateTime,
        endOperationsDateTime: task.endOperationsDateTime,
        departureDate: task.departureDate
    }, task.dueHours + reminder.dueHours, task.dueDateReferenceId)

    let logs = task.statusLog && task.statusLog.length > 0 ? task.statusLog.filter(log => log.reminder.id === reminder.taskReminderId) : [],
        selectedLog = logs ? logs[logs.length - 1] : null,
        attachments = selectedLog && selectedLog.messageResponse && selectedLog.messageResponse.responseEmail
            && selectedLog.messageResponse.responseEmail.attachments && selectedLog.messageResponse.responseEmail.attachments.length > 0 ?
            selectedLog.messageResponse.responseEmail.attachments : []

    return (
        <Grid container item spacing={2}>

            <Grid item xs={2} sm={1} >
                <IconButton style={{
                    cursor: "pointer",
                    color: reminder.taskReminderStatusId ? reminder.taskReminderStatusId === 4 ? 'red' : reminder.taskReminderStatusId === 3 ? green[400] : '#66788A' : '#66788A'
                }}
                    onClick={() => {
                        if (!task.statusLog || task.statusLog.length === 0) {
                            showEmailDetail(reminder)
                        } else {
                            setOpenMessagesDetails(true)
                            setSelectedLog(selectedLog)
                        }
                    }}>
                    {reminder.taskReminderStatusId && reminder.taskReminderStatusId === 3 ?
                        <Drafts style={{ height: '25px', width: '25px', color: green[400] }} />
                        :
                        <MailOutline style={{
                            height: '25px', width: '25px',
                            color: reminder.taskReminderStatusId ?
                                reminder.taskReminderStatusId === 1 ? 'inherit' :
                                    reminder.taskReminderStatusId === 2 ? '#0767DB' :
                                        reminder.taskReminderStatusId === 4 ? 'red' : 'inherit' : 'inherit'
                        }} />
                    }
                </IconButton>

            </Grid>

            <Grid item xs={10} sm={7} >
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography
                        variant='body1'
                        color={task.remindersSent && task.remindersSent.length > 0 && !task.complete && !task.isAssignToUser ? 'textPrimary' : 'textSecondary'}
                        style={{ marginBottom: '4px', fontSize: '16px' }}>
                        {reminder.entityName}<br />
                    </Typography>
                    {reminder.users && reminder.users.map((u, i) => (
                        <>
                            <Typography key={i}
                                variant='body2'
                                color={task.remindersSent && task.remindersSent.length > 0 && !task.complete && !task.isAssignToUser ? 'textPrimary' : 'textSecondary'}
                                style={{ fontSize: '14px', marginBottom: '4px' }}>
                                {u.name}
                            </Typography>
                            <Typography key={i}
                                variant='body2'
                                color={task.remindersSent && task.remindersSent.length > 0 && !task.complete && !task.isAssignToUser ? 'textPrimary' : 'textSecondary'}
                                style={{ fontSize: '14px' }}>
                                {`<${u.email}>`}
                            </Typography>
                        </>))}

                    {!reminder.users && reminder.entityContactId && <>
                        <Typography variant='body2'
                            color={task.remindersSent && task.remindersSent.length > 0 && !task.complete && !task.isAssignToUser ? 'textPrimary' : 'textSecondary'}
                            style={{ fontSize: '14px', marginBottom: '4px' }}>
                            {reminder.entityContactName}
                        </Typography>
                        <Typography variant='body2'
                            color={task.remindersSent && task.remindersSent.length > 0 && !task.complete && !task.isAssignToUser ? 'textPrimary' : 'textSecondary'}
                            style={{ fontSize: '14px' }}>
                            {`<${reminder.entityContactEmail}>`}
                        </Typography>
                    </>}
                </div>
            </Grid>

            <Grid item xs={12} sm={4} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: isMobile ? 'flex-start' : 'flex-end',
                justifyContent: 'center',
                paddingRight: '8px'
            }}>

                {(!reminder.taskReminderStatusId || (reminder.taskReminderStatusId && reminder.taskReminderStatusId === 1)) &&
                    <Typography variant='body2' color='textSecondary'
                        style={{ fontSize: '14px' }}>
                        {t('The system will send an email')}
                    </Typography>
                }

                {reminder.taskReminderStatusId && reminder.taskReminderStatusId === 2 &&
                    <Typography variant='body2' color='textSecondary'
                        style={{ fontSize: '14px', color: '#0767DB' }}>
                        {t('Sent - Waiting for response')}
                    </Typography>
                }

                {reminder.taskReminderStatusId && reminder.taskReminderStatusId === 3 &&
                    <Typography variant='body2' color='textSecondary'
                        style={{ fontSize: '14px', color: green[400] }}>
                        {t('Response received')}
                    </Typography>
                }

                {reminder.taskReminderStatusId && reminder.taskReminderStatusId === 4 &&
                    <Typography variant='body2' color='textSecondary'
                        style={{ fontSize: '14px', color: 'red' }}>
                        {t('Reminder {{retryCount}} - Waiting for response', { retryCount: reminder.retryCount })}
                    </Typography>
                }

                <div style={{ display: 'flex', marginTop: '8px' }}>
                    <Typography variant='body2' color='textSecondary'
                        style={{
                            fontSize: '14px'
                        }}>
                        {selectedLog && selectedLog.timestamp ? moment(selectedLog.timestamp).format('MMMM DD hh:mm a').replace(/^\w/, c => c.toUpperCase()) : `${reminderDueDate.date} ${reminderDueDate.time}`}
                    </Typography>

                </div>

            </Grid>

            {attachments && attachments.length > 0 &&
                <Grid item xs={12}>
                    {attachments.map(attachment => {
                        let separatedName = attachment.name.split('.'),
                            ext = separatedName[separatedName.length - 1].toLowerCase()
                        // isImage = imagesExt.includes(ext.toLowerCase())

                        // if (!isImage) {
                        return (
                            <div key={attachment.id} style={{
                                marginBottom: 10,
                                marginLeft: 58,
                                display: 'flex',
                                height: 'fit-content'
                            }} >
                                <a href={attachment.url}
                                    target='_blank'
                                    style={{
                                        color: theme.palette.primary.main
                                    }}
                                    rel="noopener noreferrer" >
                                    <OpenInNew style={{
                                        width: 18,
                                        height: 18
                                    }} />
                                </a>
                                <div style={{ marginLeft: 10 }}>
                                    <Typography variant='body1' color='textPrimary'>{attachment.name}</Typography>
                                    <Typography variant='body2' color='textSecondary'>{moment.utc(attachment.timestamp).format('MMMM DD hh:mm a')}</Typography>
                                </div>
                            </div>
                        )
                        // } else {
                        // return <></>
                        // }
                    })}
                </Grid>
            }

        </Grid >
    )
}

export default Reminder;