import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import {
    Button,
    Popover,
    withStyles
} from '@material-ui/core';
import { compose } from 'recompose'
import { DateRange } from '@material-ui/icons'
import moment from 'moment'
import styles from './styles';

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as generalActions from 'actions/generalActions'


class CalendarDateSelector extends Component {
    state = {
        anchorEl: null,
        openPopover: false,
        selectedFilter: this.props.generalParameters && this.props.generalParameters.selectedFilter ? this.props.generalParameters.selectedFilter : {
            value: 1,
            label: this.props.t('Esta semana')
        },
        filterOptions: [
            {
                value: 1,
                label: this.props.t('Esta semana'),
                from: moment.utc().startOf('isoWeek'),
                to: moment.utc().endOf('isoWeek')
            },
            {
                value: 2,
                label: this.props.t('Semana próxima'),
                from: moment.utc().add(1, 'week').startOf('isoWeek'),
                to: moment.utc().add(1, 'week').endOf('isoWeek')
            },
            {
                value: 3,
                label: this.props.t('Semana pasada'),
                from: moment.utc().add(-1, 'week').startOf('isoWeek'),
                to: moment.utc().add(-1, 'week').endOf('isoWeek')
            },
            {
                value: 4,
                label: this.props.t('Este mes'),
                from: moment.utc().startOf('month'),
                to: moment.utc().endOf('month')
            },
        ]
    }
    componentWillUnmount = () => {
        generalActions.clearSelectedFilter()
    }
    componentWillReceiveProps = nextProps => {
        if (!nextProps.generalParameters || (nextProps.generalParameters && !nextProps.generalParameters.selectedFilter)) {
            this.setState({
                selectedFilter: {
                    value: 1,
                    label: this.props.t('Esta semana')
                },
            })
        }
    }
    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            openPopover: true
        })
    }
    handleClose = () => {
        this.setState({
            anchorEl: null,
            openPopover: false
        })
    }
    handleChangeFilter = (id) => {
        let { filterOptions } = this.state;
        let { generalActions } = this.props;
        let filter = filterOptions.find(f => f.value === id)
        generalActions.setSelectedFilter(filter)
        this.setState({
            selectedFilter: filter,
            openPopover: false
        }, () => {
            let dateFilter = {
                from: filter.from,
                to: filter.to
            };
            this.props.handleChangeFilter && this.props.handleChangeFilter(dateFilter)
        })
    }

    render() {
        const { classes } = this.props;
        const {
            openPopover,
            anchorEl,
            selectedFilter,
            filterOptions
        } = this.state;

        return (
            <>
                <Button
                    color='primary'
                    variant='outlined'
                    size='small'
                    onClick={this.handleClick}>
                    <DateRange style={{ marginRight: 5 }} />
                    {selectedFilter.label}
                </Button>
                <Popover
                    anchorEl={anchorEl}
                    open={openPopover}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div style={{ padding: 0 }}>
                        {filterOptions.map((f, i) => (
                            <div key={i} className={`${classes.filter__option} ${selectedFilter.value === f.value ? classes['filter__option--selected'] : ''}`}>
                                <Button
                                    disabled={selectedFilter.value === f.value}
                                    style={{ width: '100%' }}
                                    color='primary'
                                    onClick={() => {
                                        this.handleChangeFilter(f.value)
                                    }}>
                                    {f.label}
                                </Button>
                            </div>
                        ))}
                    </div>
                </Popover>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({ generalActions: bindActionCreators(generalActions, dispatch) });
const mapStateToProps = ({ generalParameters }) => ({ generalParameters });

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
    withStyles(styles)
)(CalendarDateSelector)
