import React, { useState, useEffect } from 'react'
import {
    withStyles,
    TextField,
    MenuItem
} from '@material-ui/core'

import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import styles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { setUsersFilter } from 'actions/usersFilters'

const UsersFilterSelector = (props) => {
    const { classes } = props,
        { t } = useTranslation(),
        generalParameters = useSelector(state => state.generalParameters),
        currentPort = useSelector(state => state.currentPort),
        users = generalParameters.users.filter(u => u.portCode === currentPort.code),
        usersFilter = useSelector(state => state.usersFilter),
        [selectedUsers, setSelectedUsers] = useState(!generalParameters.loading ? [...generalParameters.users.filter(u => u.portCode === currentPort.code).filter(u => usersFilter.map(us => us.id).indexOf(u.id) >= 0)] : []),
        [selectedType, setSelectedType] = useState(selectedUsers.length > 1 ? 0 : (selectedUsers.length > 0 ? (users.find(u => u.id === selectedUsers[0].id) ? 0 : 0) : 0)),
        dispatch = useDispatch();



    useEffect(() => {
        handleChangeType({ target: { value: users.find(u => u.id === selectedType) ? selectedType : 0 } })
    }, [currentPort])

    useEffect(() => {
        if (usersFilter.length === 1 && selectedType !== usersFilter[0].id) {
            // setSelectedType(usersFilter[0].id);
            setSelectedType(0);
        } else if (usersFilter.length > 1 && selectedType !== 0) {
            setSelectedType(0);
        }
    }, [usersFilter])


    const selectAndFilterUser = user => {
        var selected = [{ ...user }];
        setSelectedUsers(selected)
        dispatch(setUsersFilter(selected))
    }

    const selectAndFilterAll = () => {
        var selected = [...generalParameters.users.filter(u => u.portCode === currentPort.code)];
        setSelectedUsers(selected)
        dispatch(setUsersFilter(selected))
    }



    const handleChangeType = e => {
        setSelectedType(e.target.value)
        if (e.target.value === 0) {
            selectAndFilterAll()
        } else {
            selectAndFilterUser(users.find(u => u.id === e.target.value))
        }
    }


    return (
        <>

            <TextField
                InputProps={{
                    inputProps: {
                        className: classes.select__root
                    }
                }}
                style={isMobile ? {
                    marginRight: 10,
                    width: 200
                } : {
                        marginRight: 10,
                        width: 200
                    }}
                select
                labe=''
                value={selectedType}
                onChange={handleChangeType}
                variant='outlined'
            >
                <MenuItem value={0} >
                    {t('peopleFilterEveryone')}
                </MenuItem>
                {users.map((user, i) => (
                    <MenuItem key={user.id} value={user.id} >
                        {`${user.firstName} ${user.lastName}`}
                    </MenuItem>
                ))}
            </TextField>

        </>
    )
}

export default withStyles(styles)(UsersFilterSelector)