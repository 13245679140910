import React, { useState, useEffect } from 'react'
import {
    Button,
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
    Grid,
    Select,
    MenuItem,
    TextField,
    FormControl,
    InputLabel,
    CircularProgress,
    ListSubheader,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormHelperText
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import styles from './styles'

import { useDispatch, useSelector } from 'react-redux'
import { Tasks } from 'services'
import { createTask as createTaskAction } from 'actions/tasksActions'
import { SnackbarNotification } from 'components'
import { CheckList } from '../TaskDetails/components'

const CreateTask = props => {
    const { t } = useTranslation(),
        { classes, recalada } = props,
        [openDialog, setOpenDialog] = useState(false),
        [saving, setSaving] = useState(false),
        [showValidations, setShowValidations] = useState(false),
        [invalidFields, setInvalidFields] = useState({}),
        [name, setName] = useState(''),
        [hours, setHours] = useState(0),
        [description, setDescription] = useState(''),
        [requiredResult, setRequiredResult] = useState(false),
        [checklist, setChecklist] = useState([]),
        [timeOption, setTimeOption] = useState(0),
        [hoursOrDaysOption, setHoursOrDaysOption] = useState(0),
        [dateReferenceOption, setDateReferenceOption] = useState(1),
        [selectedType, setSelectedType] = useState(0),
        [selectedUser, setSelectedUser] = useState(0),
        [rolesAndUsers, setRolesAndUsers] = useState([]),
        [showNotification, setShowNotification] = useState(false),
        [notificationMessage, setShowNotificationMessage] = useState(''),
        generalParameters = useSelector(state => state.generalParameters),
        roles = generalParameters.roles,
        users = generalParameters.users,
        taskTypes = generalParameters.taskTypes,
        dueDateReferences = generalParameters.dueDateReferences,
        dispatch = useDispatch();


    useEffect(() => {
        let rolesUsers = [];
        if (rolesAndUsers.length === 0) {
            roles.forEach(role => {
                rolesUsers.push({
                    label: role.name,
                    value: role.roleId,
                    isRole: true,
                    taskTypeId: role.taskTypeId
                })
                users.filter(u => u.roleId === role.roleId).forEach(user => {
                    rolesUsers.push({
                        label: `${user.firstName} ${user.lastName}`,
                        value: user.id,
                        isRole: false,
                        taskTypeId: role.taskTypeId
                    })
                })
            })
            setRolesAndUsers(rolesUsers)
        }
    }, [rolesAndUsers, roles, users])



    const beforeAfterOptions = [
        {
            value: 0,
            label: t('Before')
        },
        {
            value: 1,
            label: t('After')
        }
    ],
        hoursOrDays = [
            {
                value: 0,
                label: t('Horas')
            },
            {
                value: 1,
                label: t('Días')
            }
        ]


    const handleChangeName = e => {
        if (e.target.value.length <= 150) {
            setName(e.target.value)
        }
    }
    const handleChangeHours = e => {
        setHours(e.target.value);
    }
    const handleChangeDescription = e => {
        setDescription(e.target.value)
    }
    const handleChangeOption = e => {
        setTimeOption(e.target.value);
    }
    const handleChangeHoursOrDayOption = e => {
        setHoursOrDaysOption(e.target.value);
    }
    const handleChangeDateReferenceOption = e => {
        setDateReferenceOption(e.target.value);
    }
    const handleChangeType = (e) => {
        setSelectedType(e.target.value)
        setSelectedUser(0)
    }
    const handleChangeUser = (e) => {
        setSelectedUser(e.target.value)
    }

    const onExited = () => {
        setName('')
        setDescription('')
        setShowValidations(false)
        setInvalidFields({})
        setHours(0)
        setChecklist([])
        setRequiredResult(false)
        setTimeOption(0)
        setHoursOrDaysOption(0)
        setSelectedUser(0)
        setSelectedType(0)
        setSaving(false)
    }

    const hideDialog = () => {
        setOpenDialog(false)
    }
    const validateParameters = () => {
        let valid = true;
        let fields = {};
        if (name.trim() === '') {
            valid = false;
            fields = {
                ...fields,
                name: true
            }
        }
        if (!selectedUser) {
            valid = false;
            fields = {
                ...fields,
                user: true
            }
        }
        if (!selectedType) {
            valid = false;
            fields = {
                ...fields,
                type: true
            }
        }
        if (!valid) {
            setShowValidations(true)
            setInvalidFields(fields)
        } else {
            setShowValidations(false)
        }
        return valid;
    }


    const handleCancel = () => {
        setOpenDialog(false)
    }

    const handleSave = () => {
        if (validateParameters()) {
            setSaving(true)
            let totalHours = hoursOrDaysOption === 0 ? hours : hours * 24;
            let dueHours = timeOption === 0 ? 0 - totalHours : totalHours;


            let parameters = {
                recaladaId: recalada.recaladaId,
                name,
                description,
                dueHours,
                userId: selectedUser,
                requiredResult: requiredResult ? 1 : 0,
                keywords: [],
                checklist,
                taskTypeId: selectedType,
                dueDateReferenceId: dateReferenceOption
            }

            Tasks.createTask(parameters)
                .then(response => {
                    if (response.success) {
                        dispatch(createTaskAction(response.record))
                        setSaving(false)
                        setOpenDialog(false)
                        setShowNotification(true)
                        setShowNotificationMessage(t('Task created'))
                    }
                })
        }
    }

    const closeNotification = () => {
        setShowNotification(false)
        setShowNotificationMessage('')
    }

    const addChecklist = (checklist) => {
        setChecklist(checklist)
    }

    const handleChangeRequiredResult = (event) => {
        setRequiredResult(event.target.checked)
    };

    if (!recalada) return null;

    return (
        <>
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    setOpenDialog(true)
                }}
            >
                {t('Add Task')}
            </Button>


            <Dialog
                maxWidth='sm'
                classes={{
                    paper: classes.dialog__paper
                }}
                onClose={hideDialog}
                open={openDialog}
                onExited={onExited}>
                <DialogTitle className={classes.dialog__title}>
                    <div className={classes.dialog__title__container}>
                        <Typography variant='h5' color='inherit'>{t('Add Task')}</Typography>
                        <IconButton color="inherit"
                            onClick={hideDialog}>
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent classes={{
                    root: classes.dialog__container
                }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                disabled={saving}
                                required
                                error={showValidations && invalidFields.name}
                                label={t('Name')}
                                autoFocus
                                value={name}
                                onChange={handleChangeName}
                                style={{
                                    width: '100%'
                                }}
                                InputProps={{
                                    inputProps: {
                                        className: classes.textField__input
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant='outlined' />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' color='textSecondary'>{t('Due Hours')}</Typography>
                            <div className={classes.dueHours__root}>
                                <TextField
                                    disabled={saving}
                                    value={hours}
                                    onChange={handleChangeHours}
                                    type='number'
                                    style={{
                                        minWidth: 80
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            className: classes.dueHours__textfield__input
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    placeholder={t('Horas')}
                                    variant='outlined'
                                />
                                <Select
                                    disabled={saving}
                                    value={hoursOrDaysOption}
                                    onChange={handleChangeHoursOrDayOption}
                                    variant='outlined'
                                    style={{ marginLeft: 5, marginRight: 5 }}
                                    classes={{
                                        root: classes.select__root
                                    }}>
                                    {hoursOrDays.map(option => {
                                        return (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <Select
                                    disabled={saving}
                                    value={timeOption}
                                    onChange={handleChangeOption}
                                    variant='outlined'
                                    style={{ marginLeft: 5, marginRight: 5 }}
                                    classes={{
                                        root: classes.select__root
                                    }}>
                                    {beforeAfterOptions.map(option => {
                                        return (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <Typography className={classes.label}>{t('de')}</Typography>
                                <Select
                                    disabled={saving}
                                    style={{ marginLeft: 5, marginRight: 5 }}
                                    value={dateReferenceOption}
                                    onChange={handleChangeDateReferenceOption}
                                    variant='outlined'
                                    classes={{
                                        root: classes.select__root
                                    }}>
                                    {dueDateReferences.map(option => {
                                        return (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <FormControl required variant="outlined" style={{ minWidth: '200px', width: '100%', marginRight: 10 }}>
                                <InputLabel id="task-type-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}
                                    shrink>
                                    {t('Task Type')}
                                </InputLabel>
                                <Select
                                    disabled={saving}
                                    error={showValidations && invalidFields.type}
                                    labelId="task-type-selector"
                                    placeholder={t('Select Rol')}
                                    value={selectedType}
                                    onChange={handleChangeType}
                                    variant='outlined'
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={120}
                                >
                                    <MenuItem key={0} value={0}>{t("Select")}</MenuItem>
                                    {taskTypes.map(type => {
                                        return (
                                            <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <FormControl
                                required
                                variant="outlined"
                                style={{
                                    minWidth: '200px', width: '100%',
                                    marginRight: 10
                                }}
                                disabled={saving}
                            >
                                <InputLabel id="role-type-selector"
                                    classes={{
                                        root: classes.select__label
                                    }}
                                    shrink>
                                    {t('Responsible')}
                                </InputLabel>
                                <Select
                                    error={showValidations && invalidFields.user}
                                    labelId="role-type-selector"
                                    placeholder={t('Select User')}
                                    value={selectedUser}
                                    onChange={handleChangeUser}
                                    variant='outlined'
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    labelWidth={100}
                                >
                                    <MenuItem key={0} value={0}>{t("Select")}</MenuItem>
                                    {rolesAndUsers.map(ru => {
                                        if (ru.isRole) {
                                            return (
                                                <ListSubheader
                                                    key={`role${ru.value}`}
                                                    style={{ fontSize: 12 }}>
                                                    {ru.label}
                                                </ListSubheader>
                                            )
                                        } else {
                                            return (
                                                <MenuItem key={ru.value}
                                                    value={ru.value}
                                                    onClick={() => handleChangeUser({ target: { value: ru.value } })}
                                                    style={{ marginLeft: 8 }}>
                                                    {ru.label}
                                                </MenuItem>
                                            )
                                        }

                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={saving}
                                error={showValidations && invalidFields.description}
                                label={t('Description')}
                                value={description}
                                multiline
                                onChange={handleChangeDescription}
                                style={{
                                    width: '100%',
                                }}
                                InputProps={{
                                    inputProps: {
                                        className: classes.textField__area__input
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant='outlined' />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={requiredResult} onChange={handleChangeRequiredResult} name="requiredResult" />}
                                        label={t('Required result')}
                                    />
                                </FormGroup>
                                <FormHelperText>{t('The task may require documents or text as a result')}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <CheckList
                                addChecklist={addChecklist}
                                isCreating={true}
                            />
                        </Grid>

                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                disabled={saving}
                                onClick={handleCancel}>
                                {t('Cancel')}
                            </Button>
                            <Button
                                disabled={saving}
                                variant='contained'
                                color='primary'
                                onClick={handleSave}
                            >
                                {t('Save')}
                                {saving && (
                                    <CircularProgress color='secondary'
                                        style={{ marginLeft: 5 }}
                                        size={15} />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <SnackbarNotification
                open={showNotification && notificationMessage ? true : false}
                onClose={closeNotification}
                message={notificationMessage}
                type='success' />
        </>
    )
}
export default withStyles(styles)(CreateTask)