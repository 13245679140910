import React, { useState } from 'react';

// Externals
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Material helpers
import { makeStyles, Button, CircularProgress } from '@material-ui/core';
import { isMobile } from 'react-device-detect'

//Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as serviceActions from 'actions/serviceActions'

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import List from './List';
import { ServiceDetails } from '../../../../components'

// Component styles
const styles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1) * 4
  },
  mobile_root: {
    padding: '16px 0'
  }
}));


function Services(props) {
  const { generalParameters } = props;
  const [showServiceDetails, setShowServiceDetails] = useState(false),
    { t } = useTranslation(),
    classes = styles();

  const handleCloseService = () => {
    props.serviceActions.clearService()
    setShowServiceDetails(false)
  }

  const handleSelectService = (service) => {
    props.serviceActions.setService(service)
    setShowServiceDetails(true)
  }

  return (
    <DashboardLayout
      title={props.service ? props.service.name : t('Services')}
    >
      <div className={isMobile ? classes.mobile_root : classes.root}>
        {generalParameters.loading ? (
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300
          }} >
            <CircularProgress />
          </div>
        ) : (
            <>
              <div style={{ width: '100%', textAlign: 'right', marginBottom: '15px', padding: '0 0px' }}>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => handleSelectService(null)}
                >
                  {t('New service')}
                </Button>
              </div>

              <List
                setService={handleSelectService}
              />

              <ServiceDetails
                open={showServiceDetails}
                onClose={handleCloseService}
              />

            </>
          )}

      </div>
    </DashboardLayout>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    serviceActions: bindActionCreators(serviceActions, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    generalParameters: state.generalParameters,
    service: state.service
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Services));
