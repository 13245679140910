export default theme => ({
    dialog__paper: {
    },
    dialog__title__container: {
        color: '#fff',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    dialog__title: {
        backgroundColor: '#b3b3b3',
    },
    dialog__container: {
        padding: theme.spacing(2)
    },
    selected__tab: {
        border: '1px solid #b3b3b3',
        borderBottom: 'none'
    },
    ship__tabs: {
        borderBottom: `1px solid #b3b3b3`
    },
    email__paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: theme.spacing(3),
        height: '450px'
    },
    email__sender__container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    email__sender__timestamp: {

    },
    email__sender: {
        fontWeight: 'bold',
        textAlign: 'left'
    },
    email__recipient: {

    },
    email__subject: {
        fontWeight: 'bold',
        textAlign: 'left'
    },
    email__body: {
        textAlign: 'left',
        whiteSpace: 'pre-line'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})