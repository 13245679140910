import 'whatwg-fetch';
import config from 'config';
import auth from 'authentication/auth'

export const Services = {
    updateServiceTemplateName: ({ serviceTemplateId, name }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/servicesTemplates/${serviceTemplateId}/name`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    name
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateService: ({ serviceId, name, linerId, templateId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/services/${serviceId}`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    name,
                    linerId,
                    templateId
                }

                fetch(url, {
                    method: 'PUT',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    createService: ({ name, linerId, templateId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/services`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    name,
                    linerId,
                    templateId
                }

                fetch(url, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    createServiceTemplate: ({ portCode, name }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/servicesTemplates`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    name,
                    portCode
                }

                fetch(url, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    }
}