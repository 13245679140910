import React from 'react'
import { useSelector } from 'react-redux'
import {
    Typography,
    Grid,
} from '@material-ui/core'
import {
    Description,
} from './components';
import { useTranslation } from 'react-i18next'


export default function SecondTab(props) {
    const task = useSelector(state => (state.task)),
        { t } = useTranslation();

    return (
        <>
            <Grid item xs={12}>
                <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                    <strong>{t('Description')}</strong>
                </Typography>
            </Grid>
            {task.inputs && task.inputs.length > 0 &&
                <Grid item xs={12}>
                    <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                        <strong>{t('Pre requirements')}</strong>
                    </Typography>

                    {task.inputs && task.inputs.length > 0 && task.inputs.map((req, i) => {
                        return (
                            <Typography key={i} variant='body1' style={{ whiteSpace: 'pre-line' }} >
                                {req.name}
                            </Typography>
                        )
                    })}
                </Grid>
            }


            {task.description &&
                <Grid item xs={12} >
                    <Description task={task} />
                </Grid>
            }

            {task.outputs && task.outputs.length > 0 &&
                <Grid item xs={12}>
                    <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                        <strong>{t('Must update')}</strong>
                    </Typography>
                    {task.outputs.map(out => {
                        return (
                            <Typography variant='body1' style={{ whiteSpace: 'pre-line' }} >
                                {out.name}
                            </Typography>
                        )
                    })}
                </Grid>
            }
        </>
    )
}
