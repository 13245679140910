import React, { useState } from 'react'
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    FormControl,
    withStyles,
    Button,
    IconButton,
    FormHelperText
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { useTranslation } from 'react-i18next'
import styles from './styles'
import { Tasks } from 'services'

import { useDispatch, useSelector } from 'react-redux'
import { updateTaskRequiredResult } from 'actions/tasksActions'

const RequiredResult = (props) => {
    const { classes } = props;
    const task = useSelector(state => (state.task))
    const [showButtonsActions, setShowButtonsActions] = useState(false)
    const { t } = useTranslation(),
        [requiredResult, setRequiredResult] = useState(task ? task.requiredResult ? true : false : false),
        [resultContent, setResultContent] = useState(task ? task.resultContent : ''),
        [saving, setSaving] = useState(false),
        dispatch = useDispatch();

    // useEffect(() => {
    //     let isSuscribed = true;
    //     if (requiredResult === null && task && task.requiredResult && isSuscribed) {
    //         setRequiredResult(task.requiredResult)
    //     }
    //     return () => {
    //         isSuscribed = false;
    //     };
    // }, [task, requiredResult])

    if (!task) return null;


    const handleChangeRequiredResult = (event) => {
        if (task && ((task.requiredResult ? true : false) === event.target.checked)) {
            setShowButtonsActions(false)
        } else {
            setShowButtonsActions(true)
        }
        setRequiredResult(event.target.checked)
    }

    const saveRequiredResult = () => {
        setSaving(true)

        Tasks.updateTaskRequiredResult({
            taskId: task.taskId,
            requiredResult: requiredResult ? 1 : 0,
            resultContent
        })
            .then(response => {
                if (response.success) {
                    dispatch(updateTaskRequiredResult({
                        ...response.record,
                        serviceTemplateId: task.serviceTemplateId
                    }))
                    setSaving(false)
                    setShowButtonsActions(false)
                }
            })
    }

    const handleResetChanges = () => {
        setRequiredResult(task ? task.requiredResult ? true : false : false)
        setShowButtonsActions(false)
    }

    const handleInputChange = (e) => {
        setResultContent(e.target.value)
        setShowButtonsActions(true)
    }

    return (
        <div style={{ position: 'relative' }}>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup disabled={saving}>
                    <FormControlLabel
                        control={<Checkbox checked={requiredResult} onChange={handleChangeRequiredResult} name="requiredResult" />}
                        label={t('Required result')}
                    />
                </FormGroup>
                <FormHelperText>{t('The task may require documents or text as a result')}</FormHelperText>
            </FormControl>

            {requiredResult &&
                <TextField
                    multiline
                    className={classes.description__textfield}
                    placeholder={t('Results')}
                    value={resultContent}
                    onChange={handleInputChange}
                    autoFocus
                    disabled={saving}
                />
            }

            {showButtonsActions &&
                <div style={{ textAlign: 'right', position: 'absolute', right: 0, top: 0 }}>

                    <Button
                        style={{ width: 'max-content', fontSize: '11px' }}
                        variant='contained'
                        disabled={saving}
                        color='primary'
                        onClick={saveRequiredResult}
                    >
                        {t('Save changes')}
                    </Button>
                    <IconButton edge="end" aria-label="comments" onClick={handleResetChanges}>
                        <Close />
                    </IconButton>
                </div>
            }
        </div>
    )
}
export default withStyles(styles)(RequiredResult)