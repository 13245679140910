export default theme => ({
    dialog__paper: {
        width: '100%',
        overflow: 'visible',
    },
    dialog__title__container: {
        color: '#fff',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    dialog__title: {
        backgroundColor: theme.palette.primary.main,
    },
    dialog__container: {
        padding: theme.spacing(2)
    },
    loading__container: {
        height: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    description__container: {
        padding: theme.spacing(2)
    },
    select__root: {
        padding: 10,
    },
    select__formControl: {
        width: '100%'
    },
    textField__input: {
        padding: 10
    },
    textField_addornment__input: {
        padding: '10px 10px 10px 0'
    },
    datePicker__input__root: {
        width: '100%'
    },
    eta__date__calendar: {
        border: 'solid thin #0089ff',
        borderRadius: 5
    },
    endImpo__date__calendar: {
        border: 'solid thin #8500ff',
        borderRadius: 5
    },
    endOperations__date__calendar: {
        border: 'solid thin #ffc46d',
        borderRadius: 5
    },
    departure__date__calendar: {
        border: 'solid thin #32db64',
        borderRadius: 5
    }
})