export default theme => ({
    root: {
        display: 'flex',
        margin: `8px 0`
    },
    userChip: {
        marginRight: 10,
        cursor: 'pointer'
    },
    add__user__actions__container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    textfield: {
        padding: theme.spacing(),
        marginRigth: 5
    },
    select__root: {
        padding: 10
    },
    actions: {
        marginTop: theme.spacing(),
        display: 'flex'
    },
    actions__save: {
        marginRight: 10
    },
})