export default theme => ({
    root: {

    },
    userAvatar: {
        borderRadius: '50%',
        backgroundColor: '#f3f3f3',
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(),
    },
    message__container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    message__text__container: {
        padding: theme.spacing(),
        flex: 1,
        backgroundColor: '#f4f4f4',
        border: 'solid thin #f5f5f5',
        borderRadius: 5,
    }
})
