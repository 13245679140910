import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next'

import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  withStyles
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons'
import styles from './styles';
import schema from './schema';
import auth from '../../authentication/auth';

import {
  isMobile
} from "react-device-detect";

class LoginForm extends Component {
  state = {
    values: {
      email: '',
      password: ''
    },
    touched: {
      email: false,
      password: false
    },
    errors: {
      email: null,
      password: null
    },
    isValid: false,
    isLoading: false,
    submitError: false,
    showPassword: false
  };

  componentWillReceiveProps = (nextProps) => {
    const { t } = this.props;
    let newState = { ...this.state };
    if (nextProps.match.params.lang !== this.props.match.params.lang) {
      newState.errors = {
        email: this.state.errors.email ? t('Enter a valid email') : null,
        password: this.state.errors.password ? t('Enter your password') : null
      };
      this.setState(newState);
    }
  }

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  validateForm = _.debounce(() => {
    const { values } = this.state,
      { t } = this.props;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = {
      email: errors && errors.email ? t('Enter a valid email') : null,
      password: errors && errors.password ? t('Enter your password') : null
    };

    newState.isValid = errors ? false : true;

    this.setState(newState, () => {
      if (errors) {
        return
      }

      const { values } = this.state,
        { password, email } = values;

      this.setState({
        isLoading: true
      });

      auth.signIn({ email, password })
        .then(user => {
          this.signInSuccess(user);
        })
        .catch(err => {
          this.signInError(err)
        })
    });



  }, 300);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = false;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState)
  };


  handleSignIn = () => {
    const newState = { ...this.state };

    this.setState(newState, this.validateForm);

    // if (!isValid) {
    //   return
    // }

    // const { values } = this.state,
    //   { password, email } = values;

    // this.setState({
    //   isLoading: true
    // });

    // auth.signIn({ email, password })
    //   .then(user => {
    //     this.signInSuccess(user);
    //   })
    //   .catch(err => {
    //     this.signInError(err)
    //   })
  }

  signInSuccess = (user) => {
    this.setState({
      submitError: false
    });

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      this.changeState('requireNewPassword', user);
      this.setState({
        isLoading: false
      });
    } else {
      this.checkContact(user);
      this.setState({
        isLoading: false
      });
    }
  }

  checkContact(user) {
    auth.verifiedContact(user).then(data => {
      this.setState({
        isLoading: false
      });
      this.changeState('signedIn', user);
    });
  }

  signInError(err) {
    this.setState({
      isLoading: false
    });

    // if (err.error.code === 'UserNotConfirmedException') {
    //     this.changeState('confirmSignUp', email);
    //     return
    // }

    this.setState({
      submitError: true
    });
  }

  changeState(state, data) {
    const { onStateChange } = this.props;

    if (onStateChange) {
      onStateChange(state, data);
    }
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      this.handleSignIn()
    }
  }

  render() {
    const { classes, authState, authData, t } = this.props;

    if (!['signIn', 'signedOut'].includes(authState)) {
      return null;
    }

    const {
      values,
      errors,
      submitError,
      isLoading,
      showPassword
    } = this.state;

    const showEmailError = errors.email;
    const showPasswordError = errors.password;

    const handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    return (
      <form className={classes.form}>
        <img
          height="90"
          style={{ margin: '0 auto', width: isMobile ? '100%' : 'auto', height: 'auto' }}
          alt="CTSA"
          className={classes.logoImage}
          src="/images/logos/logoCTSA.jpg"
        />

        <Typography
          className={classes.title}
          variant="h2"
        >
          {t('Log In')}
        </Typography>

        <div className={classes.fields}>
          <TextField
            className={classes.textField}
            label={t("Email address")}
            name="email"
            autoFocus={true}
            defaultValue={authData}
            onChange={event =>
              this.handleFieldChange('email', event.target.value)
            }
            onKeyDown={this.onKeyDown}
            type="text"
            value={values.email}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                autoCapitalize: 'off',
                autoComplete: 'off',
                spellCheck: 'false',
                autoCorrect: "off"
              }
            }}
          />
          {showEmailError && (
            <Typography
              className={classes.fieldError}
              variant="body2"
            >
              {errors.email}
            </Typography>
          )}
          <TextField
            className={classes.textField}
            label={t("Password")}
            name="password"
            onChange={event =>
              this.handleFieldChange('password', event.target.value)
            }
            onKeyDown={this.onKeyDown}
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                autoCapitalize: 'off',
                autoComplete: 'off',
                spellCheck: 'false',
                autoCorrect: "off"
              },
              endAdornment: (
                <InputAdornment position="end" >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <div style={{ marginTop: '10px', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Link type='button'
              variant="body2"
              color="primary"
              style={{ fontSize: '14px' }}
              onClick={() => this.changeState('forgotPassword', values.email)}>
              {t("Forgot Password?")}
            </Link>
          </div>

          {showPasswordError && (
            <Typography
              className={classes.fieldError}
              variant="body2"
            >
              {errors.password}
            </Typography>
          )}
        </div>
        {submitError && (
          <Typography
            className={classes.submitError}
            variant="body2"
          >
            {t('Wrong email and password')}
          </Typography>
        )}
        {isLoading ? (
          <CircularProgress className={classes.progress} />
        ) : (
            <Button
              className={classes.signInButton}
              color="primary"
              // disabled={!isValid}
              onClick={this.handleSignIn}
              size="large"
              variant="contained"
            >
              {t('Sign in now')}
            </Button>
          )}
      </form>
    );
  }
}

export default withTranslation()(compose(
  withRouter,
  withStyles(styles)
)(LoginForm));
