import 'whatwg-fetch';
import config from 'config';
import auth from 'authentication/auth'

export const Users = {
	getUsers: (args = {}) => {
		return new Promise((resolve, reject) => {
			auth.getAuthenticatedUser().then(user => {
				const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
				let url = `${config.apiGateway.url}/v1/account`;

				var headers = new Headers();
				headers.append("x-api-key", config.apiGateway.api_key);
				headers.append("Authorization", prodekAuthToken);

				fetch(url, { headers })
					.then(function (response) {
						return response.json();
					})
					.then(function (jsonResponse) {
						resolve(jsonResponse);
					}).catch(err => {
						reject(err);
					});
			})
		})
	},
	createUser: (args ={}) =>{
		return new Promise((resolve, reject) => {
			auth.getAuthenticatedUser().then(user => {
				const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
				let url = `${config.apiGateway.url}/v1/account`;

				var headers = new Headers();
				headers.append("x-api-key", config.apiGateway.api_key);
				headers.append("Authorization", prodekAuthToken);

				fetch(url, { 
					headers,
					body: JSON.stringify(args.user),
					method: "POST"
				})
					.then(function (response) {
						return response.json();
					})
					.then(function (jsonResponse) {
						resolve(jsonResponse);
					}).catch(err => {
						reject(err);
					});
			})
		})
	},
	updateUser: (args ={}) =>{
		return new Promise((resolve, reject) => {
			auth.getAuthenticatedUser().then(user => {
				const prodekAuthToken = user.signInUserSession.idToken.jwtToken;
				let url = `${config.apiGateway.url}/v1/account/${args.user.id}`;

				var headers = new Headers();
				headers.append("x-api-key", config.apiGateway.api_key);
				headers.append("Authorization", prodekAuthToken);

				fetch(url, { 
					headers,
					body: JSON.stringify(args.user),
					method: "PUT"
				})
					.then(function (response) {
						return response.json();
					})
					.then(function (jsonResponse) {
						resolve(jsonResponse);
					}).catch(err => {
						reject(err);
					});
			})
		})
	}
}