import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import { Bar } from 'react-chartjs-2';

// Material helpers
import { withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import { connect } from 'react-redux'

// Material components
import { Button } from '@material-ui/core';

// Material icons
import {
  // ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon
} from '@material-ui/icons';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  PortletFooter
} from 'components';

// Chart configuration
import { data, options } from './chart';

// Component styles
import styles from './styles';

class TasksChart extends Component {
  render() {
    const { classes, currentPort, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader noDivider>
          <PortletLabel title="Tareas Semanales" />
          <PortletToolbar>
            {/* <Button
              className={classes.dropdownButton}
              size="small"
              variant="text"
            >
              Proyecciones <ArrowDropDownIcon />
            </Button> */}
          </PortletToolbar>
        </PortletHeader>
        <PortletContent>
          <div className={classes.chartWrapper}>
            <Bar
              data={data}
              options={options}
            />
          </div>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={
              () => {
                this.props.history && this.props.history.push(`/${this.props.match.params.lang}/${currentPort.code}/tasks`)
              }
            }
          >
            Detalles <ArrowRightIcon />
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

const mapStateToProps = ({ currentPort }) => ({
  currentPort
});


export default 
  connect(mapStateToProps, null)(
    compose(
      withStyles(styles)
    )(TasksChart)
  )
