import React, { useState, useEffect } from 'react'
import {
    Typography,
    withStyles,
    // Button,
    CircularProgress,
    // Link,
    // Divider,
    IconButton
} from '@material-ui/core'
import styles from './styles'
import {
    OpenInNew,
    //AttachFile, 
    RemoveCircleOutline
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import { ConfirmationDialog } from 'components'
import { Tasks } from 'services'
import {
    useDispatch,
    //useSelector 
} from 'react-redux'
import {
    deleteTaskAttachment,
    //addTaskAttachments 
} from 'actions/tasksActions'

const Attachments = props => {
    const { classes, task } = props,
        [attachments, setAttachments] = useState(task.attachments ? task.attachments.filter(doc => !doc.name.toLowerCase().startsWith('image')) : null),
        [taskAttachments, setTaskAttachments] = useState(task.taskAttachments ? task.taskAttachments : null),
        [saving, setSaving] = useState(false),
        // [attachButtonLabels, setAttachButtonLabels] = useState([]),
        // [filesAttachments, setFilesAttachments] = useState([]),
        [openConfirmation, setOpenConfirmation] = useState(false),
        [selectedAttachment, setSelectedAttachment] = useState(null),
        { t } = useTranslation(),
        // account = useSelector((state) => state.account),
        dispatch = useDispatch();

    useEffect(() => {
        if (task && !attachments && task.attachments) {
            setAttachments(task.attachments.filter(doc => !doc.name.toLowerCase().startsWith('image')))
        }
        if (task && ((!taskAttachments && task.taskAttachments) || (taskAttachments && task.taskAttachments && taskAttachments.length !== task.taskAttachments.length))) {
            setTaskAttachments(task.taskAttachments)
        }
    }, [attachments, taskAttachments, task, task.attachments, task.taskAttachments])

    /*const handleChooseFile = (e) => {
        e.stopPropagation()
        let attachButtonLabelsNew = [],
            attachButtonLabelsCopy = [...attachButtonLabels],
            files = e.target.files;

        if (files.length > 1) {
            attachButtonLabelsNew = Object.keys(files).map((key, i) => {
                return files[key].name
            })
        } else {
            attachButtonLabelsNew = [e.target.value.split("\\").pop()]
        }

        setFilesAttachments(files)
        setAttachButtonLabels(attachButtonLabelsCopy.concat(attachButtonLabelsNew))
    }

    const saveAttachments = () => {
        if (filesAttachments.length > 0) {
            setSaving(true)

            Tasks.uploadTaskAttachments({
                taskId: task.taskId,
                filesAttachments,
                userId: account.id
            })
                .then(response => {
                    if (response.success) {
                        dispatch(addTaskAttachments({
                            taskId: task.taskId,
                            ...response.record
                        }))
                        setSaving(false)
                        setSaving(false)
                        setSelectedAttachment(null)
                        setFilesAttachments([])
                        setAttachButtonLabels([])
                    }
                })
        }
    }*/

    const deleteAttachment = () => {
        setSaving(true)
        Tasks.deleteTaskAttachment({
            taskId: selectedAttachment.taskId,
            taskAttachmentId: selectedAttachment.taskAttachmentId
        }).then(response => {
            if (response.success) {
                dispatch(deleteTaskAttachment({
                    ...selectedAttachment
                }))
            }
            setSaving(false)
            setSelectedAttachment(null)
        })
    }

    /* const deleteUploadingAttachment = index => {
         let attachments = [...attachButtonLabels],
             newFilesAttachments = [...filesAttachments];
 
         attachments.splice(index, 1);
         newFilesAttachments.splice(index, 1);
         setFilesAttachments(newFilesAttachments)
         setAttachButtonLabels(attachments)
     }*/

    const confirmDeleteAttachment = (attachment) => {
        setSelectedAttachment(attachment)
        setOpenConfirmation(true)
    }


    if (!task) return null;

    return (
        <div>
            {((taskAttachments && taskAttachments.length > 0) || (attachments && attachments.length > 0)) && (
                <div className={classes.attachments__container} style={isMobile ? { margin: 0, flexDirection: 'column' } : { flexDirection: 'column' }}>
                    {taskAttachments && taskAttachments.length > 0 && (
                        <>
                            {taskAttachments.filter(tt => tt.fromTaskTemplate).length > 0 && (
                                <>
                                    {/* <Typography style={{ marginBottom: 5, marginTop: 10 }}>{t('taskTemplateAttachments')}</Typography> */}
                                    {/* <Divider style={{ marginBottom: 10 }} /> */}
                                    <div className={classes.attachments__container} style={{ flexDirection: 'row', margin: 0 }}>
                                        {taskAttachments.filter(tt => tt.fromTaskTemplate).map((attachment, i) => (
                                            <div key={i} className={classes.attachmentLink__container}>
                                                <a key={i} href={attachment.attachmentUrl}
                                                    target='_blank'
                                                    className={classes.link}
                                                    rel="noopener noreferrer" >
                                                    <OpenInNew style={{
                                                        width: 18,
                                                        height: 18
                                                    }} />
                                                </a>
                                                <div className={classes.attachment__name__container}>
                                                    <Typography variant='body1' color='textPrimary'>{attachment.attachmentName}</Typography>
                                                    <Typography variant='body2' color='textSecondary'>{moment.utc(attachment.timestamp).format('MMMM DD hh:mm a')}</Typography>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </>
                            )}

                            {taskAttachments.filter(tt => !tt.fromTaskTemplate).length > 0 && (
                                <>
                                    {/* <Typography style={{ marginBottom: 5, marginTop: 10 }}>{t('taskUserAttachments')}</Typography>
                                    <Divider style={{ marginBottom: 10 }} /> */}
                                    <div className={classes.attachments__container} style={{ flexDirection: 'row', margin: 0 }}>
                                        {taskAttachments.filter(tt => !tt.fromTaskTemplate).map((attachment, i) => (
                                            <div key={i} className={classes.attachmentLink__container} >
                                                <a key={i} href={attachment.attachmentUrl}
                                                    target='_blank'
                                                    className={classes.link}
                                                    rel="noopener noreferrer" >
                                                    <OpenInNew style={{
                                                        width: 18,
                                                        height: 18
                                                    }} />
                                                </a>
                                                <div className={classes.attachment__name__container}>
                                                    <Typography variant='body1' color='textPrimary'>{attachment.attachmentName}</Typography>
                                                    <Typography variant='body2' color='textSecondary'>{moment.utc(attachment.timestamp).format('MMMM DD hh:mm a')}</Typography>
                                                </div>
                                                {task.isAutomaticTask === 0 &&
                                                    <IconButton
                                                        disabled={saving}
                                                        style={{ marginLeft: '4px' }}
                                                        onClick={() => confirmDeleteAttachment(attachment)}>
                                                        {saving && selectedAttachment && selectedAttachment.taskTemplateAttachmentId === attachment.taskTemplateAttachmentId ? (
                                                            <CircularProgress color='secondary'
                                                                style={{ marginLeft: 5 }}
                                                                size={15} />
                                                        ) : (
                                                                <RemoveCircleOutline
                                                                    className={classes.attachment__remove__button} />

                                                            )}
                                                    </IconButton>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {/*attachments && attachments.length > 0 && (
                            <>
                                <Typography style={{ marginBottom: 5, marginTop: 10 }}>{t('taskEmailsAttachments')}</Typography>
                                <Divider style={{ marginBottom: 10 }} />
                                {attachments.map((attachment, i) => (
                                    <div key={i} className={classes.attachmentLink__container}>
                                        <a key={i} href={attachment.url}
                                            target='_blank'
                                            className={classes.link}
                                            rel="noopener noreferrer" >
                                            <OpenInNew style={{
                                                width: 18,
                                                height: 18
                                            }} />
                                        </a>
                                        <div className={classes.attachment__name__container}>
                                            <Typography variant='body1' color='textPrimary'>{attachment.name}</Typography>
                                            <Typography variant='body2' color='textSecondary'>{moment.utc(attachment.timestamp).format('MMMM DD hh:mm a')}</Typography>
                                        </div>
                                    </div>

                                ))}
                            </>
                    )*/}
                </div>
            )}

            {/*task.isAutomaticTask === 0 && task.complete === 0 &&
                < div style={{ width: '100%' }}>
                    <label htmlFor="text-button-file">
                        <Link
                            disabled={saving}
                            variant='body1'
                            color="primary"
                            className={classes.attach__button}
                        >
                            <AttachFile className={classes.attach__icon} />
                            {t("Add Attachment")}
                        </Link>
                    </label>
                    <input
                        disabled={saving}
                        accept="image/*, application/pdf, .gif, .jpg, .png, .doc, .txt, .rtf, .xls, .xlsx"
                        className={classes.attach__input__file}
                        id="text-button-file"
                        multiple
                        type="file"
                        onChange={handleChooseFile}
                    />
                    {attachButtonLabels.length > 0 && (
                        <div className={classes.new_attachments__container}>
                            {attachButtonLabels.map((label, i) => {
                                return (
                                    <div className={classes.attachment__item} key={i}>
                                        <Typography
                                            className={classes.attachment__label__container} noWrap>
                                            <RemoveCircleOutline
                                                disabled={saving}
                                                onClick={() => deleteUploadingAttachment(i)}
                                                style={{ marginRight: 4, marginLeft: 0 }}
                                                className={classes.attachment__remove__button} />
                                            {label}
                                        </Typography>
                                    </div>
                                )
                            })}
                            <div className={classes.upload__button__container}>
                                <Button
                                    disabled={saving}
                                    variant='contained'
                                    color='primary'
                                    size="small"
                                    onClick={saveAttachments}
                                >
                                    {t('Upload')}
                                    {saving && (
                                        <CircularProgress color='secondary'
                                            style={{ marginLeft: 5 }}
                                            size={15} />
                                    )}
                                </Button>
                            </div>
                        </div>
                    )}

                </div>
            */}

            <ConfirmationDialog
                handleClose={() => {
                    setOpenConfirmation(false)
                    setSelectedAttachment(null)
                }}
                onConfirm={() => {
                    setOpenConfirmation(false)
                    deleteAttachment()
                }}
                message={t('Are you sure you wish to delete this attachment?')}
                cancelLabel={t('Cancel')}
                confirmLabel={t('Continue')}
                open={openConfirmation}
            />
        </div >
    )
}
export default withStyles(styles)(Attachments)