import React, { useState, useEffect } from 'react'
import {
    Button,
    Select,
    TextField,
    Typography,
    Grid,
    MenuItem,
    CircularProgress,
    makeStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Tasks } from 'services'
import {
    createTaskTemplateReminder,
    updateTaskTemplateReminder
} from 'actions/taskTemplateActions'

import styles from './styles'
const useStyles = makeStyles(styles);

const ReminderForm = (props) => {
    const {
        taskTemplate,
        reminder
    } = props,
        classes = useStyles(),
        { t } = useTranslation(),
        [showValidations, setShowValidations] = useState(false),
        [invalidFields, setInvalidFields] = useState({}),
        [hours, setHours] = useState(1),
        [saving, setSaving] = useState(false),
        [timeOption, setTimeOption] = useState(0),
        [hoursOrDaysOption, setHoursOrDaysOption] = useState(1),
        [selectedType, setSelectedType] = useState(''),
        [selectedUserRole, setSelectedUserRole] = useState(''),
        [title, setTitle] = useState(''),
        generalParameters = useSelector(state => state.generalParameters),
        taskTypes = generalParameters.taskTypes,
        beforeAfterOptions = generalParameters.beforeAfterOptions,
        hoursOrDays = generalParameters.hoursOrDays,
        roles = generalParameters.roles,
        dispatch = useDispatch();

    useEffect(() => {
        let isMounted = true;
        if (reminder && isMounted) {
            let totalHours = Math.abs(parseInt(reminder.dueHours)),
                rest = totalHours % 24;
            setTitle(reminder.title)
            setSelectedType(reminder.typeId)
            setSelectedUserRole(reminder.roleId)

            if (totalHours < 24 || (totalHours >= 24 && rest !== 0)) {
                setHours(totalHours)
                setHoursOrDaysOption(0);
            } else {
                let days = parseInt(totalHours / 24);
                setHours(days)
                setHoursOrDaysOption(1);
            }

        }
        return () => {
            isMounted = false;
        }
    }, [reminder])

    const handleChangeHours = e => {
        setHours(e.target.value);
    }
    const handleChangeHoursOrDayOption = e => {
        setHoursOrDaysOption(e.target.value);
    }
    const handleChangeOption = e => {
        setTimeOption(e.target.value);
    }
    const handleChangeType = (e) => {
        setSelectedType(e.target.value)
    }
    const handleChangeRole = (e) => {
        setSelectedUserRole(e.target.value)
    }
    const handleTitleChange = (e) => {
        setTitle(e.target.value)
    }

    const clearForm = () => {
        const { unselectReminder } = props;
        setHours(1);
        setSaving(false);
        setTimeOption(0);
        setHoursOrDaysOption(0);
        setSelectedType('');
        setSelectedUserRole('');
        setTitle('');
        unselectReminder()
    }

    const validateParameters = () => {
        let valid = true;
        let fields = {};
        if (title.trim() === '') {
            valid = false;
            fields = {
                ...fields,
                title: true
            }
        }
        if (!selectedUserRole) {
            valid = false;
            fields = {
                ...fields,
                role: true
            }
        }
        if (!selectedType) {
            valid = false;
            fields = {
                ...fields,
                type: true
            }
        }
        if (!valid) {
            setShowValidations(true)
            setInvalidFields(fields)
        } else {
            setShowValidations(false)
        }
        return valid;
    }

    const handleSaveReminder = () => {
        if (validateParameters()) {

            setSaving(true)
            let totalHours = hoursOrDaysOption === 0 ? hours : hours * 24;
            let dueHours = timeOption === 0 ? 0 - totalHours : totalHours;
            let newReminder = {
                taskTemplateReminderId: reminder && reminder.taskTemplateReminderId ? reminder.taskTemplateReminderId : undefined,
                serviceTemplateId: taskTemplate.serviceTemplateId,
                taskTemplateId: taskTemplate.id,
                title,
                dueHours,
                typeId: selectedType,
                departmentName: taskTypes.find(type => type.id === selectedType).name,
                roleId: selectedUserRole,
                roleName: roles.find(role => role.roleId === selectedUserRole).name
            }

            if (reminder && reminder.taskTemplateReminderId) {
                Tasks.updateTaskTemplateReminder(newReminder)
                    .then(reminderData => {
                        clearForm()
                        setSaving(false)
                        dispatch(updateTaskTemplateReminder({
                            ...newReminder,
                            ...reminderData.record,
                        }))
                        props.onSave()
                    })
            } else {
                Tasks.createTaskTemplateReminder(newReminder)
                    .then(reminderData => {
                        clearForm()
                        setSaving(false)
                        dispatch(createTaskTemplateReminder({
                            ...newReminder,
                            ...reminderData.record,
                        }))
                        props.onSave()
                    })
            }
        }
    }


    const handleCancel = () => {
        const { onCancel } = props;
        clearForm()
        onCancel && onCancel()
    }

    return (
        <div className={classes.reminder__form}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5'>{t('Create Reminder')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6'>{t('Title')}</Typography>
                    <TextField
                        disabled={saving}
                        label={''}
                        variant='outlined'
                        autoFocus
                        fullWidth
                        error={showValidations && invalidFields.title}
                        value={title}
                        onChange={handleTitleChange}
                        InputProps={{
                            inputProps: {
                                className: classes.title__textfield__input
                            }
                        }} />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.reminder__form__date}>
                        <TextField
                            disabled={saving}
                            value={hours}
                            onChange={handleChangeHours}
                            type='number'
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    className: classes.dueHours__textfield__input
                                }
                            }}
                            style={{ marginRight: 0, minWidth: 80, padding: 4, paddingLeft: 0 }}
                            className={classes.textfield}
                            placeholder={t('##')}
                            variant='outlined'
                        />

                        <Select
                            disabled={saving}
                            value={hoursOrDaysOption}
                            onChange={handleChangeHoursOrDayOption}
                            variant='outlined'
                            style={{ marginRight: 5, flex: 1 }}
                            classes={{
                                root: classes.select__root
                            }}>
                            {hoursOrDays.map(option => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                )
                            })}
                        </Select>


                        <Select
                            disabled={saving}
                            value={timeOption}
                            onChange={handleChangeOption}
                            variant='outlined'
                            style={{ marginRight: 5, flex: 1 }}
                            classes={{
                                root: classes.select__root
                            }}>
                            {beforeAfterOptions.map(option => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                )
                            })}
                        </Select>
                        <Typography
                            className={`${classes.label} ${classes.reference__label}`}>
                            {t('due date')}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.reminder__form__users}>
                                <Typography variant='h6'>{t('reminders_department')}</Typography>
                                <Select
                                    disabled={saving}
                                    labelId="task-type-selector"
                                    placeholder={t('Select Type')}
                                    value={selectedType}
                                    error={showValidations && invalidFields.type}
                                    onChange={handleChangeType}
                                    variant='outlined'
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    fullWidth
                                    labelWidth={0}
                                >
                                    {taskTypes.map(type => {
                                        return (
                                            <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.reminder__form__users}>
                                <Typography variant='h6'>{t('Responsible')}</Typography>
                                <Select
                                    error={showValidations && invalidFields.role}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    placeholder={t('Select User')}
                                    disabled={!selectedType || saving}
                                    value={selectedUserRole}
                                    variant='outlined'
                                    onChange={handleChangeRole}
                                    classes={{
                                        root: classes.select__root
                                    }}
                                    fullWidth
                                    labelWidth={0}>
                                    {roles.filter(role => role.taskTypeId === selectedType).map(rol => {
                                        return (
                                            <MenuItem key={rol.roleId} value={rol.roleId}>{rol.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.actions}>
                        <Button
                            disabled={saving}
                            variant='contained'
                            size='small'
                            color='primary'
                            onClick={handleSaveReminder}
                            className={classes.actions__save}>
                            {t('Save')}
                            {saving && (
                                <CircularProgress color='secondary'
                                    style={{ marginLeft: 5 }}
                                    size={15} />
                            )}
                        </Button>
                        <Button
                            disabled={saving}
                            variant='outlined'
                            onClick={handleCancel}>
                            {t('Cancel')}
                        </Button>
                    </div>
                </Grid>
            </Grid>

        </div>

    )
}


export default ReminderForm