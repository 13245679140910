import React, { useState } from 'react'
import {
	Grid,
	Dialog,
	Button,
	withMobileDialog,
	withStyles,
	CircularProgress,
	Typography,
	IconButton,
	DialogTitle,
	DialogContent,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { compose } from 'recompose'
import { Ships as ShipsService } from 'services'

import styles from './styles'

const ShipDetails = props => {
	const { classes, onClose, selectedShip, changeShipInfo } = props,
		[currentShip, setCurrentShip] = useState(selectedShip ? selectedShip : null),
		[fetching, setFetching] = useState(false),
		{ t } = useTranslation();

	const onExited = () => {
		setCurrentShip(null)
	}

	const getVesselDetail = () => {
		setFetching(true)
		ShipsService.getShipInfo({
			IMO: selectedShip.IMO
		}).then(response => {
			if (response.success) {
				let resDetails = response.data,
					newShip = {
						...currentShip,
						IMO: currentShip.IMO,
						name: currentShip.name,
						MMSI: resDetails.MMSI,
						type: resDetails.VESSEL_TYPE,
						yearBuild: resDetails.BUILD,
						flag: resDetails.FLAG,
						breadthExtreme: resDetails.BREADTH_EXTREME,
						summerDWT: resDetails.SUMMER_DWT,
						lengthOverall: resDetails.LENGTH_OVERALL,
					}
				setFetching(false)
				setCurrentShip(newShip)
				changeShipInfo(newShip)
			}
		})
	}

	return (
		<div>
			<Dialog
				open={true}
				fullScreen={isMobile ? true : false}
				classes={{
					paper: classes.dialog__paper
				}}
				onExited={onExited}
				maxWidth='xs'>
				<DialogTitle
					className={classes.dialog__title}>
					<div className={classes.dialog__title__container}>
						<Typography variant='h5' color='inherit'>
							{`${currentShip.linerName} - ${currentShip.name} `}
						</Typography>
						<IconButton
							color='inherit'
							onClick={onClose} >
							<Close />
						</IconButton>
					</div>
				</DialogTitle>
				<DialogContent classes={{
					root: classes.dialog__container
				}}>
					{currentShip.MMSI && !fetching &&
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12}>
								<Typography variant='body1' className={classes.title__content}>
									<strong style={{ marginRight: '6px' }}>{t('IMO')}</strong>
									<span style={{ paddingLeft: '10px' }}>{currentShip.IMO}</span>
								</Typography>
								<Typography variant='body1' className={classes.title__content}>
									<strong style={{ marginRight: '6px' }}>{t('MMSI')}</strong>
									<span style={{ paddingLeft: '10px' }}>{currentShip.MMSI}</span>
								</Typography>
								<Typography variant='body1' className={classes.title__content}>
									<strong style={{ marginRight: '6px' }}>{t('Type')}</strong>
									<span style={{ paddingLeft: '10px' }}>{currentShip.type}</span>
								</Typography>
								<Typography variant='body1' className={classes.title__content}>
									<strong style={{ marginRight: '6px' }}>{t('Flag')}</strong>
									<span style={{ paddingLeft: '10px' }}>{currentShip.flag}</span>
								</Typography>
								<Typography variant='body1' className={classes.title__content}>
									<strong style={{ marginRight: '6px' }}>{t('Summer DWT')}</strong>
									<span style={{ paddingLeft: '10px' }}>{currentShip.summerDWT}</span>
								</Typography>
								<Typography variant='body1' className={classes.title__content}>
									<strong style={{ marginRight: '6px' }}>{t('Length Overall x Breadth Extreme')}</strong>
									<span style={{ paddingLeft: '10px' }}>{`${currentShip.lengthOverall}m x ${currentShip.breadthExtreme}m`}</span>
								</Typography>
								<Typography variant='body1' className={classes.title__content}>
									<strong style={{ marginRight: '6px' }}>{t('Year Built')}</strong>
									<span style={{ paddingLeft: '10px' }}>{currentShip.yearBuild}</span>
								</Typography>
							</Grid>
						</Grid>
					}


					{!currentShip.MMSI && !fetching &&
						<div>
							<Typography variant='h6'>
								{t('The information of this ship has not been saved')}
							</Typography>
							<div style={{ textAlign: 'center', marginTop: '32px' }}>
								<Button
									color="primary"
									size="small"
									variant="contained"
									onClick={getVesselDetail}
								>
									{t('Get Ship Detail')}
								</Button>
							</div>
						</div>
					}

					{fetching &&
						<div className={classes.progressWrapper}>
							<CircularProgress />
						</div>
					}
				</DialogContent>
			</Dialog >
		</div >
	)
}



export default compose(
	withMobileDialog(),
	withStyles(styles)
)(ShipDetails)