import React, { useState, useEffect } from 'react'
import {
    CircularProgress,
    makeStyles
} from '@material-ui/core'

import { Dashboard as DashboardLayout } from 'layouts';
import { useTranslation } from 'react-i18next'
import { Portlet, PortletContent } from 'components';
import { Recaladas } from 'services';
import RecaladasTable from './RecaladasTable'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing()}px 0`
        }
    },
    content: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing()
        }
    },
    dialog__paper: {
        width: '100%'
    },
    progressWrapper: {
        paddingTop: '48px',
        paddingBottom: '24px',
        display: 'flex',
        justifyContent: 'center'
    },
    noRecaladas__container: {
        width: '100%',
        background: '#fff',
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

export default function RecentRecaladasPage(props) {
    const { t } = useTranslation(),
        classes = useStyles(),
        [recaladas, setRecaladas] = useState({}),
        [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        if (!recaladas.BUN) {
            Recaladas.getRecaladas({
                port: 'BUN',
                getActiveRecaladas: true
            }).then(response => {
                setRecaladas({
                    ...recaladas,
                    BunFetched: true,
                    BUN: response.data.records.recaladas || null
                })
            })
        }

        if (!recaladas.CTG) {
            Recaladas.getRecaladas({
                port: 'CTG',
                getActiveRecaladas: true
            }).then(response => {
                setRecaladas({
                    ...recaladas,
                    CtgFetched: true,
                    CTG: response.data.records.recaladas || null
                })
            })
        }
        if (recaladas.BunFetched && recaladas.CtgFetched) {
            setIsLoading(false);
        }
    }, [recaladas])

    return (
        <DashboardLayout
            bigTitle={t('Active Recaladas')}>
            <div className={classes.root}>

                {isLoading &&
                    <div className={classes.progressWrapper}>
                        <CircularProgress />
                    </div>
                }
                {!isLoading && (
                    <>
                        <Portlet >
                            <PortletContent noPadding>
                                <RecaladasTable
                                    {...props}
                                    portCode='BUN'
                                    portName='Buenaventura'
                                    recaladas={recaladas.BUN}
                                    isLoading={isLoading} />
                            </PortletContent>
                        </Portlet>
                        <br />
                        <Portlet >
                            <PortletContent noPadding>
                                <RecaladasTable
                                    {...props}
                                    portCode='CTG'
                                    portName='Cartagena'
                                    recaladas={recaladas.CTG}
                                    isLoading={isLoading} />
                            </PortletContent>
                        </Portlet>
                    </>
                )}
            </div>
        </DashboardLayout>
    )
}
