import React, { useState, useEffect } from 'react'
import {
    IconButton,
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    makeStyles,
    CircularProgress
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Assignment, Close } from '@material-ui/icons'

import { Recaladas, Emails } from 'services';



//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as recaladasActions from 'actions/recaladasActions'

const useStyles = makeStyles(theme => {
    return {
        assignTask__button__container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end'
        },
        assignTask__shipname: {
            fontSize: '18px',
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        dialog__paper: {
            width: '100%'
        },
        dialog__title: {
            height: 50,
            padding: '4px 16px',
            backgroundColor: theme.palette.primary.main,
        },
        dialog__title__container: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: theme.palette.getContrastText(theme.palette.primary.main)
        },
        textField: {
            marginTop: 2,
            minWidth: 120,
            maxWidth: '100%',
            width: '100%'
        },
        dialog__content: {
            padding: theme.spacing(3)
        },
        assignTask__button: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff'
        }
    }
})


function EmailTaskSelection(props) {
    const [email, setEmail] = useState(props.email)
    const [openDialog, setOpenDialog] = useState(false)
    const [shipsRecaladas, setShipsRecaladas] = useState(props.recaladas ? props.recaladas.map(lf => ({
        ...lf,
        label: `${lf.shipName} V-${lf.itineraryName}`,
        value: lf.recaladaId,
        id: lf.recaladaId,
    })) : null)
    const [selectedRecalada, setSelectedRecalada] = useState(null)
    const [selectedTask, setSelectedTask] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [saving, setSaving] = useState(false)
    const { t } = useTranslation()
    const classes = useStyles()

    useEffect(() => {
        let isSuscribed = true;
        if (!shipsRecaladas) {
            setFetching(true)
            Recaladas.getRecaladas({ emails: true })
                .then(response => {
                    if (isSuscribed) {
                        if (response.status === 'success') {
                            setShipsRecaladas(response.data.records.recaladas.map(lf => ({
                                ...lf,
                                label: `${lf.shipName} V-${lf.itineraryName}`,
                                value: lf.recaladaId,
                                id: lf.recaladaId,
                            })))
                        }
                        setFetching(false)
                    }
                })
        }
        return () => {
            isSuscribed = false;
        };
    }, [shipsRecaladas])


    if (!email) return null;

    const handleOpenAssignTaskDialog = () => {
        let selectedlf = (email.recalada && email.recalada.id && props.recaladas ? props.recaladas.find(lf => lf.recaladaId === email.recalada.id) : null),
            selectedtsk = selectedlf && selectedlf.tasks && email.task.id ? selectedlf.tasks.find(t => t.taskId === email.task.id) : null;
        setSelectedRecalada(selectedlf);
        setSelectedTask(selectedtsk);
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setSelectedRecalada(null);
        setSelectedTask(null);
        setOpenDialog(false)
    }
    const handleAssignEmail = () => {
        setFetching(true)
        setSaving(true)
        Emails.assignEmailToTask({
            emailId: email.emailId,
            recaladaId: selectedRecalada.recaladaId,
            taskId: selectedTask.taskId
        })
            .then(response => {
                setFetching(false)
                setSaving(false)
                setEmail({
                    ...response.data.record
                })
                props.updateEmails && props.updateEmails(response.data.record)
                setOpenDialog(false)
            }).catch(_ => {
                setFetching(false)
                setSaving(false)
            })
    }
    const handleSelectRecaladaChange = (e) => {
        let shipRecalada = shipsRecaladas.find(s => parseInt(s.id) === parseInt(e.target.value));
        setSelectedRecalada(shipRecalada || null)
    }
    const handleSelectTaskChange = (e) => {
        let task = selectedRecalada ? selectedRecalada.tasks.find(s => parseInt(s.taskId) === parseInt(e.target.value)) : null;
        setSelectedTask(task || null)
    }

    return (
        <>
            <div className={classes.assignTask__button__container}>
                {email.ship && email.ship.name &&
                    <Typography color='secondary' className={classes.assignTask__shipname}>
                        {`${email.ship.name} ${email.itinerary && email.itinerary.name ? `V-${email.itinerary.name}` : ''}`}
                    </Typography>
                }
                <Button
                    color='primary'
                    onClick={handleOpenAssignTaskDialog}
                    style={{ fontSize: '12px' }}>
                    {`${email.task && email.task.name ? ` ${email.task.name}` : t('No task')} `}
                    <Assignment style={{ marginLeft: '4px' }} />
                </Button>
            </div>
            <Dialog
                open={openDialog}
                classes={{
                    paper: classes.dialog__paper
                }}
                maxWidth='xs'>
                <DialogTitle className={classes.dialog__title}>
                    <div className={classes.dialog__title__container}>
                        <Typography variant='h5' color='inherit'>
                            {t('Assign email to task')}
                        </Typography>
                        <IconButton
                            onClick={handleCloseDialog}
                            color='inherit'
                            disabled={saving}>
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialog__content}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                disabled={fetching}
                                className={classes.textField}
                                SelectProps={{ native: true }}
                                value={selectedRecalada && selectedRecalada.recaladaId ? selectedRecalada.recaladaId : '0'}
                                select
                                variant='outlined'
                                label={t('Buque')}
                                margin="dense"
                                placeholder={t('Seleccionar')}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={handleSelectRecaladaChange}
                            >
                                <option
                                    value={0}
                                >
                                    {t('Seleccionar')}
                                </option>
                                {shipsRecaladas && shipsRecaladas.map(option => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={fetching || !selectedRecalada}
                                className={classes.textField}
                                SelectProps={{ native: true }}
                                value={selectedTask && selectedTask.taskId ? selectedTask.taskId : '0'}
                                select
                                variant='outlined'
                                label={t('Tarea')}
                                margin="dense"
                                placeholder={t('Seleccionar')}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={handleSelectTaskChange}
                            >
                                <option
                                    value={0}
                                >
                                    {t('Seleccionar')}
                                </option>
                                {selectedRecalada && selectedRecalada.tasks && selectedRecalada.tasks.map(option => (
                                    <option
                                        key={option.taskId}
                                        value={option.taskId}
                                    >
                                        {option.name}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={fetching}
                        onClick={handleCloseDialog}>
                        {t('Cancel')}
                    </Button>
                    <Button color='primary'
                        disabled={fetching || (!selectedRecalada || !selectedTask)}
                        onClick={handleAssignEmail}>
                        {t('Save')}
                        {saving && (
                            <CircularProgress color="secondary" size={20} style={{ marginLeft: 10 }} />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        recaladasActions: bindActionCreators(recaladasActions, dispatch),
    }
}


function mapStateToProps(state, ownProps) {
    return {
        recaladas: state.recaladas,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTaskSelection);