import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  withWidth
} from '@material-ui/core';
import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next'
import BottomTabsNav from '../BottomTabsNavigation'

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(1) * 4,
    marginBottom: theme.spacing(4),
  },
  company: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1) * 0.5
  },
  bottomNavigation__container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    marginTop: 50
  }
});

class Footer extends Component {
  render() {

    return (
      <>
        <BottomTabsNav />
      </>
    );
  }
}

Footer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withWidth()(
  compose(
    withRouter,
    withTranslation(),
    withStyles(styles)
  )(Footer)
)
