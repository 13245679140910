import React from 'react'
import {
    Typography,
    Tooltip,
    withStyles
} from '@material-ui/core'
import styles from './styles'

const UserAvatar = (props) => {
    const { user, classes } = props;
    return (
        <Tooltip title={`${user.firstName} ${user.lastName}`}>
            <div className={classes.userAvatar}>
                <Typography variant='h6' color='textSecondary'>
                    {user.firstName[0].toUpperCase()}
                </Typography>
            </div>
        </Tooltip>
    )
}
export default withStyles(styles)(UserAvatar)