export default theme => ({
    root: {
        display: 'flex',
        margin: theme.spacing(),
        alignItems: 'center',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        }
    },
    textfield: {
        padding: theme.spacing(),
        marginRight: 15,
        width: 60,
    },
    label: {
        marginRigth: 15,
    },
    select__root: {
        padding: 10,
        marginTop: 1,
        marginRight: 15,
    },
    actions: {
        display: 'flex'
    },
    actions__save: {
        marginRight: 10
    },
    dueHours__textfield__input: {
        padding: 10,
        width: 80,

    },
})