export default [
  {
    id: 'ABC1234924',
    amount: 30.5,
    tipo: '20 DRY',
    customer: 'DEV70522522222',
    products: ['DEV738089'],
    createdAt: 1555016400000,
    status: 'en reparación'
  },
  {
    id: 'DEVA898812',
    amount: 25.1,
    tipo: '20 DRY',
    customer: 'DEV696649',
    products: ['DEV774585'],
    createdAt: 1555016400000,
    status: 'apto'
  },
  {
    id: 'DEBV793788',
    amount: 10.99,
    tipo: '40 HC',
    customer: 'DEV702967',
    products: ['DEV654476'],
    createdAt: 1554930000000,
    status: 'dañado'
  },
  {
    id: 'DEV841788',
    amount: 96.43,
    tipo: '40 REEFER',
    customer: 'DEV663348',
    products: ['DEV654476', 'DEV773559'],
    createdAt: 1554757200000,
    status: 'en reparación'
  },
  {
    id: 'DEV552540',
    amount: 32.54,
    tipo: '20 DRY',
    customer: 'DEV728981',
    products: ['DEV738089'],
    createdAt: 1554670800000,
    status: 'apto'
  },
  {
    id: 'DEV783653',
    tipo: '20 DRY',
    amount: 34.2,
    customer: 'DEV883167',
    products: ['DEV773559'],
    createdAt: 1554325200000,
    status: 'apto'
  },
  {
    id: 'DEV593146',
    tipo: '20 DRY',
    amount: 43.75,
    customer: 'DEV883167',
    products: ['DEV795381'],
    createdAt: 1554325200000,
    status: 'dañado'
  }
];
