import 'whatwg-fetch';
import config from 'config';
import auth from 'authentication/auth'

export const Tasks = {
    createTask: ({
        name,
        description,
        userId,
        dueHours,
        keywords,
        checklist,
        requiredResult,
        recaladaId,
        taskTypeId,
        dueDateReferenceId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/recaladas/${recaladaId}/tasks`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    name,
                    description,
                    userId,
                    dueHours,
                    checklist,
                    requiredResult,
                    keywords,
                    taskTypeId,
                    dueDateReferenceId
                }

                fetch(url, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    getTask: ({ taskId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    listTask: ({ portCode, from, to, search, taskType } = {}) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks?port=${portCode ? portCode : ''}&from=${from ? from : ''}&to=${to ? to : ''}&search=${search ? search : ''}&taskType=${taskType ? taskType : ''}`;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);
                fetch(url, { headers })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTask: ({ task }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${task.taskId}`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    name: task.name,
                    description: task.description,
                    keywords: task.keywords.join(', '),
                    dueDate: task.dueDate,
                    complete: task.complete,
                    responsibleUserId: task.responsibleUserId,
                    messages: JSON.stringify(task.messages)
                }

                fetch(url, {
                    method: 'PUT',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    sendReminders: ({ taskId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/sendreminder`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                fetch(url, {
                    method: 'POST',
                    headers
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    addMessage: ({ taskId, authorUserId, message }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/messages`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    message,
                    authorUserId
                }

                fetch(url, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateKeywords: ({ taskId, keywords }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/keywords`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    keywords
                }

                fetch(url, {
                    method: 'PUT',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskName: ({ taskId, name }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/name`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    name
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskDescription: ({ taskId, description }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/description`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    description
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskRequiredResult: ({ taskId, requiredResult, resultContent }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/requiredResult`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    requiredResult,
                    resultContent
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskChecklist: ({ taskId, checklist }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/checklist`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    checklist
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskDueHours: ({ taskId, dueHours, dueDateReferenceId = 1 }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/dueHours`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    dueHours,
                    dueDateReferenceId
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskComplete: ({ taskId, complete, filesAttachments, resultText }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/complete`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    complete
                },
                    data = new FormData(),
                    isMultipartForm = false;
                if (filesAttachments || resultText) {
                    body.resultText = resultText;
                    isMultipartForm = true;
                    Object.keys(body).forEach(key => {
                        if (body[key]) {
                            data.append(key, typeof body[key] === 'object' ? JSON.stringify(body[key]) : body[key])
                        }
                    })

                    if (filesAttachments) {
                        for (const file of filesAttachments) {
                            data.append('files', file, file.name)
                        }
                    }
                }

                fetch(`${url}${isMultipartForm ? '/withResult' : ''}`, {
                    method: 'PATCH',
                    headers,
                    body: isMultipartForm ? data : JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskAddUser: ({ taskId, userId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/users`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    userId
                }

                fetch(url, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    uploadTaskAttachments: ({ taskId, filesAttachments, userId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/attachments`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);


                let body = {
                    userId
                }
                var data = new FormData()
                Object.keys(body).forEach(key => {
                    if (body[key]) {
                        data.append(key, typeof body[key] === 'object' ? JSON.stringify(body[key]) : body[key])
                    }
                })

                if (filesAttachments) {
                    for (const file of filesAttachments) {
                        data.append('files', file, file.name)
                    }
                }

                fetch(url, {
                    body: data,
                    method: 'PUT',
                    headers
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    deleteTaskAttachment: ({ taskId, taskAttachmentId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/attachments`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    taskAttachmentId,
                }

                fetch(url, {
                    method: 'DELETE',
                    body: JSON.stringify(body),
                    headers
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskRemoveUser: ({ taskId, userId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasks/${taskId}/users`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    userId
                }

                fetch(url, {
                    method: 'DELETE',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskTemplateName: ({ taskTemplateId, name }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/name`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    name
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    createTaskTemplate: ({
        name,
        description,
        roleId,
        dueHours,
        keywords,
        checklist,
        requiredResult,
        serviceTemplateId,
        taskTypeId,
        dueDateReferenceId,
        perviousTaskId,
        filesAttachments,
        entities,
        userId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    name,
                    description: description ? description : '',
                    roleId,
                    dueHours: dueHours ? dueHours : 0,
                    keywords,
                    checklist,
                    requiredResult,
                    serviceTemplateId,
                    taskTypeId,
                    dueDateReferenceId,
                    perviousTaskId,
                    userId,
                    entities: entities ? entities : []
                }

                var data = new FormData()
                Object.keys(body).forEach(key => {
                    if (body[key]) {
                        data.append(key, typeof body[key] === 'object' ? JSON.stringify(body[key]) : body[key])
                    }
                })

                if (filesAttachments) {
                    for (const file of filesAttachments) {
                        data.append('files', file, file.name)
                    }
                }

                fetch(url, {
                    body: data,
                    headers,
                    method: 'POST',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskTemplateDescription: ({ taskTemplateId, description }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/description`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    description
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskTemplateKeywords: ({ taskTemplateId, keywords }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/keywords`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    keywords
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskTemplateChecklist: ({ taskTemplateId, checklist }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/checklist`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    checklist
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskTemplateRequiredResult: ({ taskTemplateId, requiredResult }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/requiredResult`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    requiredResult
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskTemplateRole: ({ taskTemplateId, roleId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/role`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    roleId
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskTemplateType: ({ taskTemplateId, taskTypeId, roleId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/type`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    taskTypeId,
                    roleId
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskTemplateParentTask: ({ taskTemplateId, parentTaskTemplateId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/parentTaskTemplate`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    parentTaskTemplateId,
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskTemplateDueHours: ({ taskTemplateId, dueHours, dueDateReferenceId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/dueHours`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    dueHours,
                    dueDateReferenceId
                }

                fetch(url, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    deleteTaskTemplate: ({ taskTemplateId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                fetch(url, {
                    method: 'DELETE',
                    headers
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    deleteTaskTemplateAttachment: ({ taskTemplateId, taskTemplateAttachmentId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/attachments`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    taskTemplateAttachmentId,
                }

                fetch(url, {
                    method: 'DELETE',
                    body: JSON.stringify(body),
                    headers
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    uploadTaskTemplateAttachments: ({ taskTemplateId, filesAttachments, userId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/attachments`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);


                let body = {
                    userId
                }
                var data = new FormData()
                Object.keys(body).forEach(key => {
                    if (body[key]) {
                        data.append(key, typeof body[key] === 'object' ? JSON.stringify(body[key]) : body[key])
                    }
                })

                if (filesAttachments) {
                    for (const file of filesAttachments) {
                        data.append('files', file, file.name)
                    }
                }

                fetch(url, {
                    body: data,
                    method: 'PUT',
                    headers
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    createTaskTemplateReminder: ({ taskTemplateId, serviceTemplateId, title, dueHours, typeId, roleId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/reminders`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    serviceTemplateId,
                    title,
                    dueHours,
                    typeId: typeId,
                    roleId
                }

                fetch(url, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    updateTaskTemplateReminder: ({ taskTemplateReminderId, taskTemplateId, serviceTemplateId, title, dueHours, typeId, roleId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/reminders/${taskTemplateReminderId}`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                let body = {
                    serviceTemplateId,
                    title,
                    dueHours,
                    typeId: typeId,
                    roleId
                }

                fetch(url, {
                    method: 'PUT',
                    headers,
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    },
    deleTeaskTemplateReminder: ({ taskTemplateReminderId, taskTemplateId }) => {
        return new Promise((resolve, reject) => {
            auth.getAuthenticatedUser().then(user => {
                const authToken = user.signInUserSession.idToken.jwtToken;
                let url = `${config.apiGateway.url}/v1/tasksTemplates/${taskTemplateId}/reminders/${taskTemplateReminderId}`;
                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", authToken);

                fetch(url, {
                    method: 'DELETE',
                    headers,
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            })
        })
    }
}