import React, { useState } from 'react'
import {
    Typography,
    IconButton,
    makeStyles,
} from '@material-ui/core'
import { Alarm, Close, Person, MailOutline } from '@material-ui/icons'
import styles from './styles'
import { reminderFrendlyDueHours } from 'lib'
import { useDispatch } from 'react-redux'
import { deleteTaskTemplateReminder } from 'actions/taskTemplateActions'
import { Tasks } from 'services'
import { useTranslation } from 'react-i18next'
import { ConfirmationDialog } from 'components'


const useStyles = makeStyles(styles);

const Reminder = props => {
    const { reminder, taskTemplate } = props,
        { t } = useTranslation(),
        classes = useStyles(),
        [openConfirmation, setOpenConfirmation] = useState(false),
        dispatch = useDispatch();

    const confirmDeleteReminder = (e) => {
        e.stopPropagation()
        props.onDeleteConfirmation && props.onDeleteConfirmation();
        setOpenConfirmation(true)
    }
    const deleteReminder = () => {
        dispatch(deleteTaskTemplateReminder({ ...reminder, serviceTemplateId: taskTemplate.serviceTemplateId }))
        Tasks.deleTeaskTemplateReminder({
            taskTemplateReminderId: reminder.taskTemplateReminderId,
            taskTemplateId: reminder.taskTemplateId
        }).then(_ => {
            console.log("Reminder deleted")
        })
    }

    return (
        <div className={classes.reminder__row__content}>

            {/* <IconButton size='small' onClick={confirmDeleteReminder}>
                <Close />
            </IconButton> */}

            {/* <Typography variant='body1' color='textPrimary' style={{ marginBottom: 5 }}>
                {reminder.title}
            </Typography> */}

            {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                <Alarm
                    style={{
                        marginRight: 5,
                        fontSize: 15,
                        width: 15,
                        height: 15
                    }}
                    color='textSecondary' />
                <Typography variant='body1' color='textSecondary'> {reminderFrendlyDueHours(reminder.dueHours)}</Typography>
            </div> */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <Person style={{
                    marginRight: 5,
                    fontSize: 15,
                    width: 15,
                    height: 15
                }}
                    color='textSecondary' /> */}
                <IconButton style={{
                    cursor: "pointer",
                    color: '#66788A'
                    // onClick={() => { props.handleSelect(reminder) }}
                }}>

                    <MailOutline style={{ height: '25px', width: '25px' }} />
                </IconButton>
                <Typography variant='body1' color='textPrimary'>{reminder.entityName ? reminder.entityName : reminder.roleName} - </Typography>
                <Typography variant='body1' color='textPrimary'>{taskTemplate ? taskTemplate.name : ''} </Typography>
            </div>

            <ConfirmationDialog
                handleClose={(e) => {
                    setOpenConfirmation(false)
                    e.stopPropagation()
                }}
                onConfirm={(e) => {
                    e.stopPropagation()
                    setOpenConfirmation(false)
                    deleteReminder()
                }}
                message={t('Are you sure you wish to remove this reminder?')}
                cancelLabel={t('Cancel')}
                confirmLabel={t('Continue')}
                open={openConfirmation}
            />
        </div>
    )
}

export default Reminder;