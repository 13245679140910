export const SET_TASK = 'SET_TASK'
export const CREATE_TASK = 'CREATE_TASK'
export const CLEAR_TASK = 'CLEAR_TASK'
export const ADD_TASK = 'ADD_TASK'
export const DELETE_TASK = 'DELETE_TASK'
export const UPDATE_TASK = 'UPDATE_TASK'
export const SET_TASKS = 'SET_TASKS'
export const CLEAR_TASKS = 'CLEAR_TASKS'
export const ADD_TASK_MESSAGE = 'ADD_TASK_MESSAGE'
export const UPDATE_TASK_KEYWORDS = 'UPDATE_TASK_KEYWORDS'
export const UPDATE_TASK_NAME = 'UPDATE_TASK_NAME'
export const UPDATE_TASK_REQUIRED_RESULT = 'UPDATE_TASK_REQUIRED_RESULT'
export const UPDATE_TASK_DESCRIPTION = 'UPDATE_TASK_DESCRIPTION'
export const UPDATE_TASK_DUE_HOURS = 'UPDATE_TASK_DUE_HOURS'
export const UPDATE_TASK_COMPLETE = 'UPDATE_TASK_COMPLETE'
export const UPDATE_TASK_ADD_USER = 'UPDATE_TASK_ADD_USER'
export const UPDATE_TASK_REMOVE_USER = 'UPDATE_TASK_REMOVE_USER'
export const SET_TASK_CHECKLIST = 'SET_TASK_CHECKLIST'
export const CREATE_TASK_REMINDER = 'CREATE_TASK_REMINDER'
export const DELETE_TASK_REMINDER = 'DELETE_TASK_REMINDER'
export const UPDATE_TASK_REMINDER = 'UPDATE_TASK_REMINDER'
export const ADD_TASK_ATTACHMENTS = 'ADD_TASK_ATTACHMENTS'
export const DELETE_TASK_ATTACHMENT = 'DELETE_TASK_ATTACHMENT'



export function addTaskAttachments(data) {
    return {
        type: ADD_TASK_ATTACHMENTS,
        data
    }
}

export function deleteTaskAttachment(data) {
    return {
        type: DELETE_TASK_ATTACHMENT,
        data
    }
}



export function createTask(data) {
    return {
        type: CREATE_TASK,
        data
    }

}

export function setTasks(tasks) {
    return {
        type: SET_TASKS,
        tasks
    }
}
export function clearTasks() {
    return {
        type: CLEAR_TASKS
    }
}

export function setTask(task) {
    return {
        type: SET_TASK,
        task
    }
}
export function clearTask() {
    return {
        type: CLEAR_TASK,
    }
}
export function addTask(task) {
    return {
        type: ADD_TASK,
        task
    }
}
export function deleteTask(task) {
    return {
        type: DELETE_TASK,
        task
    }
}
export function updateTask(task) {
    return {
        type: UPDATE_TASK,
        task
    }
}
export function addTaskMessage(message) {
    return {
        type: ADD_TASK_MESSAGE,
        message
    }
}

export function updateTaskKeywords(data) {
    return {
        type: UPDATE_TASK_KEYWORDS,
        data
    }
}

export function updateTaskName(data) {
    return {
        type: UPDATE_TASK_NAME,
        data
    }
}

export function updateTaskRequiredResult(data) {
    return {
        type: UPDATE_TASK_REQUIRED_RESULT,
        data
    }
}

export function updateTaskDescription(data) {
    return {
        type: UPDATE_TASK_DESCRIPTION,
        data
    }
}

export function updateTaskDueHours(data) {
    return {
        type: UPDATE_TASK_DUE_HOURS,
        data
    }
}

export function updateTaskComplete(data) {
    return {
        type: UPDATE_TASK_COMPLETE,
        data
    }
}

export function updateTaskAddUser(data) {
    return {
        type: UPDATE_TASK_ADD_USER,
        data
    }
}

export function updateTaskRemoveUser(data) {
    return {
        type: UPDATE_TASK_REMOVE_USER,
        data
    }
}

export function setTaskChecklist(data) {
    return {
        type: SET_TASK_CHECKLIST,
        data
    }
}


export function createTaskReminder(reminder) {
    return {
        type: CREATE_TASK_REMINDER,
        reminder
    }
}
export function deleteTaskReminder(reminder) {
    return {
        type: DELETE_TASK_REMINDER,
        reminder
    }
}
export function updateTaskReminder(reminder) {
    return {
        type: UPDATE_TASK_REMINDER,
        reminder
    }
}