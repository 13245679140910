import React, { useEffect, useState } from 'react'
import {
    Typography,
    makeStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    withWidth,
    ListItem,
    List,
    Divider
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next'
import useReactRouter from 'use-react-router';
import { isMobile } from 'react-device-detect'
import {
    useSelector,
    useDispatch
} from 'react-redux'
import moment from 'moment'
import { longDateFormat } from 'lib'
import { Recaladas } from 'services'
import { setRecaladas } from 'actions/recaladasActions'
import { Warning, Schedule, Close, CheckCircle } from '@material-ui/icons'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    rootrow: {
        display: 'flex',
        marginBottom: -1,
        width: '100%',
        justifyContent: 'center',
        paddingTop: 5,
        paddingBottom: 5,
        border: 'solid thin #cdcdcd'
    },
    recaladas__container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    table__header: {
        background: '#dfe3e8',
    },
    tableCell: {
        whiteSpace: 'nowrap',
        padding: '10px'
    },
    dialog__paper: {
        width: '100%'
    },
    tasks__container: {
        marginBottom: theme.spacing(),
    },
    tasks__indicator__container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
    },
    list__item__card: {
        padding: 10,
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    'list__item__card--warning': {
        backgroundColor: 'rgba(255, 0, 0, 0.1)'
    },
    'list__item__card--success': {
        // backgroundColor: 'rgba(0, 255, 0, 0.1)'
    },
    list__item__card__icon__container: {
        // position: 'absolute',
        // right: theme.spacing(1),
        // top: theme.spacing(1)
    },
    list__item__dueDate: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
}))

const ActiveRecaladas = props => {
    const { width } = props,
        classes = useStyles(),
        recaladas = useSelector(state => state.recaladas),
        currentPort = useSelector(state => state.currentPort),
        [openDialog, setOpenDialog] = useState(false),
        [selectedRecalada, setSelectedRecalada] = useState(null),
        [fetching, setFetching] = useState(true),
        { history } = useReactRouter(),
        dispatch = useDispatch(),
        { t } = useTranslation();;


    useEffect(() => {
        let isMounted = true;

        Recaladas.getRecaladas({
            port: currentPort.code,
            getActiveRecaladas: true,
        }).then(response => {
            if (isMounted) {
                dispatch(setRecaladas(response.data.records.recaladas))
                setFetching(false);
            }
        })

        return () => {
            isMounted = false;
        }
    }, [currentPort, dispatch])

    if (!recaladas || (recaladas && recaladas.length === 0)) return null;

    if (fetching) return null;

    return (
        <div className={classes.root}>
            <div className={classes.recaladas__container}>
                {(width !== 'xs' && !isMobile) ? (
                    <Table size='small'>
                        <TableHead>
                            <TableRow className={classes.table__header}>
                                <TableCell
                                    align="center"
                                    className={classes.tableCell__header}
                                    style={{ paddingLeft: '24px' }}>
                                    {t('Liner')}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.tableCell__header}
                                    style={{ paddingLeft: '24px' }}>
                                    {t('Ship')}
                                </TableCell>
                                <TableCell
                                    className={classes.tableCell__header}
                                    align="center">
                                    {t('Voyage')}
                                </TableCell>
                                <TableCell
                                    className={classes.tableCell__header}
                                    align="center">
                                    {t('Status')}
                                </TableCell>
                                <TableCell
                                    className={classes.tableCell__header}
                                    align="center">
                                    {t('ETA / Arrive')}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.tableCell__header}>
                                    {t('ETD / Departure')}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    style={{ paddingRight: '24px' }}
                                    className={classes.tableCell__header}>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recaladas.map((recalada, i) => {
                                return (
                                    <TableRow
                                        hover
                                        key={recalada.recaladaId}
                                    >
                                        <TableCell
                                            className={classes.tableCell}
                                            style={{ maxWidth: 50, textAlign: 'center' }}>
                                            <Typography
                                                className={classes.typeText}
                                                variant="body2"
                                                color={'textPrimary'}
                                            >
                                                {recalada.linerName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{ maxWidth: 50, textAlign: 'center' }}
                                        >
                                            <Typography
                                                className={classes.typeText}
                                                variant="body2"
                                                color={'textPrimary'}
                                            >
                                                {recalada.ship.name}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            style={{ maxWidth: 50, textAlign: 'center' }}
                                        >
                                            <Typography
                                                className={classes.typeText}
                                                variant="body2"
                                                color={'textPrimary'}
                                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                onClick={() => {
                                                    history.push(`./recaladas/${recalada.recaladaId}`)
                                                }}
                                            >
                                                {`V-${recalada.itineraryName}`}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            style={{ maxWidth: 40, textAlign: 'center' }}
                                        >
                                            <Typography
                                                className={classes.typeText}
                                                variant="body2"
                                                style={moment(recalada.ETA).isBefore(moment()) && moment(recalada.departureDate).isAfter(moment()) ?
                                                    { fontSize: '14px', background: 'greenyellow', padding: '4px' } : {}}
                                                color={'textPrimary'}
                                            >
                                                {moment(recalada.departureDate).isBefore(moment()) ? t('Zarpó') :
                                                    moment(recalada.ETA).isBefore(moment()) && moment(recalada.departureDate).isAfter(moment()) ? t('In port') :
                                                        moment(recalada.ETA).isAfter(moment()) ? t('In transit') : ''
                                                }
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Typography
                                                variant='body2'
                                                color='textSecondary'
                                                style={{
                                                    color: moment(recalada.ETA).isBefore(moment()) ? 'green' : (moment(recalada.ETA).isAfter(moment()) ? '#0d87af' : 'grey'),
                                                }}
                                                className={classes.recalada__text__part}>
                                                {/* {moment(recalada.ETA).isBefore(moment(), 'day') ? t('Arrived') : t('ETA')}  */}
                                                {longDateFormat(recalada.ETA)}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Typography variant='body2'
                                                color='textSecondary'
                                                style={{
                                                    color: moment(recalada.departureDate).isBefore(moment()) ? 'green' : (moment(recalada.departureDate).isAfter(moment()) ? '#0d87af' : 'grey'),
                                                }}
                                                className={classes.recalada__text__part}>
                                                {recalada.departureDate ?
                                                    // `${moment(recalada.departureDate).isBefore(moment()) ? t('Zarpó') : t('ETD')} 
                                                    `${longDateFormat(recalada.departureDate)}` : ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCell}
                                            style={{ textAlign: 'center', maxWidth: 40 }}
                                            padding="checkbox">

                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setSelectedRecalada(recalada)
                                                    setOpenDialog(true)
                                                }}
                                                color='inherit'>
                                                {recalada.tasksCounters.totalDueTasks ?
                                                    <Warning style={{ color: 'red' }} />
                                                    : <>
                                                        {recalada.tasksCounters.totalAboutToDueTasks &&
                                                            <Schedule style={{ color: 'orange' }} />
                                                        }
                                                    </>}
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                ) :
                    <List
                        classes={{
                            root: classes.list__paper
                        }}>
                        {recaladas
                            .map((recalada, i) => (
                                <React.Fragment key={i}>
                                    <ListItem key={i}
                                        className={`${classes.list__item__card}`}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <Typography variant="body1" >
                                                {`${recalada.linerName} - `}<strong>{recalada.ship ? recalada.ship.name : ''} V-{recalada.itineraryName}</strong>
                                            </Typography>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography
                                                variant='body2'
                                                color='textSecondary'
                                                style={{
                                                    color: moment(recalada.ETA).isBefore(moment()) ? 'green' : (moment(recalada.ETA).isAfter(moment()) ? '#0d87af' : 'grey'),
                                                }}
                                                className={classes.recalada__text__part}>
                                                {`${moment(recalada.ETA).isBefore(moment(), 'day') ? t('Arrived') : t('ETA')} ${longDateFormat(recalada.ETA)}`}
                                            </Typography>

                                            <Typography variant='body2'
                                                color='textSecondary'
                                                style={{
                                                    color: moment(recalada.departureDate).isBefore(moment()) ? 'green' : (moment(recalada.departureDate).isAfter(moment()) ? '#0d87af' : 'grey'),
                                                }}
                                                className={classes.recalada__text__part}>
                                                {recalada.departureDate ?
                                                    `${moment(recalada.departureDate).isBefore(moment()) ? t('Zarpó') : t('ETD')} ${longDateFormat(recalada.departureDate)}` : ''}
                                            </Typography>
                                        </div>

                                        <div className={classes.list__item__dueDate}>
                                            <Typography
                                                className={classes.typeText}
                                                variant="body2"
                                                style={moment(recalada.ETA).isBefore(moment()) && moment(recalada.departureDate).isAfter(moment()) ?
                                                    { fontSize: '14px', background: 'greenyellow', padding: '4px' } : {}}
                                                color={'textPrimary'}
                                            >
                                                {moment(recalada.departureDate).isBefore(moment()) ? t('Zarpó') :
                                                    moment(recalada.ETA).isBefore(moment()) && moment(recalada.departureDate).isAfter(moment()) ? t('In port') :
                                                        moment(recalada.ETA).isAfter(moment()) ? t('In transit') : ''
                                                }
                                            </Typography>

                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setSelectedRecalada(recalada)
                                                    setOpenDialog(true)
                                                }}
                                                color='inherit'>
                                                {recalada.tasksCounters.totalDueTasks ?
                                                    <Warning style={{ color: 'red' }} />
                                                    : <>
                                                        {recalada.tasksCounters.totalAboutToDueTasks &&
                                                            <Schedule style={{ color: 'orange' }} />
                                                        }
                                                    </>}
                                            </IconButton>
                                        </div>

                                    </ListItem>
                                    <Divider style={{ width: '100%' }} />
                                </React.Fragment>
                            ))}
                    </List>
                }


                {selectedRecalada &&
                    <Dialog
                        onClose={() => {
                            setOpenDialog(false)
                            setSelectedRecalada(null)
                        }}
                        classes={{
                            paper: classes.dialog__paper
                        }}
                        maxWidth='xs'
                        open={openDialog}>
                        <DialogContent>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                                <Typography variant='h5'>
                                    {`${selectedRecalada.ship.name} V-${selectedRecalada.itineraryName}`}
                                </Typography>
                                <IconButton
                                    color='textSecondary'
                                    onClick={() => {
                                        setOpenDialog(false)
                                        setSelectedRecalada(null)
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </div>

                            <div className={classes.tasks__container} style={{ width: '220px' }}>
                                <div className={classes.tasks__indicator__container}>
                                    <Warning style={{ width: '14px', color: 'red', marginRight: '6px' }} />

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <Typography variant='body1' color='textSecondary'>
                                            {t('Tasks past due')}
                                        </Typography>
                                        <Typography variant='body1' color='textSecondary'>
                                            {selectedRecalada.tasksCounters.totalDueTasks ? selectedRecalada.tasksCounters.totalDueTasks : 0}
                                        </Typography>
                                    </div>
                                </div>

                                <div className={classes.tasks__indicator__container}>
                                    <Schedule style={{ width: '14px', color: 'orange', marginRight: '6px' }} />

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <Typography variant='body1' color='textSecondary'>
                                            {t('Tasks due soon')}
                                        </Typography>
                                        <Typography variant='body1' color='textSecondary'>
                                            {selectedRecalada.tasksCounters.totalAboutToDueTasks ? selectedRecalada.tasksCounters.totalAboutToDueTasks : 0}
                                        </Typography>
                                    </div>
                                </div>

                                <div className={classes.tasks__indicator__container}>
                                    <CheckCircle style={{ width: '14px', color: green[400], marginRight: '6px' }} />

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <Typography variant='body1' color='textSecondary'>
                                            {t('Tasks done')}
                                        </Typography>
                                        <Typography variant='body1' color='textSecondary'>
                                            {selectedRecalada.tasksCounters.totalDoneTasks ? selectedRecalada.tasksCounters.totalDoneTasks : 0}
                                        </Typography>
                                    </div>
                                </div>

                                <div className={classes.tasks__indicator__container}>
                                    <div style={{ width: '23px' }} />

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <Typography variant='body1' color='textSecondary'>
                                            {t('Total task')}
                                        </Typography>
                                        <Typography variant='body1' color='textSecondary'>
                                            {selectedRecalada.tasksCounters.totalTasks ? selectedRecalada.tasksCounters.totalTasks : 0}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    history.push(`./recaladas/${selectedRecalada.recaladaId}`)
                                }}
                                color='primary'>
                                {t('Go to Recalada')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </div>
        </div>
    )
}

export default withWidth()(ActiveRecaladas)