import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import {
  CircularProgress,
  Typography,
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';

import { Recaladas } from '../../services';

import {
  //RecaladaToolbar,
  RecaladasTable, RecaladaDetails
} from './components';

import styles from './style';

//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as recaladasActions from '../../actions/recaladasActions'
import * as recaladaActions from '../../actions/recaladaActions'

let timeout = null

class ShipsList extends Component {
  signal = true;

  state = {
    isLoading: true,
    limit: 10,
    selectedRecaladas: [],
    error: null,
    search: '',
    selectedEmail: null,
    showRecaladaDetails: false,
    title: this.props.t('Ships'),
    showBackMenuButton: false,
    from: undefined,
    to: undefined,
    fetching: false,
    ships: []
  };

  componentDidMount = () => {
    this.signal = true;
    if (this.state.isLoading && this.props.currentPort) {
      this.getRecaladas()
    }
  }

  getRecaladas() {
    let { search, port, from, to, fetching } = this.state,
      { match, currentPort, i18n } = this.props

    if (fetching) return

    this.setState({
      isLoading: true,
      fetching: true
    });

    if (!port && currentPort) {
      port = currentPort.code
    }

    Recaladas.getRecaladas({
      search,
      port,
      from,
      to
    })
      .then(response => {
        this.props.recaladasActions.setRecaladas(response.data.records.recaladas)

        this.setState({
          ships: response.data.records.ships.sort((a, b) => {
            if (!a.ETA && !b.ETA) return 1;
            if (!a.ETA && b.ETA) return 1;
            if (a.ETA && !b.ETA) return -1;
            if (a.ETA < b.ETA) return -1;
            if (a.ETA > b.ETA) return 1;
            return 0;
          })
        })

        if (match && match.params.recaladaId) {
          let recalada = response.data.records.recaladas.find(r => `${r.recaladaId}` === match.params.recaladaId)
          if (recalada) {
            this.props.recaladaActions.setRecalada(recalada ? recalada : null)
            this.setState({
              showRecaladaDetails: true
            })
          } else {
            this.props.history.push(`/${i18n.language}/${currentPort ? currentPort.code : match.params.port}/recaladas`)
          }
        }

        this.setState({
          isLoading: false,
          fetching: false
        })

      }).catch(err => {
        console.log(err)
        this.setState({
          isLoading: false,
          fetching: false
        })
      })
  }

  componentWillReceiveProps = (nextProps) => {
    const { match, recalada, recaladas, t, i18n, currentPort } = nextProps

    if (match && recaladas && recaladas.length > 0 && match.params.recaladaId && !recalada.recaladaId) {


      let recalada = recaladas.find(r => `${r.recaladaId}` === match.params.recaladaId)
      //Todo: Esto hay que cambiarlo. El detalle de una recalada debe cargarse independientemente de las recaladas "padre"
      //Si hay recalada significa que el id al que tratamos de acceder pertenece a las recaladas activas
      if (recalada) {
        this.props.recaladaActions.setRecalada(recalada ? recalada : null)
      } else {
        //Sino significa que es de una recalada no activa, entonces regresamos a recaladas para que el usuario vea las activas
        this.props.history.push(`/${i18n.language}/${currentPort ? currentPort.code : match.params.port}/recaladas`)
        return;
      }
    }

    if (match && match.params.recaladaId && recalada.recaladaId) {
      if (recaladas.length > 0) {
        this.setState({
          showRecaladaDetails: true,
          title: t('Ship'),
          showBackMenuButton: true
        })
      } else {
        this.props.history.push(`/${i18n.language}/${currentPort.code}/recaladas`)
      }
    }

    if (match && !match.params.recaladaId && recalada.recaladaId) {
      this.props.recaladaActions.setRecalada(null)
      this.setState({
        showRecaladaDetails: false,
        title: t('Ships'),
        showBackMenuButton: false
      })
    }

    if ((!this.props.currentPort && nextProps.currentPort) || (this.props.currentPort && nextProps.currentPort && nextProps.currentPort.code && this.props.currentPort.code !== nextProps.currentPort.code)) {
      this.setState({
        port: nextProps.currentPort.code
      }, () => {
        this.getRecaladas()
      })
    } else if (this.state.isLoading) {
      this.getRecaladas()
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSelect = selectedRecaladas => {
    this.setState({ selectedRecaladas });
  };

  handleSearch = (e) => {
    this.setState({
      search: e.target.value
    })

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      this.getRecaladas()

      clearTimeout(timeout);
      timeout = null;
    }, 700)
  }

  openEmailDetails = (emails, emailId) => {
    this.setState({
      selectedEmail: emails.find(e => e.emailId === emailId)
    })
  }

  closeEmailDetail = () => {
    this.setState({
      selectedEmail: null
    })
  }

  componentWillUnmount = () => {
    this.props.recaladaActions.setRecalada(null)
  }
  handleChangeFilter = (filter) => {
    this.setState({
      from: filter.from.format('YYYY-MM-DD'),
      to: filter.to.format('YYYY-MM-DD')
    }, () => {
      this.getRecaladas();
    })
  }

  render() {
    const { classes, t, recaladas, i18n, currentPort, match } = this.props;
    const {
      // selectedRecaladas,
      isLoading,
      error,
      showRecaladaDetails,
      title,
      showBackMenuButton,
      from,
      to,
      ships
    } = this.state;

    return (
      <DashboardLayout
        title={title}
        showBackMenuButton={showBackMenuButton}
        goBackUrl={`/${i18n.language}/${currentPort ? currentPort.code : match.params.port}/recaladas`}>

        {showRecaladaDetails ?
          <RecaladaDetails />
          :
          <div className={classes.root}>

            {
              // !isLoading &&
              // <RecaladaToolbar
              //   selectedRecaladas={selectedRecaladas}
              //   handleSearch={this.handleSearch}
              //   handleChangeFilter={this.handleChangeFilter}
              // />
            }

            <div className={classes.content}>
              {isLoading &&
                <div className={classes.progressWrapper}>
                  <CircularProgress />
                </div>
              }

              {error &&
                <Typography variant="h6">{error}</Typography>
              }

              {recaladas && recaladas.length === 0 && !isLoading &&
                <div className={classes.noRecaladas__container}>
                  <Typography variant="h6">{
                    !from ? t('There are no recaladas') : t('There are no recaladas between {{from}} and {{to}}', { from, to })
                  }</Typography>
                </div>
              }

              {!isLoading && !error && ((recaladas && recaladas.length > 0) || (ships && ships.length > 0)) &&
                <RecaladasTable
                  onSelect={this.handleSelect}
                  openEmailDetails={this.openEmailDetails}
                  ships={ships}
                />
              }
            </div>
          </div>
        }
      </DashboardLayout>
    );
  }
}

ShipsList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};


function mapDispatchToProps(dispatch) {
  return {
    recaladasActions: bindActionCreators(recaladasActions, dispatch),
    recaladaActions: bindActionCreators(recaladaActions, dispatch),
  }
}


function mapStateToProps(state, ownProps) {
  return {
    recaladas: state.recaladas,
    recalada: state.recalada,
    currentPort: state.currentPort
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(withStyles(styles)(ShipsList))));
