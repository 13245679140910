import React, { useEffect, useState } from 'react'
import {
    Paper,
    IconButton,
    Button,
    Typography,
    Divider,
    makeStyles,
    Link,
    CircularProgress
} from '@material-ui/core'
import {
    Delete,
    // Archive,
    MoveToInbox,
    KeyboardArrowLeft,
    InsertDriveFile,
} from '@material-ui/icons'
import { Emails } from '../../../../services';
import { AttachmentPreview } from '..'
import { EmailTaskSelection } from '..'

import i18n from 'i18next'
import moment from 'moment'


//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as recaladasActions from '../../../../actions/recaladasActions'
import { ConfirmationDialog } from '../../../../components';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    email__actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing()
        }
    },
    email__paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: theme.spacing(3)
    },
    email__sender__container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    email__sender__timestamp: {

    },
    email__sender: {
        fontWeight: 'bold',
        textAlign: 'left'
    },
    email__recipient: {

    },
    email__subject: {
        fontWeight: 'bold',
        textAlign: 'left'
    },
    email__body: {
        textAlign: 'left',
        whiteSpace: 'pre-line'
    },
    attachments__container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    attachment: {
        maxWidth: 150,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer'
    },
    attachments__conponents: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    search__root: {
        margin: '10px 0',
        maxWidth: 400,
        width: '100%',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    search__input: {
        width: '90%'
    },
    file__list__item: {
        cursor: 'pointer',
        border: 'solid thin transparent',
        '&:hover': {
            backgroundColor: '#eeeeee',
            boxShadow: '3px 3px #dddddd',
            border: 'solid thin #dddddd'
        }
    },
    dialog__paper: {
        width: '100%'
    },
    assign__button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary
    },
    actionsButtons: {
        color: theme.palette.text.secondary
    },
}))

function EmailDetails(props) {
    let { history, folderId, isRecaladaSummary, changeFolder, currentPort } = props;
    const classes = useStyles();
    const [fetching, setFetching] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [selectedAttachment, setSelectedAttachment] = useState(null)
    const [openAttachmentPreview, setOpenAttachmentPreview] = useState(false)

    const { selectedEmail, updateEmails } = props;


    const handleBack = () => {
        props.back && props.back()
    }
    const formatedDate = (date) => {
        if (moment().isSame(moment(date), 'day')) {
            return moment(date).format('hh:mm a')
        } else {
            return moment(date).format('MMM DD')
        }
    }

    useEffect(() => {
        if (selectedEmail && !selectedEmail.read) {
            Emails.markEmail({
                read: 1,
                folderId: selectedEmail.folderId,
                emailId: selectedEmail.emailId
            }).then(updatedResponse => {
                let updatedEmail = updatedResponse.data.record;
                updateEmails && updateEmails(updatedEmail)
            })
        }
    }, [selectedEmail, updateEmails])

    const changeEmailsFolder = (folderId) => {
        setFetching(true)
        let newFolder = props.folders.find(f => f.id === folderId);

        Emails.changeEmailsFolder({
            body: {
                emailsIds: [props.selectedEmail.emailId],
                folderId: folderId
            }
        })
            .then(response => {
                history.push(`/${props.match.params.lang}/${currentPort.code}/emails/${newFolder.name.toLowerCase()}/${props.selectedEmail.emailId}`);
                setFetching(false)
            }).catch(err => {
                console.log(err);
            })
    }

    const cancelConfirmation = () => {
        setOpenConfirmation(false)
	}
	
	const onConfirm=()=>{
        setOpenConfirmation(true)
	}


    return (
        <div className={classes.root}>
            <div className={classes.email__actions}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flex: 1, background: '#f8fafc', zIndex: '1000'}}>
                        <IconButton onClick={handleBack}>
                            <KeyboardArrowLeft />
                        </IconButton>

                        {!isRecaladaSummary && <>
                            {fetching ?
                                <CircularProgress color='secondary' size={16} />
                                : <>
                                    {folderId !== 1 &&
                                        <Button className={classes.actionsButtons} style={{ marginRight: '8px' }}
                                            onClick={() => changeEmailsFolder(1)}>
                                            <MoveToInbox /> <div style={{fontSize: '12px', margin: '2px 0 0 3px'}}>{i18n.t("Move to inbox")}</div>
                                        </Button>
                                    }

                                    {
                                    // folderId !== 2 &&
                                    //     <IconButton className={classes.actionsButtons} style={{ marginRight: '8px' }}
                                    //         onClick={() => changeEmailsFolder(2)}>
                                    //         <Archive />
                                    //     </IconButton>
                                    }

                                    {folderId !== 3 &&
                                        <Button className={classes.actionsButtons}
                                            onClick={onConfirm}>
                                            <Delete />
                                            <div style={{fontSize: '12px', margin: '2px 0 0 3px'}}>{i18n.t("Move to trash")}</div>
                                        </Button>
                                    }
                                </>}
                        </>}
                    </div>
                    {!fetching && !isRecaladaSummary && (
                        <div style={{ justifySelf: 'flex-end' }}>
                            <EmailTaskSelection email={props.selectedEmail} updateEmails={updateEmails} />
                        </div>
                    )}
                </div>
            </div>
            <Paper className={classes.email__paper}>
                <div className={classes.email__sender__container}>
                    <Typography variant='body1' color='textSecondary' className={classes.email__sender}>
                        {props.selectedEmail.senderWithFormat ? props.selectedEmail.senderWithFormat : props.selectedEmail.sender}
                    </Typography>
                    <Typography variant='body2' color='textSecondary' className={classes.email__sender__timestamp}>
                        {formatedDate(props.selectedEmail.timestamp)}
                    </Typography>
                </div>
                <Typography variant='body1' color='textSecondary' className={classes.email__recipient}>
                    {i18n.t('To')} {props.selectedEmail.recipient}
                </Typography>
                <Divider style={{ width: '100%' }} />
                <br />
                <Typography variant='h6' className={classes.email__subject}>
                    {props.selectedEmail.subject}
                </Typography>
                <br />
                <Typography variant='body1' className={classes.email__body}>
                    {props.selectedEmail.bodyPlain}
                </Typography>
                {props.selectedEmail.attachments && props.selectedEmail.attachments.length > 0 && (
                    <>
                        <br />
                        <Divider style={{ width: '100%' }} />
                        <br />
                        <div className={classes.attachments__container}>
                            {props.selectedEmail.attachments.map(att => (
                                <Link key={att.id}
                                    className={classes.attachment}
                                    color='textSecondary'
                                    onClick={() => {
                                        setSelectedAttachment(att)
                                        setOpenAttachmentPreview(true)
                                    }} variant='button'>
                                    <div className={classes.attachments__conponents}>
                                        <InsertDriveFile color='secondary' className={classes.notes__list__edit__btn__icon} />
                                        <Typography variant='body2' noWrap>{att.name}</Typography>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </>
                )}
            </Paper>

            <AttachmentPreview
                selectedAttachment={selectedAttachment}
                open={openAttachmentPreview}
                close={() => {
                    setSelectedAttachment(null)
                    setOpenAttachmentPreview(false)
                }} />

            <ConfirmationDialog
                handleClose={cancelConfirmation}
                onConfirm={()=>{
                    setOpenConfirmation(false)
                    changeFolder(3)
                }}
                message={i18n.t('Are you sure you wish to move this email to trash?')}
                cancelLabel={i18n.t('Cancel')}
                confirmLabel={i18n.t('Move to trash')}
                open={openConfirmation}
            />
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        recaladasActions: bindActionCreators(recaladasActions, dispatch),
    }
}


function mapStateToProps(state, ownProps) {
    return {
        recaladas: state.recaladas,
        currentPort: state.currentPort
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailDetails);