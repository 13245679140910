import React, { useState, useEffect } from 'react';

// Externals
import 'moment/locale/es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { compose } from 'recompose'


// Material helpers
import {
  withStyles,
  withWidth,
  Link
} from '@material-ui/core';

// Material components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';

// Shared components
import { Portlet, PortletContent } from 'components';

//Redux
import { connect } from 'react-redux'

// Component styles
import styles from './styles';

function TemplatesList(props) {
  const { generalParameters, classes, setSelectedTemplate, currentPort, width } = props;
  const [servicesTemplates, setServicesTemplates] = useState([]),
    { t } = useTranslation();


  useEffect(() => {
    if (generalParameters && generalParameters.servicesTemplates && currentPort) {
      setServicesTemplates(generalParameters.servicesTemplates.filter(t => t.portCode === currentPort.code))
    }
  }, [setServicesTemplates, generalParameters, generalParameters.servicesTemplates, currentPort])

  return (
    <>
      {(width !== 'xs' && !isMobile) ? (
        <Portlet>
          <PortletContent noPadding>
            <PerfectScrollbar>
              <Table>
                <TableHead>
                  <TableRow className={classes.recalada__header}>
                    <TableCell
                      align="left"
                      className={classes.tableCell__header}
                      style={{ paddingLeft: '24px' }}>
                      {t('Name')}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell__header}>
                      {t('Task Templates')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {servicesTemplates
                    .map((template, i) => (
                      <TableRow
                        className={classes.tableRow}
                        onClick={() => setSelectedTemplate(template)}
                        hover
                        key={i}
                      >
                        <TableCell className={classes.tableCell}>
                          <div className={classes.tableCellInner}>
                            <Link to="#">
                              <Typography
                                className={classes.nameText}
                                variant="body1"
                              >
                                {template.name}
                              </Typography>

                            </Link>
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Typography
                            variant="body1"
                          >
                            {template.taskTemplates ? template.taskTemplates.length : ''}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </PortletContent>
        </Portlet>
      ) : (
          <List
            classes={{ root: classes.list__paper }}>
            {servicesTemplates
              .map((template, i) => (
                <React.Fragment key={i}>
                  <ListItem key={i}
                    className={classes.list__item__card}
                    onClick={() => setSelectedTemplate(template)}
                  >
                    <Typography
                      variant="body1"
                    >
                      <strong>{template.name}</strong>
                    </Typography>
                    <br />
                    <Typography
                      variant="body1"
                    >
                      {template.taskTemplates && template.taskTemplates.length > 0 ? template.taskTemplates.length === 1 ? `${template.taskTemplates.length}  ${t('Task Template')}` : `${template.taskTemplates.length}  ${t('Task Templates')}` : ''}
                    </Typography>
                  </ListItem>
                  <Divider style={{ width: '100%' }} />
                </React.Fragment>
              ))}
          </List>
        )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    generalParameters: state.generalParameters,
    currentPort: state.currentPort
  }
}

export default compose(
  connect(mapStateToProps, null),
  withStyles(styles),
  withWidth())(TemplatesList);
