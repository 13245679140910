import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import {
    Button,
    CircularProgress,
    Link,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core'
import styles from './styles';
import auth from '../../authentication/auth'



const ForgotPassword = (props) => {
    const { authState, classes, t, credentials, authData } = props;

    const [inputs, setInputs] = useState({ email: credentials && credentials.username ? credentials.credentials : '' })
    const [fetching, setFetching] = useState(false)
    const [validationErrors, setValidationErrors] = useState({})
    const [responseErrors, setResponseErrors] = useState(null)
    const [secretEmail, setSecretEmail] = useState(null)
    const [showResendCounter, setShowResendCounter] = useState(false)
    const [counter, setCounter] = useState(60)

    useEffect(() => {
        if (authData && !inputs.email) {
            setInputs({
                ...inputs,
                email: authData
            })
        }
    }, [authData, setInputs, inputs])

    const handleInputChange = event => {
        event.persist();

        setInputs({
            ...inputs,
            [event.target.name]: event.target.type !== 'checkbox' ? event.target.value : event.target.checked
        })
    }

    const validateEmail = (email) => {
        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const resendCode = (e) => {
        e.stopPropagation()

        setFetching(true)
        setShowResendCounter(true)
        setResponseErrors(null)

        let count = 60;
        let timer = setInterval(() => {
            if (count === 1) {

                setShowResendCounter(false)

                clearInterval(timer)
                timer = null
                count = 60;
            } else {
                count = count - 1;
            }
            setCounter(count)

        }, 1000)


        auth.forgotPassword({ username: inputs.email })
            .then(_ => {
                setFetching(false)
            })
            .catch(err => {
                setFetching(false)

                if (err.code === 'UserNotFoundException') {
                    setResponseErrors(<span style={{ maxWidth: '250px' }}>
                        {t('User is not registered. Contact whit admin to create an account')}
                    </span>)
                }
                console.log(err)
            })
    }


    const onSubmit = e => {
        e.preventDefault();

        setResponseErrors(null)
        setFetching(true)

        auth.forgotPassword({ username: inputs.email })
            .then(response => {
                setSecretEmail(response.CodeDeliveryDetails.Destination)
                setFetching(false)
            })
            .catch(err => {
                setFetching(false)

                if (err.code === 'UserNotFoundException') {
                    setResponseErrors(<span style={{ maxWidth: '250px' }}>
                        {t('User is not registered. Contact whit admin to create an account')}</span>)

                } else {
                    setResponseErrors(`${t('Uncaught error')}: ${err.message}`)
                }
                console.log(err)
            })
    }


    const backToSignIn = () => {
        setFetching(false)
        setResponseErrors(null)
        setValidationErrors({})
        setInputs({ email: '' })

        props.onStateChange('signIn');
    }

    if (authState !== "forgotPassword") return null;

    return (
        <div>
            <form
                className={classes.form}
                onSubmit={onSubmit}
                noValidate
                autoComplete='false'
            >
                <img
                    height="90"
                    style={{ margin: '0 auto' }}
                    alt="CTSA"
                    className={classes.logoImage}
                    src="/images/logos/logoCTSA.jpg"
                />

                <Typography
                    className={classes.title}
                    variant={secretEmail ? "h5" : "h2"}
                >
                    {secretEmail ?
                        t('We have sent you an email with instructions for resetting your password')
                        :
                        t('Forgot Password')
                    }
                </Typography>

                {secretEmail && <>
                    {!showResendCounter ? (<div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='body2' color='textSecondary' style={{ marginTop: '16px', marginRight: '8px' }}>
                            {t('Didn\'t you receive the email?')}
                        </Typography>
                        <Link component='button'
                            variant="body2"
                            color="primary"
                            style={{ fontSize: '14px', marginTop: '16px' }}
                            onClick={(e) => resendCode(e)}>
                            {t('Resend email')}
                        </Link>
                    </div>) : (
                            <Typography variant='body2' color='textSecondary' style={{ marginTop: '16px' }}>
                                {t('Resend email in')} {counter} {t('second')}
                            </Typography>
                        )}
                </>}

                {!secretEmail && <>
                    <Typography
                        className={classes.forgot__form__description}
                        style={{ marginTop: '8px' }}
                        variant="h6">
                        {t('Enter your email and we will send you instructions to recover your password')}
                    </Typography>

                    <div className={classes.fields}>
                        <TextField
                            disabled={fetching}
                            required
                            fullWidth
                            id="email"
                            label={t('Email')}
                            name="email"
                            autoComplete="off"
                            onChange={handleInputChange}
                            value={inputs.email}
                            variant="outlined"
                            error={validationErrors.email ? true : false || (inputs.email && !validateEmail(inputs.email))}
                            InputLabelProps={{ shrink: true }}
                        />
                        {validationErrors.email && (
                            <Typography className={classes.fieldError} variant='body2' >
                                {validationErrors.email}
                            </Typography>
                        )}
                        {responseErrors && (
                            <Typography
                                className={classes.fieldError}
                                variant="body2"
                            >
                                {responseErrors}
                            </Typography>
                        )}

                        <div style={{ textAlign: 'center' }}>
                            <Button
                                className={classes.signInButton}
                                variant="contained"
                                color="primary"
                                type="submit"
                                size="large"
                                disabled={fetching || !inputs.email || (inputs.email && !validateEmail(inputs.email))}>
                                {t('Send instructions')}
                                {fetching && (
                                    <CircularProgress
                                        size={16}
                                        style={{
                                            marginLeft: '5px',
                                            color: 'grey'
                                        }} />
                                )}
                            </Button>
                        </div>
                    </div>
                </>}

                <Link component='button'
                    variant="body2"
                    color="primary"
                    style={{ marginTop: '16px', fontSize: '14px' }}
                    onClick={backToSignIn}>
                    {t('Back to Sign In')}
                </Link>
            </form>
        </div>
    )
}

export default withTranslation()(withStyles(styles)(ForgotPassword))