export default theme => ({
    root: {

    },
    keywords__container: {
        padding: theme.spacing(),
        borderRadius: 5,
        backgroundColor: '#f4f4f4',
        margin: `${theme.spacing(2)}px 0`,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    actions: {
        marginTop: theme.spacing(),
        display: 'flex'
    },
    keywords__textfield: {
        padding: theme.spacing(),
        marginRigth: 5
    },
    keyword__chip: {
        marginRight: 10,
        marginBottom: 10,
        marginTop: 10,
    },
    add__keyword__actions__container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 10,
        marginTop: 10,
    },
    actions__save: {
        marginRight: 10
    },
})
