import React, { Component } from 'react';

// Externals
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect'

import {
    withStyles,
    Typography,
    Hidden,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    List,
    ListItem,
    Divider,
    Grid,
    Button
} from '@material-ui/core';
import {
    InsertDriveFileOutlined,
    ImageOutlined,
    EmailOutlined,
    CloudUploadOutlined,
    PictureAsPdfOutlined,
    Close
} from '@material-ui/icons'
import moment from "moment"
import { AttachmentPreview } from '../../../Emails/components'

// Component styles
import styles from './styles';

//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as recaladaActions from '../../../../actions/recaladaActions'
import RecaladaTaskTypeFilter from 'components/RecaladaTaskTypeFilter';

const imagesExt = ['jpg', 'png', 'jpeg', 'gif', 'bmp', 'svg']

class ShipDetails extends Component {

    state = {
        documents: this.props.recalada && this.props.recalada.documents ? this.props.recalada.documents.filter(doc => !doc.name.toLowerCase().startsWith('image')) : [],
        originalDocuments: this.props.recalada && this.props.recalada.documents ? this.props.recalada.documents.filter(doc => !doc.name.toLowerCase().startsWith('image')) : [],
        selectedDocument: null,
        selectedType: null
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            documents: nextProps.recalada && nextProps.recalada.documents ? nextProps.recalada.documents.filter(doc => !doc.name.toLowerCase().startsWith('image')) : [],
            originalDocuments: nextProps.recalada && nextProps.recalada.documents ? nextProps.recalada.documents.filter(doc => !doc.name.toLowerCase().startsWith('image')) : []
        })
    }


    formatedDate = (date) => {
        const { i18n } = this.props

        if (moment().isSame(moment(date), 'day')) {
            return moment(date).format('hh:mm a')
        } else {
            if (i18n.language === 'es') {
                return moment(date).format('DD/MM hh:mm a')
            } else {
                return moment(date).format('MM/DD hh:mm a')
            }
        }
    }

    handleClickDocument = (id) => {
        const { documents } = this.state

        this.setState({
            selectedDocument: documents.find(doc => doc.id === id)
        })

    }

    closeDocumentDetail = () => {
        this.setState({
            selectedDocument: null
        })
    }

    handleTypeFilter = (type) => {
        const { originalDocuments } = this.state;

        let newDocuments = [...originalDocuments];

        if (type) {
            newDocuments = newDocuments.filter(doc => type === 5 ? !doc.taskTypeId : doc.taskTypeId === type)
        }

        this.setState({
            documents: newDocuments
        })
    }

    render() {
        const { classes, t } = this.props,
            { documents, selectedDocument, originalDocuments } = this.state;

        return (
            <div style={{ position: 'relative' }} >
                <div style={{ padding: isMobile ? '16px 8px' : '0 0 16px 0', display: 'flex', justifyContent: originalDocuments && originalDocuments.length > 0 ? 'space-between' : 'flex-end', alignItems: 'center' }}>
                    {originalDocuments && originalDocuments.length > 0 &&
                        <RecaladaTaskTypeFilter
                            onChange={this.handleTypeFilter}
                        />
                    }
                    <div>
                        {/* <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={() => { alert('No implementado aun') }}
                        >
                            {t('Add Document')}
                        </Button> */}
                    </div>
                </div>
                {documents && documents.length > 0 ? <>
                    <Hidden xsDown implementation="css">
                        <Table>
                            <TableHead>
                                <TableRow className={classes.recalada__header}>
                                    <TableCell
                                        align="left"
                                        className={classes.tableCell__header}>
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className={classes.tableCell__header}
                                        style={{ paddingLeft: '24px' }}>
                                        {t('Name')}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className={classes.tableCell__header}>
                                        {t('Task')}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className={classes.tableCell__header}>
                                        {t('Source')}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={classes.tableCell__header}>
                                        {t('Date')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documents.sort((a, b) => {
                                    if (a.timestamp > b.timestamp) {
                                        return -1;
                                    }
                                    if (b.timestamp > a.timestamp) {
                                        return 1;
                                    }
                                    return 0;
                                }).map((document, i) => {
                                    let urlParts = document.name.split('.'),
                                        ext = urlParts[urlParts.length - 1],
                                        isImage = imagesExt.includes(ext.toLowerCase())

                                    urlParts.pop()

                                    return (
                                        <TableRow
                                            className={classes.tableRow}
                                            hover
                                            key={document.id}
                                            onClick={() => { this.handleClickDocument(document.id) }}
                                        >
                                            <TableCell className={classes.tableCell} padding='none'>
                                                <div style={{ display: 'flex' }}>
                                                    {isImage &&
                                                        <ImageOutlined className={classes.docs__icons} style={{ width: '30px', height: '30px', color: '#a6b1bb' }} />
                                                    }

                                                    {ext.toLowerCase() === 'pdf' &&
                                                        <PictureAsPdfOutlined className={classes.docs__icons} style={{ width: '30px', height: '30px', color: '#f44336' }} />
                                                    }

                                                    {!isImage && ext.toLowerCase() !== 'pdf' &&
                                                        <div style={{ position: 'relative' }}>
                                                            <InsertDriveFileOutlined className={classes.docs__icons} style={{ width: '30px', height: '30px', color: ext.toLowerCase().includes('doc') ? 'primary' : ext.toLowerCase().includes('xls') || ext.toLowerCase().includes('csv') ? 'green' : 'inherit' }} />
                                                            <Typography color='textSecondary' style={{ position: 'absolute', top: '8px', left: '8px', fontSize: '9px' }}>
                                                                {ext.toLowerCase()}
                                                            </Typography>
                                                        </div>
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{ maxWidth: 250 }}>
                                                <div className={classes.tableCellInner}>
                                                    <Typography color="textPrimary" variant='body1' noWrap>
                                                        <strong>{urlParts.join(' ')}</strong>
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Typography color="textSecondary" variant='body2'>
                                                    {document.taskName ? document.taskName : <Close color={'error'} />}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {document.emailId ?
                                                    <Typography color='textSecondary' variant='body2'>
                                                        {document.emailSender}
                                                    </Typography>
                                                    :
                                                    <Typography color='textSecondary' variant='body2'>
                                                        {`${document.authorUserFirstName} ${document.authorUserLastName}`}
                                                    </Typography>
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align='center' style={{ color: '#999' }}>
                                                <Typography color="textSecondary" variant='body2'>
                                                    {this.formatedDate(document.timestamp)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Hidden>
                    <Hidden smUp implementation="css">
                        <List
                            className={classes.list}>
                            {documents.map(document => {
                                let urlParts = document.name.split('.'),
                                    ext = urlParts[urlParts.length - 1],
                                    isImage = imagesExt.includes(ext.toLowerCase())

                                urlParts.pop()

                                return (
                                    <React.Fragment key={document.id}>
                                        <ListItem button
                                            key={document.id}
                                            onClick={() => this.handleClickDocument(document.id)}
                                            className={classes.listItem}>
                                            <div style={{
                                                width: '100%',
                                                position: 'relative'
                                            }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={1}>
                                                        <div style={{ display: 'flex' }}>
                                                            {isImage &&
                                                                <ImageOutlined className={classes.docs__icons} style={{ width: '30px', height: '30px', color: '#a6b1bb' }} />
                                                            }

                                                            {ext.toLowerCase() === 'pdf' &&
                                                                <PictureAsPdfOutlined className={classes.docs__icons} style={{ width: '30px', height: '30px', color: '#f44336' }} />
                                                            }

                                                            {!isImage && ext.toLowerCase() !== 'pdf' &&
                                                                <div style={{ position: 'relative' }}>
                                                                    <InsertDriveFileOutlined className={classes.docs__icons}
                                                                        style={{ width: '30px', height: '30px', color: ext.toLowerCase().includes('doc') ? 'primary' : ext.toLowerCase().includes('xls') || ext.toLowerCase().includes('csv') ? 'green' : 'inherit' }} />
                                                                    <Typography color='textSecondary' style={{ position: 'absolute', top: '8px', left: '8.5px', fontSize: '9px' }}>
                                                                        {ext.toLowerCase()}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={11} style={{ paddingLeft: '10px' }}>
                                                        <Typography color="textPrimary" variant='body1' noWrap>
                                                            <strong>{urlParts.join(' ')}</strong>
                                                        </Typography>
                                                        <Typography color="textSecondary" variant='body2'>
                                                            {this.formatedDate(document.timestamp)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography color="textSecondary" variant='body2'>
                                                            {document.taskName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {document.emailId ?
                                                            <Typography color='textSecondary' variant='body2' style={{ display: 'flex', alignItems: 'center' }}>
                                                                <EmailOutlined style={{ width: '12px', marginRight: '4px' }} />
                                                                {document.emailSender}
                                                            </Typography>
                                                            :
                                                            <Typography color='textSecondary' variant='body2' style={{ display: 'flex', alignItems: 'center' }}>
                                                                <CloudUploadOutlined style={{ width: '12px', marginRight: '4px' }} />
                                                                {`${document.authorUserFirstName} ${document.authorUserLastName}`}
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                    {/* <Grid item xs={12}>
                                                        {document.emailId &&
                                                            <Typography color="textSecondary" variant='body2'>
                                                                {document.emailSubject.toLowerCase()}
                                                            </Typography>
                                                        }
                                                    </Grid> */}
                                                </Grid>
                                            </div>
                                        </ListItem>
                                        <Divider style={{ width: '100%', heigth: 5, margin: 0 }} />
                                    </React.Fragment>
                                )
                            })}
                        </List>
                    </Hidden>
                </> :
                    <div style={{
                        width: '100%',
                        margin: '24px auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 300,
                    }}>
                        <Typography variant="h6">
                            {t('No documents')}
                        </Typography>
                    </div>
                }

                <AttachmentPreview
                    selectedAttachment={selectedDocument}
                    open={selectedDocument ? true : false}
                    close={this.closeDocumentDetail}
                />

            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        recaladaActions: bindActionCreators(recaladaActions, dispatch)
    }
}


function mapStateToProps(state, ownProps) {
    return {
        recalada: state.recalada
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withStyles(styles)(ShipDetails))));
